import moment from 'moment';
import React from 'react';
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from 'availity-reactstrap-validation';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Grid,
  Segment,
  Modal,
  Button,
  Icon,
  Dropdown,
} from 'semantic-ui-react';
import { map, get } from '../../../utils/lodash';
import { EmptyContainer, CustomTable } from '../../../components';
import {
  getBroadcastNotifications,
  createBroadcastNotifications,
  getNotifications,
  getOrganizationUsers,
  updateNotificationReadStatus,
} from '../../../store/actions';
import './organization.scss';

class NotificationForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      usersError: false,
      isChecked: true,
    };
  }

  handleValidSubmit(event, values) {
    const { isChecked, users } = this.state;
    const { toggle, createBroadcastNotifications, resetPage } = this.props;

    if (!isChecked && !users.length) {
      this.setState({ usersError: true });
    } else {
      values.isAllUsers = isChecked;
      values.users = users;
      values.isOwnOrganisation = true;
      createBroadcastNotifications(values);
      this.form && this.form.reset();
      if (resetPage) resetPage();
      toggle();
    }
  }

  getUsersOptions() {
    let { orgUsers } = this.props;
    return map(orgUsers, (item) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));
  }

  async onChangeUsers(event, data) {
    this.setState({
      users: data.value,
      usersError: !data.value.length,
    });
  }

  async onChangeCheckBox() {
    await this.setState((prevState) => ({
      isChecked: !prevState.isChecked,
    }));
  }

  render() {
    const { usersError, isChecked } = this.state;
    const { toggle } = this.props;
    const options = this.getUsersOptions();
    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        className="broadcast-form"
      >
        <AvField
          label="Title"
          className="org-inputs"
          name="title"
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: 'Title is required',
            },
            maxLength: {
              value: 100,
            },
          }}
        />
        <AvField
          label="Message"
          className="org-inputs-textarea"
          name="message"
          type="textarea"
          validate={{
            required: {
              value: true,
              errorMessage: 'message is required',
            },
          }}
        />

        <AvCheckboxGroup name="isAllUsers">
          <AvCheckbox
            label="Send to All Users"
            checked={isChecked}
            onChange={(e) => this.onChangeCheckBox()}
          />
        </AvCheckboxGroup>

        {!isChecked ? (
          <div className="greenlight-broadcast">
            <label>SELECT USERS</label>
            <Dropdown
              fluid
              multiple
              search
              selection
              options={options}
              onChange={(event, data) => this.onChangeUsers(event, data)}
              error={usersError}
              placeholder="Select Users"
              className="broadcast-dropdown"
            />
            {usersError ? (
              <div className="invalid-dropdown-input">
                Select user is required
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button content="Send" type="submit" className="Primary-Button" />
        </div>
      </AvForm>
    );
  }
}

class CreateNotification extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    return (
      <div className="add-goal">
        <Modal
          trigger={
            <Button onClick={() => this.toggle()} className="Primary-Button">
              Contact Organisation Users
            </Button>
          }
          size="small"
          open={modal}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
          className="broadcast-new-message"
        >
          <Modal.Header>Broadcast New Message</Modal.Header>
          <Modal.Content>
            <NotificationForm {...this.props} toggle={() => this.toggle()} />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export class ViewNotification extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  async toggle() {
    await this.setState((prevState) => ({
      modal: !prevState.modal,
    }));

    const { records, updateNotificationReadStatus, isBroadcast } = this.props;
    const { modal } = this.state;
    if (modal && !isBroadcast && !records.isRead) {
      updateNotificationReadStatus(records.id);
    }
  }
  render() {
    const { modal } = this.state;
    const { records, isBroadcast, trigger } = this.props;
    return (
      <Modal
        trigger={trigger}
        size="small"
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        className="view-broadcast-message"
      >
        <Modal.Header>View Broadcast Message</Modal.Header>
        <Modal.Content className="pre-line">
          {isBroadcast
            ? get(records, 'message')
            : get(records, 'notification.message')}
        </Modal.Content>
      </Modal>
    );
  }
}

class Communication extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      columns: [
        {
          title: 'Title',
          width: '7',
          render: (data) => {
            return <label>{get(data, 'title', '-')}</label>;
          },
        },
        {
          title: 'Sender',
          width: '3',
          render: (data) => (
            <label>
              {get(data, 'sender.firstName', '-')} {get(data, 'sender.surname')}
            </label>
          ),
        },
        {
          title: 'Created Date',
          width: '4',
          render: (data) => {
            return (
              <label>
                {get(data, 'createdAt')
                  ? moment(get(data, 'createdAt')).format(
                      'D MMMM YYYY, hh:mm A'
                    )
                  : '-'}
              </label>
            );
          },
        },
        {
          title: '',
          width: '1',
          render: (data) => {
            return (
              <div className="org-checklist-actions">
                <ViewNotification
                  records={data}
                  trigger={
                    <label className="activity-actions-warn">View</label>
                  }
                  {...this.props}
                />
              </div>
            );
          },
        },
      ],
      notificationsColumns: [
        {
          title: 'Title',
          width: '7',
          render: (data) => {
            return (
              <div>
                <label>{get(data, 'notification.title', '-')}</label>
                {!get(data, 'isRead') ? (
                  <span className="new-label">New</span>
                ) : null}
              </div>
            );
          },
        },
        {
          title: 'Sender',
          width: '3',
          render: (data) => {
            return (
              <label>
                {get(data, 'notification.sender.firstName', '-')}{' '}
                {get(data, 'notification.sender.surname')}
              </label>
            );
          },
        },
        {
          title: 'Created Date',
          width: '4',
          render: (data) => {
            return (
              <label>
                {get(data, 'createdAt')
                  ? moment(get(data, 'createdAt')).format(
                      'D MMMM YYYY, hh:mm A'
                    )
                  : '-'}
              </label>
            );
          },
        },
        {
          title: '',
          width: '1',
          render: (data) => {
            return (
              <div className="org-checklist-actions">
                <ViewNotification
                  {...this.props}
                  records={data}
                  trigger={
                    <label className="activity-actions-warn">View</label>
                  }
                />
              </div>
            );
          },
        },
      ],
    };
  }

  resetPage() {
    this.setState({
      currentPage: 1,
    });
  }

  componentDidMount() {
    const {
      user,
      getBroadcastNotifications,
      getNotifications,
      getOrganizationUsers,
      isBroadcast,
    } = this.props;
    if (isBroadcast) {
      const page = 1;
      const limit = 10;
      const isOwnOrganisation = true;
      getBroadcastNotifications(page, limit, isOwnOrganisation);
      getOrganizationUsers(user.organisation.id);
    } else {
      getNotifications();
    }
  }

  async notificationsPageChange(page) {
    const { getNotifications } = this.props;
    this.setState({ currentPage: page }, () => {
      getNotifications(page);
    });
  }

  async broadcastPageChange(page) {
    const { getBroadcastNotifications } = this.props;
    this.setState({ currentPage: page }, () => {
      getBroadcastNotifications(page);
    });
  }

  render() {
    const { columns, notificationsColumns, currentPage } = this.state;
    const {
      isBroadcast,
      notificationsLoading,
      broadcastMessage,
      notifications,
    } = this.props;
    return (
      <Segment
        className="content-segment org-checklist"
        loading={
          isBroadcast
            ? notificationsLoading.broadcast
            : notificationsLoading.notification
        }
      >
        <Grid>
          {isBroadcast ? (
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                textAlign="right"
                verticalAlign="bottom"
              >
                <div className="com-contact-btn">
                  <div>
                    <a
                      href="mailto:support@takso-outcomes.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="Secondary-Button support-mail"
                    >
                      Contact Takso Support
                    </a>
                  </div>

                  <CreateNotification {...this.props} />
                </div>
              </Grid.Column>
            </Grid.Row>
          ) : null}
        </Grid>
        {isBroadcast ? (
          get(broadcastMessage, 'docs') && broadcastMessage.docs.length ? (
            <CustomTable
              header
              columns={columns}
              data={broadcastMessage.docs}
              customClass="org-checklist-table"
              pagination
              handlePaginationChange={(page) => this.broadcastPageChange(page)}
              page={currentPage}
              noOfPages={broadcastMessage.pages}
              keyExtractor={(item, index) => item.id}
            />
          ) : (
            <EmptyContainer msg="No notification found…" />
          )
        ) : get(notifications, 'docs') && notifications.docs.length ? (
          <CustomTable
            header
            columns={notificationsColumns}
            data={notifications.docs}
            customClass="org-checklist-table"
            pagination
            handlePaginationChange={(page) =>
              this.notificationsPageChange(page)
            }
            page={currentPage}
            noOfPages={notifications.pages}
            keyExtractor={(item, index) => item.id}
          />
        ) : (
          <EmptyContainer msg="No notification found…" />
        )}
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  const { organization, authentication, user } = state;
  return {
    user: authentication.user,
    orgUsers: organization.orgUsers,
    notificationsLoading: user.notificationsLoading,
    broadcastMessage: user.broadcastMessage,
    notifications: user.notifications,
    organization: organization.organization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getBroadcastNotifications,
      createBroadcastNotifications,
      getNotifications,
      getOrganizationUsers,
      updateNotificationReadStatus,
    },
    dispatch
  );
};

Communication = connect(mapStateToProps, mapDispatchToProps)(Communication);

export { Communication };
