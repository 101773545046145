import {
  AvForm,
  AvField,
  AvCheckbox,
  AvCheckboxGroup,
} from 'availity-reactstrap-validation';
import React, { Component } from 'react';
import {
  Button,
  Grid,
  Icon,
  Modal,
  Radio,
  Segment,
  Search,
  Label,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  CustomTable,
  EmptyContainer,
  CustomTooltip,
} from '../../../../components';
import { map, debounce } from '../../../../utils/lodash';
import moment from 'moment';
import {
  postPermissionGroups,
  updatePermissionGroup,
  getFeatures,
  getPermissionGroups,
} from '../../../../store/actions';
import { isBrowser, isTablet } from 'react-device-detect';
import { filter } from 'lodash';
import { isFeatureSelected } from '../../../../utils';

export default class PermissionGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      modal: false,
      keyword: '',
    };
    this.LIMIT = 15;
  }

  async componentDidMount() {
    const { getPermissionGroups } = this.props;
    getPermissionGroups(this.state.currentPage);
  }

  pageChange = (page) => {
    const { getPermissionGroups } = this.props;
    this.setState(
      {
        currentPage: page,
      },
      () => {
        getPermissionGroups(page, this.LIMIT, this.state.keyword);
      }
    );
  };

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  handleSearchChange = (e, { value }) => {
    const { getPermissionGroups } = this.props;
    const pageNumber = 1;
    getPermissionGroups(pageNumber, this.LIMIT, value);
    this.setState({ currentPage: pageNumber, keyword: value });
  };

  render() {
    const { modal, currentPage } = this.state;
    return (
      <div>
        <div className="outcome-admin">
          <div className="search-bar mr-3">
            <Search
              onSearchChange={debounce(this.handleSearchChange, 500)}
              placeholder="Search by permission name"
              showNoResults={false}
            />
          </div>
          <div className="button-outcome">
            <Modal
              open={modal}
              onOpen={() => this.toggle()}
              closeIcon={
                <Icon
                  onClick={() => this.toggle()}
                  name="close"
                  className="closeicon"
                />
              }
              size="large"
              trigger={
                <Button className="Primary-Button">
                  <div className="add-icon-activity-add">
                    <Icon name="plus circle" />
                    <div className="add-text">Add Permission Group</div>
                  </div>
                </Button>
              }
              className="create-permission-group"
            >
              <Modal.Header>Create Permission Group</Modal.Header>
              <Modal.Content>
                <CreatePermissionGroups
                  toggle={() => this.toggle()}
                  pageChange={this.pageChange}
                  {...this.props}
                />
              </Modal.Content>
            </Modal>
          </div>
        </div>
        <PermissionGroupList
          currentPage={currentPage}
          pageChange={this.pageChange}
          {...this.props}
        />
      </div>
    );
  }
}
class CreatePermissionGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: true,
      isVisible: true,
      selectedFeaturs: [],
      isSelectedFeatursEmpty: false,
    };
  }

  async componentDidMount() {
    const { getFeatures } = this.props;
    getFeatures(0);
  }

  handleActivateToggle() {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));
  }

  handleVisbleToggle() {
    this.setState((prevState) => ({
      isVisible: !prevState.isVisible,
    }));
  }

  async handleValidSubmit(event, values) {
    try {
      const { postPermissionGroups, toggle, pageChange } = this.props;
      const { selectedFeaturs, isActive, isVisible } = this.state;
      if (!selectedFeaturs.length) {
        this.setState({ isSelectedFeatursEmpty: true });
        return;
      }
      values.isActive = isActive;
      values.isVisible = isVisible;
      values.features = selectedFeaturs;
      await postPermissionGroups(values);

      toggle();
      pageChange(1);
    } catch (error) {
      error.log('Permission create submit ~~', error);
    }
  }

  handelFeatureSelected(id) {
    const { selectedFeaturs, isSelectedFeatursEmpty } = this.state;
    if (isFeatureSelected(id, selectedFeaturs)) {
      if (selectedFeaturs.length === 1)
        this.setState({ isSelectedFeatursEmpty: true });
      this.setState({
        selectedFeaturs: filter(
          selectedFeaturs,
          (featureId) => featureId !== id
        ),
      });
    } else {
      this.setState((prevState) => ({
        selectedFeaturs: [...prevState.selectedFeaturs, id],
      }));
      if (isSelectedFeatursEmpty)
        this.setState({ isSelectedFeatursEmpty: false });
    }
  }

  featureSelectAll() {
    const { features } = this.props;
    if (features) {
      this.setState({
        selectedFeaturs: map(features.docs, (doc) => doc.id),
        isSelectedFeatursEmpty: false,
      });
    }
  }
  render() {
    const { toggle, postPermissionGroupLoading, features, getfeatureLoading } =
      this.props;
    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        className="venue-form"
      >
        <AvField
          label="Name"
          className="org-inputs"
          name="name"
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: 'Name is required',
            },
          }}
        />

        <AvField
          label="Description"
          className="org-inputs-textarea"
          name="description"
          type="textarea"
          validate={{
            required: {
              value: true,
              errorMessage: 'Description is required',
            },
          }}
        />
        <div className="featurelist-heder">
          <div className="button-container">
            <Button
              type="button"
              onClick={() => {
                this.featureSelectAll();
              }}
              className="Secondary-Button"
            >
              Select All
            </Button>
            <Button
              type="button"
              onClick={() => {
                this.setState({ selectedFeaturs: [] });
              }}
              className="Secondary-Button"
            >
              Clear All
            </Button>
          </div>
        </div>
        <label>Features</label>

        <Segment
          className="content-segment mt-0"
          loading={getfeatureLoading}
          disabled={getfeatureLoading}
        >
          {features.docs && features.docs.length ? (
            <AvCheckboxGroup name="features">
              <Grid className="features-checkbox-container">
                {map(features.docs, (doc) => (
                  <Grid.Column
                    key={doc.id}
                    className="permission-features-list"
                    computer={5}
                    verticalAlign="middle"
                  >
                    <div className="features-checkbox">
                      <AvCheckbox
                        onChange={(e, value) => {
                          this.handelFeatureSelected(doc.id);
                        }}
                        checked={isFeatureSelected(
                          doc.id,
                          this.state.selectedFeaturs
                        )}
                        value={doc.id}
                        label={
                          <CustomTooltip
                            state={true}
                            content={doc.description}
                            position="top left"
                            wide="very"
                          >
                            <div
                              className={
                                this.state.isSelectedFeatursEmpty
                                  ? 'feature-err'
                                  : ''
                              }
                            >
                              {doc.displayName}
                            </div>
                          </CustomTooltip>
                        }
                      />
                    </div>
                  </Grid.Column>
                ))}
              </Grid>
            </AvCheckboxGroup>
          ) : (
            <EmptyContainer msg="No Features..." />
          )}
        </Segment>

        <Grid className="activate-notify-row">
          <Grid.Column
            computer={3}
            className="evaluation-activity-toggel-group linked-activity-model-column"
          >
            <label className="lbl-margin-left">Activate</label>
            <Radio
              toggle
              name="activateToggel"
              checked={this.state.isActive}
              onChange={() => this.handleActivateToggle()}
            />
          </Grid.Column>
          <Grid.Column
            computer={3}
            className="evaluation-activity-toggel-group linked-activity-model-column"
          >
            <label className="lbl-margin-left">Visibility</label>
            <Radio
              toggle
              name="visibleToggel"
              checked={this.state.isVisible}
              onChange={() => this.handleVisbleToggle()}
            />
          </Grid.Column>
        </Grid>
        <div className="model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            content="Create"
            type="submit"
            className="Primary-Button"
            loading={postPermissionGroupLoading}
            disabled={postPermissionGroupLoading}
          />
        </div>
      </AvForm>
    );
  }
}

class EditPermissionGroupModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      form: {
        name: '',
        description: '',
      },
    };
  }

  editSelectedQuestion(form) {
    form.features = form.featureGroup
      ? map(form.featureGroup, (feature) => feature.featureId)
      : [];

    this.setState({
      form,
    });
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  render() {
    const { modal, form } = this.state;
    const { item } = this.props;

    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="large"
        trigger={
          <label
            className="activity-actions-warn mb-0"
            onClick={() => this.editSelectedQuestion(item)}
          >
            Edit
          </label>
        }
        className="edit-permission-group"
      >
        <Modal.Header>Edit Permission Group</Modal.Header>
        <Modal.Content>
          <EditPermissionForm
            form={form}
            toggle={() => this.toggle()}
            {...this.props}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

class PermissionGroupList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
  }

  getColumns() {
    return [
      {
        title: (
          <div>
            <strong>Permission</strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className="blocked-badge-container">
              <div className={!data.isActive && 'disabled'}> {data.name}</div>
              {!data.isActive && (
                <Label className="blocked-badge" color="red" size="tiny">
                  Disabled
                </Label>
              )}
            </div>
          );
        },
        width: 3,
        textAlign: isBrowser || isTablet ? 'left' : null,
      },
      {
        title: (
          <div className="user-act-heading-container-custom-feature">
            <strong>Description</strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className={!data.isActive && 'disabled'}>
              {' '}
              {data.description}
            </div>
          );
        },
        width: 4,
        textAlign: isBrowser || isTablet ? 'left' : null,
      },
      {
        title: (
          <div className="user-act-heading-container-custom-feature">
            <strong>Created Date</strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className={!data.isActive && 'disabled'}>
              {' '}
              {moment(data.createdAt).format('ll hh:mm A')}
            </div>
          );
        },
        width: 3,
        textAlign: isBrowser || isTablet ? 'left' : null,
      },
      {
        title: (
          <div className="user-act-heading-container-custom-feature">
            <strong>Updated Date</strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className={!data.isActive && 'disabled'}>
              {' '}
              {moment(data.updatedAt).format('ll hh:mm A')}
            </div>
          );
        },
        width: 3,
        textAlign: isBrowser || isTablet ? 'left' : null,
      },
      {
        title: (
          <div className="user-act-heading-container-custom-feature">
            <strong>Visibility</strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className={!data.isActive && 'disabled'}>
              {' '}
              <div className="active-status-container">
                {data.isVisible ? (
                  <div className="active-status">Visible</div>
                ) : (
                  <div className="block-status">Not Visible</div>
                )}
              </div>
            </div>
          );
        },
        width: 2,
        textAlign: isBrowser || isTablet ? 'centre' : null,
      },
      {
        title: <div></div>,
        render: (data) => {
          const { currentPage } = this.props;
          return (
            <div className="venue-edit">
              <EditPermissionGroupModal
                item={data}
                currentPage={currentPage}
                {...this.props}
              />
            </div>
          );
        },
        width: 1,
        textAlign: isBrowser || isTablet ? 'left' : null,
      },
    ];
  }

  async componentDidMount() {
    try {
      const { currentPage, getPermissionGroups } = this.props;
      await getPermissionGroups(currentPage);
    } catch (error) {}
  }

  pageChange(page) {
    const { getPermissionGroups } = this.props;
    this.setState(
      {
        currentPage: page,
      },
      () => {
        getPermissionGroups(page);
      }
    );
  }

  render() {
    const {
      getPermissionGroup,
      getPermissionLoading,
      pageChange,
      currentPage,
    } = this.props;
    return (
      <Segment
        className="content-segment p-0"
        loading={getPermissionLoading}
        disabled={getPermissionLoading}
      >
        {getPermissionGroup.docs ? (
          <CustomTable
            header
            columns={this.getColumns()}
            data={getPermissionGroup.docs}
            pagination
            keyExtractor={(item, index) => item.id}
            customClass="super-admin-table tbody-hover"
            handlePaginationChange={(page) => pageChange(page)}
            page={currentPage}
            noOfPages={getPermissionGroup.pages}
          />
        ) : (
          <EmptyContainer msg="No permission grants" />
        )}
      </Segment>
    );
  }
}
class EditPermissionForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
      isActive: true,
      selectedFeaturs: [],
      isSelectedFeatursEmpty: false,
    };
  }

  async componentDidMount() {
    const { getFeatures, form } = this.props;
    getFeatures(0);
    if (!form.isVisible) {
      this.setState({
        isVisible: false,
      });
    }

    if (!form.isActive) {
      this.setState({
        isActive: false,
      });
    }

    this.setState({
      selectedFeaturs: form.features,
    });
  }

  async onValidSubmit(event, values) {
    const formData = { name: values.name, description: values.description };
    try {
      const { updatePermissionGroup, form, toggle, currentPage } = this.props;
      const { selectedFeaturs, isVisible, isActive } = this.state;
      if (!selectedFeaturs.length) {
        this.setState({ isSelectedFeatursEmpty: true });
        return;
      }
      formData.isVisible = isVisible;
      formData.isActive = isActive;
      formData.features = selectedFeaturs;
      await updatePermissionGroup(form.id, formData, currentPage);
      toggle();
    } catch (error) {
      // handle error here
    }
  }

  handleVisbleToggle() {
    this.setState((prevState) => ({
      isVisible: !prevState.isVisible,
    }));
  }

  async handelFeatureSelected(id) {
    const { selectedFeaturs, isSelectedFeatursEmpty } = this.state;
    const isd = await isFeatureSelected(id, selectedFeaturs);
    if (isd) {
      if (selectedFeaturs.length === 1)
        this.setState({ isSelectedFeatursEmpty: true });
      this.setState({
        selectedFeaturs: filter(
          selectedFeaturs,
          (featureId) => featureId !== id
        ),
      });
    } else {
      this.setState({ selectedFeaturs: [...selectedFeaturs, id] });
      if (isSelectedFeatursEmpty)
        this.setState({ isSelectedFeatursEmpty: false });
    }
  }

  featureSelectAll() {
    const { features } = this.props;
    if (features) {
      this.setState({
        selectedFeaturs: map(features.docs, (doc) => doc.id),
        isSelectedFeatursEmpty: false,
      });
    }
  }

  handleActivateToggle() {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));
  }

  render() {
    const {
      toggle,
      form,
      updatePermissionGroupLoading,
      features,
      getfeatureLoading,
    } = this.props;
    const { selectedFeaturs } = this.state;

    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.onValidSubmit(event, values)}
        className="venue-form"
        model={form}
      >
        <AvField
          label="Title"
          className="org-inputs"
          name="name"
          type="text"
          validate={{
            required: {
              value: true,
              errorMessage: 'Title is required',
            },
          }}
        />

        <AvField
          label="Description"
          className="org-inputs-textarea"
          name="description"
          type="textarea"
          validate={{
            required: {
              value: true,
              errorMessage: 'Description  is required',
            },
          }}
        />

        <div className="featurelist-heder">
          <div className="button-container">
            <Button
              type="button"
              onClick={() => {
                this.featureSelectAll();
              }}
              className="Secondary-Button"
            >
              Select All
            </Button>
            <Button
              type="button"
              onClick={() => {
                this.setState({ selectedFeaturs: [] });
              }}
              className="Secondary-Button"
            >
              Clear All
            </Button>
          </div>
        </div>
        <label>Features</label>
        <Segment
          className="content-segment mt-0"
          loading={getfeatureLoading}
          disabled={getfeatureLoading}
        >
          {features.docs && features.docs.length ? (
            <AvCheckboxGroup name="features_">
              <Grid className="features-checkbox-container">
                {map(features.docs, (doc) => (
                  <Grid.Column
                    key={doc.id}
                    className="permission-features-list"
                    computer={5}
                    verticalAlign="middle"
                  >
                    <div className="features-checkbox">
                      <AvCheckbox
                        onChange={(e, value) => {
                          this.handelFeatureSelected(doc.id);
                        }}
                        checked={isFeatureSelected(doc.id, selectedFeaturs)}
                        value={doc.id}
                        label={
                          <CustomTooltip
                            state={true}
                            content={doc.description}
                            position="top left"
                            wide="very"
                          >
                            <div
                              className={
                                this.state.isSelectedFeatursEmpty
                                  ? 'feature-err'
                                  : ''
                              }
                            >
                              {doc.displayName}
                            </div>
                          </CustomTooltip>
                        }
                      />
                    </div>
                  </Grid.Column>
                ))}
              </Grid>
            </AvCheckboxGroup>
          ) : (
            <EmptyContainer msg="No Features..." />
          )}
        </Segment>

        <Grid className="activate-notify-row mt-3">
          <Grid.Column
            computer={3}
            className="evaluation-activity-toggel-group linked-activity-model-column"
          >
            <label className="lbl-margin-left">Activate</label>
            <Radio
              toggle
              name="activateToggel"
              checked={this.state.isActive}
              onChange={() => this.handleActivateToggle()}
            />
          </Grid.Column>
          <Grid.Column
            computer={3}
            className="evaluation-activity-toggel-group linked-activity-model-column"
          >
            <label className="lbl-margin-left">Visibility</label>
            <Radio
              toggle
              name="visibleToggel"
              checked={this.state.isVisible}
              onChange={() => this.handleVisbleToggle()}
            />
          </Grid.Column>
        </Grid>

        <div className="model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            content="Save"
            type="submit"
            className="Primary-Button"
            loading={updatePermissionGroupLoading}
            disabled={updatePermissionGroupLoading}
          />
        </div>
      </AvForm>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    PermissionGroups: state.extra.PermissionGroups,
    updatePermissionGroupLoading: state.admin.updatePermissionGroupLoading,
    getPermissionGroup: state.admin.getPermissionGroup,
    getPermissionLoading: state.admin.getPermissionLoading,
    deletePermissionLoading: state.admin.deletePermissionLoading,
    features: state.admin.features,
    getfeatureLoading: state.admin.getfeatureLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postPermissionGroups,
      updatePermissionGroup,
      getPermissionGroups,
      getFeatures,
    },
    dispatch
  );
};

PermissionGroups = connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionGroups);

export { PermissionGroups };
