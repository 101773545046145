/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Segment, Grid } from 'semantic-ui-react';
import { map, isEmpty, get } from '../../../utils/lodash';
import './activity.scss';
import { ContentAndHeader, getValuesFormat, setValue } from '../../../utils';
import { isParentProgramActivity } from '../../../utils/activity';
import { getParentKeyData } from '../../../store/actions';

class ActualOutput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { getParentKeyData, allData } = this.props;
    isParentProgramActivity(allData.activityPlanTypeId) &&
      getParentKeyData(allData.id);
  }

  keyData() {
    const { parentKeyData, currencyType } = this.props;

    return !isEmpty(parentKeyData) ? (
      parentKeyData.map((item) => (
        <Grid.Column mobile={8} tablet={8} computer={5} className="my-3">
          <label className="info-title">{get(item, 'output', '')}</label>
          <div>
            {setValue(item.data, get(currencyType, 'sign', '$')) || '-'}
          </div>
        </Grid.Column>
      ))
    ) : (
      <></>
    );
  }

  actualOutputs(currentActivity) {
    try {
      let formats = get(currentActivity, 'eventDetail.outputFormat.format', []);
      return map(formats, (item, index) => {
        const outputObj = currentActivity.output.find(
          (outputItem) => outputItem.outputFormatId === item.outputFormatId
        );

        if (isEmpty(outputObj)) return; // do not show currency values on activity report page

        let itemValue = get(outputObj, 'actualValue', '0');

        itemValue = itemValue ? Number(itemValue) : 0;

        switch (item.type) {
          case 'checkbox':
            return (
              <Grid.Column mobile={16} tablet={5} computer={5} key={index}>
                <ContentAndHeader title={item.label}>
                  {itemValue ? 'Yes' : 'No'}
                </ContentAndHeader>
              </Grid.Column>
            );
          default:
            itemValue = getValuesFormat(
              itemValue,
              item.type,
              get(currentActivity, 'currencyType.sign', '$')
            );
            return (
              <Grid.Column mobile={16} tablet={5} computer={5} key={index}>
                <ContentAndHeader title={item.label}>
                  <div className="approval-request-data">{itemValue}</div>
                </ContentAndHeader>
              </Grid.Column>
            );
        }
      });
    } catch (error) {
      return '-';
    }
  }
  render() {
    const { allData, venue, volunteers, parentKeyDataReportLoading } =
      this.props;
    const venuesList = map(venue, (item) => item.name);
    return (
      <Segment
        className="report-activity-seg"
        loading={parentKeyDataReportLoading}
      >
        <div className="key-activity-data">
          <Grid>
            {isParentProgramActivity(allData.activityPlanTypeId) ? (
              <Grid.Row>
                {/* Key Activity Data */}
                {this.keyData()}

                {/* TODO: Venues might be needed in future, so keep it */}
                {/* <Grid.Column mobile={8} tablet={8} computer={5}>
                  <label className="info-title">Venue(s)</label>
                  <div>{venuesList.length ? venuesList.join(', ') : '-'}</div>
                </Grid.Column> */}
              </Grid.Row>
            ) : (
              <>
                <Grid.Row className=" key-activity-data-row" columns={4}>
                  {this.actualOutputs(allData)}
                </Grid.Row>
                <Grid.Row className="key-activity-data-row-reduce ">
                  <Grid.Column mobile={8} tablet={8} computer={5}>
                    <label className="info-title">Total Volunteers</label>
                    <p className="info-desc">{volunteers ? volunteers : '-'}</p>
                  </Grid.Column>
                  <Grid.Column mobile={8} tablet={8} computer={5}>
                    <label className="info-title">Venue(s)</label>
                    <div>{venuesList.length ? venuesList.join(', ') : '-'}</div>
                  </Grid.Column>
                </Grid.Row>
              </>
            )}
          </Grid>
        </div>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parentKeyData: state.report.parentKeyDataReport,
    parentKeyDataReportLoading: state.report.parentKeyDataReportLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getParentKeyData }, dispatch);
};

ActualOutput = connect(mapStateToProps, mapDispatchToProps)(ActualOutput);

export { ActualOutput };
