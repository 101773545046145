/* eslint-disable react-hooks/exhaustive-deps */
import '../organization.scss';
import React, { useEffect, useState } from 'react';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Grid,
  Icon,
  Modal,
  Segment,
} from 'semantic-ui-react';

import plusOutline from '../../../assets/add-icon-white-bg.svg';
import downloadIcon from '../../../assets/downloadIcon.svg';
import infoIcon from '../../../assets/info-outline.svg'; // TODO: remove this when creating new cross reference while toggling button
import { CustomTable, SeeMore } from '../../../components';
import { CustomFileUpload, CustomInput } from '../../../components/form';
import {
  archiveOrgGoalReferences,
  getCrossReferenceDocumentList,
  getOrganizationGoals,
  getOrgGoalRefList,
  postCreateOrgGoalReferences,
  postCrossReferenceDocument,
  putCrossReferenceDocument,
  putOrgArchivedGoalReferences,
  putOrgGoalReferences,
} from '../../../store/actions';
import { filter, find, get, isEmpty, map, size } from '../../../utils/lodash';
import {
  crossReferenceInitialValues,
  crossReferenceValidationSchema,
} from '../../../validations/crossReferenceValidation';
import ShowMoreText from 'react-show-more-text';

const CrossReferenceDocumentForm = ({
  isNew,

  toggle,
  user,
  crossRefCurrentDoc,
}) => {
  const dispatch = useDispatch();

  const [file, setFile] = useState('');

  const crossRefDocLoading = useSelector(
    (state) => state.organization.crossRefDocLoading
  );

  const onSubmit = async (values) => {
    const { documentTitle, referenceFieldLabel } = values;

    let formData = new FormData();
    formData.append('organisationId', parseInt(user.organisationId));
    formData.append('documentTitle', documentTitle);
    formData.append('referenceFieldLabel', referenceFieldLabel);
    formData.set('uploadFile', file ? file.file : ' ');

    if (isNew) {
      const isSubmitted = await dispatch(postCrossReferenceDocument(formData));

      if (isSubmitted) {
        toggle();
      }
    } else {
      const isSubmitted = await dispatch(
        putCrossReferenceDocument(crossRefCurrentDoc.id, formData)
      );

      if (isSubmitted) {
        toggle();
      }
    }
  };

  return (
    <Formik
      onSubmit={(values) => {
        onSubmit(values);
      }}
      initialValues={crossReferenceInitialValues(crossRefCurrentDoc, isNew)}
      validationSchema={crossReferenceValidationSchema}
    >
      {({ handleSubmit, errors, isSubmitting, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <div className="model-upper">
            <CustomInput
              id="documentTitle"
              name="documentTitle"
              labelText="Document Title"
            />
            <CustomInput
              id="referenceFieldLabel"
              name="referenceFieldLabel"
              labelText="Reference Field Label"
            />
            <CustomFileUpload
              id="file"
              labelText="Upload Reference Document"
              name="file"
              accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/pdf"
              buttonText="Browse File"
              mediaUpload={true}
              infoMessage="Max file size - 50 MB"
              setFile={setFile}
              loading={crossRefDocLoading}
              showMsg={true}
              placeholder="Accepted file types: .pdf, .docx"
              restrictedSize={2}
              setFieldValue={setFieldValue}
            />
          </div>
          <Divider section />

          <div className="model-below">
            <div className="model-button">
              <Button onClick={toggle} className="modal-cancel">
                Cancel
              </Button>
              <Button
                loading={crossRefDocLoading}
                className="modal-proceed"
                content="Save"
                type="submit"
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const CrossReferenceDocumentCreateModal = (props) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <Modal
      open={modal}
      onOpen={toggle}
      size={'tiny'} //  : 'small'
      trigger={
        <Dropdown.Item>
          <label>Create New Cross-Reference Document</label>
        </Dropdown.Item>
      }
    >
      <Modal.Content>
        <div className="align-self-center justify-content-center text-center my-3">
          <img
            className="modal-report-error-img"
            alt="greenRight"
            src={infoIcon}
          />{' '}
        </div>

        <p className="text-center modal-report-error-paragraph">
          This function is currently being built and will be available soon.
          <br /> In the meantime, please contact Takso support{' '}
          <span>
            <a
              className="contactUs"
              href="mailto:support@takso-outcomes.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@takso-outcomes.com{' '}
            </a>
          </span>
          to request for your new references to be added to your account.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <div className="align-self-center justify-content-center text-center">
          <Button
            className="modal-proceed px-5"
            content="Got it"
            onClick={toggle}
          />
        </div>
      </Modal.Actions>
    </Modal>
  );
};
const CrossReferenceDocumentUpdateModal = (props) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <Modal
      className="add-cross-ref-model"
      open={modal}
      closeIcon={<Icon onClick={toggle} name="close" className="closeicon" />}
      trigger={
        <Dropdown.Item>
          {' '}
          <label>Edit</label>
        </Dropdown.Item>
      }
      onOpen={toggle}
      size="small"
    >
      <Modal.Header>Update Cross-Reference Document</Modal.Header>
      <Modal.Content>
        <CrossReferenceDocumentForm {...props} isNew={false} toggle={toggle} />
      </Modal.Content>
    </Modal>
  );
};

const ReferenceForm = ({
  toggle,
  user,
  crossRefActiveDoc,
  isNew,
  goalTitle,
  goalId,
  goalCrossReferenceId,
  referencesEditArr,
  goalOptions,
}) => {
  const dispatch = useDispatch();
  const [referenceArr, setReferenceArr] = useState(
    isNew
      ? [
          {
            key: 1,
            value: '',
          },
        ]
      : []
  ); // new references
  const [referenceEditArr, setReferenceEditArr] = useState(referencesEditArr); // editing references/ existing references
  const [referenceArrLength, setReferenceArrLength] = useState(isNew ? 1 : 0);

  const crossReferenceModificationLoading = useSelector(
    (state) => state.organization.crossReferenceModificationLoading
  );

  useEffect(() => {
    (async () => {
      setReferenceArr(
        isNew
          ? [
              {
                key: 1,
                value: '',
              },
            ]
          : []
      );
    })();
  }, []);

  const handleAddNewReference = () => {
    setReferenceArr([
      ...referenceArr,
      { key: referenceArrLength + 1, value: '' },
    ]);
    setReferenceArrLength(referenceArrLength + 1);
  };

  const onDeleteReference = (isExistingReference, key) => {
    const references = !isExistingReference ? referenceArr : referenceEditArr;
    const referenceNewArray = filter(references, (f) => f.key !== key);

    !isExistingReference
      ? setReferenceArr(referenceNewArray)
      : setReferenceEditArr(referenceNewArray);
  };

  const onChangeGoalReferences = async (isExistingReference, value, key) => {
    const references = !isExistingReference ? referenceArr : referenceEditArr;
    const editedReferences = map(references, (reference) => {
      if (reference.key === key) {
        return {
          key: key,
          value: value.trim(),
        };
      } else {
        return reference;
      }
    });
    !isExistingReference
      ? setReferenceArr(editedReferences)
      : setReferenceEditArr(editedReferences);
  };

  const onSaveGoalReferences = async (event, values) => {
    const { referenceGoal } = values;

    const newReferencesList = map(referenceArr, (reference) => reference.value);
    const existingReferencesList = !isNew ? referenceEditArr : [];
    const documentId = crossRefActiveDoc.id;

    const isModalHidden = isNew
      ? dispatch(
          postCreateOrgGoalReferences(
            referenceGoal,
            documentId,
            newReferencesList
          )
        )
      : dispatch(
          putOrgGoalReferences(
            goalId,
            documentId,
            goalCrossReferenceId,
            existingReferencesList,
            newReferencesList
          )
        );

    isModalHidden && toggle();
  };

  const deletingArrLength = isNew
    ? referenceArr.length
    : referenceArr.length + referenceEditArr.length;

  return (
    <AvForm
      onValidSubmit={(event, values) => {
        onSaveGoalReferences(event, values);
      }}
    >
      <div className="model-upper">
        {isNew ? (
          <>
            {/* creating goal dropdown */}
            <AvField
              label="Goal"
              name="referenceGoal"
              type="select"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Goal is required',
                },
              }}
            >
              <option value="" defaultValue disabled>
                Select a goal...
              </option>
              {map(goalOptions, (e) => (
                <option key={e.key} value={e.value}>
                  {' '}
                  {e.text}
                </option>
              ))}
            </AvField>
            <p className="text-muted">
              Once you selected the goal you can’t change it
            </p>
          </>
        ) : (
          <>
            {/* editing ui label */}
            <AvField
              name="referenceGoal"
              label="Goal"
              value={goalTitle}
              required
              disabled
            />
          </>
        )}
        <label className="modal-subheader">
          {crossRefActiveDoc.referenceFieldLabel}
        </label>
        {!isNew &&
          referenceEditArr.map((item) => {
            return (
              <div className="d-flex cross-reference-label">
                <AvField
                  className={deletingArrLength > 1 ? 'textarea-with-btn' : ''}
                  type="textarea"
                  name={`goalReference_${item.key}`}
                  required
                  onChange={(e) =>
                    onChangeGoalReferences(true, e.target.value, item.key)
                  }
                  value={item.value}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Reference is required',
                    },
                  }}
                  readOnly={crossReferenceModificationLoading}
                />

                {deletingArrLength > 1 ? (
                  <Button
                    onClick={() => onDeleteReference(true, item.key)}
                    icon="trash"
                    attached="right"
                    disabled={crossReferenceModificationLoading}
                  ></Button>
                ) : null}
              </div>
            );
          })}

        {referenceArr.map((item) => {
          return (
            <div className="d-flex cross-reference-label">
              <AvField
                className={deletingArrLength > 1 ? 'textarea-with-btn' : ''}
                type="textarea"
                name={`goalReference_${item.key}`}
                value={item.value}
                required
                onChange={(e) =>
                  onChangeGoalReferences(false, e.target.value, item.key)
                }
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Reference is required',
                  },
                }}
                readOnly={crossReferenceModificationLoading}
              />

              {deletingArrLength > 1 ? (
                <Button
                  onClick={() => onDeleteReference(false, item.key)}
                  icon="trash"
                  attached="right"
                  disabled={crossReferenceModificationLoading}
                ></Button>
              ) : null}
            </div>
          );
        })}

        <div className="d-flex align-items-end flex-column">
          <div>
            <Button
              onClick={handleAddNewReference}
              className="btn-add-ref"
              type="button"
              disabled={crossReferenceModificationLoading}
            >
              <span>
                <img className="plus-icon" alt="plus" src={plusOutline} /> Add
                More References
              </span>
            </Button>
          </div>
        </div>
      </div>
      <Divider section />

      <div className="model-below">
        <div className="model-button">
          <Button onClick={toggle} className="Secondary-Button">
            Cancel
          </Button>
          <Button
            type="submit"
            className="Primary-Button"
            content="Save"
            loading={crossReferenceModificationLoading}
          />
        </div>
      </div>
    </AvForm>
  );
};

const ReferenceCreate = (props) => {
  const { user } = props;
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    (async () => {
      await dispatch(getOrganizationGoals());
    })();
  }, []);

  return (
    <Modal
      open={modal}
      size="small"
      closeIcon={<Icon onClick={toggle} name="close" className="closeicon" />}
      className="add-ref-model"
      trigger={
        <div className="ref-plans-show">
          <div className="add-new-ref">
            <Button
              onClick={toggle}
              className="Primary-Button add-reference-btn"
            >
              <Icon name="plus" />
              Add Reference
            </Button>
          </div>
        </div>
      }
    >
      <Modal.Header>
        Add{' '}
        {props.crossRefActiveDoc.referenceFieldLabel
          ? props.crossRefActiveDoc.referenceFieldLabel
          : 'Reference'}
      </Modal.Header>
      <Modal.Content>
        <ReferenceForm {...props} toggle={toggle} isNew={true}></ReferenceForm>
      </Modal.Content>
    </Modal>
  );
};

const ReferenceUpdate = (props) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const referencesEditArr = map(props.references, (reference) => {
    return {
      key: reference.id,
      value: reference.crossReferenceLabel,
    };
  });

  return (
    <Modal
      open={modal}
      size="small"
      closeIcon={<Icon onClick={toggle} name="close" className="closeicon" />}
      className="edit-ref-model"
      trigger={
        <label className="org-users-actions-warn default-color">Edit</label>
      }
      onOpen={toggle}
    >
      <Modal.Header>
        Edit {props.crossRefActiveDoc.referenceFieldLabel}
      </Modal.Header>
      <Modal.Content>
        <ReferenceForm
          {...props}
          toggle={toggle}
          referencesEditArr={referencesEditArr}
          isNew={false}
        ></ReferenceForm>
      </Modal.Content>
    </Modal>
  );
};

const UpdateReference = ({
  user,
  crossRefActiveDoc,
  goalCrossReferenceId,
  references,
  goalOptions,
  goalId,
}) => {
  const dispatch = useDispatch();

  const [updateReferenceArr, setUpdateReferenceArr] = useState([]);

  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    const refArr = map(references, (m) => m.crossReferenceLabel);
    setUpdateReferenceArr(refArr);
  }, []);

  const handleValidSubmit = async (event, values) => {
    const { referenceEditNewGoalId } = values;

    dispatch(
      putOrgArchivedGoalReferences(
        goalId, // existing goal id
        referenceEditNewGoalId, // new goal id (transfer references to old goal to new goal)
        crossRefActiveDoc.id,
        goalCrossReferenceId,
        updateReferenceArr
      )
    );
  };

  return (
    <Modal
      trigger={
        <label className="org-users-actions-warn default-color">
          Update Reference
        </label>
      }
      size="tiny"
      open={modal}
      onOpen={toggle}
      closeIcon={<Icon onClick={toggle} name="close" className="closeicon" />}
      className="edit-ref-model"
    >
      <Modal.Header>
        Update{' '}
        {crossRefActiveDoc.referenceFieldLabel
          ? crossRefActiveDoc.referenceFieldLabel
          : null}
      </Modal.Header>
      <Modal.Content>
        <AvForm
          onValidSubmit={(event, values) => {
            handleValidSubmit(event, values);
          }}
        >
          <div className="model-upper">
            <AvField
              label="Goal"
              name="referenceEditNewGoalId"
              type="select"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Goal is required',
                },
              }}
            >
              <option value="" defaultValue disabled>
                Select a goal...
              </option>
              {map(goalOptions, (e) => (
                <option key={e.key} value={e.value}>
                  {' '}
                  {e.text}
                </option>
              ))}
            </AvField>
            <p className="text-muted">
              Once you selected the goal you can’t change it
            </p>
            <div>
              <p className="modal-subheader">References</p>

              <ul className="ref-ul-custom">
                {map(updateReferenceArr, (item, i) => {
                  return <li> {item}</li>;
                })}
              </ul>
            </div>
          </div>

          <Divider section />
          <div className="model-below">
            <div className="model-button">
              <Button onClick={toggle} className="modal-cancel">
                Cancel
              </Button>
              <Button className="modal-proceed" content="Save" type="submit" />
            </div>
          </div>
        </AvForm>
      </Modal.Content>
    </Modal>
  );
};

const ArchiveGoalReferences = ({
  goalId,
  goalReferenceId,
  user,
  crossRefActiveDoc,
}) => {
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const handleValidSubmit = (goalId, goalReferenceId) => {
    const documentId = crossRefActiveDoc.id;
    dispatch(archiveOrgGoalReferences(documentId, goalId, goalReferenceId));
    toggle();
  };

  return (
    <Modal
      open={modal}
      size="tiny"
      trigger={<label className="ref-delete-label">Archive</label>}
      onOpen={toggle}
    >
      <Modal.Header>Archive Goal References</Modal.Header>
      <Modal.Content>
        Are you sure you want to archive this references?
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={toggle} className="modal-cancel">
          Cancel
        </Button>
        <Button
          onClick={() => handleValidSubmit(goalId, goalReferenceId)}
          className="modal-proceed"
          content="Archive"
        />
      </Modal.Actions>
    </Modal>
  );
};

export const CrossReference = (props) => {
  const [crossRefCurrentDoc, setCrossRefCurrentDoc] = useState({});
  const [crossRefActiveDoc, setCrossRefActiveDoc] = useState({});
  const [goalOptions, setGoalOptions] = useState([]);

  const crossRefDocuments = useSelector(
    (state) => state.organization.crossRefDocuments
  );
  const orgGoalsReferences = useSelector(
    (state) => state.organization.orgGoalsReferences
  );
  const organisationGoalsReferencesLoading = useSelector(
    (state) => state.organization.organisationGoalsReferencesLoading
  );
  const user = useSelector((state) => state.authentication.user);
  const organisationGoals = useSelector(
    (state) => state.organization.organisationGoals
  );

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      // get cross reference documents
      dispatch(getCrossReferenceDocumentList());
      dispatch(getOrganizationGoals());
    })();
  }, []);

  const setOrganisationGoals = async () => {
    const goalOptions = map(organisationGoals, (item, i) => ({
      key: item.id,
      value: item.id,
      text: item.goalTitle,
    }));

    setGoalOptions([...goalOptions]);
  };

  useEffect(() => {
    (async () => {
      await setOrganisationGoals();
    })();
  }, [organisationGoals]);

  useEffect(() => {
    const currentRefDoc = find(
      crossRefDocuments,
      (docItem) => docItem.documentEndedDateTime === null
    );
    if (currentRefDoc !== undefined) {
      setCrossRefCurrentDoc(currentRefDoc);
      setCrossRefActiveDoc(currentRefDoc);
    }
    getOrgGoalReferences();
  }, [crossRefDocuments]);

  const getOrgGoalReferences = async () => {
    if (crossRefCurrentDoc && Object.keys(crossRefCurrentDoc).length !== 0) {
      //get organisation goal with included references
      dispatch(getOrgGoalRefList(crossRefCurrentDoc.id));
    }
  };

  const referenceGroup = (props) => {
    const { item, rest } = props;
    return (
      <div
        className={
          rest.isArchived
            ? 'reference-mainText-muted item'
            : 'reference-mainText item'
        }
      >
        {' '}
        {!isEmpty(item.crossReferenceLabel) ? (
          <ShowMoreText
            lines={2}
            more="Read More"
            less="Read Less"
            anchorClass="show-more-less-clickable"
            expanded={false}
            truncatedEndingComponent={'... '}
            className="pre-line"
          >
            <p>{item.crossReferenceLabel}</p>
          </ShowMoreText>
        ) : (
          '-'
        )}
      </div>
    );
  };

  const columns = [
    {
      title: 'Goal',
      width: 3,
      render: (data) => {
        let goalTitle = '';
        let labelTitle = '';

        const isGoalArchived = get(data, 'goal.isArchived', false);
        const isGoalDeleted = get(data, 'goal.isDeleted', false);

        if (size(data) > 0) {
          goalTitle = get(data, 'goal.goalTitle', '');
        }
        if (!isEmpty(goalTitle)) {
          goalTitle = goalTitle.split('.');
        }

        // set label title for deleted or archived goals
        if (isGoalArchived) {
          labelTitle = 'Archived Goal';
        } else if (isGoalDeleted) {
          labelTitle = 'Deleted Goal';
        }

        return (
          <>
            <div className="reference-container">
              <label
                className={
                  isGoalArchived || isGoalDeleted
                    ? 'reference-mainText-muted'
                    : 'reference-mainText'
                }
              >
                {' '}
                {!isEmpty(goalTitle) ? goalTitle : '-'}
              </label>
            </div>
            {labelTitle && <span className="archived-lbl">{labelTitle}</span>}
          </>
        );
      },
    },
    {
      title: crossRefCurrentDoc.referenceFieldLabel
        ? crossRefCurrentDoc.referenceFieldLabel
        : null,
      width: 7,
      render: (data) => {
        let references = [];
        let isArchived = false;
        const isGoalArchived = get(data, 'goal.isArchived', false);
        if (data) {
          references = get(data, 'references', []);
          isArchived = get(data, 'isArchived', false);
        }

        return size(references) > 3 ? (
          <div>
            <SeeMore
              minCount={3}
              dataSet={references}
              CustomComponent={referenceGroup}
              isView={true}
              rest={{ isArchived }}
            />
          </div>
        ) : (
          <div>
            {map(references, (ref) => {
              return (
                <label
                  className={
                    isGoalArchived
                      ? // isArchived
                        'reference-mainText-muted item'
                      : 'core-data-label'
                  }
                >
                  {!isEmpty(ref.crossReferenceLabel) ? (
                    <ShowMoreText
                      lines={2}
                      more="Read More"
                      less="Read Less"
                      anchorClass="show-more-less-clickable"
                      expanded={false}
                      truncatedEndingComponent={'... '}
                      className="pre-line"
                    >
                      <p>{ref.crossReferenceLabel}</p>
                    </ShowMoreText>
                  ) : (
                    '-'
                  )}
                </label>
              );
            })}
          </div>
        );
      },
    },
    {
      title: '',
      width: 1,
      render: (data) => {
        const goalTitle = get(data, 'goal.goalTitle', '');
        const isGoalArchived = get(data, 'goal.isArchived', false);
        const goalCrossReferenceId = data.id;
        const isArchived = get(data, 'isArchived', false);

        const references = get(data, 'references', []);

        const goalId = get(data, 'goalId', null);
        const goalReferenceId = get(data, 'id');

        return (
          <div className="action-container">
            {isArchived || isGoalArchived ? (
              <UpdateReference
                {...props}
                user={user}
                crossRefActiveDoc={crossRefActiveDoc}
                goalId={data.goalId}
                goalCrossReferenceId={goalCrossReferenceId}
                goalTitle={goalTitle}
                references={references}
                goalOptions={goalOptions}
              />
            ) : (
              <ReferenceUpdate
                {...props}
                user={user}
                crossRefActiveDoc={crossRefActiveDoc}
                goalId={data.goalId}
                goalCrossReferenceId={goalCrossReferenceId}
                goalTitle={goalTitle}
                references={references}
              />
            )}

            <ArchiveGoalReferences
              goalId={goalId}
              goalReferenceId={goalReferenceId}
              crossRefActiveDoc={crossRefActiveDoc}
              user={user}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Segment
        className="org-cross-ref-segment"
        loading={organisationGoalsReferencesLoading}
      >
        <div className="financial-method-container content-segment-settings">
          <Grid>
            <Grid.Row>
              <Grid.Column width={5}>
                <div className="financial-method-radio">
                  <label className="ref-label">Reference Document</label>

                  <span>
                    <div className="ref-download-link">
                      {!isEmpty(crossRefCurrentDoc) ? (
                        crossRefCurrentDoc.document ? (
                          <div>
                            <img
                              className="plus-icon"
                              alt="plus"
                              src={downloadIcon}
                            />{' '}
                            <a
                              href={crossRefCurrentDoc.document.documentUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {crossRefCurrentDoc.documentTitle}
                            </a>
                          </div>
                        ) : (
                          <span className="ref-label-code">
                            {crossRefCurrentDoc.documentTitle}
                          </span>
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </span>
                </div>
              </Grid.Column>
              <Grid.Column width={5}>
                <div className="financial-method-radio">
                  <label className="ref-label">Reference Field Label </label>
                  <span className="ref-label-code">
                    {!isEmpty(crossRefCurrentDoc)
                      ? crossRefCurrentDoc.referenceFieldLabel
                      : ''}
                  </span>
                </div>
              </Grid.Column>
              <Grid.Column width={6}>
                <div className="menu-dropdown">
                  <Dropdown
                    className="select-hover"
                    icon="ellipsis vertical"
                    direction="left"
                    inline
                    selectOnBlur={false}
                  >
                    <Dropdown.Menu>
                      <CrossReferenceDocumentUpdateModal
                        {...props}
                        crossRefDocuments={crossRefDocuments}
                        user={user}
                        crossRefCurrentDoc={crossRefCurrentDoc}
                      />

                      {/* <Dropdown.Item
                        text="Manage Groups"
                        className="dropdown-customItem"
                      > */}
                      <CrossReferenceDocumentCreateModal
                        {...props}
                        user={user}
                        crossRefCurrentDoc={crossRefCurrentDoc}
                      />
                      {/* </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        <ReferenceCreate
          {...props}
          user={user}
          crossRefActiveDoc={crossRefActiveDoc}
          goalOptions={goalOptions}
        />

        <CustomTable
          header
          columns={columns}
          data={orgGoalsReferences}
          customClass="org-ref-table"
          height={true}
          emptyMessage="No reference have been added"
          keyExtractor={(item, index) => item.id}
        />
      </Segment>
    </div>
  );
};
