import React from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Segment,
  Modal,
  Button,
  Icon,
  Label,
  Form,
  Dropdown,
  Message,
} from 'semantic-ui-react';
import { assign, isEmpty, get } from '../../../../utils/lodash';

import { EmptyContainer, CustomTable } from '../../../../components';
import {
  getOrganizationUsers,
  getOrganizationUsersList,
  createOrganizationUsers,
  updateOrgUserType,
  resetUserPassword,
  deleteOrganizationUser,
  getUserActivitiesCount,
  getDepartmentsLists,
  getDepartmentGoals,
  getDepartmentUnits,
  assignDepartmentToUser,
} from '../../../../store/actions';

import '../organization.scss';
import WithdrawUserConfirmationModal from './withdrawUserConfirmationModal';
import PasswordChange from '../../../../components/passwordChange';
import {
  addNewUserByOrgInitialValues,
  addNewUserByOrgValidationSchema,
  assignDepartmentValidationSchema,
  assignDepartmentValues,
} from '../../../../validations/addNewUserByOrgValidation';
import { Formik } from 'formik';
import {
  CustomInput,
  CustomPasswordInput,
  CustomDropdown,
} from '../../../../components/form';
import { LIMIT } from '../../../../utils/constant';
import { authMsg, stopPropagation } from '../../../../utils';
import { map } from 'jquery';

class UserTypeForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleValidSubmit(event, values) {
    const { updateOrgUserType, toggle, user, resetPage } = this.props;
    let form = {
      role: {},
    };
    if (values.role === 'organisationAdmin') {
      form.role.organisationAdmin = true;
    } else if (values.role === 'user') {
      form.role.organisationAdmin = false;
    } else {
      return;
    }
    updateOrgUserType(user.id, form);
    this.form && this.form.reset();
    if (resetPage) resetPage();
    toggle();
  }

  render() {
    const { toggle, user } = this.props;
    const defaultValue = user;
    return (
      <AvForm
        model={defaultValue}
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        className="venue-form"
      >
        <AvField
          label="User type"
          className="org-inputs"
          name="role"
          type="select"
          validate={{
            required: {
              value: true,
              errorMessage: 'User type is required',
            },
          }}
        >
          <option value="" disabled defaultValue>
            Select an User type
          </option>
          <option value="organisationAdmin">Organisation Admin</option>
          <option value="user">User</option>
        </AvField>
        <div className="model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button content="Update" type="submit" className="Primary-Button" />
        </div>
      </AvForm>
    );
  }
}

class PassChangeForm extends React.Component {
  handleValidSubmit(values) {
    const { user, toggle, resetUserPassword } = this.props;

    const form = assign(user, values);
    resetUserPassword(form);
    this.form && this.form.reset();
    toggle();
  }

  render() {
    const { toggle } = this.props;
    return (
      <PasswordChange
        toggle={() => toggle()}
        handleValidSubmit={(values) => this.handleValidSubmit(values)}
      />
    );
  }
}

const ManageUserFilter = ({ organization, departments, ...props }) => {
  const dispatch = useDispatch();
  const renderDepartmentsNames = () => {
    const data = [
      { key: 'None', text: 'None', value: 'None' },
      ...departments.map((item) => ({
        text: item.name,
        value: item.id,
      })),
    ];
    return data;
  };

  const renderDepartmentsUnits = () => {
    const { departmentUnits } = organization;

    const data = [
      { key: 'All', text: 'All', value: 'All' },
      ...departmentUnits.map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
        rest: {
          key: item.id,
        },
      })),
    ];
    return data;
  };

  const onDepartmentChange = (value, setFieldValue) => {
    const currentPage = 1;
    if (value !== 'None') {
      dispatch(getDepartmentUnits(value));

      setFieldValue('unitId', 'All');
      dispatch(getOrganizationUsersList(currentPage, LIMIT, value));
    } else {
      dispatch(getOrganizationUsersList(currentPage));
    }
  };

  const onUnitChange = (value, departmentId) => {
    const currentPage = 1;
    if (value !== 'All') {
      dispatch(getOrganizationUsersList(currentPage, LIMIT, null, value));
    } else {
      if (departmentId !== 'None') {
        dispatch(getOrganizationUsersList(currentPage, LIMIT, departmentId));
      } else {
        dispatch(getOrganizationUsersList(currentPage));
      }
    }
  };

  const { departmentUnits } = organization;

  const departmentsNames = renderDepartmentsNames();
  const unitsNames = renderDepartmentsUnits();
  const departmentUnitLoading = useSelector(
    (state) => state.organization.departmentUnitLoading
  );

  return (
    <Formik initialValues={{ departmentId: 'None', unitId: 'All' }}>
      {({ values, setFieldValue }) => (
        <Form>
          <div className="manage-user-drop">
            {!isEmpty(departments) && (
              <div className="manage-user-container">
                <label>Department</label>
                <br />
                <CustomDropdown
                  className="manage-user-type"
                  name="departmentId"
                  id="departmentId"
                  selection
                  options={departmentsNames}
                  getValue={(value) => onDepartmentChange(value, setFieldValue)}
                />
              </div>
            )}

            {values.departmentId !== 'None' &&
              !isEmpty(departmentUnits) &&
              !departmentUnitLoading && (
                <div className="manage-user-container">
                  <label>Unit </label>
                  <br />
                  <CustomDropdown
                    className="manage-user-type"
                    name="unitId"
                    id="unitId"
                    selection
                    options={unitsNames}
                    getValue={(value) =>
                      onUnitChange(value, values.departmentId)
                    }
                  />
                </div>
              )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

class EditUserForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteDepartment: false,
      departmentId: this.props.departmentId,
      departmentUnits: this.props.departmentUnits,
    };
  }

  async handleValidSubmit(values, setSubmitting) {
    try {
      const { toggle, assignDepartmentToUser, auth, user } = this.props;
      if (values.unitId === '') {
        delete values.unitId;
      }

      await assignDepartmentToUser(user.id, values);
      toggle();
    } catch (error) {
      setSubmitting(false);
    }
  }

  validate = (value, ctx, input, cb) => {
    const { auth } = this.props;
    const domain = auth.user.organisation ? auth.user.organisation.domain : '-';
    let getDomain = value.split('@');

    return getDomain[1] === domain
      ? true
      : 'Email does not match with organisation email domain.';
  };

  renderDepartmentsNames(departments, isUnit = false) {
    const data = [
      {
        key: '',
        text: isUnit ? 'No unit' : 'No department',
        value: '',
      },
    ];
    map(departments, (item, key) => {
      data.push({
        key,
        text: item.name,
        value: item.id,
        rest: {
          key: item.id,
        },
      });
    });
    return data;
  }

  async onChange(value) {
    this.setState({
      departmentId: value,
    });
    if (value && this.state.departmentId !== value) {
      await this.props.getDepartmentUnits(value);
      const { departmentUnits } = this.props.organization;
      if (!isEmpty(departmentUnits)) {
        this.setState({
          isDeleteDepartment: false,
          departmentUnits: departmentUnits,
        });
      } else {
        this.setState({
          isDeleteDepartment: false,
          departmentUnits: null,
        });
      }
    } else {
      this.setState({
        isDeleteDepartment: true,
        departmentUnits: null,
      });
    }
  }

  render() {
    const { toggle, user } = this.props;
    const { departmentId, departmentUnits, isDeleteDepartment } = this.state;
    const departmentsNames = this.renderDepartmentsNames(
      this.props.departments
    );

    const unitNames = departmentUnits
      ? this.renderDepartmentsNames(departmentUnits, true)
      : null;

    return (
      <Formik
        initialValues={assignDepartmentValues(
          user.department,
          user.departmentUnit
        )}
        onSubmit={(values, { setSubmitting }) => {
          this.handleValidSubmit(values, setSubmitting);
        }}
        validationSchema={assignDepartmentValidationSchema}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, errors, values }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <CustomDropdown
                name="departmentId"
                id="departmentId"
                labelText="Department"
                options={departmentsNames}
                getValue={(e) => {
                  this.onChange(e);
                  setFieldValue('unitId', '');
                }}
                placeholder="Select Department"
                selection
                search
              />
              {isDeleteDepartment && this.props.departmentId && (
                <div className="msg-report">
                  <Message
                    color="blue"
                    icon="exclamation circle"
                    content={' Department will be removed from the user'}
                  />
                </div>
              )}
              {unitNames && (
                <CustomDropdown
                  name="unitId"
                  id="unitId"
                  labelText="Unit"
                  options={unitNames}
                  placeholder={values.unit ? 'Select Unit' : 'No Unit Selected'}
                  disabled={departmentId === null ? true : false}
                  loading={this.props.organization.departmentUnitLoading}
                  selection
                  search
                />
              )}

              <div className="model-buttons">
                <Button
                  className="modal-cancel"
                  type="button"
                  onClick={() => toggle()}
                >
                  Cancel
                </Button>
                <Button
                  content="Save"
                  type="submit"
                  className="modal-proceed"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

class UserForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pass: false,
      isShownPass: false,
      departmentId: null,
      departmentUnitsArr: [],
    };
  }

  handleValidSubmit(values) {
    try {
      const { createOrganizationUsers, toggle, auth, resetPage } = this.props;
      const orgId = auth.user.organisation ? auth.user.organisation.id : null;
      //handling departmentId passing as an empty string scenario
      if (!values.departmentId) {
        values.departmentId = null;
      }
      if (values.unitId === '') {
        delete values.unitId;
      }
      if (values.confirmPassword) delete values.confirmPassword;
      createOrganizationUsers(values);
      this.form && this.form.reset();
      if (resetPage) resetPage();
      toggle();
    } catch (error) {
      console.error('UserForm -> handleValidSubmit -> error :', error);
    }
  }

  validate = (value, ctx, input, cb) => {
    const { auth } = this.props;
    const domain = auth.user.organisation ? auth.user.organisation.domain : '-';
    let getDomain = value.split('@');

    return getDomain[1] === domain
      ? true
      : 'Email does not match with organisation email domain.';
  };

  renderDepartmentsNames() {
    const { departments } = this.props;
    const data = [
      { key: '', text: 'No department', value: null, rest: { key: '' } },
    ];
    map(departments, (item, key) => {
      data.push({
        key,
        text: item.name,
        value: item.id,
        rest: { key: item.id },
      });
    });
    return data;
  }

  async onChange(value) {
    this.setState({
      departmentId: value,
    });
    if (value && this.state.departmentId !== value) {
      await this.props.getDepartmentUnits(value);
      const { departmentUnits } = this.props.organization;
      this.setState({
        departmentUnitsArr: departmentUnits,
      });
    } else {
      this.setState({
        departmentUnitsArr: null,
      });
    }
  }

  renderDepartmentsUnits(departmentUnitsArr) {
    const data = [
      {
        key: '',
        text: 'No unit',
        value: null,
        rest: { key: '' },
      },
    ];
    map(departmentUnitsArr, (item) => {
      data.push({
        key: item.id,
        text: item.name,
        value: item.id,
        rest: { key: item.id },
      });
    });
    return data;
  }

  render() {
    const { isShownPass, departmentId, departmentUnitsArr } = this.state;

    const { auth, toggle } = this.props;
    let settings = get(auth, 'user.organisation.settings', [null]);
    [settings] = settings;

    const isDepartmentOn = settings
      ? get(settings, 'department', false)
      : false;

    const departmentsNames = this.renderDepartmentsNames();

    const units = departmentUnitsArr
      ? this.renderDepartmentsUnits(departmentUnitsArr)
      : null;

    return (
      <Formik
        initialValues={addNewUserByOrgInitialValues(isDepartmentOn)}
        onSubmit={(values) => {
          this.handleValidSubmit(values);
        }}
        validationSchema={addNewUserByOrgValidationSchema(isDepartmentOn)}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, errors }) => (
          <Form onSubmit={handleSubmit}>
            <div className="formik-custom-field-margin-top-zero">
              <CustomInput
                name="firstName"
                id="firstName"
                labelText="First Name"
                placeholder="Enter First Name"
              />
            </div>
            <CustomInput
              name="surname"
              id="surname"
              labelText="Surname"
              placeholder="Enter Surname"
            />

            <CustomInput
              name="email"
              id="email"
              labelText="Email"
              placeholder="Enter email"
            />

            {isDepartmentOn && (
              <>
                <CustomDropdown
                  name="departmentId"
                  id="departmentId"
                  labelText="Department"
                  options={departmentsNames}
                  getValue={(e) => this.onChange(e)}
                  placeholder="Select Department"
                  selection
                />

                {departmentId && !isEmpty(departmentUnitsArr) && (
                  <CustomDropdown
                    name="unitId"
                    id="unitId"
                    labelText="Unit"
                    options={units}
                    placeholder="Select Unit"
                    selection
                    loading={this.props.organization.departmentUnitLoading}
                    disabled={this.state.departmentId === null ? true : false}
                  />
                )}
              </>
            )}

            <CustomPasswordInput
              id="password"
              name="password"
              labelText="New Password"
              placeholder="New Password"
            />

            <CustomInput
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm Password"
              labelText="Confirm Password"
              type={isShownPass ? 'text' : 'password'}
              icon={
                <Icon
                  name={isShownPass ? 'eye' : 'eye slash'}
                  onClick={() => {
                    this.setState({ isShownPass: !isShownPass });
                  }}
                  link
                />
              }
            />

            <div className="model-buttons">
              <Button
                className="modal-cancel"
                type="button"
                onClick={() => toggle()}
              >
                Cancel
              </Button>
              <Button content="Save" type="submit" className="modal-proceed" />
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

class UserDelete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  async toggle() {
    const { modal } = this.state;
    const { user, getUserActivitiesCount } = this.props;
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
    !modal && (await getUserActivitiesCount(user.id));
  }

  async handleDeleteOrgUser() {
    const {
      deleteOrganizationUser,
      user,
      resetPage,
      withdrawUserConfirmationModalHandler,
      userActivities,
    } = this.props;
    const ownedActivities =
      userActivities.inActiveOwned +
      userActivities.inDevelopmentOwned +
      userActivities.completedOwned;

    if (ownedActivities > 0) {
      authMsg(
        'error',
        `User has ${ownedActivities} owned activities and, Activities for this user must first be reassigned.`
      );
      this.toggle();
    } else if (
      userActivities.inDevelopmentInvited > 0 ||
      userActivities.inActiveInvited > 0
    ) {
      this.toggle();
      withdrawUserConfirmationModalHandler(true, user);
    } else {
      deleteOrganizationUser(user.id);
      if (resetPage) resetPage();
      this.toggle();
    }
  }

  render() {
    const { modal } = this.state;
    const { user } = this.props;

    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        onClick={(e) => stopPropagation(e)}
        onFocus={(e) => stopPropagation(e)}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="mini"
        trigger={this.props.trigger}
        className="delete-user"
      >
        <Modal.Header>Delete User - {user.name}</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this user?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.toggle()} className="Secondary-Button">
            No
          </Button>
          <Button
            onClick={() => this.handleDeleteOrgUser()}
            className="Primary-Button"
            content="Yes"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

class UserPassChange extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    const { user } = this.props;
    return (
      <Modal
        trigger={
          <Dropdown.Item>
            <label className="black-text">Reset Password</label>
          </Dropdown.Item>
        }
        size="tiny"
        open={modal}
        onOpen={() => this.toggle()}
        onClick={(e) => stopPropagation(e)}
        onFocus={(e) => stopPropagation(e)}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
      >
        <Modal.Header>Reset Password - {user.name}</Modal.Header>
        <Modal.Content>
          <PassChangeForm toggle={() => this.toggle()} {...this.props} />
        </Modal.Content>
      </Modal>
    );
  }
}

class UserTypeChange extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    const { user } = this.props;
    return (
      <Modal
        trigger={
          <Dropdown.Item>
            <label className="black-text">Change User Type</label>
          </Dropdown.Item>
        }
        size="small"
        open={modal}
        onOpen={() => this.toggle()}
        onClick={(e) => stopPropagation(e)}
        onFocus={(e) => stopPropagation(e)}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
      >
        <Modal.Header>Change User Type - {user.name}</Modal.Header>
        <Modal.Content>
          <UserTypeForm toggle={() => this.toggle()} {...this.props} />
        </Modal.Content>
      </Modal>
    );
  }
}

class UserCreate extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    return (
      <div className="add-user">
        <Modal
          trigger={
            <Button className="Primary-Button">
              <Icon name="plus" />
              Add New User
            </Button>
          }
          size="small"
          open={modal}
          onOpen={() => this.toggle()}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
          className="add-new-user"
        >
          <Modal.Header>Add New User</Modal.Header>
          <Modal.Content>
            <UserForm toggle={() => this.toggle()} {...this.props} />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class EditUser extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      departmentUnits: null,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async componentDidMount() {
    const { user } = this.props;

    if (!isEmpty(user.department)) {
      const departmentId = user.department.id;
      this.setState({
        departmentId,
      });

      await this.props.getDepartmentUnits(departmentId);
      const { departmentUnits } = this.props.organization;

      if (!isEmpty(departmentUnits)) {
        this.setState({
          departmentUnits: departmentUnits,
        });
      } else {
        this.setState({
          departmentUnits: null,
        });
      }
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { user } = this.props;

    if (!isEmpty(user.department) && user !== prevProps.user) {
      const departmentId = user.department.id;
      this.setState({
        departmentId,
      });

      await this.props.getDepartmentUnits(departmentId);
      const { departmentUnits } = this.props.organization;

      if (!isEmpty(departmentUnits)) {
        this.setState({
          departmentUnits: departmentUnits,
        });
      } else {
        this.setState({
          departmentUnits: null,
        });
      }
    }
  }

  componentWillUnmount() {
    this.setState({
      departmentUnits: null,
    });
  }

  render() {
    const { modal, departmentUnits } = this.state;

    return (
      <Modal
        trigger={
          <Dropdown.Item>
            <label className="black-text"> Edit Department</label>
          </Dropdown.Item>
        }
        size="small"
        open={modal}
        onOpen={() => this.toggle()}
        onClick={(e) => stopPropagation(e)}
        onFocus={(e) => stopPropagation(e)}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        className="add-new-user"
      >
        <Modal.Header>Edit Department</Modal.Header>
        <Modal.Content>
          <EditUserForm
            toggle={() => this.toggle()}
            {...this.props}
            departmentId={get(this.props, 'user.department.id', null)}
            departmentUnits={departmentUnits}
          />
        </Modal.Content>
      </Modal>
    );
  }
}

class OrganizationUserManagement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      withdrawUserConfirmationModal: false,
      selectedUser: {},
    };
  }

  columns = [
    {
      title: 'User Name',
      render: (data) => {
        return (
          <div className="org-users-container">
            <span className="org-users-cdn-container">
              <label className="org-users-mainText">{data.name}</label>
              {String(data.email).includes('cdn-admin@') ? (
                <Label color="orange" horizontal className="cdn-admin">
                  cdn-admin
                </Label>
              ) : null}
            </span>
            <label className="org-users-text">
              <b>Type: </b>
              {this.renderUserType(data.role)}
            </label>
          </div>
        );
      },
      width: 4,
    },

    {
      title: 'Email',
      render: (data) => {
        return <label className="org-users-text">{data.email}</label>;
      },
      width: 4,
    },
    {
      title: 'Phone',
      render: (data) => {
        return data.phone ? (
          <label className="org-users-text">{data.phone}</label>
        ) : null;
      },
      width: 3,
    },
    {
      title: '',
      render: (data) => {
        const { auth } = this.props;
        return (
          <div className="invited">
            {data.role !== 'admin' && data.id !== auth.user.id ? (
              <Dropdown
                icon="ellipsis vertical"
                direction="left"
                inline
                selectOnBlur={false}
              >
                <Dropdown.Menu>
                  <UserTypeChange
                    user={data}
                    {...this.props}
                    resetPage={() => this.resetPage()}
                  />
                  <UserPassChange
                    user={data}
                    {...this.props}
                    resetPage={() => this.resetPage()}
                  />
                  <UserDelete
                    user={data}
                    {...this.props}
                    resetPage={() => this.resetPage()}
                    withdrawUserConfirmationModalHandler={(status, user) =>
                      this.withdrawUserConfirmationModalHandler(status, user)
                    }
                    trigger={
                      <Dropdown.Item
                        text="Remove"
                        className="dropdown-customItem"
                      >
                        <div className="remove-activity">
                          <span>Remove</span>
                        </div>
                      </Dropdown.Item>
                    }
                  />
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <div></div>
            )}
          </div>

          // <div className="org-users-actions">
          //   {data.role !== 'admin' && data.id !== auth.user.id ? (
          //     <Fragment>

          //     </Fragment>
          //   ) : (
          //     ''
          //   )}
          // </div>
        );
      },
      width: 1,
    },
  ];

  withDepartmentColumns = [
    {
      title: 'User Name',
      render: (data) => {
        return (
          <div className="org-users-container">
            <span className="org-users-cdn-container">
              <label className="org-users-mainText">{data.name}</label>
              {String(data.email).includes('cdn-admin@') ? (
                <Label color="orange" horizontal className="cdn-admin">
                  cdn-admin
                </Label>
              ) : null}
            </span>
            <label className="org-users-text">
              <b>Type: </b>
              {this.renderUserType(data.role)}
            </label>
          </div>
        );
      },
      width: 4,
    },
    {
      title: 'Department',
      render: (data) => {
        return get(data, 'department.name', '-');
      },
      width: 2,
    },

    {
      title: 'Unit',
      render: (data) => {
        return get(data, 'departmentUnit.name', '-');
      },
      width: 2,
    },

    {
      title: 'Email',
      render: (data) => {
        return <label className="org-users-text">{data.email}</label>;
      },
      width: 4,
    },
    {
      title: 'Phone',
      render: (data) => {
        return data.phone ? (
          <label className="org-users-text">{data.phone}</label>
        ) : null;
      },
      width: 3,
    },
    {
      title: '',
      render: (data) => {
        const { auth } = this.props;
        return (
          <div className="invited">
            {data.role !== 'admin' && data.id !== auth.user.id ? (
              <Dropdown
                icon="ellipsis vertical"
                direction="left"
                inline
                selectOnBlur={false}
              >
                <Dropdown.Menu>
                  <EditUser
                    user={data}
                    departments={this.props.organization.deptList}
                    {...this.props}
                  />

                  <UserTypeChange
                    user={data}
                    {...this.props}
                    resetPage={() => this.resetPage()}
                  />

                  <UserPassChange
                    user={data}
                    {...this.props}
                    resetPage={() => this.resetPage()}
                  />

                  <Dropdown.Item>
                    <UserDelete
                      user={data}
                      {...this.props}
                      resetPage={() => this.resetPage()}
                      withdrawUserConfirmationModalHandler={(status, user) =>
                        this.withdrawUserConfirmationModalHandler(status, user)
                      }
                      trigger={
                        <Dropdown.Item
                          text="Remove"
                          className="dropdown-customItem"
                        >
                          <div className="remove-activity">
                            <span>Remove</span>
                          </div>
                        </Dropdown.Item>
                      }
                    />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <div></div>
            )}
          </div>

          // <div className="org-users-actions">
          //   {data.role !== 'admin' && data.id !== auth.user.id ? (
          //     <Fragment>

          //     </Fragment>
          //   ) : (
          //     ''
          //   )}
          // </div>
        );
      },
      width: 1,
    },
  ];

  withdrawUserConfirmationModalHandler(status, selectedUser) {
    this.setState({ withdrawUserConfirmationModal: status, selectedUser });
  }

  resetPage() {
    this.setState({
      currentPage: 1,
    });
  }

  renderUserType(role) {
    switch (role) {
      case 'admin':
        return 'Super Admin';
      case 'organisationAdmin':
        return 'Organisation Admin';
      case 'user':
        return 'User';
      case 'guest':
        return 'Guest';
      default:
        break;
    }
  }

  async componentDidMount() {
    const { currentPage } = this.state;
    const {
      getOrganizationUsersList,
      getOrganizationUsers,
      auth,
      getDepartmentsLists,
    } = this.props;
    if (!isEmpty(auth.user) && auth.user.organisation) {
      await Promise.all([
        getOrganizationUsers(),
        getOrganizationUsersList(currentPage),
        getDepartmentsLists(),
      ]);
    }
  }

  pageChange(page) {
    const { auth, getOrganizationUsersList } = this.props;
    this.setState(
      {
        currentPage: page,
      },
      () => {
        if (!isEmpty(auth.user) && auth.user.organisation) {
          getOrganizationUsersList(page);
        }
      }
    );
  }

  render() {
    const { auth } = this.props;
    const { currentPage } = this.state;
    const { loading, orgUsersLoading, orgUsersList } = this.props.organization;
    const [orgDepartment] = get(auth, 'user.organisation.settings', [
      { department: false },
    ]);

    let settings = get(auth, 'user.organisation.settings', [null]);
    [settings] = settings;

    const isDepartmentOn = settings
      ? get(settings, 'department', false)
      : false;

    return (
      <Segment
        className="content-segment org-users"
        loading={loading || orgUsersLoading}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="manage-user-drop">
                {orgDepartment.department && (
                  <ManageUserFilter
                    departments={this.props.organization.deptList}
                    {...this.props}
                  />
                )}

                <div className="manage-user-addUser">
                  <UserCreate
                    departments={this.props.organization.deptList}
                    {...this.props}
                    resetPage={() => this.resetPage()}
                  />
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {orgUsersList.docs && orgUsersList.docs.length ? (
          <div className="org-table-container">
            <CustomTable
              header
              columns={
                isDepartmentOn ? this.withDepartmentColumns : this.columns
              }
              data={orgUsersList.docs}
              customClass="org-users-table"
              pagination
              handlePaginationChange={(page) => this.pageChange(page)}
              page={currentPage}
              noOfPages={orgUsersList.pages}
              keyExtractor={(item, index) => item.id}
            />
          </div>
        ) : (
          <EmptyContainer msg="No users have been added…" />
        )}

        <div>
          <WithdrawUserConfirmationModal
            user={this.state.selectedUser}
            userActivities={this.props.userActivities}
            // {...this.props}
            resetPage={() => this.resetPage()}
            modal={this.state.withdrawUserConfirmationModal}
            handleModal={(status) =>
              this.withdrawUserConfirmationModalHandler(status)
            }
          />
        </div>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    organization: state.organization,
    userActivities: state.organization.userActivities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrganizationUsers,
      getOrganizationUsersList,
      createOrganizationUsers,
      updateOrgUserType,
      resetUserPassword,
      deleteOrganizationUser,
      getUserActivitiesCount,
      getDepartmentsLists,
      getDepartmentGoals,
      getDepartmentUnits,
      assignDepartmentToUser,
    },
    dispatch
  );
};

OrganizationUserManagement = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationUserManagement);

export { OrganizationUserManagement };
