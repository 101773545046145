import React, { Component } from 'react';
import { useState } from 'react';
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from 'availity-reactstrap-validation';
import {
  CustomCheckbox,
  CustomPasswordInput,
} from '../../../../components/form';
import { Formik, Form } from 'formik';
import { CustomInput, CustomDropdown } from '../../../../components/form';
import {
  getAdminOrgUserCreateInitialValues,
  getAdminUserCreateValidationSchemaOrg,
} from '../../../../validations/AddNewIndOrOrgByAdminValidation';
import { getAdminUserCreateValidationSchemaIndv } from '../../../../validations/AddNewIndOrOrgByAdminValidation';
import { isBrowser, isTablet } from 'react-device-detect';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  Dropdown,
  Grid,
  Icon,
  Modal,
  Form as SemanticForm,
  Popup,
  Radio,
  Segment,
  Search,
  Label,
} from 'semantic-ui-react';

import {
  AvFieldUpload,
  CustomTable,
  EmptyContainer,
  CustomTooltip,
} from '../../../../components';
import {
  find,
  isEmpty,
  map,
  debounce,
  forEach,
  sortedUniq,
  uniq,
  get,
} from '../../../../utils/lodash';
import {
  BETA_LAUNCH_ADMIN_EMAIL,
  DEFAULT_CURRENCY_TYPE_ID,
  INVOICE_STATUS_OPTIONS,
} from '../../../../utils/constant';
import {
  getAustralianStates,
  getIndustrySectorTypes,
  getOrgAll,
  postOrganisation,
  updateOrganisationDetails,
  updateOrganizationLogo,
  getSubscriptions,
  getPaymentPreferences,
  getInvoices,
  updateMarkBy,
  getFeatures,
  getPermissionGroups,
  postAccountPermissionGroups,
  getCurrencyTypes,
} from '../../../../store/actions';

import moment from 'moment';
import { getInvoicesStatus, stopPropagation } from '../../../../utils';

const account_option = [
  {
    key: 'All',
    text: <b>All</b>,
    value: 'all',
  },
  {
    key: 'organisation',
    text: <b>Organisation</b>,
    value: 'organisation',
  },
  {
    key: 'individual',
    text: <b>Individual</b>,
    value: 'individual',
  },
];

const status_option = [
  {
    key: 'all',
    text: <b>All</b>,
    value: 'all',
  },
  {
    key: 'active',
    text: <b>Active</b>,
    value: 'active',
  },
  {
    key: 'inactive',
    text: <b>Inactive</b>,
    value: 'inactive',
  },
];

class Organizations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      currentPage: 1,
      accType: 'all',
      value: '',
      statusType: 'active',
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async componentDidMount() {
    const {
      getIndustrySectorTypes,
      getAustralianStates,
      getSubscriptions,
      getPaymentPreferences,
      getCurrencyTypes,
    } = this.props;
    await Promise.all([
      getAustralianStates(),
      getIndustrySectorTypes(),
      getSubscriptions(),
      getPaymentPreferences(),
      getCurrencyTypes(),
    ]);
  }

  pageChange(page) {
    const { getOrgAll } = this.props;
    const { accType, statusType, value } = this.state;
    this.setState(
      {
        currentPage: page,
      },
      async () => {
        await getOrgAll(page, accType, statusType, value);
      }
    );
  }

  setFilterAccount(accType) {
    this.setState(
      {
        accType,
        currentPage: 1,
      },
      async () => {
        const { getOrgAll } = this.props;
        const { currentPage, value, statusType, accType } = this.state;
        await getOrgAll(currentPage, accType, statusType, value);
      }
    );
  }

  setFilterStatus(statusType) {
    this.setState(
      {
        statusType,
        currentPage: 1,
      },
      async () => {
        const { getOrgAll } = this.props;
        const { currentPage, accType, statusType, value } = this.state;
        await getOrgAll(currentPage, accType, statusType, value);
      }
    );
  }

  handleSearchChange = (e, { value }) => {
    this.setState({ value }, async () => {
      const { getOrgAll } = this.props;
      const { currentPage, accType, statusType } = this.state;
      await getOrgAll(currentPage, accType, statusType, value);
    });
  };

  render() {
    const { modal, accType, value, statusType } = this.state;
    const { industrySectorTypes, currencyTypes, auth } = this.props;

    const currencyTypeOptions = currencyTypes
      ? currencyTypes.map((item) => ({
          key: item.id,
          value: item.id,
          text: `${item.name} (${item.sign})`,
        }))
      : [];

    const loggedUserEmail = get(auth, 'user.email', null);
    const isBetaLaunchAdmin =
      loggedUserEmail && loggedUserEmail === BETA_LAUNCH_ADMIN_EMAIL;

    return (
      <div>
        <div className="outcome-admin">
          <div className="user-search-account-settings">
            <div className="search-bar">
              <Search
                onSearchChange={debounce(this.handleSearchChange, 1000, {
                  leading: true,
                })}
                value={value}
                placeholder="Search by name / email"
              />
            </div>
            <div className="button-outcome">
              <Modal
                open={modal}
                onOpen={() => this.toggle()}
                closeIcon={
                  <Icon
                    onClick={() => this.toggle()}
                    name="close"
                    className="closeicon"
                  />
                }
                className="contentOrganization-edit"
                size="large"
                trigger={
                  <Button className="Primary-Button">
                    <div className="add-icon-activity-add">
                      <Icon name="plus circle" />
                      <div className="add-text">Add New Account</div>
                    </div>
                  </Button>
                }
              >
                <Modal.Header>
                  {' '}
                  <div className="add-org-header">Add New Account</div>
                </Modal.Header>

                <Modal.Content>
                  <CreateNewOrganization
                    toggle={() => this.toggle()}
                    isBetaLaunchAdmin={isBetaLaunchAdmin}
                    currencyTypeOptions={currencyTypeOptions}
                    {...this.props}
                    {...this.state}
                    industrySectorTypes={industrySectorTypes}
                  />
                </Modal.Content>
              </Modal>
            </div>
          </div>
        </div>
        <div className="outcome-admin-less-padding">
          <div className="user-search-account-filter">
            <div className="account-filter-title">Account Type:</div>
            <div className="account-filter">
              <Dropdown
                direction="right"
                inline
                selectOnBlur={false}
                options={account_option}
                value={accType}
                onChange={(e, { value }) => this.setFilterAccount(value)}
              />
            </div>

            <div className="status-filter-title ">Status:</div>
            <div className="status-filter">
              <Dropdown
                direction="right"
                inline
                selectOnBlur={false}
                options={status_option}
                value={statusType}
                onChange={(e, { value }) => this.setFilterStatus(value)}
              />
            </div>
          </div>
        </div>
        <OrganizationList
          {...this.props}
          isBetaLaunchAdmin={isBetaLaunchAdmin}
          currencyTypeOptions={currencyTypeOptions}
          industrySectorTypes={industrySectorTypes}
          pageChange={(page) => this.pageChange(page)}
          currentPage={this.state.currentPage}
          accType={this.state.accType}
          statusType={this.state.statusType}
          keyword={this.state.value}
        />
      </div>
    );
  }
}

const CreateNewOrganization = ({
  toggle,
  postOrganisation,
  subscriptions,
  paymentPreferences,
  admin,
  currencyTypeOptions,
  isBetaLaunchAdmin,
  ...props
}) => {
  const [isOrganisation, setIsOrganisation] = useState(true);
  const [isDemo, setIsDemo] = useState(false);
  const [isEvidenceBlock, setIsEvidenceBlock] = useState(false);
  const [isTaksoAdminEmail, setIsTaksoAdminEmail] = useState(false);

  const handleInputChange = (name) => {
    switch (name) {
      case 'isOrganisation':
        setIsOrganisation(!isOrganisation);
        break;
      case 'isDemo':
        setIsDemo(!isDemo);
        break;
      case 'isEvidenceBlock':
        setIsEvidenceBlock(!isEvidenceBlock);
        break;
      default:
        break;
    }
  };

  const handleValidSubmit = async (values) => {
    try {
      const orgData = isOrganisation
        ? {
            organisation: {
              domain: values.orgDomain,
              industrySectorTypeId: values.orgIndustrySectorTypeId,
              email: values.orgEmail,
              phone: values.orgPhone,
              name: values.organisationName,
              isDemo: isDemo,
              isEvidenceBlock: isEvidenceBlock,
              currencyTypeId: isBetaLaunchAdmin
                ? values.currencyTypeId
                : DEFAULT_CURRENCY_TYPE_ID,
            },
            user: {
              email: values.userEmail,
              isTaksoAdminEmail,
              firstName: values.userFirstName,
              surname: values.userSurname,
              password: values.password,
            },
          }
        : {
            individual: {
              email: values.userEmailIndv,
              isTaksoAdminEmail,
              password: values.passwordIndv,
              paymentPreferenceId: values.individualPaymentPreferenceId,
              firstName: values.userFirstNameIndv,
              surname: values.userSurnameIndv,
              phone: values.userPhoneIndv,
              subscription: values.individualSubscription,
              isDemo: isDemo,
              isEvidenceBlock: isEvidenceBlock,
              currencyTypeId: isBetaLaunchAdmin
                ? values.currencyTypeId
                : DEFAULT_CURRENCY_TYPE_ID,
            },
          };

      await postOrganisation(orgData);
      toggle();
    } catch (error) {
      console.error('CreateNewOrganization ~ handleValidSubmit ~ error', error);
    }
  };

  const selectType = (value) => {
    setIsOrganisation(value === 'organisation');
  };

  const renderSubscriptions = () => {
    return subscriptions
      .filter((item) => item.accountTypes.includes('INDIVIDUAL'))
      .map((item, key) => {
        return {
          key,
          text: item.title,
          value: item.id.toString(),
        };
      });
  };
  const renderPaymentPreference = () => {
    return paymentPreferences.map((item, key) => {
      return {
        key,
        text: item.title,
        value: item.id,
      };
    });
  };
  return (
    <div>
      <div className="admin-org-user-upper">
        <div className="org-inputs">Account Type and Subscription</div>
        <div className="radio-org-add">
          <label className="admin-org-acc-type">Select Account Type</label>
          <SemanticForm className="org-radio">
            <Grid>
              <Grid.Row>
                <SemanticForm.Field>
                  <Radio
                    label="Individual"
                    name="group"
                    className="org-inputs"
                    value="individual"
                    onChange={(e, { value }) => selectType(value)}
                    checked={!isOrganisation}
                  />
                </SemanticForm.Field>
              </Grid.Row>
              <Grid.Row>
                <SemanticForm.Field>
                  <Radio
                    label="Organisation"
                    name="group"
                    className="org-inputs"
                    value="organisation"
                    onChange={(e, { value }) => selectType(value)}
                    checked={isOrganisation}
                  />
                </SemanticForm.Field>
              </Grid.Row>
            </Grid>
          </SemanticForm>
        </div>
      </div>
      <Formik
        initialValues={getAdminOrgUserCreateInitialValues(admin)}
        onSubmit={(values) => {
          handleValidSubmit(values);
        }}
        validationSchema={
          isOrganisation
            ? getAdminUserCreateValidationSchemaOrg(
                isTaksoAdminEmail,
                isBetaLaunchAdmin
              )
            : getAdminUserCreateValidationSchemaIndv(
                isTaksoAdminEmail,
                isBetaLaunchAdmin
              )
        }
      >
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              {isOrganisation ? (
                <Grid columns={2} divided stackable>
                  <Grid.Row className=" ">
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <>
                        <div className="org-details-header">
                          Organisation Details
                        </div>
                        <OrganizationDetail
                          currencyTypeOptions={currencyTypeOptions}
                          isBetaLaunchAdmin={isBetaLaunchAdmin}
                          onChange={handleInputChange}
                          {...props}
                        />
                      </>
                    </Grid.Column>
                    <Grid.Column
                      mobile={16}
                      tablet={8}
                      computer={8}
                      className="org-modal-column "
                    >
                      <OrganizationForAdmin
                        isTaksoAdminEmail={isTaksoAdminEmail}
                        setIsTaksoAdminEmail={() => {
                          setIsTaksoAdminEmail(!isTaksoAdminEmail);
                        }}
                        {...props}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              ) : (
                <Grid columns={2}>
                  <Grid.Row>
                    <Grid.Column className="">
                      <CustomDropdown
                        name="individualSubscription"
                        id="individualSubscription"
                        labelText="Subscription"
                        options={renderSubscriptions()}
                        placeholder="Select Subscription"
                        selection
                        requiredStar
                        className="org-inputs"
                      />
                    </Grid.Column>
                    <Grid.Column className="org-ind-subscripton">
                      <CustomDropdown
                        name="individualPaymentPreferenceId"
                        id="individualPaymentPreferenceId"
                        labelText="Payment Preference"
                        options={renderPaymentPreference()}
                        placeholder="Select Payment Preference"
                        selection
                        requiredStar
                        className="org-inputs"
                      />
                    </Grid.Column>

                    {isBetaLaunchAdmin && (
                      <Grid.Column>
                        <CustomDropdown
                          name="currencyTypeId"
                          id="currencyTypeId"
                          className="org-inputs"
                          labelText="Currency Type"
                          options={currencyTypeOptions}
                          placeholder="Select currency type"
                          selection
                          requiredStar
                          search
                        />
                      </Grid.Column>
                    )}
                  </Grid.Row>
                  <Grid.Row className="org-details-raw">
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <div>
                        <div className="org-details-header">Admin Details</div>
                        <IndividualDetails
                          isTaksoAdminEmail={isTaksoAdminEmail}
                          onChange={handleInputChange}
                          {...props}
                        />
                      </div>
                    </Grid.Column>
                    <Grid.Column
                      mobile={16}
                      tablet={8}
                      computer={8}
                      className="org-modal-column "
                    >
                      <IndividualRightSide
                        isTaksoAdminEmail={isTaksoAdminEmail}
                        setIsTaksoAdminEmail={() => {
                          setIsTaksoAdminEmail(!isTaksoAdminEmail);
                        }}
                        {...props}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}

              <div className="model-buttons-org">
                <Button
                  className="Secondary-Button"
                  type="button"
                  onClick={() => toggle()}
                >
                  Cancel
                </Button>
                <Button
                  content="Create"
                  type="submit"
                  className="Primary-Button"
                  loading={admin.postOrgLoading}
                  disabled={admin.postOrgLoading}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const OrganizationDetail = ({
  isBetaLaunchAdmin,
  currencyTypeOptions,
  industrySectorTypes,
  onChange,
}) => {
  const selectSectorType = () => {
    return industrySectorTypes.map((item, key) => {
      return {
        key,
        text: item.name,
        value: item.id,
      };
    });
  };

  return (
    <div className="down-modal">
      <CustomInput
        id="organisationName"
        name="organisationName"
        className="org-inputs"
        labelText="Organisation Name"
        requiredStar
      />
      <CustomDropdown
        name="orgIndustrySectorTypeId"
        id="orgIndustrySectorTypeId"
        className="org-inputs"
        labelText="Organisation Sector Type"
        options={selectSectorType()}
        placeholder="Select an Sector"
        selection
        requiredStar
        search
      />{' '}
      {isBetaLaunchAdmin && (
        <CustomDropdown
          name="currencyTypeId"
          id="currencyTypeId"
          className="org-inputs"
          labelText="Currency Type"
          options={currencyTypeOptions}
          placeholder="Select currency type"
          selection
          requiredStar
          search
        />
      )}
      <CustomInput
        id="orgPhone"
        name="orgPhone"
        placeholder="Enter Phone"
        className="org-inputs"
        labelText="Phone"
        requiredStar
      />
      <CustomInput
        id="orgDomain"
        name="orgDomain"
        placeholder="Enter Web Domain"
        className="org-inputs"
        labelText="Web Domain"
        requiredStar
      />
      <CustomInput
        id="orgEmail"
        name="orgEmail"
        placeholder="Enter Email"
        labelText="Email"
        className="org-inputs"
        requiredStar
      />
      <div className="account-type-toggle">
        <div className="edit-outcome-status-bar">
          <div className="two-step-radio-container-admin radio-left">
            <Radio
              toggle
              className="modal-toggle-qs-acctype"
              onChange={() => onChange('isDemo')}
            />
          </div>
          <label>Demo Account</label>
        </div>
        <div className="edit-outcome-status-bar">
          <div className="two-step-radio-container-admin radio-left">
            <Radio
              toggle
              className="modal-toggle-qs-acctype"
              onChange={() => onChange('isEvidenceBlock')}
            />
          </div>
          <label>Block Evidence</label>
        </div>
      </div>
    </div>
  );
};

const OrganizationForAdmin = ({
  isTaksoAdminEmail,
  setIsTaksoAdminEmail,
  ...props
}) => {
  const [isShownPass, setIsShownPass] = React.useState(false);

  return (
    <div>
      <div className="add-org-admin ">
        <div className="org-header">Organisation Admin</div>
      </div>

      <div className="down-modal-admin">
        <CustomInput
          id="userFirstName"
          name="userFirstName"
          placeholder="Enter First Name"
          className="org-inputs"
          labelText="First Name"
          requiredStar
        />
        <CustomInput
          id="userSurname"
          name="userSurname"
          placeholder="Enter Surname"
          className="org-inputs"
          labelText="Surname"
          requiredStar
        />

        <CustomInput
          id="userEmail"
          name="userEmail"
          placeholder="Enter Email"
          className="org-inputs"
          labelText="Email"
          requiredStar
        />

        <CustomCheckbox
          className="check-box"
          id="isTaksoAdminEmail"
          name="isTaksoAdminEmail"
          label="Takso support email  (system won't send emails to this email)"
          checked={isTaksoAdminEmail}
          onChange={() => setIsTaksoAdminEmail()}
        />

        {isTaksoAdminEmail && (
          <>
            <CustomPasswordInput
              id="password"
              name="password"
              className="org-inputs"
              labelText="New Password"
              placeholder="New Password"
            />

            <CustomInput
              id="confirmPassword"
              className="org-inputs"
              name="confirmPassword"
              placeholder="Confirm Password"
              labelText="Confirm Password"
              type={isShownPass ? 'text' : 'password'}
              icon={
                <Icon
                  name={isShownPass ? 'eye' : 'eye slash'}
                  onClick={() => {
                    setIsShownPass(!isShownPass);
                  }}
                  link
                />
              }
            />
          </>
        )}
      </div>
    </div>
  );
};
class OrganizationList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      checked: false,
    };
  }

  getTableColumns() {
    return [
      {
        width: 6,
        title: (
          <div>
            <strong>Account</strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className="blocked-badge-container">
              <div className={!data.isActive ? 'disabled' : ''}>
                {data.name}
              </div>
              {!data.isActive && (
                <Label className="blocked-badge" color="red" size="tiny">
                  Disabled
                </Label>
              )}
              {data.isDemo && (
                <Label className="demo-badge" color="orange" size="tiny">
                  Demo
                </Label>
              )}
              {data.isEvidenceBlock && (
                <Label
                  className="evidence-block-badge"
                  color="black"
                  size="tiny"
                >
                  Evidence Blocked
                </Label>
              )}
            </div>
          );
        },
        textAlign: isBrowser || isTablet ? 'left' : null,
      },
      {
        width: 2,
        title: (
          <div className="user-heading-container">
            <strong>Type</strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className={!data.isActive ? 'disabled' : ''}>
              {data.type === 'ORGANISATION' ? 'Organisation' : 'Individual'}
            </div>
          );
        },
        textAlign: isBrowser || isTablet ? 'centre' : null,
      },
      {
        width: 2,
        title: (
          <div className="user-heading-container">
            <strong>Organisation Code</strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className={!data.isActive ? 'disabled' : ''}>
              {get(data, 'organisationCode', '-')}
            </div>
          );
        },
        textAlign: isBrowser || isTablet ? 'centre' : null,
      },
      {
        width: 2,
        title: (
          <div className="user-heading-container">
            <strong>No. of Users</strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className={!data.isActive ? 'disabled' : ''}>
              {data.noOfUsers}
            </div>
          );
        },
        textAlign: isBrowser || isTablet ? 'centre' : null,
      },
      {
        width: 2,
        title: (
          <div className="user-heading-container">
            <strong>Last Payment</strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className={!data.isActive ? 'disabled' : ''}>
              {!isEmpty(data.invoices)
                ? moment(data.invoices.createdAt).format('ddd DD MMM YYYY')
                : '-'}
            </div>
          );
        },
        textAlign: isBrowser || isTablet ? 'left' : null,
      },
      {
        width: 3,
        title: (
          <div className="user-heading-container">
            <strong>Created Date</strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className={!data.isActive ? 'disabled' : ''}>
              {!isEmpty(data.createdDateTime)
                ? moment(data.createdDateTime).format('ll hh:mm A')
                : '-'}
            </div>
          );
        },
        textAlign: isBrowser || isTablet ? 'left' : null,
      },
      {
        width: 1,
        title: (
          <div className="user-act-heading-container">
            <strong></strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className="owned">
              <Dropdown
                icon="ellipsis vertical"
                direction="left"
                inline
                selectOnBlur={false}
              >
                <Dropdown.Menu>
                  <EditOrganization data={data} {...this.props} />

                  {data.type === 'INDIVIDUAL' ? (
                    <Dropdown.Item
                      text="Billing and Invoices"
                      className="dropdown-customItem"
                    >
                      <BillingInvoices data={data} {...this.props} />
                    </Dropdown.Item>
                  ) : null}
                  <PermissionGroup data={data} {...this.props} />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
        textAlign: isBrowser || isTablet ? 'center' : null,
      },
    ];
  }

  async componentDidMount() {
    try {
      const { currentPage } = this.props;
      const { getOrgAll } = this.props;
      await getOrgAll(currentPage);
    } catch (error) {}
  }

  render() {
    const { currentPage, pageChange } = this.props;

    const { allOrganisations, allOrgLoading } = this.props.admin;

    return (
      <Segment
        className="content-segment p-0"
        loading={allOrgLoading}
        disabled={allOrgLoading}
      >
        {allOrganisations.docs && allOrganisations.docs.length ? (
          <CustomTable
            header
            columns={this.getTableColumns()}
            data={allOrganisations.docs}
            pagination
            handlePaginationChange={(page) => pageChange(page)}
            customClass="super-admin-table tbody-hover"
            page={currentPage}
            noOfPages={allOrganisations.pages}
            keyExtractor={(item, index) => item.id}
          />
        ) : (
          <EmptyContainer msg="No Organisations..." />
        )}
      </Segment>
    );
  }
}

class PermissionGroup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFeatuers: [],
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  setSelectedFeatuers = (featuerList) => {
    this.setState({
      selectedFeatuers: featuerList,
    });
  };

  render() {
    const { modal, selectedFeatuers } = this.state;
    return (
      <div className="button-outcome">
        <Modal
          open={modal}
          onOpen={() => this.toggle()}
          onClick={(e) => stopPropagation(e)}
          onFocus={(e) => stopPropagation(e)}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
          className="account-permission-group"
          size="large"
          trigger={
            <Dropdown.Item>
              <label className="edit-org">
                Edit Permission
                <Icon name="unlock" />
              </label>
            </Dropdown.Item>
          }
        >
          <Modal.Header>
            <div className="add-org-header">Account Permission Group</div>
          </Modal.Header>

          <Modal.Content>
            <PermissionGroupForm
              selectedFeatuers={selectedFeatuers}
              setSelectedFeatuers={this.setSelectedFeatuers}
              toggle={() => this.toggle()}
              {...this.props}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class PermissionGroupForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isActive: true,
      permissionGroupValue: [],
      redundantPermission: [],
      isPermissionEmpty: false,
    };
  }

  async componentDidMount() {
    const { getFeatures, getPermissionGroups, data, setSelectedFeatuers } =
      this.props;
    getFeatures(0);
    getPermissionGroups(0);
    const permissionGroups = [];
    const permissionFeatures = [];
    forEach(data.organisationFeatures, (group) => {
      permissionGroups.push(group.featureGroup.groupId);
      permissionFeatures.push(group.featureGroup.featureId);
    });
    this.setState({
      permissionGroupValue: sortedUniq(permissionGroups),
    });
    setSelectedFeatuers(permissionFeatures);
  }

  async handleValidSubmit(event, values) {
    try {
      const {
        postAccountPermissionGroups,
        pageChange,
        currentPage,
        toggle,
        data,
        selectedFeatuers,
        setSelectedFeatuers,
      } = this.props;
      if (!this.state.permissionGroupValue.length) {
        this.setState({ isPermissionEmpty: true });
        setSelectedFeatuers([]);
        return;
      }

      const isMultiLevelProgramSelected = selectedFeatuers.some(
        (item) => item === 38
      );

      if (isMultiLevelProgramSelected) {
        const isProgramSelected = isMultiLevelProgramSelected
          ? selectedFeatuers.some((item) => [35, 36, 37].includes(item))
          : false;

        if (!isProgramSelected) return;
      }

      values.organisationId = data.id;
      values.permissionGroup = this.state.permissionGroupValue;
      values.features = selectedFeatuers;

      await postAccountPermissionGroups(values);
      pageChange(currentPage);
      toggle();
    } catch (error) {
      error.log('Permission create submit ~~', error);
    }
  }

  getOptions() {
    const { getPermissionGroup, selectedFeatuers } = this.props;
    const { docs } = getPermissionGroup;
    return map(docs, (permission) => {
      const isMultiLevelProgram = permission.name === 'Multi Level Program';
      const isProgramSelected = isMultiLevelProgram
        ? selectedFeatuers.some((item) => [35, 36, 37].includes(item))
        : false;

      return {
        disabled: isMultiLevelProgram && !isProgramSelected,
        key: permission.id,
        text: (
          <>
            {isMultiLevelProgram && !isProgramSelected ? (
              <Popup
                content={<div>Required Program Feature!</div>}
                position="top left"
                trigger={<p>{permission.name}</p>}
              />
            ) : (
              permission.name
            )}
          </>
        ),
        value: permission.id,
        permission: permission,
      };
    });
  }

  featureIsChecked(id) {
    const { selectedFeatuers } = this.props;

    const isExist = find(selectedFeatuers, (feature) => feature === id);
    return isExist ? true : false;
  }

  permissionGroupOnChange(value) {
    this.setState({ permissionGroupValue: uniq(value) });

    const { getPermissionGroup, setSelectedFeatuers } = this.props;
    if (isEmpty(value)) {
      setSelectedFeatuers([]);
    }

    const { docs } = getPermissionGroup;
    let featuerList = [];
    let redundantPermissionGroups = [];
    forEach(value, (PermissionGroupId) => {
      const PermissionGroup = find(docs, (doc) => doc.id === PermissionGroupId);
      let isRedundant = true;
      forEach(PermissionGroup.featureGroup, (feature) => {
        const isExist = find(
          featuerList,
          (featureId) => feature.featureId === featureId
        );
        if (!isExist) {
          featuerList.push(feature.featureId);
          isRedundant = false;
        }
      });
      if (isRedundant) redundantPermissionGroups.push(PermissionGroup.name);
      setSelectedFeatuers(featuerList);
    });

    if (redundantPermissionGroups.length)
      this.setState({
        redundantPermission: redundantPermissionGroups,
        isPermissionEmpty: false,
      });
    else this.setState({ redundantPermission: [], isPermissionEmpty: false });
  }

  render() {
    const { toggle, features, getfeatureLoading } = this.props;
    const { permissionGroupValue, redundantPermission, isPermissionEmpty } =
      this.state;

    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        className="venue-form"
      >
        <label>Permission Group</label>
        {redundantPermission.length ? (
          <div className="invalid-permission-group-custom">
            "{redundantPermission.join('","')}" will be not added since all
            featureGroups are redundant.
          </div>
        ) : null}
        <Dropdown
          placeholder="Permissions"
          fluid
          className="org-inputs"
          multiple
          selection
          options={this.getOptions()}
          value={uniq(permissionGroupValue)}
          onChange={(event, { value }) => this.permissionGroupOnChange(value)}
        />
        {isPermissionEmpty ? (
          <div className="invalid-permission-group-custom">
            Please Select at least one Permission Group
          </div>
        ) : null}

        <label className="mt-4">Features</label>
        <Segment
          className="content-segment mt-0"
          loading={getfeatureLoading}
          disabled={getfeatureLoading}
        >
          {features.docs && features.docs.length ? (
            <AvCheckboxGroup inline name="features">
              <Grid>
                {map(features.docs, (doc, i) => (
                  <Grid.Column
                    key={doc.id}
                    className="permission-features-list"
                    computer={5}
                    verticalAlign="middle"
                  >
                    <CustomTooltip
                      state={true}
                      content={doc.description}
                      position="top left"
                      wide="very"
                    >
                      <div>{doc.displayName}</div>
                    </CustomTooltip>
                    {/* <AvCheckbox
                      onChange={(e) => {}}
                      value={doc.id}
                      checked={this.featureIsChecked(doc.id)}
                    /> */}

                    {this.featureIsChecked(doc.id) ? (
                      <Icon
                        name="check circle"
                        className="permission-check"
                        size="large"
                      />
                    ) : (
                      <Icon
                        name="times circle"
                        className="permission-close"
                        size="large"
                      />
                    )}
                  </Grid.Column>
                ))}
              </Grid>
            </AvCheckboxGroup>
          ) : (
            <EmptyContainer msg="No Features..." />
          )}
        </Segment>

        <div className="model-buttons">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            content="Update Permission"
            type="submit"
            className="Primary-Button"
            // loading={postPermissionGroupLoading}
            // disabled={postPermissionGroupLoading}
          />
        </div>
      </AvForm>
    );
  }
}
class EditOrganization extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      form: {
        orgName: '',
        orgSector: '',
        phone: '',
        webDomain: '',
        email: '',
        streetAddress: '',
        city: '',
        postCode: '',
        currencyTypeId: '',
      },
    };
  }

  editOrganization(form) {
    form.stateId = Boolean(form.stateId) ? form.stateId : '';
    form.postalStateId = form.postalStateId === null ? '' : form.postalStateId;
    this.setState({
      form,
    });
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal, form } = this.state;
    const { data, currencyTypeOptions, isBetaLaunchAdmin } = this.props;

    return (
      <div className="button-outcome">
        <Modal
          open={modal}
          onOpen={() => this.toggle()}
          onClick={(e) => stopPropagation(e)}
          onFocus={(e) => stopPropagation(e)}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
          className="contentOrganization-edit"
          size="large"
          trigger={
            <Dropdown.Item
              text="Edit Organisation"
              className="dropdown-customItem"
            >
              <label
                className="edit-org"
                onClick={() => this.editOrganization(data)}
              >
                Edit Account
                <Icon name="write" />
              </label>
            </Dropdown.Item>
          }
        >
          <Modal.Header>
            <div className="add-org-header">
              Edit Account Type - {form.type}
            </div>
          </Modal.Header>

          <Modal.Content>
            <EditOrganizationModal
              form={form}
              isBetaLaunchAdmin={isBetaLaunchAdmin}
              currencyTypeOptions={currencyTypeOptions}
              toggle={() => this.toggle()}
              {...this.props}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class EditOrganizationModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapse: true,
      advanceCollapse: false,
      submitLoading: false,
      isChecked: false,

      isEvidenceBlock: false,
      isDemo: false,
      isActive: false,
    };
    this.fileInput = React.createRef();
    this.diffAddress = this.diffAddress.bind(this);
  }

  diffAddress() {
    this.setState((prevState) => ({
      isChecked: !prevState.isChecked,
    }));
  }

  renderState() {
    const { australianStates } = this.props;
    return map(australianStates, (item, i) => (
      <option key={i} value={item.id}>
        {item.name}
      </option>
    ));
  }

  selectSectorType() {
    const { industrySectorTypes } = this.props;

    return map(industrySectorTypes, (item, i) => {
      return (
        <option key={i} value={item.id}>
          {item.name}
        </option>
      );
    });
  }

  currencyTypeOptions() {
    const { currencyTypeOptions } = this.props;

    return map(currencyTypeOptions, (item) => {
      const { key, value, text } = item;
      return (
        <option key={key} value={value}>
          {text}
        </option>
      );
    });
  }

  onChange = (value) => {
    switch (value) {
      case 'isDisable':
        this.setState({
          isActive: !this.state.isActive,
        });
        break;
      case 'isDemo':
        this.setState({
          isDemo: !this.state.isDemo,
        });
        break;
      case 'isEvidenceBlock':
        this.setState({
          isEvidenceBlock: !this.state.isEvidenceBlock,
        });
        break;

      default:
        break;
    }
  };

  async componentDidMount() {
    const { form } = this.props;

    if (form.isActive === true) {
      this.setState({ isActive: true });
    }
    if (form.isDemo === true) {
      this.setState({ isDemo: true });
    }
    if (form.isEvidenceBlock === true) {
      this.setState({ isEvidenceBlock: true });
    }

    this.setState({ isEvidenceBlock: Boolean(form.isEvidenceBlock) });
    if (
      form.postalStreetAddress ||
      form.postalStreetAddress2 ||
      form.postalCitySuburb ||
      form.postalStateId ||
      form.postalPostcode
    ) {
      this.setState({ isChecked: true });
    }
  }

  handleValidSubmit(event, values) {
    delete values.isAccountType;
    this.setState(
      {
        submitLoading: true,
      },
      async () => {
        try {
          const { form, toggle, accType, currentPage } = this.props;

          const { isEvidenceBlock, isActive, isDemo } = this.state;

          const file = this.fileInput.current.getCurrentImage();
          delete values.diffPostalAddress;
          if (!this.state.isChecked) {
            values.postalStreetAddress = null;
            values.postalStreetAddress2 = null;
            values.postalCitySuburb = null;
            values.postalStateId = null;
            values.postalPostcode = null;
          }

          if (file) {
            await this.props.updateOrganizationLogo(form.id, file);
          }

          let data = {};

          if (form.type === 'INDIVIDUAL') {
            data = {
              individual: {
                ...values.individual,
                phone: values.phone,
                email: values.email,
                paymentPreferenceId: values.paymentPreferenceId,
                isDemo: isDemo,
                isActive: isActive,
                isEvidenceBlock: isEvidenceBlock,
                currencyTypeId: get(
                  values,
                  'currencyTypeId',
                  DEFAULT_CURRENCY_TYPE_ID
                ), //
              },
            };
          } else {
            data = {
              ...values,
              isDemo: isDemo,
              isActive: isActive,
              isEvidenceBlock: isEvidenceBlock,
              currencyTypeId: get(
                values,
                'currencyTypeId',
                DEFAULT_CURRENCY_TYPE_ID
              ),
            };
          }

          await this.props.updateOrganisationDetails(
            form.id,
            data,
            currentPage,
            accType
          );

          await this.setState({ submitLoading: false });
          toggle();

          this.form && this.form.reset();
        } catch (error) {
          error.log('error from updating organization: ', error);
          this.setState({
            submitLoading: false,
          });
          NotificationManager.error('Oops, Something went wrong!');
        }
      }
    );
  }

  renderSubscriptionsEdit() {
    const { subscriptions, form } = this.props;
    const subscription = find(subscriptions, {
      id: parseInt(form.subscriptionId),
    });

    if (!subscription) return null;
    return <div className="subscription-title">{subscription.title}</div>;
  }

  renderPaymentPreferenceEdit() {
    const { paymentPreferences } = this.props;
    return map(paymentPreferences, (item, i) => {
      return (
        <option key={i} value={item.id}>
          {item.title}
        </option>
      );
    });
  }

  render() {
    const { toggle, form, isBetaLaunchAdmin } = this.props;
    const file = form.logo ? form.logo.documentUrl : null;
    const defaultValueLogo = file ? file : null;
    const { isEvidenceBlock, isChecked, submitLoading, isActive, isDemo } =
      this.state;

    return (
      <AvForm
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        model={form}
      >
        <Grid columns={2} divided stackable>
          <Grid.Row className="">
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="down-modal">
                {form.type === 'ORGANISATION' ? (
                  <>
                    <AvField
                      label="Organisation Name"
                      className="org-inputs"
                      name="name"
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Organisation name is required',
                        },
                      }}
                    />

                    <AvField
                      label="Organisation Sector Type"
                      className="org-inputs"
                      name="industrySectorTypeId"
                      type="select"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Organisation sector type is required',
                        },
                      }}
                    >
                      <option value="" disabled defaultValue>
                        Select an Sector
                      </option>
                      {this.selectSectorType()}
                    </AvField>

                    {isBetaLaunchAdmin && (
                      <AvField
                        label="Currency Type"
                        className="org-inputs"
                        name="currencyTypeId"
                        type="select"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Currency type is required',
                          },
                        }}
                      >
                        <option value="" disabled defaultValue>
                          Select currency type
                        </option>
                        {this.currencyTypeOptions()}
                      </AvField>
                    )}

                    <AvField
                      label="Phone"
                      className="org-inputs"
                      name="phone"
                      type="number"
                      validate={{
                        minLength: {
                          value: 10,
                          errorMessage: 'Must be a minimum of 10 digits',
                        },
                        required: {
                          value: true,
                          errorMessage: 'Phone is required',
                        },
                      }}
                    />

                    <AvField
                      label="Web Domain"
                      className="org-inputs"
                      name="domain"
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Web domain is required',
                        },
                      }}
                    />
                    <AvField
                      label="Email"
                      className="org-inputs"
                      name="email"
                      type="email"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Email is required',
                        },
                      }}
                    />
                    <div className="org-account-img-upload">
                      <AvFieldUpload
                        ref={this.fileInput}
                        label="Organisation Logo"
                        name="logo"
                        imageUrl={defaultValueLogo}
                        helpMessage="Supports jpg, png, gmp formats"
                        accept="image/gmp, image/jpg, image/jpeg, image/png"
                        buttonText="Upload Image"
                      />
                    </div>

                    <div className="account-type-toggle">
                      <Grid>
                        <Grid.Row mobile={16} tablet={16} computer={10}>
                          <div className="edit-outcome-status-bar">
                            <label>Activate Accounts</label>
                            <div className="two-step-radio-container-admin radio-left">
                              <Radio
                                toggle
                                className="modal-toggle"
                                checked={isActive}
                                onChange={() => this.onChange('isDisable')}
                              />
                            </div>
                          </div>
                        </Grid.Row>
                        <Grid.Row mobile={16} tablet={16} computer={10}>
                          <div className="edit-outcome-status-bar">
                            <label>Block Evidencee</label>
                            <div className="two-step-radio-container-admin radio-left">
                              <Radio
                                toggle
                                className="modal-toggle-bevidence"
                                checked={isEvidenceBlock}
                                onChange={() =>
                                  this.onChange('isEvidenceBlock')
                                }
                              />
                            </div>
                          </div>
                          <div className="edit-outcome-status-bar">
                            <label>Demo Account</label>
                            <div className="two-step-radio-container-admin radio-left">
                              <Radio
                                toggle
                                className="modal-toggle"
                                checked={isDemo}
                                onChange={() => this.onChange('isDemo')}
                              />
                            </div>
                          </div>
                        </Grid.Row>
                      </Grid>
                    </div>
                  </>
                ) : (
                  //individual
                  <div className="">
                    <div className="form-group">
                      <label>Subscription</label>
                      <label className="act-inputs form-control primary-goal-container">
                        {this.renderSubscriptionsEdit()}
                      </label>
                    </div>

                    {isBetaLaunchAdmin && (
                      <AvField
                        label="Currency Type"
                        className="org-inputs"
                        name="currencyTypeId"
                        type="select"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Currency type is required',
                          },
                        }}
                      >
                        <option value="" disabled defaultValue>
                          Select currency type
                        </option>
                        {this.currencyTypeOptions()}
                      </AvField>
                    )}

                    <AvField
                      label="First Name"
                      className="org-inputs"
                      name="individual.firstName"
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'First name is required',
                        },
                      }}
                    />

                    <div />
                    <AvField
                      label="Phone"
                      className="org-inputs-individual-phone"
                      name="phone"
                      type="number"
                      validate={{
                        minLength: {
                          value: 10,
                          errorMessage: 'Must be a minimum of 10 digits',
                        },
                        required: {
                          value: true,
                          errorMessage: 'Phone is required',
                        },
                      }}
                    />
                    <div className="individual-account-img-upload">
                      <AvFieldUpload
                        ref={this.fileInput}
                        label="Account Logo"
                        name="logo"
                        imageUrl={defaultValueLogo}
                        helpMessage="Supports jpg, png, gmp formats"
                        accept="image/gmp, image/jpg, image/jpeg, image/png"
                        buttonText="Upload Image"
                      />
                    </div>

                    <div className="account-type-toggle-individual">
                      <Grid>
                        <Grid.Row mobile={16} tablet={16} computer={10}>
                          <div className="edit-outcome-status-bar">
                            <label>Activate Account</label>
                            <div className="two-step-radio-container-admin radio-left">
                              <Radio
                                toggle
                                className="modal-toggle"
                                checked={isActive}
                                onChange={() => this.onChange('isDisable')}
                              />
                            </div>
                          </div>
                        </Grid.Row>
                        <Grid.Row mobile={16} tablet={16} computer={10}>
                          <div className="edit-outcome-status-bar">
                            <label>Demo Account</label>
                            <div className="two-step-radio-container-admin radio-left">
                              <Radio
                                toggle
                                className="modal-toggle-bdemo"
                                checked={isDemo}
                                onChange={() => this.onChange('isDemo')}
                              />
                            </div>
                          </div>
                          <div className="edit-outcome-status-bar">
                            <label>Block Evidence</label>
                            <div className="two-step-radio-container-admin radio-left">
                              <Radio
                                toggle
                                className="modal-toggle"
                                checked={isEvidenceBlock}
                                onChange={() =>
                                  this.onChange('isEvidenceBlock')
                                }
                              />
                            </div>
                          </div>
                        </Grid.Row>
                      </Grid>
                    </div>
                  </div>
                )}
              </div>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              className="org-modal-column "
            >
              <div>
                {form.type === 'ORGANISATION' ? (
                  <div>
                    <AvField
                      label="Address Line 1"
                      className="org-inputs"
                      name="streetAddress"
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Address is required',
                        },
                      }}
                    />

                    <AvField
                      label="Address Line 2"
                      className="org-inputs"
                      name="streetAddress2"
                      type="text"
                    />

                    <AvField
                      label="City / Suburb"
                      className="org-inputs"
                      name="citySuburb"
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'City / Suburb is required',
                        },
                      }}
                    />

                    <AvField
                      label="State"
                      className="org-inputs"
                      name="stateId"
                      type="select"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'State is required',
                        },
                      }}
                    >
                      <option value="" disabled defaultValue>
                        Select an Sector
                      </option>
                      {this.renderState()}
                    </AvField>

                    <AvField
                      label="Postal Code"
                      className="org-inputs"
                      name="postcode"
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Postal code is required',
                        },
                      }}
                    />
                    <AvCheckboxGroup
                      name="diffPostalAddress"
                      className="org-checkbox-edit"
                    >
                      <AvCheckbox
                        label="Different Postal Address"
                        value="diffPostalAddress"
                        checked={isChecked}
                        onChange={(e) => this.diffAddress()}
                      />
                    </AvCheckboxGroup>
                    {isChecked ? (
                      <div>
                        <AvField
                          label="Postal Address Line 1"
                          className="org-inputs"
                          name="postalStreetAddress"
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: 'Postal Street Address is required',
                            },
                          }}
                        />

                        <AvField
                          label="Postal Address Line 2"
                          className="org-inputs"
                          name="postalStreetAddress2"
                          type="text"
                        />
                        <AvField
                          label="Postal City / Suburb"
                          className="org-inputs"
                          name="postalCitySuburb"
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: 'Postal City / Suburb is required',
                            },
                          }}
                        />
                        <AvField
                          label="Postal State"
                          className="org-inputs"
                          name="postalStateId"
                          type="select"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: 'Postal State is required',
                            },
                          }}
                        >
                          <option value="" defaultValue disabled>
                            Select a state
                          </option>
                          {this.renderState()}
                        </AvField>

                        <AvField
                          label="Postal Postcode"
                          className="org-inputs"
                          name="postalPostcode"
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: 'Postal Post Code is required',
                            },
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <AvField
                      label="Payment Preference"
                      className="org-inputs"
                      name="paymentPreferenceId"
                      type="select"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Payment Preference  is required',
                        },
                      }}
                    >
                      <option value="" defaultValue disabled>
                        Select Payment Preference
                      </option>
                      {this.renderPaymentPreferenceEdit()}
                    </AvField>
                    <AvField
                      label="Surname"
                      className="org-inputs"
                      name="individual.surname"
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Surname is required',
                        },
                      }}
                      autoComplete="off"
                    />

                    <AvField
                      label="Email"
                      className="org-inputs"
                      name="email"
                      type="email"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: 'Email is required',
                        },
                      }}
                    />
                  </div>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <div className="model-buttons-org">
          <Button
            className="Secondary-Button"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            content="Save"
            type="submit"
            className="Primary-Button"
            loading={submitLoading}
            disabled={submitLoading}
          />
        </div>
      </AvForm>
    );
  }
}

class BillingInvoices extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  render() {
    const { modal } = this.state;
    const { data } = this.props;

    return (
      <div className="button-outcome">
        <Modal
          open={modal}
          onOpen={() => this.toggle()}
          onClick={(e) => stopPropagation(e)}
          onFocus={(e) => stopPropagation(e)}
          closeIcon={
            <Icon
              onClick={() => this.toggle()}
              name="close"
              className="closeicon"
            />
          }
          className="billing-and-invoices"
          size="small"
          trigger={
            <label className="edit-org">
              Billing and Invoices
              <Icon name="file outline" />
            </label>
          }
        >
          <Modal.Header>
            <div className="add-org-header">
              Billing and Invoices - {data.name}
            </div>
          </Modal.Header>

          <Modal.Content>
            <BillingForm {...this.props} toggle={() => this.toggle()} />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

const IndividualDetails = ({ isTaksoAdminEmail, onChange }) => {
  const [isShownPass, setIsShownPass] = React.useState(false);

  return (
    <div className="down-modal">
      <CustomInput
        id="userFirstNameIndv"
        name="userFirstNameIndv"
        placeholder="Enter First Name"
        className="org-inputs"
        labelText="First Name"
        requiredStar
      />

      <CustomInput
        id="userPhoneIndv"
        name="userPhoneIndv"
        placeholder="Enter Phone"
        className="org-inputs"
        labelText="Phone"
        requiredStar
      />

      {isTaksoAdminEmail && (
        <>
          <CustomPasswordInput
            id="passwordIndv"
            name="passwordIndv"
            className="org-inputs"
            labelText="New Password"
            placeholder="New Password"
          />

          <CustomInput
            id="confirmPasswordIndv"
            className="org-inputs"
            name="confirmPasswordIndv"
            placeholder="Confirm Password"
            labelText="Confirm Password"
            type={isShownPass ? 'text' : 'password'}
            icon={
              <Icon
                name={isShownPass ? 'eye' : 'eye slash'}
                onClick={() => {
                  setIsShownPass(!isShownPass);
                }}
                link
              />
            }
          />
        </>
      )}

      <div className="account-type-toggle">
        <div className="edit-outcome-status-bar">
          <div className="two-step-radio-container-admin radio-left">
            <Radio
              toggle
              className="modal-toggle-qs-acctype"
              onChange={() => onChange('isDemo')}
            />
          </div>
          <label>Demo Account</label>
        </div>
        <div className="edit-outcome-status-bar">
          <div className="two-step-radio-container-admin radio-left">
            <Radio
              toggle
              className="modal-toggle-qs-acctype"
              onChange={() => onChange('isEvidenceBlock')}
            />
          </div>
          <label>Block Evidence</label>
        </div>
      </div>
    </div>
  );
};

class BillingForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: <div>Date</div>,
          render: (data) => {
            return <div>{moment(data.createdAt).format('DD-MM-YYYY')}</div>;
          },
          textAlign: isBrowser || isTablet ? 'centre' : null,
        },
        {
          title: <div>Invoice ID</div>,
          render: (data) => {
            if (data.invoiceUrl) {
              return (
                <a
                  className="invoice-link"
                  href={data.invoiceUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div>{data.invoiceNo}</div>
                </a>
              );
            }
            return <div>{data.invoiceNo}</div>;
          },
          textAlign: isBrowser || isTablet ? 'centre' : null,
        },
        {
          title: <div>Amount</div>,
          render: (data) => {
            return <div>{data.amount}</div>;
          },
          textAlign: isBrowser || isTablet ? 'centre' : null,
        },
        {
          title: <div>Status</div>,
          render: (data) => {
            return (
              <div className={`subscription-status ${data.status}`}>
                {getInvoicesStatus(data.status)}
              </div>
            );
          },
          textAlign: isBrowser || isTablet ? 'centre' : null,
        },
        {
          title: <div>Marked By </div>,
          render: (data) => {
            return (
              <div className="acc-marked">
                {data.paymentPreferenceId === 2 && data.status !== 'paid' ? (
                  <MarkAcceptanceModal
                    {...this.props}
                    data={data}
                    status={this.state.status}
                  />
                ) : (
                  <>
                    <div>
                      {data.status === 'paid' && data.paymentPreferenceId === 1
                        ? 'System'
                        : data.marked
                          ? data.marked.name
                          : '-'}
                    </div>
                    <div className="icon-comment">
                      {data.markedDescription !== null ? (
                        <Popup
                          content={
                            <div>
                              <b>Description - </b>
                              {data.markedDescription}
                              <br></br>
                              <b>Reference - </b>
                              {data.bankReferenceId}
                            </div>
                          }
                          trigger={
                            <Icon name="comment alternate" color="orange" />
                          }
                        />
                      ) : null}
                    </div>
                  </>
                )}
              </div>
            );
          },
          textAlign: isBrowser || isTablet ? 'centre' : null,
        },
        {
          title: <div>Marked On</div>,
          render: (data) => {
            return (
              <div>
                {data.status === 'paid' && data.paymentPreferenceId === 1
                  ? moment(data.updatedAt).format('ddd DD MMM YYYY')
                  : data.markedOn
                    ? moment(data.markedOn).format('ddd DD MMM YYYY')
                    : '-'}
              </div>
            );
          },
          textAlign: isBrowser || isTablet ? 'centre' : null,
        },
      ],
      currentPage: 1,
      status: 'all',
    };
  }

  async setStatus(statusType) {
    const { getInvoices, data } = this.props;

    await this.setState({
      status: statusType,
    });

    const { currentPage, status } = this.state;
    getInvoices(data.id, {
      currentPage,
      status,
    });
  }

  componentDidMount() {
    const { getInvoices, data } = this.props;
    const { status, currentPage } = this.state;

    getInvoices(data.id, {
      currentPage,
      status,
    });
  }

  pageChange(page) {
    const { getInvoices, user } = this.props;
    const { status } = this.state;
    this.setState(
      {
        currentPage: page,
      },
      () => {
        getInvoices(user.organisation.id, {
          page,
          status,
        });
      }
    );
  }

  render() {
    const { status, columns, currentPage } = this.state;
    const { orgInvoices, orgInvoicesLoading } = this.props;

    return (
      <div>
        <div className="billing-filter m-4">
          <div className="label">Status:</div>
          <div className="account-filter ">
            <Dropdown
              selectOnBlur={false}
              options={INVOICE_STATUS_OPTIONS}
              value={status}
              onChange={(e, { value }) => this.setStatus(value)}
            />
          </div>
        </div>
        <Segment
          basic
          loading={orgInvoicesLoading}
          disabled={orgInvoicesLoading}
        >
          {orgInvoices.docs && orgInvoices.docs.length ? (
            <CustomTable
              header
              columns={columns}
              data={orgInvoices.docs}
              pagination
              customClass="super-admin-table tbody-hover"
              handlePaginationChange={(page) => this.pageChange(page)}
              page={currentPage}
              noOfPages={orgInvoices.pages}
              keyExtractor={(item, index) => item.id}
            />
          ) : (
            <EmptyContainer msg="There are no invoices for this organisation…" />
          )}
        </Segment>
      </div>
    );
  }
}

class MarkAcceptanceModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  markToggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  render() {
    const { modal } = this.state;

    return (
      <div className="button-outcome">
        <Modal
          open={modal}
          onOpen={() => this.markToggle()}
          closeIcon={
            <Icon
              onClick={() => this.markToggle()}
              name="close"
              className="closeicon"
            />
          }
          className="contentOrganization-edit"
          size="small"
          trigger={<div className="markPaid">Mark as paid now</div>}
        >
          <Modal.Header>
            <div className="add-org-header">MARK AS PAID NOW</div>
          </Modal.Header>

          <Modal.Content>
            <AcceptanceForm
              markToggle={() => this.markToggle()}
              {...this.props}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class AcceptanceForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async handleValidSubmit(event, values) {
    try {
      const { updateMarkBy, data, markToggle, status } = this.props;

      await updateMarkBy(data.id, data.organisationId, values, status);
      markToggle();
    } catch (error) {
      console.error('Mark by ~~', error);
    }
  }

  render() {
    const { toggle } = this.props;
    const { markByLoading } = this.props.admin;

    return (
      <div>
        <AvForm
          onValidSubmit={(event, values) =>
            this.handleValidSubmit(event, values)
          }
        >
          <div className="mark-paid-modal">
            <AvField
              label="DATE"
              className="org-inputs"
              name="markedOn"
              type="date"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Date is required',
                },
              }}
            />

            <AvField
              label="BANK REFERENCE ID"
              className="org-inputs"
              name="bankReferenceId"
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Bank Reference is required',
                },
              }}
            />

            <AvField
              label="COMMENTS"
              className="org-inputs-textarea"
              name="markedDescription"
              type="textarea"
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Description is required',
                },
              }}
            />
          </div>
          <div className="model-buttons-account">
            <Button
              className="goal-cancel-button"
              type="button"
              onClick={() => toggle()}
            >
              Cancel
            </Button>
            <Button
              content="Save"
              type="submit"
              className="goal-create-button"
              loading={markByLoading}
              disabled={markByLoading}
            />
          </div>
        </AvForm>
      </div>
    );
  }
}

const IndividualRightSide = ({ isTaksoAdminEmail, setIsTaksoAdminEmail }) => {
  return (
    <div className="down-modal-individual-right-side">
      <CustomInput
        id="userSurnameIndv"
        name="userSurnameIndv"
        placeholder="Enter Surname"
        className="org-inputs"
        labelText="Surname"
        requiredStar
      />

      <CustomCheckbox
        className="check-box"
        id="isTaksoAdminEmail"
        name="isTaksoAdminEmail"
        label="Takso support email  (system won't send emails to this email)"
        checked={isTaksoAdminEmail}
        onChange={() => setIsTaksoAdminEmail()}
      />

      <CustomInput
        id="userEmailIndv"
        name="userEmailIndv"
        placeholder="Enter Email"
        labelText="Email"
        className="org-inputs"
        requiredStar
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    industrySectorTypes: state.extra.industrySectorTypes,
    admin: state.admin,
    australianStates: state.extra.australianStates,
    organization: state.organization,
    auth: state.authentication,
    orgInvoices: state.organization.orgInvoices,
    orgInvoicesLoading: state.organization.orgInvoicesLoading,
    subscriptions: state.extra.subscriptions,
    paymentPreferences: state.extra.paymentPreferences,
    individualUser: state.admin.individualUser,
    getPermissionGroup: state.admin.getPermissionGroup,
    getPermissionLoading: state.admin.getPermissionLoading,
    features: state.admin.features,
    getfeatureLoading: state.admin.getfeatureLoading,
    currencyTypes: state.extra.currencyTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getIndustrySectorTypes,
      getOrgAll,
      postOrganisation,
      getAustralianStates,
      updateOrganizationLogo,
      updateOrganisationDetails,
      getInvoices,
      updateMarkBy,
      getSubscriptions,
      getPaymentPreferences,
      getFeatures,
      getPermissionGroups,
      postAccountPermissionGroups,
      getCurrencyTypes,
    },
    dispatch
  );
};

Organizations = connect(mapStateToProps, mapDispatchToProps)(Organizations);

export { Organizations };
