/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
  getPartnerDetailsInitialValues,
  partnerDetailsAnonymousValidationSchema,
  partnerDetailsExternalValidationSchema,
  partnerDetailsGuestValidationSchema,
} from '../../../../validations/partnerFormValidation';
import {
  Grid,
  Segment,
  Modal,
  Button,
  Dropdown,
  Icon,
  Input,
  Search,
  Message,
  Form,
} from 'semantic-ui-react';
import moment from 'moment';
import syslogo from '../../../../assets/logo.svg';
import { getActivityStatus, getActivityType } from '../../../../utils';
import { Link } from 'react-router-dom';
import { stopPropagation } from '../../../../utils';
import {
  CustomCheckbox,
  CustomInput,
  CustomRadioGroup,
  CustomTextArea,
} from '../../../../components/form';
import { CustomTable, EmptyContainer } from '../../../../components';
import { getABN } from '../../../../utils';
import '../organization.scss';
import { get, isEmpty } from 'lodash';
import {
  createOrgPartner,
  deleteOrgPartner,
  getOrgPartners,
  getOrgPartnersHistory,
  updateOrgPartner,
} from '../../../../store/actions';
import { PARTNER_RELATIONSHIP } from '../../../../utils/constant';
// import { EmailVerificationForm } from '../../../../components/customEmailVerification';
import { isExternalLinkedActivity } from '../../../../utils/activity';
import { PartnerEmailVerificationForm } from '../../../../components/customPartnerEmailVerification';

const PartnerForm = (props) => {
  const {
    selectedPartner,
    isNew,
    isFromActivity = false, // used for checking activity form loading inside activity
    viewOnly,
    projected,
    isAssignExistingPartner = false, // used for checking assigning partner to activity from search results [edit modal used for it]
  } = props;
  // const [abnList, setAbnList] = React.useState([]);
  const [organisationName, setOrganisationName] = React.useState('');
  const [selectedAbn, setSelectedAbn] = React.useState(' ');
  const [searchLoading, setSearchLoading] = React.useState(false);
  // const [error, setError] = React.useState(false);
  const [reportOpen, setReportOpen] = React.useState(false);
  const [isUserConfirmed, setIsUserConfirmed] = React.useState(true);
  const [searchResults, setSearchResults] = React.useState([]);
  const [supportType, setSupportType] = React.useState(
    isFromActivity && !isNew && !isAssignExistingPartner
      ? selectedPartner.supportType
      : 'IN_KIND'
  );
  const [isAnonymous, setIsAnonymous] = React.useState(
    selectedPartner
      ? selectedPartner.firstName.trim() === 'Anonymous Donor'
      : false
  );
  const [userEmailResponse, setUserEmailResponse] = React.useState({
    firstName: '',
    organisation: '',
    isConfirmed: false,
    isVerified: false,
    isCleared: false,
    isExistingUser: false,
  });

  const addPartnerLoading = useSelector(
    (state) => state.organization.addPartnerLoading
  );
  const updatePartnerLoading = useSelector(
    (state) => state.organization.updatePartnerLoading
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!isNew) {
      setOrganisationName(
        selectedPartner.organisationName ? selectedPartner.organisationName : ''
      );
      setSelectedAbn(selectedPartner.acn ? selectedPartner.acn : '');
      setIsAnonymous(
        selectedPartner.firstName.trim() === 'Anonymous Donor' ? true : false
      );
    }
  }, []);
  const handleSupportType = (value) => {
    if (value !== undefined) setSupportType(value);
  };

  const handleSearchChange = async () => {
    try {
      setSearchLoading(true);
      const _abnList = await getABN(organisationName);

      // setAbnList([..._abnList.Names]);
      setSearchResults([..._abnList.Names]);
      setReportOpen(true);
      setSearchLoading(false);
    } catch (error) {
      setSearchLoading(false);
    }
  };

  const handleResult = (e, { result }, setFieldValue) => {
    setSelectedAbn(result.Abn);
    setFieldValue('acn', result.Abn);

    setOrganisationName(result.Name);
    setReportOpen(false);
  };

  const closeResults = (e, data) => {
    setReportOpen(false);
  };

  const handleValidSubmit = async (values) => {
    try {
      const { isNew, setModal, projectId } = props;
      if (
        !isEmpty(userEmailResponse) &&
        !userEmailResponse.isConfirmed &&
        userEmailResponse.isExistingUser
      ) {
        setIsUserConfirmed(false);
        return;
      }
      setIsUserConfirmed(true);

      if (isAnonymous) {
        values.firstName = 'Anonymous Donor';
        values.secondName = '';
        values.email = '';
        values.phone = '';
      }
      values.isTaksoUser = userEmailResponse.isExistingUser;

      if (userEmailResponse.isExistingUser) {
        values.firstName = userEmailResponse.firstName;
        values.secondName = userEmailResponse.surname;
        values.email = userEmailResponse.email;
        values.phone = userEmailResponse.phone;
        values.abn = userEmailResponse.abn;
        values.suburb = userEmailResponse.suburb;
        values.state = userEmailResponse.state;
        values.postCode = userEmailResponse.postCode;
        values.streetAddress = userEmailResponse.address1;
        values.streetAddress2 = userEmailResponse.address2;
      }
      delete values.surname;
      delete values.address1;
      delete values.address2;
      delete values.entityName;
      delete values.abn;
      values.organisationName = organisationName;
      values.organisationId && delete values.organisationId;
      values.createdBy && delete values.createdBy;
      values.isAnonymous = isAnonymous;

      if (isNew) {
        const isSubmitted = await dispatch(createOrgPartner(values, projectId));
        if (isSubmitted) {
          setModal(false);
        }
      } else {
        const { selectedPartner } = props;
        const isSubmitted = dispatch(
          updateOrgPartner(
            selectedPartner.id,
            values,
            projectId,
            (isFromActivity && isNew) || isAssignExistingPartner
          )
        );
        if (isSubmitted) {
          setModal(false);
        }
      }
    } catch (error) {
      console.error('ContractorForm ~ handleValidSubmit ~ error: ', error);
    }
  };

  const onConfirmation = async (val) => {
    try {
      setUserEmailResponse(val);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const onVerification = async (val) => {
    try {
      setUserEmailResponse(val);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const resetFieldsData = () => {
    if (isAnonymous) {
      setUserEmailResponse({
        firstName: '',
        organisation: '',
        isConfirmed: false,
        isVerified: false,
        isCleared: false,
        isExistingUser: false,
      });
    }
  };

  useEffect(() => {
    resetFieldsData();
  }, [isAnonymous]);

  const addValidationSchema = (
    isAnonymous,
    userEmailResponse,
    isFromActivity
  ) => {
    return isAnonymous
      ? partnerDetailsAnonymousValidationSchema
      : userEmailResponse.isExistingUser
        ? partnerDetailsExternalValidationSchema(isFromActivity)
        : partnerDetailsGuestValidationSchema(isFromActivity);
  };

  const updateValidationSchema = (selectedPartner) => {
    return isAnonymous
      ? partnerDetailsAnonymousValidationSchema
      : selectedPartner.organisationId !== null ||
          userEmailResponse.isExistingUser
        ? partnerDetailsExternalValidationSchema
        : partnerDetailsGuestValidationSchema;
  };

  return (
    <Formik
      initialValues={getPartnerDetailsInitialValues(selectedPartner)}
      onSubmit={(values) => {
        handleValidSubmit(values);
      }}
      validationSchema={
        isNew
          ? addValidationSchema(isAnonymous, userEmailResponse, isFromActivity)
          : updateValidationSchema(selectedPartner)
      }
      className="venue-form"
    >
      {({ handleSubmit, setFieldValue, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          {isFromActivity &&
          ((!isNew && selectedPartner.organisationId === null) || isNew) ? (
            <div className="anonymous-row">
              <CustomCheckbox
                id="isAnonymous"
                name="isAnonymous"
                className="check-box"
                label="Anonymous Donor"
                checked={isAnonymous}
                onChange={(e, { checked }) => [
                  setIsAnonymous(checked),
                  setFieldValue('firstName', isAnonymous ? ' ' : 'Anonymous'),
                  resetFieldsData(),
                ]}
                disabled={viewOnly}
              />
            </div>
          ) : null}
          {isAnonymous && (
            <div className="org-search-partner">
              <div className="contact-flex">
                <div className="col inputs-left-align-flex">
                  {viewOnly ? (
                    <CustomInput
                      id="organisationName"
                      name="organisationName"
                      className="venue-inputs-new"
                      labelText="Organisation Name"
                      disabled={viewOnly}
                      requiredStar
                    />
                  ) : (
                    <>
                      <div className="formik-custom-field act-inputs-new">
                        <label>Organisation Name</label>

                        <div className="search-fl">
                          <Search
                            name="organisationName"
                            onResultSelect={(e, obj) =>
                              handleResult(e, obj, setFieldValue)
                            }
                            onSearchChange={(e, data) => {
                              setOrganisationName(data.value);
                            }}
                            open={reportOpen}
                            onBlur={closeResults}
                            icon="none"
                            results={searchResults}
                            className="contactSearch search-custom-height"
                            value={organisationName}
                            placeholder="Search"
                            resultRenderer={({
                              Abn,
                              Name,
                              Postcode,
                              State,
                            }) => {
                              return (
                                <div>
                                  <label>
                                    {Name.charAt(0).toUpperCase() +
                                      Name.slice(1)}
                                  </label>
                                  <div className="contact-search-result">
                                    <label>
                                      <label className="sub-info">
                                        State:{'  '}
                                      </label>
                                      <label className="info"> {State}</label>
                                    </label>
                                    <label className="leftInfo">
                                      <label className="sub-info">
                                        Postcode:{' '}
                                      </label>
                                      <label className="info">
                                        {' '}
                                        {Postcode}
                                      </label>
                                    </label>
                                    <label className="leftInfo">
                                      <label className="sub-info">ABN: </label>
                                      <label className="info"> {Abn}</label>
                                    </label>
                                  </div>
                                </div>
                              );
                            }}
                          />

                          <div className="icon-button-partner">
                            <Button.Group>
                              <Button
                                type="button"
                                labelPosition="left"
                                icon="search"
                                content="Search"
                                className="search-button-contact"
                                onClick={() => handleSearchChange()}
                                loading={searchLoading}
                                disabled={searchLoading}
                              ></Button>
                            </Button.Group>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="col inputs-right-align-flex">
                  {viewOnly ? (
                    <CustomInput
                      id="acn"
                      name="acn"
                      className="venue-inputs-acn-new"
                      placeholder="Enter ABN/ ACN"
                      labelText={<div>ABN / ACN </div>}
                      disabled={viewOnly}
                      requiredStar
                    />
                  ) : (
                    <CustomInput
                      id="acn"
                      className="venue-inputs-acn-new"
                      name="acn"
                      placeholder="Enter ABN/ ACN"
                      labelText={<div>ABN / ACN </div>}
                      defaultValue={selectedAbn}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          {isAnonymous && (
            <div className="contact-flex">
              <div className="col inputs-left-align-flex">
                <CustomInput
                  id="streetAddress"
                  name="streetAddress"
                  labelText="Address Line 1"
                  className={
                    isAnonymous ? 'partner-inputs-disabled' : 'venue-inputs-new'
                  }
                  disabled={viewOnly}
                />
              </div>
              <div className="col inputs-right-align-flex">
                <CustomInput
                  id="streetAddress2"
                  name="streetAddress2"
                  labelText="Address Line 2"
                  disabled={viewOnly}
                />
              </div>

              <div className="contact-flex">
                <div className="col inputs-left-align-flex">
                  <CustomInput
                    id="suburb"
                    name="suburb"
                    className={'venue-inputs-new'}
                    labelText="Suburb"
                    disabled={viewOnly}
                  />
                </div>
                <div className="col">
                  <CustomInput
                    id="state"
                    name="state"
                    className={'venue-inputs-new'}
                    labelText="State"
                    disabled={viewOnly}
                  />
                </div>
                <div className="col inputs-right-align-flex">
                  <CustomInput
                    id="postCode"
                    name="postCode"
                    className={'venue-inputs-new'}
                    labelText="Postcode"
                    disabled={viewOnly}
                  />
                </div>
              </div>
            </div>
          )}
          {!isAnonymous && (isNew || selectedPartner.isAnonymous) && (
            <div className="email-verification">
              <PartnerEmailVerificationForm
                isUserConfirmed={isUserConfirmed}
                setFieldValue={() => setFieldValue('email', '')}
                onConfirmation={(val) => onConfirmation(val)}
                onVerification={(val) => onVerification(val)}
              />
            </div>
          )}

          {!isAnonymous &&
            !isNew &&
            selectedPartner.partnerUserId !== null &&
            !selectedPartner.isAnonymous && (
              <EmailResponseFormExistingUser data={selectedPartner} />
            )}

          {!isAnonymous &&
            !isNew &&
            selectedPartner.partnerUserId === null &&
            !selectedPartner.isAnonymous && (
              <EmailResponseFormGuestUser
                data={selectedPartner}
                setOrganisationName={(value) => setOrganisationName(value)}
                handleResult={handleResult}
                closeResults={closeResults}
                searchResults={searchResults}
                handleSearchChange={handleSearchChange}
                searchLoading={searchLoading}
                organisationName={organisationName}
                setFieldValue={setFieldValue}
                reportOpen={reportOpen}
              />
            )}

          {/* existing user */}
          {userEmailResponse.isExistingUser &&
            !isAnonymous &&
            userEmailResponse.isVerified &&
            userEmailResponse.isConfirmed && (
              <EmailResponseFormExistingUser
                data={userEmailResponse}
                projected={projected}
              />
            )}

          {/* new gest user */}
          {userEmailResponse.isVerified &&
            !userEmailResponse.isExistingUser && (
              <EmailResponseFormGuestUser
                data={userEmailResponse}
                setOrganisationName={(value) => setOrganisationName(value)}
                handleResult={handleResult}
                closeResults={closeResults}
                searchResults={searchResults}
                handleSearchChange={handleSearchChange}
                searchLoading={searchLoading}
                organisationName={organisationName}
                setFieldValue={setFieldValue}
                reportOpen={reportOpen}
              />
            )}

          {isFromActivity && (
            <div className="formik-custom-field act-inputs-new">
              <label className="contact-category-header">Relationship</label>
              <div className="partner-relationship">
                <CustomRadioGroup
                  options={PARTNER_RELATIONSHIP}
                  name="supportType"
                  type="radio"
                  getValue={(e) => handleSupportType(e)}
                  setDefaultValue={supportType}
                  disabled={!isNew && selectedPartner.isFromProgramLevel}
                  requiredStar
                />
              </div>
            </div>
          )}

          {isFromActivity && (
            <div className="col inputs-left-align-flex">
              <div className="partner-description">
                <CustomTextArea
                  name="description"
                  id="description"
                  className="w-100 txt-partner-description"
                  placeholder="Enter Description"
                  labelText="Description"
                  disabled={!isNew && selectedPartner.isFromProgramLevel}
                />
              </div>
            </div>
          )}
          {/* {isFromActivity && (
            <div className="col inputs-left-align-flex over-ride-message">
              <Message
                color="orange"
                icon="exclamation circle"
                content={
                  <>
                    <span>
                      Key activity information is reported to your partners in
                      accordance with{' '}
                    </span>
                    <span className="link-url">
                      <a
                        href="https://stage.whitebox.org.au/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Takso terms and conditions
                      </a>
                    </span>
                    <br /> <br />
                    {!isNew &&
                      !isEmpty(selectedPartner) &&
                      isExternalLinkedActivity(selectedPartner) && (
                        <span>
                          {props.activity?.currentActivity?.parentActivity
                            ?.reportYear
                            ? `Final Reporting Date : ${moment(
                                props.activity.currentActivity.parentActivity
                                  .reportYear
                              ).format('DD MMM YYYY')}`
                            : null}
                        </span>
                      )}
                  </>
                }
              />
            </div>
          )} */}

          {!isEmpty(userEmailResponse.error) && userEmailResponse.error}
          {!viewOnly ? (
            <div className="contact-modal-buttons">
              <Button
                disabled={isNew ? addPartnerLoading : updatePartnerLoading}
                loading={isNew ? addPartnerLoading : updatePartnerLoading}
                content={isNew ? 'Add Partner' : 'Save Changes'}
                size="small"
                type="submit"
                className="Primary-Button"
              />
            </div>
          ) : null}
        </Form>
      )}
    </Formik>
  );
};

export const PartnerCreate = (props) => {
  const { projectId, disabled } = props;

  const [modal, setModal] = React.useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <div className="add-contact-container">
      <Modal
        trigger={
          <Button
            disabled={disabled}
            className={
              projectId ? 'customColor-wrapper-contact' : 'Primary-Button'
            }
          >
            <Icon name="plus" />
            Add New Partner
          </Button>
        }
        size="small"
        open={modal}
        onOpen={toggle}
        closeIcon={
          <div className="new-activity-modal-close-icon" onClick={toggle}>
            &times;
          </div>
        }
      >
        <Modal.Header className="modal-header-font">
          Add New Partner
        </Modal.Header>
        <Modal.Content>
          <PartnerForm setModal={setModal} isNew={true} {...props} />
        </Modal.Content>
      </Modal>
    </div>
  );
};

const EmailResponseFormExistingUser = ({ data = {} }) => {
  return (
    <div>
      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <div className="existing-user-partner">
            <div className="formik-custom-field act-inputs-new">
              <label>First Name</label>
              <Input
                id="firstName"
                name="firstName"
                value={get(data, 'firstName', '')}
                placeholder="Enter First Name"
                disabled
              />
            </div>
          </div>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <div className="existing-user-partner">
            <div className="formik-custom-field act-inputs-new">
              <label>Last Name</label>
              <Input
                id="secondName"
                name="secondName"
                value={get(data, 'secondName', '')}
                placeholder="Enter Last Name"
                disabled
              />
            </div>
          </div>
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <div className="existing-user-partner-middle">
            <div className="formik-custom-field act-inputs-new">
              <label>Email</label>
              <Input
                id="email"
                name="email"
                value={get(data, 'email', '')}
                placeholder="Enter Email"
                disabled
              />
            </div>
          </div>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <div className="existing-user-partner-middle">
            <div className="formik-custom-field act-inputs-new">
              <label>Phone</label>
              <Input
                id="phone"
                name="phone"
                placeholder="Enter Phone"
                value={get(data, 'phone', '')}
                disabled
              />
            </div>
          </div>
        </Grid.Column>
      </Grid>

      <>
        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <div className="existing-user-partner-middle">
              <div className="formik-custom-field act-inputs-new">
                <label>Business/ Organization Name</label>
                <Input
                  id="entityName"
                  name="entityName"
                  placeholder="Enter Business/ Organization Name"
                  value={
                    data.userData
                      ? get(data, 'userData.organisation.name', null) != null
                        ? get(data, 'userData.organisation.name', '')
                        : get(data, 'userData.organisationName', '')
                      : data.organisationName
                  }
                  disabled
                />
              </div>
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <div className="existing-user-partner-middle">
              <div className="formik-custom-field act-inputs-new">
                <label>ABN</label>
                <Input
                  id="abn"
                  name="abn"
                  placeholder="Enter ABN"
                  value={
                    data.userData
                      ? get(data, 'userData.organisation.name', null) != null
                        ? get(data, 'userData.organisation.abn', '')
                        : get(data, 'userData.abn', '')
                      : data.acn
                  }
                  disabled
                />
              </div>
            </div>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <div className="existing-user-partner-middle">
              <div className="formik-custom-field act-inputs-new">
                <label>Address Line 1</label>
                <Input
                  id="streetAddress"
                  name="streetAddress"
                  placeholder="Enter Address Line 1"
                  value={
                    data.userData
                      ? get(
                          data,
                          'userData.organisation.streetAddress',
                          null
                        ) != null
                        ? get(data, 'userData.organisation.streetAddress', '')
                        : get(data, 'userData.address1', '')
                      : data.streetAddress
                  }
                  disabled
                />
              </div>
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <div className="existing-user-partner-middle">
              <div className="formik-custom-field act-inputs-new">
                <label>Address Line 2</label>
                <Input
                  id="streetAddress2"
                  name="streetAddress2"
                  placeholder="Enter Address Line 2"
                  value={
                    data.userData
                      ? get(
                          data,
                          'userData.organisation.streetAddress2',
                          null
                        ) != null
                        ? get(data, 'userData.organisation.streetAddress2', '')
                        : get(data, 'userData.address2', '')
                      : data.streetAddress2
                  }
                  disabled
                />
              </div>
            </div>
          </Grid.Column>
        </Grid>

        <Grid>
          <Grid.Column mobile={16} tablet={6} computer={6}>
            <div className="existing-user-partner-middle">
              <div className="formik-custom-field act-inputs-new">
                <label>Suburb</label>
                <Input
                  id="suburb"
                  name="suburb"
                  placeholder="Enter Suburb"
                  value={
                    data.userData
                      ? get(data, 'userData.organisation.citySuburb', null) !=
                        null
                        ? get(data, 'userData.organisation.citySuburb', '')
                        : get(data, 'userData.suburb', '')
                      : data.suburb
                  }
                  disabled
                />
              </div>
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={5} computer={5}>
            <div className="existing-user-partner-middle">
              <div className="formik-custom-field act-inputs-new">
                <label>State</label>
                <Input
                  id="state"
                  name="state"
                  placeholder="Enter State"
                  value={
                    data.userData
                      ? get(
                          data,
                          'userData.organisation.orgState.name',
                          null
                        ) != null
                        ? get(data, 'userData.organisation.orgState.name', '')
                        : get(data, 'userData.state', '')
                      : data.state
                  }
                  disabled
                />
              </div>
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={5} computer={5}>
            <div className="existing-user-partner-middle">
              <div className="formik-custom-field act-inputs-new">
                <label>Postcode</label>
                <Input
                  id="postcode"
                  name="postcode"
                  placeholder="Enter Postcode"
                  value={
                    data.userData
                      ? get(data, 'userData.organisation.postcode', null) !=
                        null
                        ? get(data, 'userData.organisation.postcode', '')
                        : get(data, 'userData.postcode', '')
                      : data.postCode
                  }
                  disabled
                />
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </>
    </div>
  );
};

const EmailResponseFormGuestUser = ({
  data = {}, // FIXME: is this used ?
  setOrganisationName,
  organisationName,
  handleResult,
  searchResults,
  setFieldValue,
  reportOpen,
  closeResults,
  handleSearchChange,
  searchLoading,
}) => {
  return (
    <div>
      <Grid>
        <Grid.Column
          className="partner-activity-model-column"
          mobile={16}
          tablet={8}
          computer={8}
        >
          <CustomInput
            id="firstName"
            name="firstName"
            placeholder="Enter First Name"
            labelText="First Name"
            // disabled={disabled}
            requiredStar
          />
        </Grid.Column>
        <Grid.Column
          className="partner-activity-model-column"
          mobile={16}
          tablet={8}
          computer={8}
        >
          <CustomInput
            id="secondName"
            name="secondName"
            placeholder="Enter Last Name"
            labelText="Last Name"
            // disabled={disabled}
            requiredStar
          />
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column
          className="partner-activity-model-column"
          mobile={16}
          tablet={8}
          computer={8}
        >
          <CustomInput
            id="email"
            name="email"
            // getValue={e => changeEmail(e)}
            placeholder="Enter Email"
            labelText="Email"
            disabled={true}
            requiredStar
          />
        </Grid.Column>
        <Grid.Column
          className="partner-activity-model-column"
          mobile={16}
          tablet={8}
          computer={8}
        >
          <CustomInput
            id="phone"
            name="phone"
            placeholder="Enter Phone"
            labelText="Phone"
            // disabled={disabled}
            requiredStar
          />
        </Grid.Column>
        <Grid.Column
          className="partner-activity-model-column"
          mobile={16}
          tablet={8}
          computer={8}
        >
          <div className="guest-searchbar">
            <div className="formik-custom-field act-inputs-new ">
              <label>Organisation Name</label>

              <div className="search-fl">
                <Search
                  name="organisationName"
                  onResultSelect={(e, obj) =>
                    handleResult(e, obj, setFieldValue)
                  }
                  onSearchChange={(e, data) => {
                    setOrganisationName(data.value);
                  }}
                  open={reportOpen}
                  onBlur={closeResults}
                  icon="none"
                  results={searchResults}
                  className="contactSearch search-custom-height"
                  value={organisationName}
                  placeholder="Search"
                  resultRenderer={({ Abn, Name, Postcode, State }) => {
                    return (
                      <div>
                        <label>
                          {Name.charAt(0).toUpperCase() + Name.slice(1)}
                        </label>
                        <div className="contact-search-result">
                          <label>
                            <label className="sub-info">State:{'  '}</label>
                            <label className="info"> {State}</label>
                          </label>
                          <label className="leftInfo">
                            <label className="sub-info">Postcode: </label>
                            <label className="info"> {Postcode}</label>
                          </label>
                          <label className="leftInfo">
                            <label className="sub-info">ABN: </label>
                            <label className="info"> {Abn}</label>
                          </label>
                        </div>
                      </div>
                    );
                  }}
                />

                <div className="icon-button-partner">
                  <Button.Group>
                    <Button
                      type="button"
                      labelPosition="left"
                      icon="search"
                      content="Search"
                      className="search-button-contact"
                      onClick={() => handleSearchChange()}
                      loading={searchLoading}
                      disabled={searchLoading}
                    ></Button>
                  </Button.Group>
                </div>
              </div>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column
          className="partner-activity-model-column-pr"
          mobile={16}
          tablet={8}
          computer={8}
        >
          <CustomInput
            id="acn"
            name="acn"
            className="venue-inputs-acn-new"
            placeholder="Enter ABN/ ACN"
            labelText="ABN / ACN "
            requiredStar
          />
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column
          className="partner-activity-model-column-pr"
          mobile={16}
          tablet={8}
          computer={8}
        >
          <CustomInput
            id="streetAddress"
            name="streetAddress"
            placeholder="Enter Address Line 1"
            labelText="Address Line 1"
            requiredStar
          />
        </Grid.Column>
        <Grid.Column
          className="partner-activity-model-column-pr"
          mobile={16}
          tablet={8}
          computer={8}
        >
          <CustomInput
            id="streetAddress2"
            name="streetAddress2"
            placeholder="Enter Address Line 2"
            labelText="Address Line 2"
          />
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column
          className="partner-activity-model-column"
          mobile={16}
          tablet={6}
          computer={6}
        >
          <CustomInput
            id="suburb"
            name="suburb"
            placeholder="Enter Suburb"
            labelText="Suburb"
            requiredStar
          />
        </Grid.Column>
        <Grid.Column
          className="partner-activity-model-column"
          mobile={16}
          tablet={5}
          computer={5}
        >
          <CustomInput
            id="state"
            name="state"
            placeholder="Enter State"
            labelText="State"
            requiredStar
          />
        </Grid.Column>
        <Grid.Column
          className="partner-activity-model-column"
          mobile={16}
          tablet={5}
          computer={5}
        >
          <CustomInput
            id="postCode"
            name="postCode"
            placeholder="Enter Postcode"
            labelText="Postcode"
            requiredStar
          />
        </Grid.Column>
      </Grid>
    </div>
  );
};

const PartnerActivityHistory = (props) => {
  const [modal, setModal] = React.useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <div className="add-user">
      <Modal
        trigger={<label className="org-users-actions-warn">View History</label>}
        size="large"
        open={modal}
        onOpen={toggle}
        closeIcon={
          <div className="new-activity-modal-close-icon" onClick={toggle}>
            &times;
          </div>
        }
      >
        <Modal.Header className="modal-header-font">Activities</Modal.Header>
        <Modal.Content>
          {/* part1 */}
          <PartnerActivityViewHistory
            isNew={false}
            viewOnly={true}
            {...props}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};

const PartnerView = (props) => {
  const [modal, setModal] = React.useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <div className="add-user">
      <Modal
        trigger={<label className="edit-org">View</label>}
        size="small"
        open={modal}
        onOpen={toggle}
        closeIcon={
          <div className="new-activity-modal-close-icon" onClick={toggle}>
            &times;
          </div>
        }
      >
        <Modal.Header className="modal-header-font">Activities</Modal.Header>
        <Modal.Content>
          <PartnerForm isNew={false} viewOnly={true} {...props} />
        </Modal.Content>
      </Modal>
    </div>
  );
};

export const PartnerUpdate = (props) => {
  const { selectedPartner } = props;

  const [modal, setModal] = React.useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <div className="add-user">
      <Modal
        trigger={
          <label className="edit-org">
            {isExternalLinkedActivity(selectedPartner) ? 'View' : 'Edit'}
          </label>
        }
        onClick={(e) => stopPropagation(e)}
        onFocus={(e) => stopPropagation(e)}
        size="small"
        open={modal}
        onOpen={toggle}
        closeIcon={
          <div className="new-activity-modal-close-icon" onClick={toggle}>
            &times;
          </div>
        }
      >
        <Modal.Header className="modal-header-font">
          Update Partner{' '}
          {!isEmpty(selectedPartner.name.trim()) && `- ${selectedPartner.name}`}
        </Modal.Header>
        <Modal.Content>
          <PartnerForm setModal={setModal} isNew={false} {...props} />
        </Modal.Content>
      </Modal>
    </div>
  );
};

export const PartnerCreateFromActivity = (props) => {
  const { projectId, disabled } = props;

  const [modal, setModal] = React.useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <div className="add-user">
      <Modal
        size="small"
        open={modal}
        trigger={
          <Button
            disabled={disabled}
            className={
              projectId ? 'customColor-wrapper-contact' : 'customColor'
            }
          >
            <Icon name="plus" />
            Add New Partner
          </Button>
        }
        onOpen={toggle}
        closeIcon={
          <div className="new-activity-modal-close-icon" onClick={toggle}>
            &times;
          </div>
        }
      >
        <Modal.Header className="modal-header-font">
          Add New Partner
        </Modal.Header>
        <Modal.Content>
          <PartnerForm
            isFromActivity={true}
            setModal={setModal}
            isNew={true}
            {...props}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};

export const PartnerUpdateFromActivity = (props) => {
  const { selectedPartner, modal, setModal } = props;

  return (
    <Modal
      size="small"
      open={modal}
      closeIcon={
        <div
          className="new-activity-modal-close-icon"
          onClick={() => setModal(false)}
        >
          &times;
        </div>
      }
    >
      <Modal.Header className="modal-header-font">
        Update Partner{' '}
        {!isEmpty(selectedPartner) &&
          !isEmpty(selectedPartner.name.trim()) &&
          `- ${selectedPartner.name}`}
      </Modal.Header>
      <Modal.Content>
        <PartnerForm
          isFromActivity={true}
          isAssignExistingPartner={true}
          setModal={setModal}
          isNew={false}
          {...props}
        />
      </Modal.Content>
    </Modal>
  );
};

const PartnerDelete = (props) => {
  const { selectedPartner } = props;
  const dispatch = useDispatch();

  const [modal, setModal] = React.useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const deleteOrgPartnerHandler = () => {
    const { selectedPartner, resetPage } = props;
    dispatch(deleteOrgPartner(selectedPartner.id));
    if (resetPage) resetPage();
    toggle();
  };

  return (
    <div className="add-contact-container">
      <Modal
        open={modal}
        onOpen={() => toggle()}
        onClose={() => toggle()}
        onClick={(e) => stopPropagation(e)}
        onFocus={(e) => stopPropagation(e)}
        size="small"
        trigger={
          <div className="partner-model-style">
            <label className="edit-org">
              <div className="partners-remove-view">Remove</div>
            </label>
          </div>
        }
      >
        <Modal.Header className="modal-header-font">
          Delete Partner - {selectedPartner.name}
        </Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this partner?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => toggle()} className="modal-cancel">
            No
          </Button>
          <Button
            onClick={() => deleteOrgPartnerHandler()}
            positive
            className="modal-proceed"
            content="Yes"
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

//partnerview activity history

const PartnerActivityViewHistory = (props) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const dispatch = useDispatch();

  const organisation = useSelector((state) => state.organization);

  const { partnerActHistory = [], loading = false } = organisation;

  React.useEffect(() => {
    dispatch(getOrgPartnersHistory(props.selectedPartner.id, currentPage));
  }, []);

  const activityColumns = () => {
    return [
      {
        width: 5,
        title: (
          <div className="sort-icon">
            <div className="lib-header">Activity Name</div>
          </div>
        ),
        render: (data) => {
          return (
            <div className="activity-name">
              <div
                className={`circle circle-bottom activity-${getActivityStatus(
                  data.statusId
                )}`}
              />
              <label>
                <Link
                  to={`/activities/${data.id}`}
                  className="enter-activity-link"
                >
                  {data.name ? data.name : ''}
                </Link>
              </label>
            </div>
          );
        },
      },
      {
        width: 2,
        title: 'Type',
        render: (data) => {
          const actType = getActivityType(data);
          return actType.type;
        },
      },
      {
        width: 2,
        title: 'Owner',
        render: (data) => {
          return data.owner && data.owner.firstName
            ? data.owner.firstName
            : '-';
        },
      },
      {
        width: 2,
        title: (
          <div className="sort-icon">
            <div>Date Created</div>
          </div>
        ),
        render: (data) => {
          return moment(data.createdDateTime).format('DD MMMM YYYY');
        },
      },
      {
        width: 2,
        title: (
          <div className="sort-icon">
            <div>Last Updated</div>
          </div>
        ),
        render: (data) => {
          return moment(data.lastEditedDateTime).format('DD MMMM YYYY');
        },
      },
      {
        width: 3,
        title: '',
        render: (data) => {
          return (
            <div className="activity-actions-inside">
              <Link to={`/activities/${data.id}`}>
                <label className="activity-actions-enter-activity">
                  Enter Activity
                </label>
              </Link>
            </div>
          );
        },
      },
    ];
  };

  const pageChange = (page) => {
    const { auth } = props;
    setCurrentPage(page);
    if (!isEmpty(auth.user) && auth.user.organisation) {
      dispatch(getOrgPartnersHistory(props.selectedPartner.id, currentPage));
    }
  };

  return (
    <Segment className="content-segment org-users" loading={loading}>
      <Grid>
        <Grid.Row className="add-contact-org-row">
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            textAlign="right"
          ></Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {partnerActHistory.docs && partnerActHistory.docs.length > 0 ? (
            <Grid.Column className="org-table-container-">
              <CustomTable
                header
                columns={activityColumns()}
                height={true}
                data={partnerActHistory.docs}
                customClass="org-users-table"
                pagination
                handlePaginationChange={(page) => pageChange(page)}
                page={currentPage}
                noOfPages={partnerActHistory.pages}
                keyExtractor={(item, index) => item.id}
              />
            </Grid.Column>
          ) : (
            <EmptyContainer msg="No Activities belongs to this partner…" />
          )}
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

const OrganizationPartners = (props) => {
  const [currentPage, setCurrentPage] = React.useState(1);

  const dispatch = useDispatch();

  const organisation = useSelector((state) => state.organization);
  const { orgPartners = [], loading = false } = organisation;

  React.useEffect(() => {
    dispatch(getOrgPartners(currentPage));
  }, []);

  const resetPage = () => {
    setCurrentPage(1);
  };

  const columns = () => {
    return [
      {
        title: (
          <div className="partner-name">
            <div className="sort-icon">
              <div className="lib-header-partner"> Name</div>
            </div>
          </div>
        ),
        render: (data) => {
          const fullName = data && data.name && `${data.name}`;
          return (
            <div>
              <div className="current-member-text pl-3 pt-3">
                {data ? data.organisationName && data.organisationName : null}
              </div>
              <div className="current-member-text pl-3 text-muted">
                {/* Check weather this user from takso or not */}
                {data && data.partnerUserId ? (
                  <span>
                    <img className="plus-icon" alt="plus" src={syslogo} />
                  </span>
                ) : null}
                {data && fullName !== undefined ? fullName && fullName : ''}
              </div>
            </div>
          );
        },
      },
      {
        title: 'Email',
        render: (data) => {
          return (
            <label className="org-users-text-partner">
              {data.email || '-'}
            </label>
          );
        },
      },
      {
        title: 'Phone',
        render: (data) => {
          return (
            <label className="org-users-text-partner">
              {data.phone || '-'}
            </label>
          );
        },
      },
      {
        title: '',
        render: (data) => {
          return (
            <div className="org-users-text-partner-view-history">
              <PartnerActivityHistory selectedPartner={data} {...props} />
            </div>
          );
        },
      },
      {
        width: 1,
        title: (
          <div className="user-act-heading-container">
            <strong></strong>
          </div>
        ),
        render: (data) => {
          return (
            <div className="org-users-text-partner">
              <div className="owned">
                <Dropdown
                  icon="ellipsis vertical"
                  direction="left"
                  inline
                  selectOnBlur={false}
                >
                  {/* View-user eith the taskso tag */}
                  <Dropdown.Menu>
                    {data && data.partnerUserId ? (
                      <Dropdown.Item
                        text="View"
                        className="dropdown-customItem"
                      >
                        <PartnerView selectedPartner={data} {...props} />
                      </Dropdown.Item>
                    ) : null}

                    {/* Edit-user without 'takso' tag */}

                    {!(data && data.partnerUserId) ? (
                      <Dropdown.Item
                        text="Edit"
                        className="dropdown-customItem"
                      >
                        <PartnerUpdate
                          selectedPartner={data}
                          resetPage={resetPage}
                          {...props}
                        />
                      </Dropdown.Item>
                    ) : null}
                    {!(data && !isEmpty(data.projectPartner)) ? (
                      <Dropdown.Item
                        text="Remove"
                        className="dropdown-customItem"
                      >
                        <PartnerDelete
                          selectedPartner={data}
                          resetPage={resetPage}
                          {...props}
                        />
                      </Dropdown.Item>
                    ) : null}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          );
        },
      },
    ];
  };

  const pageChange = (page) => {
    const { auth } = props;

    setCurrentPage(page);
    if (!isEmpty(auth.user) && auth.user.organisation) {
      dispatch(getOrgPartners(page));
    }
  };

  return (
    <Segment className="content-segment org-users" loading={loading}>
      <Grid>
        <Grid.Row className="add-contact-org-row">
          <Grid.Column mobile={16} tablet={16} computer={16} textAlign="right">
            <PartnerCreate {...props} resetPage={() => this.resetPage()} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {orgPartners.docs && orgPartners.docs.length > 0 ? (
            <Grid.Column className="org-table-container">
              <CustomTable
                header
                columns={columns()}
                height={true}
                data={orgPartners.docs}
                customClass="org-users-table"
                pagination
                handlePaginationChange={(page) => pageChange(page)}
                page={currentPage}
                noOfPages={orgPartners.pages}
                keyExtractor={(item, index) => item.id}
              />
            </Grid.Column>
          ) : (
            <EmptyContainer msg="No Activities belongs to this partner…" />
          )}
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default OrganizationPartners;
