const aggregateArrays = (arrays = []) => {
  if (!arrays || arrays.length === 0) return;

  const maxLength = Math.max(...arrays.map((arr) => arr.length));
  const aggregateArray = Array(maxLength).fill(0);

  arrays.forEach((arr) => {
    arr.forEach((value, index) => {
      const numberValue =
        typeof value === 'string' && !isNaN(Number(value))
          ? parseFloat(value)
          : typeof value === 'number'
            ? value
            : 0;

      aggregateArray[index] += numberValue;
    });
  });

  return aggregateArray;
};

export default aggregateArrays;
