import React from 'react';
import { compact, flattenDeep } from './lodash';

// navigation svg imports
import dashboard from '../assets/navigation/dashboard.svg';
import activities from '../assets/navigation/activities.svg';
import report from '../assets/navigation/report.svg';
import library from '../assets/navigation/library.svg';
import account from '../assets/navigation/account.svg';
import tuneSettings from '../assets/navigation/tune-vertical.svg';
import settings from '../assets/navigation/settings.svg';
import users from '../assets/navigation/users.svg';
import features from '../assets/navigation/features.svg';
import logs from '../assets/navigation/log-files.svg';
// import notification from '../assets/navigation/notification.svg';

// core
export const APP_NAME = 'Takso';
export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'http://localhost:10010/api';

export const LIMIT = 10;
export const DASHBOARD_LIMIT = 5;
export const DELETED_MSG = 'Deleted Successfully... ';
export const CREATED_MSG = 'Created Successfully... ';
export const UPDATED_MSG = 'Updated Successfully... ';

// sidebar routes
export const ROUTES = {
  userRoutes: [
    {
      id: 'dash-panel',
      title: 'Dashboard',
      icon: 'tachometer alternate',
      image: dashboard,
      route: '/dashboard',
      subRoutes: ['/dashboard'],
      resize: true,
      permission: [
        'admin',
        'organisationAdmin',
        'individualAdmin',
        'user',
        'guest',
      ],
      features: 'dashboard',
    },
    {
      id: 'activity-panel',
      title: 'Activities',
      icon: 'calendar alternate outline',
      image: activities,
      route: '/activities',
      subRoutes: [
        '/activities/:id/:type',
        '/activities/:id',
        '/activity/:id/preview',
        '/activity/:id/participants',
      ],
      permission: [
        'admin',
        'organisationAdmin',
        'individualAdmin',
        'user',
        'guest',
      ],
      features: 'activities',
    },
    {
      id: 'report-panel',
      title: 'Reports',
      icon: 'chart bar',
      image: report,
      route: '/reports/activities',
      subRoutes: ['/reports/:type'],
      permission: ['admin', 'organisationAdmin', 'individualAdmin', 'user'],
      features: 'report',
    },
    {
      id: 'all-activities-panel',
      title: 'Library',
      icon: 'calendar check outline',
      image: library,
      route: '/library',
      subRoutes: ['/library/:id/preview'],
      permission: ['admin', 'organisationAdmin', 'user'],
      features: 'library',
    },
    {
      id: 'organisation-panel',
      title: 'Organisation',
      icon: 'building outline',
      image: account,
      route: '/organisation',
      subRoutes: ['/organisation/:type'],
      permission: ['admin', 'organisationAdmin'],
      features: 'account',
    },
    {
      id: 'account-panel',
      title: 'Account',
      icon: 'building outline',
      image: account,
      route: '/account',
      subRoutes: ['/account/:type'],
      permission: ['individualAdmin'],
      features: 'account',
    },
  ],
  adminRoutes: compact([
    {
      id: 'activity-settings-panel',
      title: 'Activity Settings',
      icon: 'settings',
      image: tuneSettings,
      route: '/admin-activity-settings',
      subRoutes: ['/admin-activity-settings/:type'],
      permission: ['admin'],
    },
    {
      id: 'system-setting-panel',
      title: 'System Settings',
      icon: 'setting',
      image: settings,
      route: '/system-settings',
      subRoutes: ['/system-settings/:type'],
      permission: ['admin'],
    },
    {
      id: 'system-report-panel',
      title: 'System Reports',
      icon: 'chart bar',
      image: report,
      route: '/system-reports',
      subRoutes: ['/system-reports/:type'],
      permission: ['admin'],
    },
    {
      id: 'user-organisation-panel',
      title: 'Accounts & Users',
      icon: 'group',
      image: users,
      route: '/user-accounts',
      subRoutes: ['/user-accounts/:type'],
      permission: ['admin'],
    },
    {
      id: 'feature-panel',
      title: 'Features',
      icon: 'object group outline',
      image: features,
      route: '/system-features',
      subRoutes: ['/system-features/:type'],
      permission: ['admin'],
    },
    {
      id: 'system-log-panel',
      title: 'System Logs',
      icon: 'file alternate outline',
      image: logs,
      route: '/system-logs',
      subRoutes: [],
      permission: ['admin'],
    },
  ]),
};

// All system routes
export const ALL_ROUTES = [
  ...ROUTES.userRoutes,
  ...ROUTES.adminRoutes,
  {
    id: 'preview-survey',
    title: 'Preview Survey',
    route: '/preview-survey',
    icon: 'ban',
    subRoutes: ['/preview-survey/:surveyId'],
    permission: [
      'admin',
      'organisationAdmin',
      'individualAdmin',
      'user',
      'guest',
    ],
  },
  {
    id: 'user-profile',
    title: 'User-Profile',
    icon: 'ban',
    route: '/user-profile',
    subRoutes: [],
    permission: [
      'admin',
      'organisationAdmin',
      'individualAdmin',
      'user',
      'guest',
    ],
  },
  {
    id: 'user-help',
    title: 'User-Help',
    icon: 'ban',
    route: '/help',
    subRoutes: [],
    permission: [
      'admin',
      'organisationAdmin',
      'individualAdmin',
      'user',
      'guest',
    ],
  },
];

// Account Organisation type only routes
export const ORG_ONLY_ROUTES = ['all-activities-panel', 'organisation-panel'];

// activity routes
export const ACTIVITY_TABS = [
  {
    id: 0,
    title: 'GOAL',
    route: 'goal',
  },
  {
    id: 1,
    title: 'OBJECTIVE',
    route: 'objective',
  },
  {
    id: 2,
    title: 'EVIDENCE',
    route: 'evidence',
  },
  {
    id: 3,
    title: 'THEORY OF CHANGE',
    route: 'theory-of-change',
  },
  {
    id: 4,
    title: 'ACTIVITY PLAN',
    route: 'activity-plan',
  },
  {
    id: 5,
    title: 'EVALUATION',
    route: 'evaluation',
  },
  {
    id: 6,
    title: 'DOCUMENTATION',
    route: 'documentation',
  },
  {
    id: 7,
    title: 'REPORT',
    route: 'report',
  },
];

export const PARENT_ACTIVITY_TABS = [
  {
    id: 0,
    title: 'GOAL',
    route: 'goal',
  },
  {
    id: 1,
    title: 'OBJECTIVE',
    route: 'objective',
  },
  {
    id: 2,
    title: 'EVIDENCE',
    route: 'evidence',
  },
  {
    id: 3,
    title: 'THEORY OF CHANGE',
    route: 'theory-of-change',
  },
  {
    id: 4,
    title: 'ACTIVITY PLAN',
    route: 'activity-plan',
  },
  {
    id: 5,
    title: 'EVALUATION',
    route: 'evaluation',
  },
  {
    id: 6,
    title: 'DOCUMENTATION',
    route: 'documentation',
  },
  {
    id: 7,
    title: 'REPORT',
    route: 'report',
  },
];

// admin tab routes
export const ADMIN_TABS = [
  {
    id: 0,
    title: 'Outcomes',
    route: 'outcomes',
  },
  {
    id: 1,
    title: 'Evaluation Methods',
    route: 'evaluation-methods',
  },
  {
    id: 2,
    title: 'Evaluation Questions',
    route: 'evaluation-questions',
  },
  {
    id: 3,
    title: 'Output Formatters',
    route: 'output-formatters',
  },
  {
    id: 4,
    title: 'Activity Types',
    route: 'activity-types',
  },
];
export const ADMIN_FEATURES = [
  {
    id: 0,
    title: 'Features',
    route: 'features',
  },
  {
    id: 1,
    title: 'Permission Groups',
    route: 'permission-groups',
  },
];

export const ADMIN_SYSTEM_SETUP = [
  {
    id: 0,
    title: 'Industry sector types',
    route: 'industry-sector-types',
  },
  {
    id: 1,
    title: 'Venue Types',
    route: 'venue-types',
  },
  {
    id: 2,
    title: 'Help',
    route: 'systemlog-help',
  },
];

export const ADMIN_USER = [
  {
    id: 0,
    title: 'Organisation',
    route: 'organisation',
  },
  {
    id: 1,
    title: 'Users',
    route: 'organisation-users',
  },
  {
    id: 2,
    title: 'Communication',
    route: 'communication',
  },
];

export const RESTRICTION_TYPES = [
  'project.edit.greenLight',
  'project.edit.report',
];

export const permissionTypes = [
  {
    id: 1,
    title: 'Goal',
    value: 'Goal',
    state: 'project.edit.start',
  },
  {
    id: 2,
    title: 'Objective',
    value: 'Objective',
    state: 'project.edit.objective',
  },
  {
    id: 3,
    title: 'Evidence',
    value: 'Evidence',
    state: 'project.edit.evidence',
  },
  {
    id: 4,
    title: 'Theory of Change',
    value: 'TheoryOfChange',
    state: 'project.edit.theoryOfChange',
  },
  {
    id: 5,
    title: 'Activity Plan',
    value: 'ActivityPlan',
    state: 'project.edit.activityPlan',
  },
  {
    id: 6,
    title: 'Evaluation',
    value: 'Evaluation',
    state: 'project.edit.evaluationPlan',
  },
  {
    id: 7,
    title: 'Green Light',
    value: 'GreenLight',
    state: 'project.edit.greenLight',
    documentor: true,
  },
  {
    id: 8,
    title: 'Documentation',
    value: 'Documentation',
    state: 'project.edit.documentation',
    documentor: true,
  },
  {
    id: 9,
    title: 'Report',
    value: 'Report',
    state: 'project.edit.report',
    documentor: true,
    reportOnly: true,
  },
  {
    id: 10,
    title: 'Notes',
    value: 'Notes',
    state: 'project.edit.notes',
  },
];

export const ACTIVITIES_ROUTE = ['/activities/:id/:type', '/activities/:id'];

export const REPORT_ROUTE = [
  '/reports',
  '/reports/:type',
  '/reports/activities',
  '/reports/outcome-summary',
  'reports/headline-data',
  'reports/organisation-summary',
];

export const INFO_ROUTES = [
  '/dashboard',
  '/activities',
  '/library',
  '/user-profile',
  '/settings',
  '/organisation',
  '/organisation/:type',
  '/activity/:id/preview',
  '/activities/:id/:type',
  '/activity/:id/participants',
  '/admin-activity-settings',
  '/admin-activity-settings/:type',
  '/system-features',
  '/system-features/:type',
  '/system-logs',
  '/system-settings',
  '/system-settings/:type',
  '/user-accounts',
  '/user-accounts/:type',
  '/activities/:id',
  '/reports',
  '/reports/activities',
  '/reports/:type',
  '/account',
  '/account/:type',
];

export const CompletionProgress = [
  {
    section: 'Goal',
  },
  {
    section: 'Objective',
  },
  {
    section: 'Evidence',
  },
  {
    section: 'Theory of Change',
  },
  {
    section: 'Activity Plan',
  },
  {
    section: 'Evaluation',
  },
  {
    section: 'Documentation',
  },
  {
    section: 'Report',
  },
];

export const CompletionProgressGrant = [
  {
    section: 'Goal',
  },
  {
    section: 'Objective',
  },
  {
    section: 'Evidence',
  },
  {
    section: 'Theory of Change',
  },
  {
    section: 'Activity Plan',
  },
  {
    section: 'Evaluation',
  },
  {
    section: 'Documentation',
  },
  {
    section: 'Report',
  },
];

export const GRANTEE_CSV_TEMPLATE = [
  { label: 'GrantID', key: 'grantId' },
  { label: 'SectorType', key: 'sectorType' },
  { label: 'FirstName', key: 'firstName' },
  { label: 'Surname', key: 'surname' },
  { label: 'Email', key: 'email' },
  { label: 'Phone', key: 'phone' },
  { label: 'EntityName', key: 'entityName' },
  { label: 'ABN', key: 'abn' },
  { label: 'Address1', key: 'address1' },
  { label: 'Address2', key: 'address2' },
  { label: 'Suburb', key: 'suburb' },
  { label: 'State', key: 'state' },
  { label: 'Postcode', key: 'postcode' },
  { label: 'PAddress1', key: 'pAddress1' },
  { label: 'PAddress2', key: 'pAddress2' },
  { label: 'PCitySuburb', key: 'pCitySuburb' },
  { label: 'PState', key: 'pState' },
  { label: 'PPostcode', key: 'pPostcode' },
  { label: 'ActivityName', key: 'activityName' },
  { label: 'ActivityType', key: 'activityType' },
  { label: 'Objective1', key: 'objective1' },
  { label: 'Knowing1', key: 'knowing1' },
  { label: 'WeWill1', key: 'weWill1' },
  { label: 'Objective2', key: 'objective2' },
  { label: 'Knowing2', key: 'knowing2' },
  { label: 'WeWill2', key: 'weWill2' },
  { label: 'ActivitySummary', key: 'activitySummary' },
  { label: 'ProjectedGrantAmount', key: 'projectedGrantAmount' },
  { label: 'ProjectedGrossExpenditure', key: 'projectedGrossExpenditure' },
  { label: 'ActivityStartDate', key: 'activityStartDate' },
  { label: 'ActivityEndDate', key: 'activityEndDate' },
  { label: 'ProjectedIncome', key: 'projectedIncome' },
  { label: 'Evidence1', key: 'evidence1' },
  { label: 'Evidence2', key: 'evidence2' },
  { label: 'Evidence3', key: 'evidence3' },
];

export const ORGANISATION_CORE_DATA = {
  annualReport: {},
  coreData: {
    inputs: [
      {
        input:
          'Capital assets: buildings/facilities for cultural use (or % use)',
        data: { type: 'currency', value: 0 },
        dataToCollect: 'Dollar capital value (replacement value)',
      },
      {
        input: 'Capital assets: cultural collections',
        data: { type: 'currency', value: 0 },
        dataToCollect:
          'Dollar capital value (replacement value, insured valued)',
      },
      {
        input: 'Capital assets: other sites of cultural significance',
        data: { type: 'currency', value: 0 },
        dataToCollect: 'Dollar capital value',
      },
      {
        input:
          'Financial Resources: Buildings leased for cultural activities (or % use)',
        data: { type: 'currency', value: 0 },
        dataToCollect: 'Projected total expenditure over the year',
      },
      {
        input:
          'Financial Resources: Recurrent expenditure (allocated to all cultural activities)',
        data: { type: 'currency', value: 0 },
        dataToCollect: 'Projected total expenditure over the year',
      },
    ],
  },
};

export const ageOptions = [
  '0-4',
  '5-9',
  '10-14',
  '15-19',
  '20-24',
  '25-29',
  '30-34',
  '35-39',
  '40-44',
  '45-49',
  '50-54',
  '55-59',
  '60-64',
  '65-69',
  '70-74',
  '75-79',
  '80+',
];

export const SINGLE_POST_TYPES = [
  {
    value: 'SINGLE',
    label: 'Single Results',
  },
];

export const MULTIPLE_POST_TYPES = [
  {
    value: 'AGGREGATE',
    label: 'Aggregate Results',
  },
  {
    value: 'SEPERATE',
    label: 'Separated Results',
  },
];

export const SEPARATE_POST_TYPE = [
  {
    value: 'SEPERATE',
    label: 'Separated Results',
  },
];

export const INTERCEPT_SINGLE_POST_TYPES = [
  {
    value: 'INTERCEPT_SINGLE',
    label: 'Single Results',
  },
];
export const INTERCEPT_MULTIPLE_POST_TYPES = [
  {
    value: 'INTERCEPT_AGGREGATE',
    label: 'Aggregate Results',
  },
  {
    value: 'INTERCEPT_SEPERATE',
    label: 'Separated Results',
  },
];

export const INTERCEPT_SEPARATE_POST_TYPES = [
  {
    value: 'INTERCEPT_SEPERATE',
    label: 'Separated Results',
  },
];

export const SINGLE_PREPOST_TYPES = [
  {
    value: 'SINGLE_TREND',
    label: 'Trend - Rate of Change',
  },
  {
    value: 'SINGLE_INDIVIDUAL',
    label: 'Individual - Rate of Change',
  },
];

export const SINGLE_MULTIPLE_POST_TYPES = [
  {
    value: 'INTERCEPT_AGGREGATE',
    label: 'Aggregate Results',
  },
  {
    value: 'INTERCEPT_SEPERATE',
    label: 'Separated Results',
  },
];

export const MULTIPLE_PREPOST_TYPES = [
  {
    value: 'TREND',
    label: 'Aggregate Trend - Rate of Change',
  },
  {
    value: 'INDIVIDUAL',
    label: 'Aggregate Individual - Rate of Change',
  },
];

export const MULTIPLE_INDIVIDUAL_TYPES = [
  {
    value: 'INDIVIDUAL',
    label: 'Aggregate Individual - Rate of Change',
  },
];

export const SELF_ASSESSED = [
  {
    value: 'SELF-ASSESSED-POST',
    label: 'Separated',
  },
  {
    value: 'SELF-ASSESSED-PRE',
    label: 'Self-Assessed - Rate of Change',
  },
];

export const INTERCEPT_CHART_TYPES = [
  'INTERCEPT_SINGLE',
  'INTERCEPT_AGGREGATE',
  'INTERCEPT_SEPERATE',
];

export const SELF_ASSESSED_CHART_TYPES = [
  'SELF-ASSESSED-POST',
  'SELF-ASSESSED-PRE',
];

export const ALL_CHART_TYPES = flattenDeep([
  SINGLE_POST_TYPES,
  MULTIPLE_POST_TYPES,
  INTERCEPT_SINGLE_POST_TYPES,
  INTERCEPT_MULTIPLE_POST_TYPES,
  SINGLE_PREPOST_TYPES,
  MULTIPLE_PREPOST_TYPES,
  SELF_ASSESSED,
]);

export const SUBSCRIPTIONS = [
  {
    id: 1,
    includes: [
      'Plan and evaluate single activities',
      'Duplicate activity plans',
      'Group activities',
      'Aggregated and generate reports',
      'Access to Takso library',
    ],
  },
  {
    id: 2,
    includes: [
      'Basic Contract Templates',
      'Up to 25 profiles',
      'Basic Onboarding',
    ],
  },
  {
    id: 3,
    includes: [
      'Basic Contract Templates',
      'Up to 25 profiles',
      'Basic Onboarding',
    ],
  },
];

export const INVOICE_STATUS_OPTIONS = [
  {
    key: 'all',
    text: <b>All</b>,
    option: 'All',
    value: 'all',
  },
  {
    key: 'paid',
    text: <b>Paid</b>,
    option: 'Paid',
    value: 'paid',
  },
  {
    key: 'pending',
    text: <b>Pending</b>,
    option: 'Pending',
    value: 'pending',
  },
  {
    key: 'overdue',
    text: <b>Overdue</b>,
    option: 'Overdue',
    value: 'overdue',
  },
  {
    key: 'fail',
    text: <b>Fail</b>,
    option: 'Fail',
    value: 'fail',
  },
  {
    key: 'closed',
    text: <b>Closed</b>,
    option: 'Closed',
    value: 'closed',
  },
];

// braintree keys
export let DROP_IN_AUTH_KEY =
  process.env.REACT_APP_ENV_VALUE === 'production'
    ? 'production_9qykfnn2_qr37vkvczwv6snbn'
    : 'sandbox_zjgfgwx6_b82nypwxssb6x7gc';

export const KEY_ACTIVITY_DATA_TYPES = [
  'NUMBER_OF_ACTIVITIES_OUTPUT',
  'NUMBER_OF_SESSIONS_OUTPUT',
  'NUMBER_OF_PARTICIPANTS_OUTPUT',
  'NUMBER_OF_NEW_WORKS_OUTPUT',
  'COLLECTION_ACQUISITIONS_OUTPUT',
  'TOTAL_VOLUNTEERS_OUTPUT',
];

export const ABN_LOOKUP_CONFIG = {
  endpoint: 'https://abr.business.gov.au/json/MatchingNames.aspx',
  token: 'd1632a42-1241-482c-95af-e5bede72c4d5',
};

export const ALLOWED_ONBOARDING_TIPS_PATH = [
  '/dashboard',
  '/activities',
  '/activities/:id',
  '/activities/:id/:type',
];

export const OUTCOME_ADDRESS_BAR_COLORS = {
  CULTURAL: '#FFB500',
  SOCIAL: '#FFA28B',
  ECONOMIC: '#7B60A8',
  GOVERNANCE: '#194B00',
  ENVIRONMENTAL: '#007868',
};

export const GOOGLE_API_KEY =
  process.env.NODE_ENV === 'production'
    ? 'AIzaSyDsMgfBM8kNKaYkkvYy87UNcRaKTHtSWD4'
    : 'AIzaSyBhVuenFiFZb35DetyKvqMIFV7lqEtzpKQ';

export const ORGANISATION_REPORT_TYPES = [
  {
    key: 1,
    text: 'Activities',
    value: 'activities',
    disabled: false,
  },
  {
    key: 2,
    text: 'Outcomes',
    value: 'outcomes-addressed',
    disabled: false,
  },
  {
    key: 3,
    text: 'Outcomes Reported',
    value: 'outcomes-reported',
    disabled: false,
  },
  {
    key: 4,
    text: 'Inputs & Outputs',
    value: 'input-output-data',
    disabled: false,
  },
  {
    key: 5,
    text: 'Management Summary',
    value: 'management-summary',
    disabled: false,
  },
];

export const ALLOWED_DOCUMENTS_TYPES =
  '.txt, .pdf, .doc, .docx, .odt, .ods, .odp, .ppt, .pptx, .xls, .xlsx, .csv';

export const PARTNER_RELATIONSHIP_VALUES = {
  IN_KIND: 'IN_KIND',
  FUNDING: 'FUNDING',
  NETWORK: 'NETWORK',
};
export const PARTNER_RELATIONSHIP = [
  { value: PARTNER_RELATIONSHIP_VALUES.IN_KIND, label: 'In Kind' },
  { value: PARTNER_RELATIONSHIP_VALUES.FUNDING, label: 'Funding' },
  { value: PARTNER_RELATIONSHIP_VALUES.NETWORK, label: 'Network' },
];

export const ORGANISATION_REPORT_LOADING = {
  ACTIVITY_STATS_LOADING: 'orgActivityStatsLoading',
  ACTIVITY_REPORT_LOADING: 'orgActivityReportLoading',
  VENUE_REPORT_LOADING: 'orgVenueReportLoading',
  PARTICIPANT_REPORT_LOADING: 'orgParticipantReportLoading',
  //map
  ORGANISATION_MAP_LOADING: 'orgMapLoading',
  ACTIVITY_VENUE_MAP_LOADING: 'orgActVenuesMapLoading',
  CONTRACTOR_MAP_LOADING: 'orgContractorMapLoading',
  //outcome summary
  OUTCOME_ADDRESS_LOADING: 'orgOutcomeReportLoading',
  //input-output
  INPUT_OUTPUT_SUMMARY_LOADING: 'orgInputOutputLoading',
  //outcome-reported
  OUTCOME_REPORTED_LOADING: 'orgOutcomeReportLoading',
  //management
  MANAGEMENT_SUMMARY_LOADING: 'orgManagementReportLoading',

  //outcome reports
  POST_PRE_REPORT_LOADING: 'orgPostPreReportLoading',
  POST_REPORT_LOADING: 'orgPostReportLoading',
  INDIVIDUAL_RATE_REPORT_LOADING: 'orgIndividualReportLoading',
};

export const PROGRAM_REPORT_LOADING = {
  ACTIVITY_STATS_LOADING: 'programActivityStatsLoading',
  ACTIVITY_REPORT_LOADING: 'programActivityReportLoading',
  VENUE_REPORT_LOADING: 'programVenueReportLoading',
  PARTICIPANT_REPORT_LOADING: 'programParticipantReportLoading',
  //map
  ORGANISATION_MAP_LOADING: 'programOrgMapLoading',
  ACTIVITY_VENUE_MAP_LOADING: 'programVenueMapLoading',
  CONTRACTOR_MAP_LOADING: 'programContractorMapLoading',
  //outcome summary
  OUTCOME_ADDRESS_LOADING: 'programOutcomeLoading',
  //input-output
  INPUT_OUTPUT_SUMMARY_LOADING: 'programInputOutputLoading',
  //outcome-reported
  OUTCOME_REPORTED_LOADING: 'programOutcomeReportLoading',

  POST_PRE_REPORT_LOADING: 'programPostPreReportLoading',
  POST_REPORT_LOADING: 'programPostReportLoading',
  INDIVIDUAL_RATE_REPORT_LOADING: 'programIndividualReportLoading',
};

export const BETA_LAUNCH_ADMIN_EMAIL = 'cdn-admin@agencycolombo.com';

export const DEFAULT_CURRENCY_TYPE_ID = 1; // AUD {australian dollar}

export const LOGIN_ENCRYPTION_KEY = 'G1yino310m9';

export const MAP_DEFAULT_COUNTRY_NAME = 'Australia';
export const MAP_DEFAULT_COUNTRY_CODE = 'AU';

export const REPORT_FILTER_OPTIONS = {
  startDate: null,
  endDate: null,
  statuses: [], // activity status ids [1 - in development, 2 - active ,3 - completed]
  activityTypes: [],
  venues: [],
  participants: [],
  outcome: null,
  outcomes: [],
  method: 'all', // method use for only outcome report
  methods: [],
  goals: [],
  crossReferences: [],
  departments: [],
  units: [],
  sort: null,
  order: 'asc',
  page: 1,
  limit: LIMIT,
  organisationLevel: 'all',
  renderOutcomeChart: false,
  //subOptions
  subVenues: [],
  usedDataOnly: false,
};

export const MULTI_LEVEL_PROGRAM_PERMISSION_NAME = 'multi_level_program';

export const CREATE_LINKED_MANAGED_BY_OPTIONS = [
  { value: 'CURRENT_USER', label: 'Myself' },
  { value: 'INTERNAL_USER', label: 'Internally' },
  { value: 'EXTERNAL_USER', label: 'Externally' },
];

export const CREATE_LINKED_MANAGED_BY_OPTIONS_FOR_GUEST = [
  { value: 'CURRENT_USER', label: 'Myself' },
  { value: 'EXTERNAL_USER', label: 'Externally' },
];

export const SIDEBAR_TYPES = ['HELP', 'NOTE', 'FILTER'];

export const EMAIL_VERIFICATION_STATUS = [
  {
    status: 'ACTIVATED',
    header: 'Thank you for activating your',
    message: 'email address.',
    description: '',
  }, // when the email is activated at the first time
  {
    status: 'ALREADY_ACTIVATED',
    header: 'You have already activated your',
    message: 'email address.',
    description: '',
  }, // if the requested user already activated
  {
    status: 'REQUEST_INVALID',
    header: '',
    message: 'This link is no longer active.',
    description: 'Looks like this link is broken or has already been used.',
  }, // to check token is invalid or expired
];
