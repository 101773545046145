import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Dropdown,
  Grid,
  Dimmer,
  Loader,
  Segment,
  Button,
  Icon,
  Modal,
} from 'semantic-ui-react';
import { APP_NAME } from '../../../utils/constant';
import {
  getActPreviewData,
  clearCurrentActivity,
  getReports,
  approvalToggleActivity,
  getReportViaEmail,
} from '../../../store/actions';
import { CustomSegment } from '../../../components';
import { map, isEmpty, get } from '../../../utils/lodash';
import { PermissionDenied } from '../../../components/errorStatus';
import { getActivityType } from '../../../utils';
import {
  ReportActivity,
  TheoryOfChange,
  ActualOutput,
  Notes,
  ParentReportCharts,
  ActualOutcomes,
} from '../form';
import './preview.scss';
import '../activities.scss';

import emailSendIcon from '../../../assets/email-send.svg';
import { isParentProgramActivity } from '../../../utils/activity';
import { checkPermissionDenied } from '../../../utils/activity/userPermission';

class LinkedActivityApproval extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  async linkedApprovalToggle(status) {
    try {
      const { currentActivity, approvalToggleActivity } = this.props;
      this.setState({ loading: true });
      await approvalToggleActivity(
        currentActivity.parentActivity.id,
        currentActivity.id,
        status
      );
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading } = this.state;
    return (
      <Segment loading={loading} disabled={loading}>
        <Grid>
          <Grid.Row className="row-report-approval">
            <Grid.Column mobile={16} tablet={16} computer={4} />
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={4}
              textAlign="center"
            >
              <Button positive onClick={() => this.linkedApprovalToggle(true)}>
                Accept Report
              </Button>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={4}
              textAlign="center"
            >
              <Button negative onClick={() => this.linkedApprovalToggle(false)}>
                Review Report
              </Button>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={4} />
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
export class ActivityPreviewSteps extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      reportAllImagesLoaded: true,
      waitExportLoading: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        waitExportLoading: false,
      });
    }, 5000);
  }

  renderApproval() {
    const { currentActivity, user } = this.props;

    if (currentActivity.isLinkedActivity && currentActivity.statusId === 3) {
      if (currentActivity.childApproved) return null;
      const isAllowedToReportApprove = get(currentActivity, 'members', []).find(
        (member) =>
          member.userId === user.id &&
          (member.isProgramCollaborator ||
            member.isProgramAndProjectCollaborator)
      );

      return isAllowedToReportApprove ? (
        <CustomSegment
          title="REPORT APPROVAL"
          children={<LinkedActivityApproval {...this.props} />}
          extraMargin
          toggle={false}
        />
      ) : null;
    }
    return null;
  }

  async getAllImages(value) {
    return new Promise((resolve, reject) => {
      try {
        let getAllGraphImages = value['getAllGraphImages'];
        let reportLoaded = value['reportLoaded'];
        let reportAllImagesLoaded = false;

        if (getAllGraphImages || reportLoaded) {
          reportAllImagesLoaded = true;
        } else {
          reportAllImagesLoaded = false;
        }
        this.setState(
          {
            reportAllImagesLoaded,
          },
          () => {
            resolve();
          }
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  showInfoModal() {
    this.setState({
      modal: true,
    });
  }

  render() {
    try {
      const { modal } = this.state;
      const {
        currentActivity,
        actGetLoading,
        getReports,
        publicView = false,
        festivalTypes,
        user,
        getReportViaEmail,
      } = this.props;

      if (isEmpty(currentActivity)) return null;
      const domains = map(
        currentActivity.parentDomains,
        (i) => i.policy.policyName
      );

      const currencyType = get(currentActivity, 'currencyType');

      return (
        <div
          className={`content-layer${
            currentActivity &&
            isParentProgramActivity(currentActivity.activityPlanTypeId)
              ? ' parent-act'
              : ''
          }`}
        >
          <div className="content-container">
            <Grid>
              {publicView ? null : (
                <Grid.Row className="act-row no-print" textAlign="right">
                  <Grid.Column
                    // className="report-graph-col"
                    mobile={16}
                    tablet={16}
                    computer={16}
                    className="export-dropdown-btn"
                  >
                    {
                      <Button
                        size="small"
                        className="Primary-Button export"
                        icon
                        labelPosition="left"
                      >
                        <Dropdown
                          text={'Export'}
                          direction="left"
                          //inline
                          selectOnBlur={false}
                          icon="angle down"
                          //className="export-dropdown-btn"
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              className="dropdown-customItem"
                              onClick={() => {
                                getReportViaEmail(currentActivity.id).then(
                                  (status) => {
                                    if (status) {
                                      this.showInfoModal();
                                    }
                                  }
                                );
                              }}
                              children={
                                <label
                                  style={{
                                    color: 'black',
                                  }}
                                >
                                  Download Report
                                </label>
                              }
                            />
                            {user.role === 'admin' ? (
                              <Dropdown.Item
                                className="dropdown-customItem"
                                onClick={() => {
                                  getReports(
                                    `/activity/${currentActivity.id}/export-data`,
                                    'previewExport'
                                  ).then((status) => {
                                    if (status) {
                                      this.showInfoModal();
                                    }
                                  });
                                }}
                                children={
                                  <label
                                    style={{
                                      color: 'black',
                                    }}
                                  >
                                    Download JSON
                                  </label>
                                }
                              />
                            ) : null}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Button>
                    }
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row className="act-row">
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={16}
                  className="activity-sub-column"
                >
                  <div>
                    <CustomSegment
                      title="Activity"
                      extraMargin={publicView ? false : true}
                      children={
                        <ReportActivity
                          name={
                            currentActivity.name ? currentActivity.name : '-'
                          }
                          owner={
                            currentActivity.owner
                              ? currentActivity.owner.name
                                ? currentActivity.owner.name
                                : '-'
                              : '-'
                          }
                          type={
                            isParentProgramActivity(
                              currentActivity.activityPlanTypeId
                            )
                              ? getActivityType(currentActivity).title || '-'
                              : get(currentActivity, 'eventDetail.detail', '-')
                          }
                          periodGreenLight={
                            currentActivity.greenLightNotifyDate
                              ? currentActivity.greenLightNotifyDate
                              : '-'
                          }
                          periodStart={currentActivity.eventStartDate}
                          periodEnd={currentActivity.eventEndDate}
                          goal={
                            currentActivity.goal ? currentActivity.goal : '-'
                          }
                          goalDescription={
                            currentActivity.goalDescription
                              ? currentActivity.goalDescription
                              : '-'
                          }
                          summary={
                            currentActivity.summary
                              ? currentActivity.summary
                              : currentActivity.description
                                ? currentActivity.description
                                : null
                          }
                          activityPlanTypeId={
                            currentActivity.activityPlanTypeId
                          }
                          parentFestivals={currentActivity.parentFestivals}
                          festivalTypes={festivalTypes}
                        />
                      }
                      toggle={false}
                      className="activity"
                    />
                    {isParentProgramActivity(
                      currentActivity.activityPlanTypeId
                    ) ? (
                      <CustomSegment
                        className="theory-of-change"
                        title="Theory Of Change"
                        children={
                          <TheoryOfChange
                            grant
                            currentActivity={
                              currentActivity ? currentActivity : {}
                            }
                            outcomes={
                              this.props.parentOutcomes
                                ? this.props.parentOutcomes
                                : []
                            }
                            aim={`${
                              currentActivity.primaryPolicy
                                ? `${get(
                                    currentActivity,
                                    'primaryPolicy.policyName',
                                    ''
                                  )}`
                                : ''
                            }${
                              currentActivity.secondaryPolicy
                                ? ` and ${get(
                                    currentActivity,
                                    'secondaryPolicy.policyName',
                                    ''
                                  )}`
                                : !isEmpty(domains)
                                  ? ', '
                                  : ''
                            }${!isEmpty(domains) ? domains.join(', ') : ''}`}
                            knowing={get(
                              currentActivity,
                              'projectTocKnown',
                              '-'
                            )}
                            willDo={get(currentActivity, 'projectTocDo', '-')}
                            description={null}
                            evidenceTypes={
                              this.props.evidenceTypes
                                ? this.props.evidenceTypes
                                : null
                            }
                            evidence={currentActivity.evidence || []}
                          />
                        }
                        extraMargin
                        toggle={false}
                      />
                    ) : (
                      <Fragment>
                        <CustomSegment
                          title={
                            currentActivity.primaryPolicy
                              ? currentActivity.primaryPolicy.policyName
                                ? 'Objective - Intended Outcomes ' +
                                  currentActivity.primaryPolicy.policyName
                                : 'Objective - Intended Outcomes'
                              : 'Objective - Intended Outcomes'
                          }
                          className="objective_"
                          children={
                            <TheoryOfChange
                              aimLabel={
                                currentActivity.objective
                                  ? currentActivity.objective[0]
                                    ? currentActivity.objective[0].outcome
                                      ? currentActivity.objective[0].outcome
                                          .aiming
                                        ? currentActivity.objective[0].outcome
                                            .aiming
                                        : 'Aiming for '
                                      : 'Aiming for '
                                    : 'Aiming for '
                                  : 'Aiming for '
                              }
                              aim={
                                currentActivity.objective
                                  ? currentActivity.objective[0]
                                    ? currentActivity.objective[0].outcome
                                      ? currentActivity.objective[0].outcome
                                          .contentFormat
                                        ? currentActivity.objective[0].outcome
                                            .contentFormat
                                        : '-'
                                      : '-'
                                    : '-'
                                  : '-'
                              }
                              knowingLabel={
                                currentActivity.objective
                                  ? currentActivity.objective[0]
                                    ? currentActivity.objective[0].outcome
                                      ? currentActivity.objective[0].outcome
                                          .knowing
                                        ? currentActivity.objective[0].outcome
                                            .knowing
                                        : ' and knowing '
                                      : ' and knowing '
                                    : ' and knowing '
                                  : ' and knowing '
                              }
                              knowing={
                                currentActivity.objective
                                  ? currentActivity.objective[0]
                                    ? currentActivity.objective[0].tocKnown
                                      ? currentActivity.objective[0].tocKnown
                                      : '-'
                                    : '-'
                                  : '-'
                              }
                              willDo={
                                currentActivity.objective
                                  ? currentActivity.objective[0]
                                    ? currentActivity.objective[0].tocDo
                                      ? currentActivity.objective[0].tocDo
                                      : '-'
                                    : '-'
                                  : '-'
                              }
                              description={
                                currentActivity.objective
                                  ? currentActivity.objective[0]
                                    ? currentActivity.objective[0].outcome
                                      ? currentActivity.objective[0].outcome
                                          .title
                                        ? currentActivity.objective[0].outcome
                                            .title
                                        : '-'
                                      : '-'
                                    : '-'
                                  : '-'
                              }
                              evidenceTypes={
                                this.props.evidenceTypes
                                  ? this.props.evidenceTypes
                                  : null
                              }
                              evidence={
                                currentActivity.objective
                                  ? currentActivity.objective[0]
                                    ? currentActivity.objective[0].evidence
                                      ? currentActivity.objective[0].evidence
                                      : []
                                    : []
                                  : []
                              }
                            />
                          }
                          extraMargin
                          toggle={false}
                        />
                        {currentActivity.secondaryPolicyId ? (
                          <CustomSegment
                            title={
                              currentActivity.secondaryPolicy
                                ? currentActivity.secondaryPolicy.policyName
                                  ? 'Objective - Intended Outcomes ' +
                                    currentActivity.secondaryPolicy.policyName
                                  : 'Objective - Intended Outcomes'
                                : 'Objective - Intended Outcomes'
                            }
                            className="objective_"
                            children={
                              <TheoryOfChange
                                aimLabel={
                                  currentActivity.objective
                                    ? currentActivity.objective[1]
                                      ? currentActivity.objective[1].outcome
                                        ? currentActivity.objective[1].outcome
                                            .aiming
                                          ? currentActivity.objective[1].outcome
                                              .aiming
                                          : 'Aiming for '
                                        : 'Aiming for '
                                      : 'Aiming for '
                                    : 'Aiming for '
                                }
                                aim={
                                  currentActivity.objective
                                    ? currentActivity.objective[1]
                                      ? currentActivity.objective[1].outcome
                                        ? currentActivity.objective[1].outcome
                                            .contentFormat
                                          ? currentActivity.objective[1].outcome
                                              .contentFormat
                                          : '-'
                                        : '-'
                                      : '-'
                                    : '-'
                                }
                                knowingLabel={
                                  currentActivity.objective
                                    ? currentActivity.objective[1]
                                      ? currentActivity.objective[1].outcome
                                        ? currentActivity.objective[1].outcome
                                            .knowing
                                          ? currentActivity.objective[1].outcome
                                              .knowing
                                          : ' and knowing '
                                        : ' and knowing '
                                      : ' and knowing '
                                    : ' and knowing '
                                }
                                knowing={
                                  currentActivity.objective
                                    ? currentActivity.objective.length > 1
                                      ? currentActivity.objective[1]
                                        ? currentActivity.objective[1].tocKnown
                                          ? currentActivity.objective[1]
                                              .tocKnown
                                          : '-'
                                        : '-'
                                      : '-'
                                    : '-'
                                }
                                willDo={
                                  currentActivity.objective
                                    ? currentActivity.objective.length > 1
                                      ? currentActivity.objective[1]
                                        ? currentActivity.objective[1].tocDo
                                          ? currentActivity.objective[1].tocDo
                                          : '-'
                                        : '-'
                                      : '-'
                                    : '-'
                                }
                                description={
                                  currentActivity.objective
                                    ? currentActivity.objective[1]
                                      ? currentActivity.objective[1].outcome
                                        ? currentActivity.objective[1].outcome
                                            .title
                                          ? currentActivity.objective[1].outcome
                                              .title
                                          : '-'
                                        : '-'
                                      : '-'
                                    : '-'
                                }
                                evidenceTypes={
                                  this.props.evidenceTypes
                                    ? this.props.evidenceTypes
                                    : null
                                }
                                evidence={
                                  currentActivity.objective
                                    ? currentActivity.objective[1]
                                      ? currentActivity.objective[1].evidence
                                        ? currentActivity.objective[1].evidence
                                        : []
                                      : []
                                    : []
                                }
                              />
                            }
                            extraMargin
                            toggle={false}
                          />
                        ) : null}
                      </Fragment>
                    )}
                    <CustomSegment
                      className="key-activity-data"
                      title="Key Activity Data"
                      children={
                        <ActualOutput
                          currencyType={currencyType}
                          outputs={
                            currentActivity.eventDetail
                              ? currentActivity.eventDetail.outputFormat
                                ? currentActivity.eventDetail.outputFormat
                                    .format
                                  ? currentActivity.eventDetail.outputFormat
                                      .format
                                  : []
                                : []
                              : []
                          }
                          allData={currentActivity ? currentActivity : {}}
                          volunteers={
                            currentActivity.actualVolunteers
                              ? currentActivity.actualVolunteers
                              : '-'
                          }
                          venue={
                            currentActivity.venues
                              ? currentActivity.venues.length > 0
                                ? currentActivity.venues
                                : []
                              : []
                          }
                        />
                      }
                      extraMargin
                      toggle={false}
                    />
                    {isParentProgramActivity(
                      currentActivity.activityPlanTypeId
                    ) ? (
                      <ParentReportCharts
                        inParentReportPage={true}
                        // getAllImages={(value) => getAllImages(value)}
                      />
                    ) : currentActivity.evaluationPlans ? (
                      <ActualOutcomes
                        getAllImages={(value) => this.getAllImages(value)}
                      />
                    ) : null}
                    <CustomSegment
                      className="analysis-and-reflection"
                      title="Analysis and Reflection"
                      children={
                        <Notes
                          reflection={
                            currentActivity.reflection
                              ? currentActivity.reflection
                              : null
                          }
                          isAnalysisField={currentActivity.isAnalysisField}
                          analysis={
                            currentActivity.analysis
                              ? currentActivity.analysis
                              : ''
                          }
                          evaluationPlans={
                            currentActivity.evaluationPlans
                              ? currentActivity.evaluationPlans
                              : []
                          }
                          // no need to change isParent
                          isParent={isParentProgramActivity(
                            currentActivity.activityPlanTypeId
                          )}
                        />
                      }
                      extraMargin
                      toggle={false}
                    />
                    {this.renderApproval()}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <Modal
            open={modal}
            onOpen={() => this.toggle()}
            size={'mini'} //  : 'small'
          >
            <Modal.Content>
              <div className="align-self-center justify-content-center text-center my-3">
                <img
                  className="modal-report-error-img"
                  alt="greenRight"
                  src={emailSendIcon}
                />{' '}
              </div>

              <p className="text-center font-weight-bold my-3 modal-report-error-header">
                Report is being generated
              </p>
              <p className="text-center modal-report-error-paragraph">
                Your report is being compiled and will be sent to the email
                address associated with this account.
                <br />
                <br />
                Reports with multiple graphs may take a few minutes to generate.
                <br />
                <br />
                If you have not received your report in 15 minutes, please
                contact <br />
                <a
                  className="contactUs"
                  href="mailto:support@takso-outcomes.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  support@takso-outcomes.com
                </a>
              </p>
            </Modal.Content>
            <Modal.Actions>
              <div className="align-self-center justify-content-center text-center">
                <Button
                  className="modal-proceed px-5"
                  content="Got it"
                  onClick={() => this.setState({ modal: false })}
                />
              </div>
            </Modal.Actions>
          </Modal>
          <Dimmer active={actGetLoading} inverted>
            <Loader size="massive" />
          </Dimmer>
        </div>
      );
    } catch (error) {
      return null;
    }
  }
}

class ActivityPreview extends React.PureComponent {
  async componentDidMount() {
    document.title = `Activities | ${APP_NAME}`;

    try {
      const { match, getActPreviewData, clearCurrentActivity } = this.props;

      if (match.params) {
        await clearCurrentActivity();
        await getActPreviewData(match.params.id);
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const { currentActivity, user } = this.props;
    if (checkPermissionDenied(user, currentActivity))
      return <PermissionDenied />;
    return <ActivityPreviewSteps {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    user: state.authentication.user,
    evidenceTypes: state.extra.evidenceTypes,
    parentOutcomes: state.extra.outcomes,
    festivalTypes: state.extra.festivalTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActPreviewData,
      clearCurrentActivity,
      getReports,
      approvalToggleActivity,
      getReportViaEmail,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityPreview);
