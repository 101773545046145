import * as Yup from 'yup';

export const addNewGoalInitialValues = (selectedGoal) => {
  return {
    goalTitle: selectedGoal ? selectedGoal.goalTitle : '',
    goalDescription: selectedGoal ? selectedGoal.goalDescription : '',
    primaryPolicyId: selectedGoal ? selectedGoal.primaryPolicyId : '',
    secondaryPolicyId: selectedGoal ? selectedGoal.secondaryPolicyId : '',
  };
};

export const addNewGoalValidationSchema = Yup.object().shape({
  goalTitle: Yup.string()
    .required('Goal is required')
    .max(250, 'Goal should be less than 250 characters'),
  primaryPolicyId: Yup.number().required('Primary Policy is required'),
});
