/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, Segment, Dropdown, Search } from 'semantic-ui-react';
import { getLinkedActivities } from '../../../../../store/actions';
import { get } from '../../../../../utils/lodash';
import { isOnlyProgramCollaborator } from '../../../../../utils/activity/userPermission';
import CreateLinkedActivity from './modals/createLinkedActivity';
import { debounce } from 'lodash';
import { ViewLinkedActivities } from './dataTables/viewLinkedActivities';
import { MULTI_LEVEL_PROGRAM_PERMISSION_NAME } from '../../../../../utils/constant';

export default function LinkedActivities(props) {
  const { extraMargin, extraBottomMargin, style } = props;

  const [orderBy, setOrderBy] = useState('asc');
  const [status, setStatus] = useState('all');
  const [sortBy, setSortBy] = useState('activityName');

  const { currentActivity } = useSelector((state) => state.activity);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authentication);

  const ORDERBY_OPTIONS = [
    {
      key: 'asc',
      text: <span>Ascending</span>,
      value: 'asc',
    },
    {
      key: 'desc',
      text: <span>Descending</span>,
      value: 'desc',
    },
  ];

  const STATUS_OPTIONS = [
    {
      key: 'all',
      text: <span>All</span>,
      value: 'all',
    },
    {
      key: 'completed',
      text: <span>Completed</span>,
      value: 'completed',
    },
    {
      key: 'active',
      text: <span>Active</span>,
      value: 'active',
    },
    {
      key: 'ongoing',
      text: <span>Ongoing</span>,
      value: 'ongoing',
    },
    {
      key: 'projected',
      text: <span>Projected</span>,
      value: 'projected',
    },
    {
      key: 'deactivated',
      text: <span>Deactivated</span>,
      value: 'deactivated',
    },
  ];

  const SORT_BY_OPTIONS = [
    {
      key: 'activityName',
      text: <span>Activity Name</span>,
      value: 'activityName',
    },
    {
      key: 'Status',
      text: <span>Status</span>,
      value: 'status',
    },
    {
      key: 'Contact',
      text: <span>Contact</span>,
      value: 'contact',
    },
    {
      key: 'Organisation',
      text: <span>Organisation</span>,
      value: 'organisationName',
    },
    {
      key: 'Activity Type',
      text: <span>Activity Type</span>,
      value: 'activityType',
    },
  ];

  const reSetFilters = () => {
    setOrderBy('asc');
    setStatus('all');
    setSortBy('activityName');
  };

  const SearchOnchange = (e, { value }) => {
    dispatch(
      getLinkedActivities(currentActivity.id, status, sortBy, orderBy, value)
    );
  };

  const handelOrderBy = (value) => {
    setOrderBy(value);
    dispatch(getLinkedActivities(currentActivity.id, status, sortBy, value));
  };
  const handelStatus = (value) => {
    setStatus(value);
    dispatch(getLinkedActivities(currentActivity.id, value, sortBy, orderBy));
  };

  const handelSortBy = (value) => {
    setSortBy(value);
    dispatch(getLinkedActivities(currentActivity.id, status, value, orderBy));
  };

  const currentUserOnlyProgramCollaborator = isOnlyProgramCollaborator(
    get(user, 'id', null),
    get(currentActivity, 'members', null)
  );

  const currentUserFeatures = get(user, 'features', []);
  const isSingleActivityIncluded = currentUserFeatures.some(
    (item) => item === 'single_program'
  );
  const isMultiLevelIncluded = currentUserFeatures.some(
    (item) => item === MULTI_LEVEL_PROGRAM_PERMISSION_NAME
  );
  const isFestivalActivityIncluded = currentUserFeatures.some(
    (item) => item === 'festival_program'
  );
  const isGrantActivityIncluded = currentUserFeatures.some(
    (item) => item === 'grant_program'
  );
  const isMapActivityIncluded = currentUserFeatures.some(
    (item) => item === 'multi_activity_program'
  );

  return (
    <Segment.Group
      raised
      style={{
        marginTop: extraMargin ? '2.857rem' : null,
        marginBottom: extraBottomMargin ? '2.857rem' : null,
        ...style,
      }}
    >
      <Segment
        raised
        style={{
          marginTop: true ? '2.857rem' : null,
          marginBottom: false ? '2.857rem' : null,
        }}
        className="evaluation-segment"
      >
        <div className="linkedActivity-eval-plan">
          <Grid>
            <Grid.Column mobile={16} style={{ paddingBottom: '0rem' }}>
              <div className="segment-custom-heading-evidence linked-activity-margin-left">
                Program Activities
                <br></br>
              </div>
            </Grid.Column>
          </Grid>
          <Grid style={{ marginTop: '0rem' }}>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <label className="eval-plan-desc">
                The evaluation of this program will be an aggregate of
                evaluation from the programs’ linked activity records and
                reported through the Documentation page of this program
              </label>
            </Grid.Column>
            {!currentUserOnlyProgramCollaborator && (
              <Grid.Column
                mobile={16}
                tablet={8}
                computer={8}
                textAlign="right"
                verticalAlign="middle"
              >
                {!isMultiLevelIncluded ? (
                  <CreateLinkedActivity
                    isMultiLevelIncluded={false}
                    textLabel="Single Activity"
                    modalLabel="Single Linked Activity"
                    activityPlanTypeId={1} // activityPlanTypeId is single linked activity
                    parentActivityPlanTypeId={
                      currentActivity.activityPlanTypeId
                    }
                    reSetFilters={reSetFilters}
                    currentState="project.edit.evaluationPlan"
                  />
                ) : (
                  <div className="dropdown-adjust user-profile-dropdown">
                    <Dropdown
                      // disabled={getReportsLoading['evidenceExport']}
                      text="Add Linked Activity"
                      direction="left"
                      selectOnBlur={false}
                      icon="angle down"
                      className="add-linked-btn"
                    >
                      <Dropdown.Menu className="mt-1">
                        {isSingleActivityIncluded && (
                          <CreateLinkedActivity
                            textLabel="Single Activity"
                            modalLabel="Single Linked Activity"
                            activityPlanTypeId={1} // activityPlanTypeId is single linked activity
                            parentActivityPlanTypeId={
                              currentActivity.activityPlanTypeId
                            }
                            reSetFilters={reSetFilters}
                            currentState="project.edit.evaluationPlan"
                          />
                        )}

                        {/* Grant Program */}
                        {isMultiLevelIncluded && isGrantActivityIncluded && (
                          <CreateLinkedActivity
                            textLabel="Grant Program"
                            modalLabel="Grant Program Linked Activity"
                            activityPlanTypeId={4}
                            reSetFilters={reSetFilters}
                            currentState="project.edit.evaluationPlan"
                          />
                        )}

                        {/* Festival Program */}
                        {isMultiLevelIncluded && isFestivalActivityIncluded && (
                          <CreateLinkedActivity
                            textLabel="Festival Program"
                            modalLabel="Festival Program Linked Activity"
                            activityPlanTypeId={3}
                            parentActivityPlanTypeId={
                              currentActivity.activityPlanTypeId
                            }
                            reSetFilters={reSetFilters}
                            currentState="project.edit.evaluationPlan"
                          />
                        )}

                        {/* MAP Program */}
                        {isMultiLevelIncluded && isMapActivityIncluded && (
                          <CreateLinkedActivity
                            textLabel="Multi-Activity Program"
                            modalLabel="Multi-Activity Program Linked Activity"
                            activityPlanTypeId={6}
                            parentActivityPlanTypeId={
                              currentActivity.activityPlanTypeId
                            }
                            reSetFilters={reSetFilters}
                            currentState="project.edit.evaluationPlan"
                          />
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </Grid.Column>
            )}
          </Grid>
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={5}>
              <Search
                className="search-bar-custom linked-activity-margin-left"
                // style={{ marginLeft: '1.125rem' }}
                placeholder="Search by Activity Name"
                showNoResults={false}
                onSearchChange={debounce(SearchOnchange, 500)}
              />
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={11}
              textAlign="right"
              verticalAlign="middle"
            >
              <div className="org-sort">
                <span className="activities-filter">
                  Sort By: &nbsp;
                  <Dropdown
                    direction="left"
                    inline
                    selectOnBlur={false}
                    value={sortBy}
                    options={SORT_BY_OPTIONS}
                    onChange={(e, { value }) => handelSortBy(value)}
                  />
                </span>
                <span className="activities-filter linked-activity-margin-left">
                  Order by: &nbsp;
                  <Dropdown
                    direction="left"
                    inline
                    value={orderBy}
                    selectOnBlur={false}
                    options={ORDERBY_OPTIONS}
                    onChange={(e, { value }) => handelOrderBy(value)}
                  />
                </span>
                <span className="activities-filter linked-activity-margin-left">
                  Status: &nbsp;
                  <Dropdown
                    direction="left"
                    inline
                    value={status}
                    selectOnBlur={false}
                    options={STATUS_OPTIONS}
                    onChange={(e, { value }) => handelStatus(value)}
                  />
                </span>
              </div>
            </Grid.Column>
          </Grid>
        </div>
        <Grid>
          <Grid.Column
            mobile={16}
            style={{ margin: '0rem', padding: '0rem 0rem' }}
          >
            <ViewLinkedActivities
              reSetFilters={reSetFilters}
              currentState="project.edit.evaluationPlan"
            />
          </Grid.Column>
        </Grid>
      </Segment>
    </Segment.Group>
  );
}
