import { Formik } from 'formik';
import React from 'react';
import { Button, Form, Icon } from 'semantic-ui-react';
import {
  changePasswordInitialValues,
  changePasswordValidationSchema,
} from '../validations/changePasswordValidation';
import { CustomPasswordInput, CustomInput } from './form';

const PasswordChange = ({
  handleValidSubmit,
  loading = false,
  toggle = null,
  isFromForgotPassword = false,
}) => {
  const [isShownPass, setIsShownPass] = React.useState(false);
  return (
    <Formik
      initialValues={changePasswordInitialValues}
      onSubmit={(values) => {
        handleValidSubmit(values);
      }}
      validationSchema={changePasswordValidationSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <CustomPasswordInput
            id="password"
            name="password"
            labelText="New Password"
            placeholder="New Password"
          />

          <CustomInput
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Confirm Password"
            labelText="Confirm Password"
            type={isShownPass ? 'text' : 'password'}
            icon={
              <Icon
                name={isShownPass ? 'eye' : 'eye slash'}
                onClick={() => {
                  setIsShownPass(!isShownPass);
                }}
                link
              />
            }
          />

          {!toggle && !isFromForgotPassword ? (
            <Button
              disabled={loading}
              loading={loading}
              type="submit"
              className="Primary-Button"
            >
              Change
            </Button>
          ) : (
            !toggle &&
            isFromForgotPassword && (
              <Button
                type="submit"
                className="btn btn-primary signin-button"
                loading={loading}
                disabled={loading}
              >
                Reset Password
              </Button>
            )
          )}

          {toggle && !isFromForgotPassword && (
            <div className="model-buttons">
              <Button
                className="modal-cancel"
                type="button"
                onClick={() => toggle()}
              >
                Cancel
              </Button>
              <Button content="Reset" type="submit" className="modal-proceed" />
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default PasswordChange;
