//organization

import React from 'react';
import { Grid, Icon, Menu, Popup, Tab, Modal, Button } from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';
import { find, compact, findIndex, get, remove } from '../../utils/lodash';
import '../organization/organization.scss';
import { CustomSegment, CustomTooltip, FeatureWrapper } from '../../components';
import { isFeature } from '../../utils/activity/userPermission';
import { APP_NAME } from '../../utils/constant';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import {
  OrganizationProfile,
  OrganizationActivity,
  OrganizationGoals,
  OrganizationUserManagement,
  OrganizationGuestManagement,
  OrganizationUserReassign,
  OrganizationVenues,
  OrganizationCoreData,
  OrganizationGLApproval,
  OrganizationChecklistReport,
  Communication,
  CrossReference,
} from './forms';
import './organization.scss';
import { exportSystemUsers, getEmptyMessage } from '../../store/actions';
import OrganisationContacts from './forms/contacts';
import { DepartmentsUnits } from './forms/departmentsAndUnits';

class UnSavedModal extends React.PureComponent {
  render() {
    const { toggle, leaveThePage, modalShow } = this.props;
    return (
      <Modal
        size="tiny"
        open={modalShow}
        closeIcon={
          <Icon onClick={() => toggle()} name="close" className="closeicon" />
        }
      >
        <Modal.Header className="warning-header-text">
          Are you sure you want to leave this page?
        </Modal.Header>
        <Modal.Content className="warning-content-text">
          You have unsaved changes. Do you want to leave ?
        </Modal.Content>
        <div className="buttons-stay-leave">
          <Button onClick={() => toggle()} className="stay">
            Stay
          </Button>
          <Button
            onClick={() => leaveThePage()}
            content="Leave"
            className="leave"
          />
        </div>
      </Modal>
    );
  }
}

// Import other required components
const Organization = (props) => {
  const [state, setState] = useState({
    currentTab: 0,
    isEdit: false,
    isLeave: false,
    modalShow: false,
    nextTab: 0,
    crossReference: false,
    department: false,
    crossRefDocOptions: [],
    crossRefCurrentDoc: {},
    reportingYearsOptions: [],
    reportingYear: null,
    reportStart: null,
    reportEnd: null,
  });

  const isEditToggle = (isEdit) => {
    setState((prevState) => ({
      ...prevState,
      isEdit,
    }));
  };

  const auth = useSelector((state) => state.authentication);
  const {
    user: {
      organisation: { settings = [] },
    },
  } = useSelector((state) => state.authentication);
  const user = useSelector((state) => state.authentication.user);
  const {
    emptyMessage: { goalEmpty = false },
  } = useSelector((state) => state.organization);

  const helpTips = useSelector((state) => state.extra.activityHelpTips);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Organisation | ${APP_NAME}`;

    const [config = {}] = settings;

    setState((prevState) => ({
      ...prevState,
      crossReference: config.crossReference ? true : false,
      department: config.department ? true : false,
    }));

    getEmptyMessage();

    return () => {
      setState((prevState) => ({
        ...prevState,
        isEdit: false,
        isLeave: true,
      }));
    };
  }, []);

  useEffect(() => {
    const { match } = props;

    if (match.params.type) {
      changeRoute(match.params.type);
    }
  }, []);

  // const toggleCrossRefComponent = () => {
  //   setState(prevState => ({
  //     ...prevState,
  //     crossReference: !state.crossReference,
  //   }));
  // };

  const setCrossRefFromChild = (crossRefDocOptions, crossRefCurrentDoc) => {
    setState((prevState) => ({
      ...prevState,
      crossRefDocOptions,
      crossRefCurrentDoc,
    }));
  };

  // const onChangeCrossReferenceDocument = (event, data) => {
  //   setState(prevState => ({
  //     ...prevState,
  //     crossRefCurrentDocId: data.value,
  //   }));
  // };

  const generateTabs = () => {
    const {
      // crossRefDocOptions, // TODO: This will be needed to next task in cross ref
      crossReference,
      crossRefCurrentDoc,
      crossRefCurrentDocId,
    } = state;
    const panes = compact([
      isFeature(user, 'account_profile')
        ? {
            menuItem: (
              <Menu.Item key="account-profile">
                <CustomTooltip
                  state={get(user, 'options.tips')}
                  contentObject={{
                    data: helpTips,
                    key: '83',
                  }}
                  position="bottom left"
                >
                  <div>Profile</div>
                </CustomTooltip>
              </Menu.Item>
            ),
            route: 'profile',
            render: () => (
              <Tab.Pane attached={false} className="custom-tab">
                <CustomSegment
                  title="Update Organisation Details"
                  className="update-organisation-details"
                  children={<OrganizationProfile isEditToggle={isEditToggle} />}
                />
              </Tab.Pane>
            ),
          }
        : null,

      isFeature(user, 'account_goals')
        ? {
            menuItem: goalEmpty ? (
              <Menu.Item key="account-goals">
                <div>
                  <Popup
                    content={<div>Goals are not yet created.</div>}
                    trigger={
                      <Icon
                        name="warning circle"
                        color="red"
                        size="large"
                      ></Icon>
                    }
                  />
                  Goals
                </div>
              </Menu.Item>
            ) : (
              <Menu.Item key="messages">
                <CustomTooltip
                  state={get(user, 'options.tips')}
                  contentObject={{
                    data: helpTips,
                    key: '84',
                  }}
                  position="bottom left"
                >
                  <div>Goals</div>
                </CustomTooltip>
              </Menu.Item>
            ),
            route: 'goals',
            render: () => (
              <Tab.Pane attached={false} className="custom-tab">
                <OrganizationGoals />
              </Tab.Pane>
            ),
          }
        : null,

      isFeature(user, 'account_core_data')
        ? {
            menuItem: (
              <Menu.Item key="account-core-data">
                <CustomTooltip
                  state={get(user, 'options.tips')}
                  contentObject={{
                    data: helpTips,
                    key: '208',
                  }}
                  position="bottom left"
                >
                  <div>Core Data</div>
                </CustomTooltip>
              </Menu.Item>
            ),
            route: 'core-data',
            render: () => (
              <Tab.Pane attached={false} className="custom-tab">
                <OrganizationCoreData />
              </Tab.Pane>
            ),
          }
        : null,
      isFeature(user, 'account_settings')
        ? {
            menuItem: (
              <Menu.Item key="account-settings">
                <CustomTooltip
                  state={get(user, 'options.tips')}
                  contentObject={{
                    data: helpTips,
                    key: '209',
                  }}
                  position="bottom left"
                >
                  <div>Settings</div>
                </CustomTooltip>
              </Menu.Item>
            ),
            route: 'settings',
            render: () => (
              <Tab.Pane attached={false} className="custom-tab">
                <Grid>
                  <Grid.Row>
                    <Grid.Column
                      className="organization-settings-tab"
                      mobile={16}
                      tablet={16}
                      computer={16}
                    >
                      <CustomSegment
                        title="Organization Settings"
                        children={
                          <OrganizationGLApproval
                            setCrossReference={(value) => {
                              setState((prevState) => ({
                                ...prevState,
                                crossReference: value,
                              }));
                            }}
                            setDepartment={(value) => {
                              setState((prevState) => ({
                                ...prevState,
                                department: value,
                              }));
                            }}
                          />
                        }
                        className="organization-settings"
                      />
                      {crossReference && (
                        <CustomSegment
                          tooltip={{
                            user: props.user,
                            helpTips: props.helpTips,
                            id: '172',
                          }}
                          rightHeader={
                            <div className="ref-document-dropdown">
                              {/* {' '} // TODO: This will be needed to next task in cross ref
                              <Dropdown
                                selection
                                placeholder="Archived Reference Documents"
                                options={crossRefDocOptions}
                                // error={usersError}
                                onChange={(event, data) =>
                                  this.onChangeCrossReferenceDocument(
                                    event,
                                    data
                                  )
                                }
                                icon="angle down"
                              /> */}
                            </div>
                          }
                          extraMargin
                          title="Cross-Reference"
                          children={
                            <CrossReference
                              crossRefCurrentDocId={crossRefCurrentDocId}
                              crossReference={crossReference}
                              crossRefCurrentDocFromParent={crossRefCurrentDoc}
                              setCrossRefFromChild={setCrossRefFromChild}
                            />
                          }
                        />
                      )}

                      {state.department && (
                        <CustomSegment
                          tooltip={{
                            user: props.user,
                            helpTips: props.helpTips,
                            id: '172',
                          }}
                          rightHeader={
                            <div className="ref-document-dropdown"></div>
                          }
                          extraMargin
                          title="Departments and Units"
                          children={
                            <DepartmentsUnits
                              // setDepartment={value => {
                              //   this.setState({
                              //     department: value,
                              //   });
                              // }}

                              user={user}
                            />
                          }
                        />
                      )}

                      <FeatureWrapper
                        featureName="account_checklist"
                        children={
                          <div>
                            <CustomSegment
                              tooltip={{
                                user: props.user,
                                helpTips: props.helpTips,
                                id: '172',
                              }}
                              extraMargin
                              title="Checklist"
                              className="checklist"
                              children={<OrganizationChecklistReport />}
                            />
                          </div>
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Tab.Pane>
            ),
          }
        : null,

      isFeature(user, 'account_user_management')
        ? {
            menuItem: (
              <Menu.Item key="account-user-mng">
                <CustomTooltip
                  state={get(user, 'options.tips')}
                  contentObject={{
                    data: helpTips,
                    key: '85',
                  }}
                  position="bottom left"
                >
                  <div>User Management</div>
                </CustomTooltip>
              </Menu.Item>
            ),
            route: 'user-management',
            render: () => (
              <Tab.Pane attached={false} className="custom-tab">
                <CustomSegment
                  title="Manage Users"
                  className="manage-users"
                  children={<OrganizationUserManagement />}
                />

                <FeatureWrapper
                  featureName="account_assign_activities"
                  children={
                    <div>
                      <CustomSegment
                        title="Reassign Activities"
                        className="reassign-activities"
                        extraMargin
                        children={<OrganizationUserReassign />}
                      />
                    </div>
                  }
                />

                <FeatureWrapper
                  featureName="account_view_guest_users"
                  children={
                    <div>
                      <CustomSegment
                        title="Guest Users"
                        className="guest-users"
                        extraMargin
                        children={<OrganizationGuestManagement />}
                      />
                    </div>
                  }
                />
              </Tab.Pane>
            ),
          }
        : null,

      isFeature(user, 'account_activities')
        ? {
            menuItem: (
              <Menu.Item key="account-activities">
                <CustomTooltip
                  state={get(user, 'options.tips')}
                  contentObject={{
                    data: helpTips,
                    key: '210',
                  }}
                  position="bottom left"
                >
                  <div>Activities</div>
                </CustomTooltip>
              </Menu.Item>
            ),
            route: 'activity',
            render: () => (
              <Tab.Pane attached={false} className="custom-tab">
                <Grid>
                  <Grid.Row className="org-users-segment">
                    <Grid.Column
                      mobile={16}
                      tablet={16}
                      computer={16}
                      className="p-0 m-0"
                    >
                      <CustomSegment
                        title="Users"
                        children={<OrganizationActivity />}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Tab.Pane>
            ),
          }
        : null,

      isFeature(user, 'account_contractors')
        ? {
            menuItem: (
              <Menu.Item key="account-contractors">
                <CustomTooltip
                  state={get(user, 'options.tips')}
                  contentObject={{
                    data: helpTips,
                    key: '211',
                  }}
                  position="bottom left"
                >
                  <div>Contacts</div>
                </CustomTooltip>
              </Menu.Item>
            ),
            route: 'contacts',
            render: () => (
              <Tab.Pane attached={false} className="custom-tab">
                <CustomSegment
                  className="manage-contacts"
                  title="Manage Contacts"
                  children={<OrganisationContacts {...props} />}
                />
              </Tab.Pane>
            ),
          }
        : null,

      isFeature(user, 'account_venues')
        ? {
            menuItem: (
              <Menu.Item key="account-venues">
                <CustomTooltip
                  state={get(user, 'options.tips')}
                  contentObject={{
                    data: helpTips,
                    key: '86',
                  }}
                  position="bottom left"
                >
                  <div>Venues</div>
                </CustomTooltip>
              </Menu.Item>
            ),
            route: 'venues',
            render: () => (
              <Tab.Pane attached={false} className="custom-tab">
                <CustomSegment
                  title="Manage Venues"
                  className="manage-venues"
                  children={<OrganizationVenues />}
                />
              </Tab.Pane>
            ),
          }
        : null,
      isFeature(user, 'account_communication')
        ? {
            menuItem: (
              <Menu.Item key="account-communication">
                <CustomTooltip
                  state={get(user, 'options.tips')}
                  contentObject={{
                    data: helpTips,
                    key: '212',
                  }}
                  position="bottom left"
                >
                  <div>Communication</div>
                </CustomTooltip>
              </Menu.Item>
            ),
            route: 'communication',
            render: () => (
              <Tab.Pane attached={false} className="custom-tab">
                <div className="communication-row">
                  <CustomSegment
                    extraMargin
                    title="Broadcast Notification"
                    className="broadcast-notification"
                    children={<Communication isBroadcast={true} />}
                  />
                  <CustomSegment
                    extraMargin
                    title="Recieved Notification"
                    children={<Communication />}
                  />
                </div>
              </Tab.Pane>
            ),
          }
        : null,
    ]);

    // get user role
    const isAdmin = get(user, 'role', null);
    // remove communication tab is its super admin
    if (isAdmin === 'admin') {
      remove(panes, (i) => i.menuItem === 'Communication');
    }
    return panes;
  };

  const onTabClick = (data) => {
    changeRoute(data.activeIndex);
  };

  const changeRoute = async (value) => {
    try {
      const tabs = generateTabs();
      const [tab, index] = await Promise.all([
        find(tabs, (o, i) => {
          return i === value || o.route === value;
        }),
        findIndex(tabs, (o, i) => {
          return i === value || o.route === value;
        }),
      ]);
      if (tab) {
        setState((prevState) => ({
          ...prevState,
          currentTab: index,
        }));
        navigate(`/organisation/${tab.route}`);
      } else {
        setState((state) => ({
          currentTab: state.prveState,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const showModalToggle = () => {
    setState((prevState) => ({
      ...prevState,
      modalShow: !prevState.modalShow,
    }));
  };

  const leaveThePage = () => {
    setState((prevState) => ({
      ...prevState,
      modalShow: !prevState.modalShow,
      isEdit: false,
      isLeave: true,
    }));
    onTabClick({ activeIndex: state.nextTab });
  };

  const displayModel = (data) => {
    setState((prevState) => ({
      ...prevState,
      modalShow: true,
      nextTab: data.activeIndex,
    }));
  };

  const { currentTab } = state;

  return (
    <Grid.Row>
      <Tab
        menu={{ borderless: true, attached: false, tabular: false }}
        panes={generateTabs()}
        className={isMobile ? 'custom-tabs-mobile' : 'custom-tabs'}
        activeIndex={currentTab}
        onTabChange={(e, data) => {
          if (!state.isEdit || (!state.isEdit && state.isLeave)) {
            onTabClick(data);
          } else {
            displayModel(data);
          }
        }}
      />
      <UnSavedModal
        modalShow={state.modalShow}
        toggle={() => showModalToggle()}
        leaveThePage={() => leaveThePage()}
      />
    </Grid.Row>
  );
};

export default Organization;
