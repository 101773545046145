import './activity.scss';

import React, { useEffect, useRef, useState } from 'react';

import { AvField, AvForm } from 'availity-reactstrap-validation';
import moment from 'moment';
import { connect } from 'react-redux';
import Alert from 'reactstrap/lib/Alert';
import { bindActionCreators } from 'redux';
import {
  Button,
  Divider,
  Form,
  Grid,
  Icon,
  Modal,
  Search,
  Segment,
} from 'semantic-ui-react';

import { CustomTable, CustomTooltip } from '../../../components';
import {
  debounce,
  find,
  get,
  isEmpty,
  map,
  size,
  take,
} from '../../../utils/lodash';
import {
  createProjectEvidence,
  deleteObjectiveEvidence,
  getEvidenceSearch,
  getEvidenceTypes,
  updateProjectEvidence,
} from '../../../store/actions';
import {
  getPageDisable,
  getActivityType,
  threeDotSeparator,
} from '../../../utils';
import { ActivityParentGoalAndObjective } from './selectedGAndO';
import {
  evidenceInitialValues,
  evidenceValidationSchema,
} from '../../../validations/evidenceValidation';
import { Formik } from 'formik';
import { CustomDropdown, CustomTextArea } from '../../../components/form';
import { useDispatch } from 'react-redux';

const sourceFormat = {
  0: '',
  1: 'Enter either a name or position title, the current year, the activity title and source of information such as participant evaluation, personal observation, internal organisation report etc',
  2: 'Enter the surname and initial of the author/s or name of organisation, year of publication, title, source such as organisation or journal name/title and page number if applicable.',
  3: 'Source such as name of organisation, date, title of publication or event.',
};

const EvidenceForm = (props) => {
  const dispatch = useDispatch();

  const [less, setLess] = useState(true);
  const [evidenceType, setEvidenceType] = useState('');
  const [evidenceMessage, setEvidenceMessage] = useState('');
  const {
    evidence,
    extra,
    auth,
    helpTips,
    activity,
    isNew,
    disabled,
    isOwned,
  } = props;

  useEffect(() => {
    const { evidence, helpTips } = props;
    let helpMessage = '';
    if (evidence) {
      helpMessage = find(helpTips, {
        title: get(evidence, 'evidenceTypeData.evidenceType'),
      });
    }

    setEvidenceMessage(helpMessage ? helpMessage.content : '');
    setEvidenceType(evidence ? evidence.evidenceType : 0);
  }, [props.evidence, props.helpTips]);

  const handleValidSubmit = async (values, { setSubmitting }) => {
    const { isNew, evidence, activity, toggle } = props;

    try {
      let dataSet = {
        ...values,
        projectId: activity.currentActivity.id,
      };
      if (isNew) {
        dispatch(createProjectEvidence('evidence', dataSet));
      } else {
        values.id = evidence.id;
        dispatch(
          updateProjectEvidence(
            'evidence',
            activity.currentActivity.id,
            evidence.id,
            dataSet
          )
        );
      }
      toggle();
    } catch (error) {
      console.error('EvidenceForm :: handleValidSubmit -> error', error);
    }
    setSubmitting(false);
  };

  const handleEvidenceType = (evidenceType) => {
    let extraEvidenceType = find(extra.evidenceTypes, {
      id: parseInt(evidenceType),
    });

    let helpMessage = find(helpTips, {
      title: extraEvidenceType.evidenceType,
    });

    setEvidenceMessage(helpMessage.content);
    setEvidenceType(evidenceType);
  };

  let objectives = [];
  if (extra.outcomes) {
    objectives = map(activity.currentActivity.parentOutcomes, (outcome) => {
      outcome.main = find(extra.outcomes, (o) => {
        return o.id === outcome.outcomeId;
      });
      return outcome;
    });
  }

  const evidenceTypes = extra.evidenceTypes.map((item, key) => ({
    key,
    value: item.id,
    text: item.evidenceType,
  }));

  return (
    <Formik
      initialValues={evidenceInitialValues(evidence, true)}
      validationSchema={evidenceValidationSchema(true)}
      onSubmit={handleValidSubmit}
    >
      {({ handleSubmit, isSubmitting, setFieldValue, errors }) => (
        <Form onSubmit={handleSubmit} className="create-new-evidence">
          {!isOwned ? (
            <div className="ui negative message mb-30">
              Evidence from the Takso library cannot be edited.
            </div>
          ) : null}
          <Grid className="mb-0">
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <div className="evidence-modal-label extra-top-margin-evidence-modal">
                  Intended Outcomes
                </div>
              </Grid.Column>
              {isEmpty(objectives) ? (
                <Alert className="ml-3 mt-2" color="warning">
                  Please select outcomes
                </Alert>
              ) : (
                map(
                  take(objectives, less ? 6 : objectives.length),
                  (objective, i) => {
                    return (
                      <Grid.Column
                        className="grant-evidence-intended-outcomes"
                        key={i}
                        mobile={16}
                        tablet={16}
                        computer={5}
                      >
                        <p className="create-evidence-grant-intended-outcomes">
                          {get(objective, 'main.title', '-')}
                        </p>
                      </Grid.Column>
                    );
                  }
                )
              )}
              <Grid.Column mobile={16} tablet={16} computer={16}>
                {objectives.length > 6 ? (
                  <div className="see-more-see-less-btn-container-grant">
                    <Button
                      className="see-more-see-less-btn-grant"
                      type="button"
                      onClick={() => setLess((prevLess) => !prevLess)}
                    >
                      {less ? 'Show More ' : 'Show Less '}
                      <i
                        aria-hidden="true"
                        className={`chevron ${less ? 'down' : 'up'} icon`}
                      ></i>
                    </Button>
                    <Divider></Divider>
                  </div>
                ) : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <div className="my-2">
            <CustomDropdown
              selection
              name="evidenceType"
              id="evidenceType"
              labelText="Select evidence type"
              options={evidenceTypes}
              getValue={(value) => {
                handleEvidenceType(value);
              }}
              placeholder="Select evidence type"
              className="request-approval-dropdown"
              disabled={disabled}
            />
          </div>

          <div>{evidenceMessage}</div>
          <div className="my-2">
            <CustomTextArea
              id="content"
              name="content"
              placeholder="What is relevant about the evidence? (maximum 2000 characters)"
              labelText={
                <CustomTooltip
                  state={get(auth, 'user.options.tips')}
                  contentObject={{ data: helpTips, key: '29' }}
                  position="top left"
                  wide="very"
                >
                  <span>Content</span>
                </CustomTooltip>
              }
              // requiredStar
              disabled={disabled}
            />
            {/* <Field
              name="content"
              type="textarea"
              validate={(value) => {
                if (!value) {
                  return 'Content is required';
                }
              }}
              placeholder="What is relevant about the evidence? (maximum 2000 characters)"
            /> */}
          </div>
          <div className="my-2">
            <CustomTextArea
              id="source"
              name="source"
              placeholder="What is relevant about the evidence? (maximum 2000 characters)"
              labelText={
                <CustomTooltip
                  state={get(auth, 'user.options.tips')}
                  contentObject={{ data: helpTips, key: '30' }}
                  position="top left"
                  wide="very"
                >
                  <span>Source</span>
                </CustomTooltip>
              }
              // requiredStar
              disabled={disabled}
            />
          </div>
          <div className="model-btn-align-right">
            <Button
              content={isNew ? 'Create' : 'Update'}
              type="submit"
              className="Primary-Button"
              disabled={disabled || isSubmitting}
              loading={isSubmitting}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
class EvidenceViewForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSearchChange = async (event, errors, data) => {
    const { updateProjectEvidence, activity, evidence, toggle } = this.props;
    try {
      let dataSet = {
        projectId: activity.currentActivity.id,
      };
      updateProjectEvidence(
        'evidence',
        activity.currentActivity.id,
        evidence.id,
        dataSet
      );
      toggle();
    } catch (error) {
      console.error('EvidenceViewForm :: handleSearchChange -> error', error);
    }
  };

  render() {
    const { evidence, extra } = this.props;
    const type = find(extra.evidenceTypes, {
      id: evidence.evidenceType,
    });
    return (
      <div>
        <div className="evidence-box ">
          <div className="evidence-title">Evidence Type</div>
          <div className="library-evidence-modal-description">
            {type ? type.evidenceType : ''}
          </div>
        </div>

        <div className="evidence-box ">
          <div className="evidence-title">Content</div>
          <div className="library-evidence-modal-description">
            {evidence.content}
          </div>
        </div>

        <div className="evidence-box ">
          <div className="evidence-title">Source</div>
          <div className="library-evidence-modal-description">
            {evidence.source}
          </div>
        </div>

        <div className="evidence-box ">
          <div className="evidence-title">Date Added</div>
          <div className="library-evidence-modal-description">
            {moment(evidence.addedDateTime).format('DD MMM YYYY')}
          </div>
        </div>
        <div className="model-buttons">
          <Button
            content="Add"
            type="submit"
            className="Primary-Button"
            onClick={() => this.handleSearchChange()}
          />
        </div>
      </div>
    );
  }
}

class EvidenceDelete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async deleteOrgContractorHandle() {
    try {
      const { deleteObjectiveEvidence, evidence, activity } = this.props;
      deleteObjectiveEvidence(
        'evidence',
        activity.currentActivity.id,
        evidence.id
      );
      this.toggle();
    } catch (error) {
      console.error(
        'EvidenceDelete :: deleteOrgContractorHandle -> error',
        error
      );
    }
  }

  render() {
    const { modal } = this.state;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        onClose={() => this.toggle()}
        size="mini"
        trigger={
          <label className="activity-actions-error first-down">Remove</label>
        }
      >
        <Modal.Header>Remove Evidence</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to remove this evidence?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.toggle()} className="modal-cancel">
            No
          </Button>
          <Button
            onClick={() => this.deleteOrgContractorHandle()}
            className="modal-proceed"
            content="Yes"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

class EvidenceEdit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    const { isReadOnly, activity, evidence } = this.props;

    let isOwned = false;
    if (evidence.createdByProjectId === activity.currentActivity.id) {
      isOwned = true;
    }

    let disabled = false;
    if (!isOwned || isReadOnly) {
      disabled = true;
    }

    return (
      <div className="title-evidence-edit">
        <Modal
          trigger={
            <label className="label-container" onClick={() => this.toggle()}>
              {disabled ? 'View Evidence' : 'Edit Evidence'}
            </label>
          }
          size="small"
          open={modal}
        >
          <Modal.Header className="goal-confirm-modal-header">
            <div className="modal-heading"> Edit Evidence </div>
            <div className="slim-close-icon" onClick={() => this.toggle()}>
              &times;
            </div>
          </Modal.Header>
          <Modal.Content>
            <EvidenceForm
              toggle={() => this.toggle()}
              isNew={false}
              disabled={disabled}
              isOwned={isOwned}
              {...this.props}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class EvidenceCreate extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    const { auth, helpTips } = this.props;
    return (
      <div>
        <Modal
          trigger={
            <div className="add-evidence-container">
              <CustomTooltip
                state={get(auth, 'user.options.tips')}
                contentObject={{ data: helpTips, key: '24' }}
                position="bottom left"
                wide="very"
              >
                <label
                  onClick={() => this.toggle()}
                  className="Secondary-Button"
                >
                  <Icon name="plus" />
                  Add New Evidence
                </label>
              </CustomTooltip>
            </div>
          }
          size="small"
          open={modal}
          className="create-new-evidence"
        >
          <Modal.Header className="goal-confirm-modal-header">
            <div className="modal-heading"> Create New Evidence </div>
            <div className="slim-close-icon" onClick={() => this.toggle()}>
              &times;
            </div>
          </Modal.Header>
          <Modal.Content className="modal-content-evidence">
            <EvidenceForm
              toggle={() => this.toggle()}
              isNew={true}
              isOwned={true}
              {...this.props}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

class ActivityParentEvidence extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      results: [],
      value: '',
      evidence: {},
      modal: false,
    };
  }

  columns() {
    const { evidenceTypes } = this.props.extra;
    const { currentActivity, currentState, auth } = this.props;
    let disabled = getPageDisable(currentActivity, auth.user, currentState);
    // let isReadOnly = getEvidenceTextType(activity, auth);

    return [
      {
        title: 'Name',
        render: (data) => {
          return (
            <div>
              <label>
                {data.content && data.content.length > 50
                  ? `${data.content.substring(0, 50)}...`
                  : data.content || '-'}
              </label>
            </div>
          );
        },
      },
      {
        title: 'Type',
        render: (data) => {
          const type = find(evidenceTypes, {
            id: data.evidenceType,
          });

          return (
            <div>
              <label>
                {type && type.evidenceType ? `${type.evidenceType}` : '-'}
              </label>
            </div>
          );
        },
      },
      {
        title: '',
        render: (data) => {
          return (
            <div className="disabled-padding">
              <Segment>
                <EvidenceEdit
                  isReadOnly={disabled}
                  evidence={data}
                  {...this.props}
                />
              </Segment>
            </div>
          );
        },
      },
      {
        title: '',
        render: (data) => {
          return (
            <div className="disabled-padding">
              <Segment disabled={disabled}>
                <EvidenceDelete evidence={data} {...this.props} />
              </Segment>
            </div>
          );
        },
      },
    ];
  }

  componentWillMount() {
    this.resetComponent();
  }

  resetComponent() {
    this.setState({
      isLoading: false,
      results: [],
      value: '',
    });
  }

  handleResultSelect = (e, { result }) => {
    this.setState({
      evidence: result,
      modal: true,
    });
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ value }, async () => {
      if (this.state.value.length < 1) return this.resetComponent();

      const { getEvidenceSearch } = this.props;
      await getEvidenceSearch({ searchQuery: value });
    });
  };

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  onChangeFilter(check) {
    if (check) {
      this.setState({
        onlyOwnEvidence: true,
      });
    } else {
      this.setState({
        onlyOwnEvidence: false,
      });
    }
  }

  async onBlurFilter() {
    const { getEvidenceSearch } = this.props;
    const { onlyOwnEvidence, value } = this.state;
    await getEvidenceSearch({
      searchQuery: value || '',
      onlyOwnEvidence,
    });
  }

  render() {
    const { value } = this.state;
    const { auth, helpTips, activity, currentState } = this.props;
    const { evidenceLoading, evidenceSearch, currentActivity } = activity;

    const { title } = getActivityType(currentActivity);
    return (
      <>
        <div className="content-segment objective">
          <div>
            <ActivityParentGoalAndObjective noObjectives />
          </div>
          <Segment
            disabled={getPageDisable(currentActivity, auth.user, currentState)}
            className="content-segment evidence-sector"
            attached
          >
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={12}>
                  <div>
                    <label className="parent-info">
                      Consider the evidence (practice knowledge, research and
                      data) that supports the choice of a {title} and how it
                      will be structured to address the goal.
                    </label>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                {/* evidence manage */}
                <Grid.Column mobile={16} tablet={16} computer={10}>
                  <div className="evidence-parent-head">
                    <h4>Evidence</h4>
                  </div>

                  <div className="search-inline-grant-evidence">
                    <CustomTooltip
                      state={get(auth, 'user.options.tips')}
                      contentObject={{
                        data: helpTips,
                        key: '23',
                      }}
                      position="top left"
                      on="hover"
                      wide="very"
                    >
                      <Search
                        disabled={getPageDisable(
                          currentActivity,
                          auth.user,
                          currentState
                        )}
                        loading={evidenceLoading}
                        onResultSelect={this.handleResultSelect}
                        icon={<Icon name="search" />}
                        onSearchChange={debounce(
                          this.handleSearchChange,
                          2000,
                          {
                            leading: true,
                          }
                        )}
                        results={evidenceSearch}
                        value={value}
                        placeholder="Search for Evidence"
                        resultRenderer={({
                          content,
                          source,
                          addedDateTime,
                        }) => (
                          <div>
                            <div className="evidence-search-result">
                              <label className="evidence-header">
                                <b>
                                  {threeDotSeparator({
                                    name: content,
                                    length: 48,
                                    isPopUp: false,
                                  })}
                                </b>
                              </label>

                              <div className="info">
                                <label>
                                  <strong>Source: </strong>
                                  {threeDotSeparator({
                                    name: source,
                                    length: 42,
                                    isPopUp: false,
                                  })}
                                </label>

                                <label className="evidence-date">
                                  <strong>Date: </strong>
                                  {addedDateTime
                                    ? moment(addedDateTime).format(
                                        'DD MMM YYYY'
                                      )
                                    : '-'}
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                        className="evidenceSearch"
                      />
                    </CustomTooltip>
                    <div className="restrict-container">
                      <div className="checkbox-restrict">
                        <input
                          type="checkbox"
                          name="checkbox"
                          onChange={(e) =>
                            this.onChangeFilter(e.target.checked)
                          }
                          onBlur={(e) => this.onBlurFilter()}
                        />
                      </div>
                      <div className="restrict-label">
                        Restrict to my evidence
                      </div>
                    </div>
                  </div>
                  <div>
                    <Modal size="small" open={this.state.modal}>
                      <Modal.Header className="goal-confirm-modal-header">
                        <div className="modal-heading"> Assign Evidence </div>
                        <div
                          className="slim-close-icon"
                          onClick={() => this.toggle()}
                        >
                          &times;
                        </div>
                      </Modal.Header>

                      <Modal.Content>
                        {this.state.modal ? (
                          <EvidenceViewForm
                            toggle={() => this.toggle()}
                            evidence={this.state.evidence}
                            {...this.props}
                          />
                        ) : null}
                      </Modal.Content>
                    </Modal>
                  </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={6}>
                  <div className="add-evidence">
                    <EvidenceCreate {...this.props} />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment>
            <CustomTable
              header
              columns={this.columns()}
              data={currentActivity.evidence}
              customClass="evidence-table-grant"
              emptyMessage="No evidence has been added"
              keyExtractor={(item, index) => item.id}
            />
          </Segment>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authentication,
    activity: state.activity,
    extra: state.extra,
    helpTips: state.extra.activityHelpTips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getEvidenceTypes,
      getEvidenceSearch,
      createProjectEvidence,
      updateProjectEvidence,
      deleteObjectiveEvidence,
    },
    dispatch
  );
};

ActivityParentEvidence = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityParentEvidence);

export { ActivityParentEvidence };
