/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  map,
  findIndex,
  isEmpty,
  find,
  get,
  filter,
} from '../../../../utils/lodash';
import moment from 'moment';
import {
  Segment,
  Button,
  Tab,
  Icon,
  Loader,
  Grid,
  Dropdown,
  Popup,
  Modal,
  Divider,
  Input,
  Form,
} from 'semantic-ui-react';
import exclamation from '../../../../assets/exclamation-plain.svg';
import { AvForm, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import {
  CustomSegment,
  CustomAccordion,
  CustomTooltip,
} from '../../../../components';
import '../activity.scss';
import '../../activities.scss';
import {
  updateProjectInputs,
  setCurrentActivity,
  getReports,
  getOrganizationUsers,
} from '../../../../store/actions';
import {
  getIssuesInActivities,
  setParentDomains,
  ContentAndHeader,
  getValuesFormat,
  getActivityType,
  authMsg,
  getPageDisable,
} from '../../../../utils';
import thousands from 'thousands';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CustomDropdown } from '../../../../components/form';
import SurveyContainer from './surveyContainer';
import { isParentProgramActivity } from '../../../../utils/activity';
import { projectValidation } from '../../../../utils/projectValidation';
import { checkMemberPermission } from '../../../../utils/activity/userPermission';
import WHITELIST_USERS from '../../../../utils/activity/specialPermissionUsers';

class GreenLightComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      unlock: false,
      approved: false,
      evalOneVisibility: true,
      evalTwoVisibility: true,
      code: '',
      approvalMethod: 'allowSelf',
      authorisationCode: null,
      invitationModal: false,
      show: { noteOne: false, noteTwo: false },
      approvedSelection: false,
      users: [],
      usersError: false,
      approve: false,
      isGreenLitLoading: false,
      invitation: 'noSend',
      guestMembers: [],
      requestApprovalModal: false,
    };
  }

  async componentDidMount() {
    const { currentActivity } = this.props;

    this.setState({
      approvalMethod:
        currentActivity.approvalMethod === 1 ? 'ppmsCode' : 'allowSelf',
      authorisationCode: currentActivity.codeLabel
        ? currentActivity.codeLabel
        : null,
      approved: this.defaultApprovedStatus(currentActivity),
      code: currentActivity.ppmsCode ? currentActivity.ppmsCode : '',
      guestMembers: filter(
        currentActivity.members,
        (item) =>
          item.user.id != currentActivity.owner.id &&
          item.withdrawnDateTime === null
      ),
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { currentActivity: preCurrentActivity } = prevProps;
    const { currentActivity } = this.props;

    if (preCurrentActivity.ppmsCode !== currentActivity.ppmsCode) {
      await this.setState({
        approvalMethod:
          currentActivity.approvalMethod === 1 ? 'ppmsCode' : 'allowSelf',
        authorisationCode: currentActivity.codeLabel
          ? currentActivity.codeLabel
          : null,
        approved: this.defaultApprovedStatus(currentActivity),
      });
    }
  }

  defaultApprovedStatus = (currentActivity) => {
    if (currentActivity.approvalMethod !== 1 && !currentActivity.codeLabel) {
      return true;
    } else if (
      currentActivity.approvalMethod !== 1 &&
      currentActivity.ppmsCode
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleChange = async (checked) => {
    const { currentActivity } = this.props;
    const { guestMembers } = this.state;

    this.setState((prevState) => ({
      approve: !prevState.approve,
    }));

    //guestMembers - other than owner there should be guest users
    if (currentActivity.statusId < 2 && !isEmpty(guestMembers)) {
      await this.setState((prevState) => ({
        invitationModal: !prevState.invitationModal,
      }));
    } else {
      await this.approveOrReject(checked);
    }
  };

  unlockActivity() {
    this.setState({ unlock: true });
  }

  async handleValidSubmit(event, values) {
    try {
      const { users, invitation } = this.state;
      const { currentActivity } = this.props;
      let data = { inviteType: values.invitation };

      if (invitation === 'customSend' && !users.length) {
        this.setState({ usersError: true });
      } else {
        this.setState({ isGreenLitLoading: true });
        if (values.invitation === 'customSend') {
          data.userIds = users;
        }

        if (currentActivity.statusId === 2) {
          await this.approveOrReject(false);
        } else {
          await this.approveOrReject(true, data);
        }

        this.setState({ isGreenLitLoading: false });
        this.resetToggle();
      }
    } catch (error) {}
  }

  approveOrReject = async (approved = false, invitation = {}) => {
    try {
      const { currentActivity, authentication } = this.props;
      if (!authentication || !authentication.user) {
        return;
      }
      const statusId = approved ? 2 : 1;

      await this.props.updateProjectInputs(
        'greenLightApproval',
        currentActivity.id,
        {
          data: {
            completionProgress: projectValidation({
              ...currentActivity,
              statusId,
            }).completionProgress,
            statusId,
            invitation: { ...invitation },
          },
        }
      );
      this.props.setCurrentActivity(currentActivity.id);
      const { approvalMethod: method, authorisationCode } = this.state;
      await this.setState({
        approved: method === 'allowSelf' && !authorisationCode ? true : false,
        approvalUserName: '',
        invitationModal: false,
        approve: false,
      });
    } catch (error) {
      // authMsg('error', getCommonError(error));
    }
  };

  async onChangeHandler(value) {
    await this.setState({ code: value });
  }

  async onSaveManagementCode() {
    const { updateProjectInputs, currentActivity } = this.props;

    await updateProjectInputs('greenLightApproval', currentActivity.id, {
      data: {
        completionProgress: projectValidation({
          ...currentActivity,
          statusId: currentActivity.statusId,
        }).completionProgress,

        ppmsCode: this.state.code,
      },
    });
  }

  resetToggle() {
    this.setState({ invitation: 'noSend' });
  }

  toggle() {
    this.setState((prevState) => ({
      invitationModal: !prevState.invitationModal,
      approve: false,
    }));
    this.resetToggle();
  }

  handleInvite(data) {
    this.setState({
      invitation: data,
    });
  }

  getCurrentMembers() {
    const { guestMembers } = this.state;
    return map(guestMembers, (item, i) => ({
      key: i,
      value: item.user.id,
      text: item.user.firstName,
    }));
  }

  async onChangeUsers(event, data) {
    this.setState({
      users: data.value,
      usersError: !data.value.length,
    });
  }

  showInvitationModal() {
    const { usersError, invitationModal, isGreenLitLoading, invitation } =
      this.state;
    const options = this.getCurrentMembers();
    return (
      <Modal
        size="tiny"
        open={invitationModal}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
      >
        <Modal.Header>Re-Notify Invitees</Modal.Header>
        <Modal.Content>
          <AvForm
            ref={(c) => (this.form = c)}
            onValidSubmit={(event, values) =>
              this.handleValidSubmit(event, values)
            }
            className="invite-green-form"
          >
            <AvRadioGroup
              value={invitation}
              name="invitation"
              className="invitation-GreenLight"
              required
              errorMessage="Invitation method is required"
            >
              <AvRadio
                label="Don’t send"
                value="noSend"
                className="radio-green-light"
                onChange={(e) => {
                  this.handleInvite('noSend');
                }}
              />
              <AvRadio
                label="Send to all"
                value="sendAll"
                className="radio-green-light"
                onChange={(e) => {
                  this.handleInvite('sendAll');
                }}
              />
              <AvRadio
                label="Send to selected"
                value="customSend"
                className="radio-green-light"
                onChange={(e) => {
                  this.handleInvite('customSend');
                }}
              />
            </AvRadioGroup>

            {invitation === 'customSend' && (
              <div className="greenlight-broadcast">
                <label>Select Invitees</label>
                <Dropdown
                  fluid
                  multiple
                  search
                  selection
                  options={options}
                  error={usersError}
                  onChange={(event, data) => this.onChangeUsers(event, data)}
                  placeholder="Select Invitees"
                  className="broadcast-dropdown"
                />
                {usersError && (
                  <div className="invalid-dropdown-input">
                    Select user is required
                  </div>
                )}
              </div>
            )}

            <div className="model-buttons">
              <Button
                loading={isGreenLitLoading}
                disabled={isGreenLitLoading}
                content="Okay"
                className="Primary-Button"
              />
            </div>
          </AvForm>
        </Modal.Content>
      </Modal>
    );
  }

  toggleRequestApprovalModal() {
    const { getOrganizationUsers, currentActivity } = this.props;
    getOrganizationUsers();
    this.setState({
      requestApprovalModal: !this.state.requestApprovalModal,
    });
  }

  selectApprovalUsers() {
    const { ownerId } = this.props.currentActivity;
    let { orgUsers } = this.props.organization;
    orgUsers = orgUsers.filter((item) => item.id !== ownerId);

    return map(orgUsers, (item, key) => ({
      key,
      value: item.id,
      rest: { key: item.id, id: item.id, name: item.name },
      text: item.name,
    }));
  }

  async onSubmitApprovalPerson(values, setSubmitting) {
    const { approvalMethod } = this.state;
    const { currentActivity, updateProjectInputs } = this.props;
    const { approvalUser } = values;
    const statusId = approvalMethod ? 2 : 1;

    await updateProjectInputs('requestApproval', currentActivity.id, {
      data: {
        approvalUserId: approvalUser,
        completionProgress: projectValidation({
          ...currentActivity,
          statusId,
        }).completionProgress,
      },
    });

    this.setState({ requestApprovalModal: false });
    setSubmitting(false);
  }

  RequestApprovalModal() {
    const { approvalMethod, requestApprovalModal } = this.state;

    return (
      <Modal
        size="tiny"
        open={requestApprovalModal}
        closeIcon={
          <Icon
            onClick={() => this.toggleRequestApprovalModal()}
            name="close"
            className="closeicon"
          />
        }
      >
        <Modal.Header>Request Approval</Modal.Header>
        <Modal.Content>
          {approvalMethod !== 'allowSelf' ? (
            <Formik
              onSubmit={(values, { setSubmitting }) => {
                this.onSubmitApprovalPerson(values, setSubmitting);
              }}
              initialValues={{ approvalUser: '' }}
              validationSchema={Yup.object().shape({
                approvalUser: Yup.number().required(
                  'Approval User is required!'
                ),
              })}
            >
              {({ handleSubmit, errors, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid>
                    <Grid.Row className="px-3 ">
                      <>
                        <CustomDropdown
                          name="approvalUser"
                          id="approvalUser"
                          labelText="  Request Approval"
                          options={this.selectApprovalUsers()}
                          placeholder="Select Request Approval"
                          selection
                          requiredStar
                          search
                          className="request-approval-dropdown"
                        />
                      </>
                    </Grid.Row>

                    <hr className="w-100" />
                  </Grid>
                  <div className="model-buttons">
                    <Button
                      className="modal-cancel"
                      type="button"
                      onClick={() => this.toggleRequestApprovalModal()}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      content="Send Request"
                      type="submit"
                      className="modal-proceed"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          ) : null}
        </Modal.Content>
      </Modal>
    );
  }

  renderMemberApproval(unlock) {
    const { currentActivity, user } = this.props;

    const { approvalMethod } = this.state;
    const greenLightPermission = checkMemberPermission(
      user,
      currentActivity.members,
      'Green Light'
    );

    const validationOutput = getIssuesInActivities(currentActivity);
    const isValidated = validationOutput
      ? isEmpty(validationOutput.validationMessages)
      : true;

    // check not approved yet
    const approvalPending = !currentActivity.approvedDate;
    const approvalUserId = currentActivity.approvalUserId;
    const approvalUserName = currentActivity.approvalUserName;

    return (
      <>
        {unlock === 1 && !approvalUserId ? (
          <Grid>
            <Grid.Row>
              {' '}
              <p className="w-100">
                Your organisation requires activities to be approved to proceed
                before Green light can be given.
              </p>
              {greenLightPermission.write && (
                <div className="w-100">
                  <Button
                    className="modal-proceed"
                    onClick={() => this.toggleRequestApprovalModal()}
                    disabled={!isValidated}
                  >
                    Request Approval
                  </Button>
                </div>
              )}
            </Grid.Row>
          </Grid>
        ) : (
          <>
            {/* <label className="approve-lbl">
              You may set this activity back to unapproved to make
              changes/corrections but any changes will need to be re-approved
            </label> */}
            <Grid>
              {approvalMethod !== 'allowSelf' ? (
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={8} computer={5}>
                    <div className="approval-active-data">
                      {
                        <label className="w-100 py-1">
                          Green Light Request Approval
                        </label>
                      }
                      <span className="text-dark"> {approvalUserName}</span>
                      {/* green light accepted */}
                      {!approvalPending ? (
                        <span className="approved-lbl ml-2">Approved</span>
                      ) : (
                        <span className="pending-lbl ml-2">Pending</span>
                      )}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              ) : null}

              {greenLightPermission.write && approvalPending && (
                <Grid.Row>
                  <Button
                    className="modal-proceed"
                    onClick={() => this.toggleRequestApprovalModal()}
                    disabled={!greenLightPermission.write || !isValidated}
                  >
                    Re-Request Approval
                  </Button>
                </Grid.Row>
              )}
            </Grid>
          </>
        )}
        <hr className="w-100 p-0 m-0" />
        <Grid className="pb-2">
          <Grid.Row>
            {this.renderGreenLightApproval(unlock, approvalPending)}
          </Grid.Row>
        </Grid>
      </>
    );
  }

  renderGreenLightApproval(unlock, approvalPending = false) {
    const { authorisationCode, code } = this.state;
    const { currentActivity, user, getReportsLoading, getReports } = this.props;

    const greenLightPermission = checkMemberPermission(
      user,
      currentActivity.members,
      'Green Light'
    );

    const validationOutput = getIssuesInActivities(currentActivity);
    const isValidated = validationOutput
      ? isEmpty(validationOutput.validationMessages)
      : true;

    const managementCode = currentActivity.codeLabel || authorisationCode;

    // management code permission
    const pageDisabled = getPageDisable(
      currentActivity,
      user,
      'project.edit.evaluation'
    );

    const isWhitelistOwner = WHITELIST_USERS.includes(user.email);

    return (
      <>
        {managementCode && (
          <div className="mb-3">
            <div>{managementCode}</div>
            <div>
              <Grid.Column mobile={16} tablet={8} computer={6}>
                <div className="approval-active-data-code">
                  <Input
                    className="recordCode"
                    name="recordCode"
                    type="text"
                    value={code}
                    onChange={(e) => this.onChangeHandler(e.target.value)}
                    onBlur={() => this.onSaveManagementCode()}
                    disabled={currentActivity.statusId === 3 || pageDisabled}
                  />
                </div>
              </Grid.Column>
            </div>
          </div>
        )}
        {unlock === 1 && <p className="w-100">Activity requires approval</p>}
        <div className="w-100">
          {unlock === 1 ? (
            <Button
              className="modal-proceed"
              disabled={
                !isValidated ||
                (unlock === 1 && approvalPending) ||
                (!isWhitelistOwner &&
                  !(
                    greenLightPermission ||
                    (greenLightPermission && !greenLightPermission.write)
                  ))
              }
              onClick={() => {
                this.handleChange(true);
              }}
            >
              Green Light
            </Button>
          ) : (
            <Button
              className="modal-proceed"
              onClick={() => {
                this.handleChange(false);
              }}
              disabled={
                currentActivity.statusId === 3 ||
                (!isWhitelistOwner &&
                  (!greenLightPermission ||
                    (greenLightPermission && !greenLightPermission.write)))
              }
            >
              Re Open
            </Button>
          )}

          <Button
            size="medium"
            loading={getReportsLoading['signOffLoading'] ? true : false}
            disabled={getReportsLoading['signOffLoading'] ? true : false}
            className="modal-cancel ml-2"
          >
            {
              <Dropdown
                className="no-print activity-on-a-page-menu"
                disabled={getReportsLoading['signOffLoading']}
                icon={
                  <Icon
                    className="export-btn-icon ml-2"
                    size="small"
                    name={'download'}
                  />
                }
                text={'Activity on a page'}
                direction="right"
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    text="Download Report"
                    className="dropdown-customItem"
                    onClick={() =>
                      getReports(
                        `/project/${currentActivity.id}/sign-off/export`,
                        'signOffLoading'
                      )
                    }
                  />
                  <Dropdown.Item
                    text="Download Evidence"
                    className="dropdown-customItem"
                    onClick={() =>
                      getReports(
                        `/project/${currentActivity.id}/evidence/export`,
                        'signOffLoading'
                      )
                    }
                  />
                </Dropdown.Menu>
              </Dropdown>
            }
          </Button>
        </div>
      </>
    );
  }

  renderActivityApproval() {
    const { approvalMethod } = this.state;
    const { currentActivity } = this.props;

    const validationOutput = getIssuesInActivities(currentActivity);

    let unlock =
      currentActivity && currentActivity.status
        ? currentActivity.status.id
        : null;

    const isValidated = validationOutput
      ? isEmpty(validationOutput.validationMessages)
      : true;

    return (
      <Segment className="approval-seg no-print">
        {!isValidated && (
          <>
            <div className="issues-box-info">
              <p className="fw-bold">Outstanding information</p>
              <div className="padding-issue">
                {validationOutput.validationMessages.map((item, i) => {
                  return (
                    <ul key={i}>
                      <li> {item}</li>
                    </ul>
                  );
                })}
              </div>
            </div>
            <hr className="p-0 m-0" />
          </>
        )}
        <div className="approval-div">
          {unlock == null ? null : approvalMethod === 'ppmsCode' ? (
            this.renderMemberApproval(unlock)
          ) : (
            <Grid className="pb-2">
              <Grid.Row>
                {this.renderGreenLightApproval(unlock, false)}
              </Grid.Row>
            </Grid>
          )}
        </div>
        {this.RequestApprovalModal()}
        {this.showInvitationModal()}
      </Segment>
    );
  }

  setVisibility(type) {
    this.setState((prevState) => ({ evalOneVisibility: !prevState[type] }));
  }

  renderSurvey() {
    const { currentActivity } = this.props;
    if (isEmpty(currentActivity.evaluationPlans)) return null;
    const surveyValidation = moment(currentActivity.eventStartDate) > moment();
    return (
      <CustomSegment
        noPrint
        className="evaluation-plans"
        title="Evaluation Plans"
        children={
          <Segment className="survey-seg no-print">
            <div className="survey-div">
              <label>
                Click on the individual plan to access your evaluation
                information.&nbsp;
              </label>
              <Popup
                inverted
                content={
                  'For evaluations using the Takso link, survey links are available under the relevant plan. If you are not using the Takso link, the survey question is available to copy into your preferred survey tool. '
                }
                position="right center"
                trigger={<img src={exclamation} alt="!" />}
              />
              {/* <img src={exclamation}/> */}
              {map(currentActivity.evaluationPlans, (evalPlan, key) => {
                let title = evalPlan.name,
                  subtitle = '';
                if (evalPlan.seriesParentId) {
                  subtitle = evalPlan.seriesSubtitle && evalPlan.seriesSubtitle;
                  title = title + ` (${evalPlan.seriesPosition})`;
                }
                return (
                  <CustomAccordion
                    key={`survey_accordion__${key}`}
                    extraMargin
                    headerStyle={{ color: '#EF8123' }}
                    subtitleStyle={{
                      position: 'static',
                      height: '1.25rem',
                      fontStyle: 'normal',
                      fontSize: '0.625rem',
                      fontWeight: '400',
                      align: 'center',
                      background: '#F5F3FF',
                      color: '#6D28D9',
                      padding: ' 0.313rem 0.75rem',
                      marginLeft: '1.563rem',
                      size: '0.625rem',
                      textTransform: 'capitalize',
                      borderRadius: '0.25rem',
                    }}
                    title={title}
                    subtitle={subtitle}
                    evalPlan={true}
                    children={
                      <SurveyContainer
                        key={`survey_accordion_container__${key}`}
                        evalPlan={evalPlan}
                        surveyValidation={surveyValidation}
                        currentActivity={currentActivity}
                      />
                    }
                  />
                );
              })}
            </div>
          </Segment>
        }
        extraMargin
      />
    );
  }

  async approveProject() {
    const { currentActivity, updateProjectInputs, user } = this.props;

    const approvalUserId = user.id;
    const approvedDate = new Date();
    const statusId = currentActivity.statusId;

    await updateProjectInputs('greenLightUserApproval', currentActivity.id, {
      data: {
        completionProgress: projectValidation({
          ...currentActivity,
          statusId,
        }).completionProgress,
        approvalUserId,
        approvedDate,
      },
    });

    authMsg('success', [
      'Activity has been successfully approved.',
      'Activity ',
    ]);
  }

  renderReport() {
    const { currentActivity, extra, user, actPut } = this.props;

    const { referenceCodeLabel, referenceDocument, referenceNumber } =
      currentActivity;

    if (!currentActivity) {
      return null;
    }
    const domains = map(
      currentActivity.parentDomains,
      (i) => i.policy.policyName
    );

    let objectives = currentActivity.objective || [];
    let parentDomains = [];

    if (isParentProgramActivity(currentActivity.activityPlanTypeId)) {
      parentDomains = map(setParentDomains(currentActivity), (domains) => {
        const outcomes = [];
        if (extra.outcomes) {
          for (const outcome of currentActivity.parentOutcomes) {
            if (outcome.policyValue === domains.policyId) {
              const getOutcome = find(extra.outcomes, (o) => {
                return o.id === outcome.outcomeId;
              });
              if (getOutcome) {
                outcomes.push(getOutcome.title);
              }
            }
          }
        }
        return {
          domain: domains.policy.policyName,
          outcomes,
        };
      });
    }

    const [firstDomain = {}, ...restDomains] = parentDomains;
    const activity = getActivityType(currentActivity);

    const projectedTotalCost =
      currentActivity && currentActivity.parentId
        ? +currentActivity.projectedGrantAmount +
          +currentActivity.projectedBudget -
          currentActivity.projectedCost
        : +currentActivity.projectedBudget - currentActivity.projectedCost;

    // check current user has permission to approve activity
    const isApprovalUser = currentActivity.approvalUserId === user.id;

    return (
      <Segment className="green-light-report">
        <Grid>
          <Grid.Row className="heading-margin">
            <h2 className="requiring-greenlight">
              {isParentProgramActivity(currentActivity.activityPlanTypeId)
                ? activity.title
                : 'Activity'}{' '}
              Requiring Green Light
            </h2>
          </Grid.Row>

          <Grid.Row className="activity-greenlight" columns={2}>
            <Grid.Column
              className="approval-column-extra-top-margin"
              mobile={16}
              tablet={8}
              computer={5}
            >
              <ContentAndHeader title="Activity Name">
                <div className="approval-request-data">
                  {currentActivity.name}
                </div>
              </ContentAndHeader>
            </Grid.Column>
            {referenceDocument && referenceCodeLabel && referenceNumber ? (
              <Grid.Column
                className="approval-column-extra-top-margin"
                mobile={16}
                tablet={16}
                computer={5}
              >
                <ContentAndHeader title="CDP Reference Code">
                  <div className="approval-request-data">{referenceNumber}</div>
                </ContentAndHeader>
              </Grid.Column>
            ) : null}
            <Grid.Column
              className="approval-column-extra-top-margin"
              mobile={16}
              tablet={8}
              computer={5}
            >
              <ContentAndHeader title="Manager Of Activity">
                <div className="approval-request-data">
                  {currentActivity.owner ? currentActivity.owner.name : ''}
                </div>
              </ContentAndHeader>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="activity-greenlight">
            <Grid.Column
              className="approval-column-extra-top-margin"
              mobile={16}
              tablet={16}
              computer={12}
            >
              <ContentAndHeader title="Activity Type">
                <div className="approval-request-data">
                  {isParentProgramActivity(currentActivity.activityPlanTypeId)
                    ? getActivityType(currentActivity).title || '-'
                    : get(currentActivity, 'eventDetail.detail', '-')}
                </div>
              </ContentAndHeader>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="activity-greenlight">
            <Grid.Column
              className="approval-column-extra-top-margin"
              mobile={16}
              tablet={16}
              computer={12}
            >
              <ContentAndHeader title="Activity Description">
                <div className="approval-request-data">
                  {currentActivity.description}
                </div>
              </ContentAndHeader>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="activity-greenlight">
            <Grid.Column
              className="approval-column-extra-top-margin"
              mobile={16}
              tablet={16}
              computer={16}
            >
              <ContentAndHeader title="Organisation Goal">
                <div className="approval-request-data">
                  {currentActivity.goal}
                </div>
              </ContentAndHeader>
            </Grid.Column>
          </Grid.Row>
          {currentActivity.activityPlanTypeId === 3 && (
            <Grid.Row className="activity-greenlight">
              <Grid.Column
                className="approval-column-extra-top-margin"
                mobile={16}
                tablet={16}
                computer={8}
              >
                <ContentAndHeader title="Festival Types">
                  <div className="approval-request-data ">
                    {this.getFestivalTypes(
                      currentActivity.parentFestivals
                        ? currentActivity.parentFestivals
                        : []
                    )}
                  </div>
                </ContentAndHeader>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
        <Divider section />

        {isParentProgramActivity(currentActivity.activityPlanTypeId) ? (
          <Grid>
            <Grid.Row className="activity-greenlight objective-margins">
              <span className="c-h3">Primary Objective</span>
            </Grid.Row>
            <Grid.Row className="activity-greenlight objective-margins-points">
              <Grid.Column
                className="col-spacing"
                mobile={8}
                tablet={8}
                computer={6}
              >
                <div className="header-spacing domain-font">
                  {firstDomain.domain}
                </div>
                {map(firstDomain.outcomes, (outcome, i) => {
                  return (
                    <p key={i} className="report-objective-list">
                      {outcome}
                    </p>
                  );
                })}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="activity-greenlight objective-margins">
              <span className="c-h3">Secondary Objectives</span>
            </Grid.Row>
            <Grid.Row className="activity-greenlight objective-margins-points">
              {map(restDomains, (domains, d) => {
                return (
                  <Grid.Column
                    className="col-spacing"
                    key={d}
                    mobile={8}
                    tablet={8}
                    computer={8}
                  >
                    <div className="header-spacing  domain-font">
                      {domains.domain}{' '}
                    </div>
                    {map(domains.outcomes, (outcome, i) => {
                      return (
                        <p key={i} className="report-objective-list">
                          {outcome}
                        </p>
                      );
                    })}
                  </Grid.Column>
                );
              })}
            </Grid.Row>

            <Grid.Row className="activity-greenlight">
              <Grid.Column mobile={16} tablet={16} computer={12}>
                <ContentAndHeader title="Theory of Change">
                  <div className="approval-request-data">
                    <strong>Aiming for outcomes in the </strong>
                    {`${
                      currentActivity.primaryPolicy
                        ? `${get(
                            currentActivity,
                            'primaryPolicy.policyName',
                            ''
                          )}`
                        : ''
                    }${
                      currentActivity.secondaryPolicy
                        ? ` and ${get(
                            currentActivity,
                            'secondaryPolicy.policyName',
                            ''
                          )}`
                        : !isEmpty(domains)
                          ? ', '
                          : ''
                    }${!isEmpty(domains) ? domains.join(', ') : ''}`}
                    <strong> domains and knowing </strong>
                    {get(currentActivity, 'projectTocKnown', '-')}
                    <strong> we will </strong>
                    {get(currentActivity, 'projectTocDo', '-')}
                  </div>
                </ContentAndHeader>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="activity-greenlight">
              <Grid.Column
                className="approval-column-extra-top-margin"
                mobile={16}
                tablet={16}
                computer={8}
              >
                <ContentAndHeader title="Evidence Types Used">
                  <div className="approval-request-data ">
                    {this.getEvidence(
                      currentActivity.evidence ? currentActivity.evidence : []
                    )}
                  </div>
                </ContentAndHeader>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : (
          <>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <div className="primary-objective-greenlight">
                    Primary Objective
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="activity-greenlight">
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <ContentAndHeader title="Objective Name">
                    <div className="approval-request-data">
                      {objectives.length > 0 ? objectives[0].outcome.title : ''}
                    </div>
                  </ContentAndHeader>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <ContentAndHeader title="Evidence Types Used ">
                    <div className="approval-request-data ">
                      {this.getEvidence(
                        objectives[0] ? objectives[0].evidence : []
                      )}
                    </div>
                  </ContentAndHeader>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="activity-greenlight">
                <Grid.Column
                  className="approval-column-extra-top-margin"
                  mobile={16}
                  tablet={16}
                  computer={12}
                >
                  <ContentAndHeader title="Theory of Change">
                    <div className="approval-request-data">
                      <strong style={{ fontWeight: 'bold' }}>
                        {objectives.length > 0
                          ? objectives[0].outcome.aiming
                          : 'Aiming for '}
                      </strong>
                      {objectives.length > 0
                        ? objectives[0].outcome.contentFormat
                        : ''}
                      <strong style={{ fontWeight: 'bold' }}>
                        {objectives.length > 0
                          ? objectives[0].outcome.knowing
                          : ' and knowing '}
                      </strong>
                      {objectives.length > 0 ? objectives[0].tocKnown : ''}
                      <strong> we will </strong>
                      {objectives.length > 0 ? objectives[0].tocDo : ''}
                    </div>
                  </ContentAndHeader>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider section />
            <>
              {objectives &&
              objectives.length &&
              isEmpty(objectives[1]) ? null : (
                <Grid>
                  <Grid.Row>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <div className="primary-objective-greenlight">
                        Secondary Objective
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className="activity-greenlight">
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <ContentAndHeader title="Objective Name">
                        <div className="approval-request-data">
                          {objectives.length > 1
                            ? objectives[1].outcome.title
                            : ''}
                        </div>
                      </ContentAndHeader>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <ContentAndHeader title="Evidence Types Used ">
                        <div className="approval-request-data">
                          {this.getEvidence(
                            objectives[1] ? objectives[1].evidence : []
                          )}
                        </div>
                      </ContentAndHeader>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className="activity-greenlight">
                    <Grid.Column
                      className="approval-column-extra-top-margin"
                      mobile={16}
                      tablet={16}
                      computer={12}
                    >
                      <ContentAndHeader title="Theory of Change">
                        <div className="approval-request-data">
                          <strong>Aiming for </strong>
                          {objectives.length > 0
                            ? objectives[1].outcome.title
                            : ''}
                          <strong> and knowing </strong>
                          {objectives.length > 0 ? objectives[1].tocKnown : ''}
                          <strong> we will </strong>
                          {objectives.length > 0 ? objectives[1].tocDo : ''}
                        </div>
                      </ContentAndHeader>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </>
          </>
        )}
        <Divider section />
        <Grid>
          <Grid.Row className="activity-greenlight heading-margin">
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="projected-inputs-greenlight">
                Projected Inputs
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="activity-greenlight">
            {isEmpty(currentActivity.members) ? null : (
              <Grid.Column
                mobile={16}
                tablet={
                  isParentProgramActivity(currentActivity.activityPlanTypeId)
                    ? 4
                    : 5
                }
                computer={
                  isParentProgramActivity(currentActivity.activityPlanTypeId)
                    ? 4
                    : 5
                }
              >
                <ContentAndHeader title="Organisation Members In Team">
                  <div className="approval-request-data">
                    {currentActivity.members
                      ? currentActivity.members.length
                      : 0}
                  </div>
                </ContentAndHeader>
              </Grid.Column>
            )}
            <Grid.Column
              mobile={16}
              tablet={
                isParentProgramActivity(currentActivity.activityPlanTypeId)
                  ? 4
                  : 5
              }
              computer={
                isParentProgramActivity(currentActivity.activityPlanTypeId)
                  ? 4
                  : 5
              }
            >
              <ContentAndHeader title="Projected Expenditure ">
                <div className="approval-request-data">
                  ${thousands(currentActivity.projectedCost)}
                </div>
              </ContentAndHeader>
            </Grid.Column>
            {isEmpty(currentActivity.contractors) ? null : (
              <Grid.Column
                mobile={16}
                tablet={
                  isParentProgramActivity(currentActivity.activityPlanTypeId)
                    ? 4
                    : 5
                }
                computer={
                  isParentProgramActivity(currentActivity.activityPlanTypeId)
                    ? 4
                    : 5
                }
              >
                <ContentAndHeader title="Number of Contractors ">
                  <div className="approval-request-data">
                    {currentActivity.contractors
                      ? currentActivity.contractors.length
                      : 0}
                  </div>
                </ContentAndHeader>
              </Grid.Column>
            )}
          </Grid.Row>
          {isEmpty(currentActivity.venues) ? null : (
            <Grid.Row className="activity-greenlight">
              <Grid.Column
                className="approval-column-extra-top-margin"
                mobile={16}
                tablet={16}
                computer={16}
              >
                <ContentAndHeader title="Venue(s) ">
                  <div className="approval-request-data">
                    {currentActivity.venues && currentActivity.venues.length
                      ? currentActivity.venues.map((item) => {
                          return (
                            <div className="projected-input-venues">
                              {item.name}
                            </div>
                          );
                        })
                      : '-'}
                  </div>
                </ContentAndHeader>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
        {!isParentProgramActivity(currentActivity.activityPlanTypeId) && (
          <Divider section />
        )}
        <Grid>
          <Grid.Row className="heading-margin">
            <Grid.Column
              className={
                isParentProgramActivity(currentActivity.activityPlanTypeId)
                  ? 'projected-output-greenlight-grant'
                  : 'projected-output-greenlight-single'
              }
              mobile={16}
              tablet={16}
              computer={16}
            >
              <div
                className={
                  isParentProgramActivity(currentActivity.activityPlanTypeId)
                    ? 'projected-output-greenlight'
                    : 'primary-objective-greenlight'
                }
              >
                Projected Outputs
              </div>
            </Grid.Column>
          </Grid.Row>

          {isParentProgramActivity(
            currentActivity.activityPlanTypeId
          ) ? null : (
            <Grid.Row className="activity-greenlight m-0" columns={4}>
              {this.projectedOutputs(currentActivity)}
            </Grid.Row>
          )}

          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={
                isParentProgramActivity(currentActivity.activityPlanTypeId)
                  ? 4
                  : 5
              }
              computer={
                isParentProgramActivity(currentActivity.activityPlanTypeId)
                  ? 4
                  : 5
              }
            >
              <ContentAndHeader title="Projected Income" extraMargin>
                {currentActivity.projectedBudget
                  ? get(currentActivity, 'currencyType.sign', '$') +
                    thousands(currentActivity.projectedBudget)
                  : '-'}
              </ContentAndHeader>
            </Grid.Column>
            <Grid.Column
              className="ml-0"
              mobile={16}
              tablet={
                isParentProgramActivity(currentActivity.activityPlanTypeId)
                  ? 4
                  : 5
              }
              computer={
                isParentProgramActivity(currentActivity.activityPlanTypeId)
                  ? 4
                  : 5
              }
            >
              <ContentAndHeader
                title={`Projected ${
                  isParentProgramActivity(currentActivity.activityPlanTypeId)
                    ? 'Program'
                    : 'Activity'
                } Final Result`}
              >
                {projectedTotalCost
                  ? get(currentActivity, 'currencyType.sign', '$') +
                    thousands(projectedTotalCost)
                  : '-'}
              </ContentAndHeader>
            </Grid.Column>
            {isParentProgramActivity(currentActivity.activityPlanTypeId) &&
              get(currentActivity, 'eventDetail.outputFormat.format', []).map(
                (format) => {
                  const formatData = get(currentActivity, 'output', []).find(
                    (outputItem) =>
                      format.outputFormatId === outputItem.outputFormatId
                  );

                  return (
                    <Grid.Column mobile={16} tablet={4} computer={4}>
                      <ContentAndHeader title={format.label}>
                        {formatData.projectedValue
                          ? formatData.projectedValue
                          : '-'}
                      </ContentAndHeader>
                    </Grid.Column>
                  );
                }
              )}
            {currentActivity.parentId &&
              !isParentProgramActivity(currentActivity.activityPlanTypeId) && (
                <Grid.Column
                  mobile={16}
                  tablet={
                    isParentProgramActivity(currentActivity.activityPlanTypeId)
                      ? 4
                      : 5
                  }
                  computer={
                    isParentProgramActivity(currentActivity.activityPlanTypeId)
                      ? 4
                      : 5
                  }
                >
                  <ContentAndHeader title="Program Contribution">
                    {currentActivity.projectedGrantAmount
                      ? get(currentActivity, 'currencyType.sign', '$') +
                        thousands(currentActivity.projectedGrantAmount)
                      : '-'}
                  </ContentAndHeader>
                </Grid.Column>
              )}
          </Grid.Row>

          {isApprovalUser && !currentActivity.approvedDate && (
            <Grid.Row>
              <div className="ml-auto">
                <Button
                  className="approve-btn"
                  onClick={() => {
                    this.approveProject();
                  }}
                  disabled={currentActivity.approvedDate}
                  loading={actPut ? actPut.greenLightUserApproval : false}
                >
                  Approve
                </Button>
                {/* <Button
                  className="review-btn"
                  disabled={currentActivity.approvedDate}
                >
                  Review
                </Button> */}
              </div>
            </Grid.Row>
          )}
        </Grid>
      </Segment>
    );
  }

  projectedOutputs(currentActivity) {
    try {
      let formats = get(currentActivity, 'eventDetail.outputFormat.format', []);
      return map(formats, (item, index) => {
        const outputObj = currentActivity.output.find(
          (outputItem) => outputItem.outputFormatId === item.outputFormatId
        );

        if (isEmpty(outputObj)) return;

        let projectedValue = get(outputObj, 'projectedValue', '0');
        projectedValue = projectedValue ? Number(projectedValue) : 0;

        switch (item.type) {
          case 'checkbox':
            return (
              <Grid.Column
                mobile={16}
                tablet={5}
                computer={5}
                key={index}
                className="projected-outputs"
              >
                <ContentAndHeader title={item.label}>
                  {projectedValue ? 'Yes' : 'No'}
                </ContentAndHeader>
              </Grid.Column>
            );
          default:
            projectedValue = getValuesFormat(
              projectedValue,
              item.type,
              get(currentActivity, 'currencyType.sign', '$')
            );
            return (
              <Grid.Column
                mobile={16}
                tablet={5}
                computer={5}
                key={index}
                className="projected-outputs"
              >
                <ContentAndHeader title={item.label}>
                  <div className="approval-request-data">{projectedValue}</div>
                </ContentAndHeader>
              </Grid.Column>
            );
        }
      });
    } catch (error) {
      return '-';
    }
  }

  getEvidence(evidence) {
    if (isEmpty(evidence)) return null;
    let types = this.props.evidenceTypes;
    let usedTypes = [];

    types.forEach((element) => {
      if (findIndex(evidence, { evidenceType: parseInt(element.id) }) >= 0) {
        if (findIndex(usedTypes, { type: element.evidenceType }) === -1) {
          usedTypes.push({
            type: element.evidenceType,
            status: true,
          });
        }
      } else {
        if (findIndex(usedTypes, { type: element.evidenceType }) === -1) {
          usedTypes.push({
            type: element.evidenceType,
            status: false,
          });
        }
      }
    });

    return map(
      usedTypes,
      (item) =>
        item.status === true && (
          <div className="projected-input-venues">{item.type}</div>
        )
    );
  }

  getFestivalTypes(festivalTypes) {
    const { extra } = this.props;
    if (isEmpty(festivalTypes)) return null;
    const usedFestivalTypes = map(festivalTypes, (i) => i.festivalTypeId) || [];
    if (isEmpty(usedFestivalTypes)) return null;
    const getFestivalTypes = filter(extra.festivalTypes, (i) =>
      usedFestivalTypes.includes(i.id)
    );
    return map(getFestivalTypes, (i) => (
      <div className="projected-input-venues">{i.title}</div>
    ));
  }

  render() {
    const { loading, currentActivity, user } = this.props;

    return (
      <div>
        <Tab.Pane attached={false} className="custom-tab-act" loading={loading}>
          {/* <ActivitiesContainer
            next={next}
            previous={previous}
            currentTab={currentTab}
            title={title}
            children={ */}
          <div>
            <>
              <CustomSegment
                noPrint
                className="activity-approval"
                title={
                  <CustomTooltip
                    state={get(user, 'options.tips')}
                    contentObject={{
                      data: [],
                      key: '5003',
                    }}
                    position="top left"
                    wide="very"
                  >
                    <span>Activity Approval</span>
                  </CustomTooltip>
                }
                children={this.renderActivityApproval()}
                rightHeader={
                  <Loader
                    active={
                      this.props.actPut
                        ? this.props.actPut.greenLightApproval
                        : false
                    }
                  />
                }
              />
              {currentActivity.approvalUserId === user.id &&
                currentActivity.approvalUserId !== currentActivity.ownerId && (
                  <CustomSegment
                    className="activity-on-a-page"
                    title={<>Activity on a page</>}
                    children={this.renderReport()}
                    extraMargin
                  />
                )}
            </>
          </div>
          {/* }
          /> */}
        </Tab.Pane>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.activity,
    user: state.authentication.user,
    authentication: state.authentication,
    helpTips: state.extra.activityHelpTips,
    extra: state.extra,
    organization: state.organization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateProjectInputs,
      setCurrentActivity,
      getReports,
      getOrganizationUsers,
    },
    dispatch
  );
};

GreenLightComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(GreenLightComponent);

export { GreenLightComponent };
