/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Icon,
  Modal,
  Segment,
  Form,
  Radio,
  Message,
} from 'semantic-ui-react';
import {
  CustomInput,
  CustomTextArea,
  CustomDropdown,
  CustomMultiSelectDropdown,
} from '../../../components/form';
import { CustomTable } from '../../../components';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOrganizationGoals,
  postOrganisationDepartment,
  getDepartmentsUnits,
  getDepartmentsLists,
  postOrganisationUnit,
  getDepartmentGoals,
  deleteOrganisationDepartment,
  updateOrganizationDepartment,
  updateOrganizationUnit,
} from '../../../store/actions';
import { size, isEmpty, get, compact } from 'lodash';
import {
  departmentUnitValidationSchema,
  departmentUnitInitialValues,
} from '../../../validations/orgSettingsValidation';
import { ViewListModal } from '../../../components/viewListModal';
import { stopPropagation } from '../../../utils';

const EditDepartmentUnitModal = (props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    (() => {
      const departmentId = get(props, 'form.departmentId', null);
      if (!props.isDepartment && departmentId) {
        dispatch(getDepartmentGoals(departmentId));
      }
    })();
  }, []);

  const departments = useSelector((state) => state.organization.deptList);
  const departmentGoals = useSelector(
    (state) => state.organization.departmentGoals
  );

  return (
    <Modal
      open={modal}
      size="small"
      onClick={(e) => stopPropagation(e)}
      onFocus={(e) => stopPropagation(e)}
      onOpen={(e) => stopPropagation(e)}
      closeIcon={<Icon onClick={toggle} name="close" className="closeicon" />}
      className="add-ref-model"
      trigger={
        <label
          onClick={toggle}
          className="org-users-actions-warn default-color"
        >
          Edit
        </label>
      }
    >
      <Modal.Header>
        {props.isDepartment ? 'Edit Department' : 'Edit Unit'} {props.form.name}
      </Modal.Header>
      <Modal.Content>
        <EditDepartmentForm
          departmentGoals={
            props.isDepartment ? props.departmentGoals : departmentGoals
          }
          toggle={toggle}
          form={props.form}
          departments={departments}
          {...props}
        />
      </Modal.Content>
    </Modal>
  );
};

const DeleteDepartmentUnitModal = ({ isDepartment, form }) => {
  const [modal, setModal] = useState(false);

  const dispatch = useDispatch();
  const loading = useSelector(
    (state) => state.organization.deleteDepartmentLoading
  );

  const toggle = () => {
    setModal(!modal);
  };

  const handleClick = async () => {
    await dispatch(deleteOrganisationDepartment(form.id));
    setModal(false);
  };

  return (
    <Modal
      open={modal}
      closeIcon={<Icon onClick={toggle} name="close" className="closeicon" />}
      size="mini"
      trigger={
        <div onClick={toggle} className="remove-department">
          Remove
        </div>
      }
    >
      <Modal.Header>Remove {isDepartment ? 'Department' : 'Unit'}</Modal.Header>
      <Modal.Content>
        <p>
          Are you sure you want to remove this{' '}
          {isDepartment ? 'department' : 'unit'}?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={loading} onClick={toggle} className="modal-cancel">
          No
        </Button>
        <Button
          onClick={handleClick}
          loading={loading}
          disabled={loading}
          className="modal-proceed"
          content="Yes"
        />
      </Modal.Actions>
    </Modal>
  );
};

const DepartmentForm = (props) => {
  const { departmentGoals } = props;

  const [selectedGoals, setSelectedGoal] = useState([]);
  const [specificGoalSelected, setSpecificGoalSelected] = useState(false);
  // const [departmentId, setDepartmentId] = useState('');

  const dispatch = useDispatch();

  const handleSubmit = async (values, setSubmitting) => {
    try {
      delete values.goalTitle;

      if (props.isDepartment) {
        if (!specificGoalSelected) {
          delete values.goals;
        }
        delete values.departmentId;
        await dispatch(postOrganisationDepartment(values));
      } else {
        const { departmentId } = props;
        values.departmentId = departmentId;
        delete values.title;

        const data = {
          description: values.description,
          goals: values.goals,
          name: values.name,
          departmentId: departmentId,
        };

        if (!specificGoalSelected) {
          delete data.goals;
        }

        await dispatch(postOrganisationUnit(data));
      }
      props.toggle();
    } catch (e) {
      setSubmitting(false);
    }
  };

  // organisation all goals
  const orgGoals = useSelector((state) => state.organization.organisationGoals);

  const deptGoalsLoading = useSelector(
    (state) => state.organization.deptGoalsLoading
  );

  const allGoals = props.isDepartment ? orgGoals : departmentGoals;

  const getData = (values) => {
    setSelectedGoal(values);
  };

  // const onChange = e => {
  //   setDepartmentId(e);
  // };

  const onSelectedSpecificGoal = () => {
    setSpecificGoalSelected(!specificGoalSelected);
    if (!isEmpty(selectedGoals)) {
      setSelectedGoal([]);
    }
  };

  // const renderDepartmentsNames = props.departments.map(item => {
  //   return { key: item.id, text: item.name, value: item.id };
  // });

  return (
    <>
      <Segment>
        {!props.isDepartment && isEmpty(props.departments) && (
          <div className="unit-issues-box w-100">
            <div className="padding-issue ">Please create a department</div>
          </div>
        )}
        <Formik
          initialValues={departmentUnitInitialValues(props.isDepartment)}
          validationSchema={departmentUnitValidationSchema(
            props.isDepartment,
            specificGoalSelected
          )}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values, setSubmitting);
          }}
        >
          {({ handleSubmit, isSubmitting, setFieldValue, values, errors }) => (
            <Form onSubmit={handleSubmit}>
              <CustomInput
                name="name"
                id="name"
                labelText={props.isDepartment ? 'Department Name' : 'Unit Name'}
                placeholder="Enter name"
                disabled={!props.isDepartment && isEmpty(props.departments)}
              />

              <CustomTextArea
                name="description"
                id="description"
                labelText={
                  props.isDepartment
                    ? 'Department Description'
                    : 'Unit Description'
                }
                placeholder="Enter description"
                disabled={!props.isDepartment && isEmpty(props.departments)}
              />

              <div className="org three-step-radio-container">
                <label>Specific Goals</label>
                {isEmpty(allGoals) ? (
                  // TODO: need to add tooltip
                  // <CustomTooltip
                  //   state={true}
                  //   content={'Please select department'}
                  //   position="top left"
                  //   wide="very"
                  // >
                  // </CustomTooltip>
                  <Radio
                    toggle
                    checked={specificGoalSelected}
                    onChange={onSelectedSpecificGoal}
                    disabled={
                      // (!props.isDepartment && isEmpty(props.departments)) ||
                      isEmpty(allGoals)
                    }
                  />
                ) : (
                  <Radio
                    toggle
                    checked={specificGoalSelected}
                    onChange={onSelectedSpecificGoal}
                    disabled={
                      (!props.isDepartment && isEmpty(props.departments)) ||
                      isEmpty(allGoals)
                    }
                  />
                )}
              </div>

              {specificGoalSelected && (
                <>
                  <CustomMultiSelectDropdown
                    pointing="top"
                    id="goals"
                    name="goals"
                    labelText="Goals"
                    placeholder="Select Goals"
                    options={
                      !isEmpty(allGoals)
                        ? allGoals.map((goal, key) => {
                            return {
                              key,
                              value: props.isDepartment ? goal.id : goal.goalId,
                              text: goal.goalTitle,
                            };
                          })
                        : []
                    }
                    getData={getData}
                    values={values}
                    defaultValues={deptGoalsLoading ? [] : selectedGoals}
                    loading={deptGoalsLoading}
                    setFieldValue={setFieldValue}
                    specificGoalSelected={specificGoalSelected}
                  />
                </>
              )}

              {selectedGoals.length > 0 && (
                <div className="list-goals">
                  <ul>
                    {selectedGoals.map((goal) => (
                      <li>{goal.name}</li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="model-buttons">
                <Button
                  className="Secondary-Button"
                  type="button"
                  onClick={() => props.toggle()}
                >
                  Cancel
                </Button>

                <Button
                  content="Add"
                  type="submit"
                  className="Primary-Button"
                  loading={isSubmitting}
                  disabled={
                    isSubmitting ||
                    (!props.isDepartment && isEmpty(props.departments))
                  }
                />
              </div>
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  );
};

const EditDepartmentForm = (props) => {
  const { departmentGoals } = props;

  const [editSpecificGoal, setEditSpecificGoal] = useState(
    !get(props, 'form.isOrganisationGoal', true)
  );
  const [departmentId, setDepartmentId] = useState('');

  useEffect(() => {
    setDepartmentId(props.form.departmentId);
  }, []);

  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    delete values.goalTitle;

    if (!editSpecificGoal) {
      delete values.goals;
    }

    const deptId = props.form.id;
    if (props.isDepartment) {
      await dispatch(updateOrganizationDepartment(values, deptId));
    } else {
      delete values.title;
      await dispatch(updateOrganizationUnit(values, deptId));
    }
    props.toggle();
  };

  // organisation all goals
  const orgGoals = useSelector((state) => state.organization.organisationGoals);

  const formattedDepartmentGoals = departmentGoals.map((item) => {
    return { goalId: item.id, goalTitle: item.goalTitle };
  });

  const formattedOrgGoals = orgGoals.map((item) => {
    return { goalId: item.id, goalTitle: item.goalTitle };
  });

  const allGoals = props.isDepartment
    ? formattedOrgGoals
    : formattedDepartmentGoals;

  const renderDropdownValues = (allGoals) => {
    return !isEmpty(allGoals)
      ? allGoals.map((data, key) => {
          return {
            key,
            value: data.goalId,
            rest: {
              key: data.goalId,
            },
            text: !isEmpty(data.goalTitle)
              ? data.goalTitle
              : get(data, 'goals.goalTitle', ''),
          };
        })
      : [];
  };

  useEffect(() => {
    renderDropdownValues(allGoals);
  }, [allGoals]);

  //get organization goals
  useEffect(() => {
    (async () => {
      await dispatch(getOrganizationGoals());
    })();
  }, []);

  //get department goals
  useEffect(() => {
    (async () => {
      if (!props.isDepartment && departmentId !== '') {
        await dispatch(getDepartmentGoals(departmentId));
      }
    })();
  }, [departmentId]);

  const onChange = (e) => {
    setDepartmentId(e);
  };

  const renderDepartmentsNames = props.departments.map((item, key) => {
    return {
      key,
      text: item.name,
      value: item.id,
      rest: { key: item.id },
    };
  });

  const onChangeSpecificGoal = () => setEditSpecificGoal(!editSpecificGoal);

  const goalDefaultValues = (values) =>
    !isEmpty(allGoals)
      ? compact(
          allGoals.map((data) => {
            const goalId = data.goalId;
            if (values['goals'] && values['goals'].includes(goalId)) {
              const title = !isEmpty(data.goalTitle)
                ? data.goalTitle
                : get(data, 'goals.goalTitle', null);
              return title;
            }

            return null;
          })
        )
      : [];

  return (
    <Segment>
      <Formik
        initialValues={departmentUnitInitialValues(
          props.isDepartment,
          props.form
        )}
        validationSchema={departmentUnitValidationSchema(
          props.isDepartment, // isDepartment
          true,
          true // idEdit
        )}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values, errors }) => (
          <Form onSubmit={handleSubmit}>
            {/* if it is unit, show the dropdown here */}
            {!props.isDepartment && (
              <CustomDropdown
                name="departmentId"
                id="departmentId"
                labelText="Department"
                options={renderDepartmentsNames}
                placeholder="Select Departments"
                getValue={(e) => onChange(e)}
                disabled
              />
            )}

            <CustomInput
              name="name"
              id="name"
              labelText={props.isDepartment ? 'Department Name' : 'Unit Name'}
              placeholder="Enter name"
            />

            <CustomTextArea
              name="description"
              id="description"
              labelText={
                props.isDepartment
                  ? 'Department Description'
                  : 'Unit Description'
              }
              placeholder="Enter description"
            />

            <div className="org three-step-radio-container">
              <label>Specific Goals</label>
              <Radio
                toggle
                checked={editSpecificGoal}
                onChange={onChangeSpecificGoal}
              />
            </div>

            {editSpecificGoal && (
              <>
                <CustomMultiSelectDropdown
                  name="goals"
                  pointing="top"
                  setFieldValue={setFieldValue}
                  labelText={'Goals'}
                  placeholder="Select Goals"
                  options={renderDropdownValues(allGoals)}
                  values={values}
                  // loading={deptGoalsLoading}
                  defaultValuesTexts={goalDefaultValues(values)}
                  isView={false}
                  disabled={!editSpecificGoal}
                />
              </>
            )}

            {!editSpecificGoal && (
              <div className="msg-report">
                <Message
                  color="blue"
                  icon="exclamation circle"
                  content={
                    props.isDepartment
                      ? 'All organisation goals are available for this department'
                      : 'All department goals are available for this department'
                  }
                />
              </div>
            )}

            <div className="model-buttons">
              <Button
                className="Secondary-Button"
                type="button"
                onClick={() => props.toggle()}
              >
                Cancel
              </Button>

              <Button
                content="Save"
                type="submit"
                className="Primary-Button"
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Segment>
  );
};

export const DepartmentsUnits = (props) => {
  const dispatch = useDispatch();
  const columns = [
    {
      title: 'Department Name',
      width: 3,
      render: (data) => {
        return (
          <>
            <div className="reference-container">
              <label>{data.name}</label>
            </div>
          </>
        );
      },
      renderSubColumn: (data) => {
        return (
          <>
            <div className="reference-container">
              <label>{data.name}</label>
            </div>
          </>
        );
      },
    },
    {
      title: 'Goals',
      width: 3,
      render: (data) => {
        return (
          <>
            <div className="reference-container">
              <label>
                {size(data.departmentGoals)}{' '}
                {size(data.departmentGoals) === 1 ? 'goal' : 'goals'}
              </label>
              {size(data.departmentGoals) > 0 && (
                <ViewListModal
                  isUnit={false}
                  data={data.departmentGoals}
                  title="Goals"
                />
              )}
            </div>
          </>
        );
      },
      renderSubColumn: (data) => {
        return (
          <>
            <div className="reference-container">
              <label>
                {size(data.departmentGoals)}{' '}
                {size(data.departmentGoals) === 1 ? 'goal' : 'goals'}
              </label>
              {size(data.departmentGoals) > 0 && (
                <ViewListModal
                  isUnit={true}
                  data={data.departmentGoals}
                  title="Goals"
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      title: 'Users',
      width: 3,
      render: (data) => {
        return (
          <>
            <div className="reference-container">
              <label>{data.totalUsersAssociated}</label>
            </div>
          </>
        );
      },
      renderSubColumn: (data) => {
        return (
          <>
            <div className="reference-container">
              <label>{size(data.departmentUsers)}</label>
            </div>
          </>
        );
      },
    },
    {
      title: '',
      width: 2,
      render: (data) => {
        const { id, name } = data;

        return (
          <div className="action-container">
            <AddModal
              isDepartment={false}
              departmentId={id}
              departmentName={name}
              departmentGoals={data.departmentGoals}
              {...props}
            />
          </div>
        );
      },
      renderSubColumn: (data) => {
        return <></>;
      },
    },
    {
      title: '',
      width: 2,
      render: (data) => {
        return (
          <div className="action-container">
            <EditDepartmentUnitModal
              departmentGoals={data.departmentGoals}
              isDepartment={true}
              form={data}
              {...props}
            />
            <DeleteDepartmentUnitModal
              isDepartment={true}
              form={data}
              {...props}
            />
          </div>
        );
      },
      renderSubColumn: (data) => {
        const form = {
          departmentId: data.parentDepartmentId,
          ...data,
        };
        return (
          <div className="action-container">
            <EditDepartmentUnitModal
              isDepartment={false}
              form={form}
              {...props}
            />
            <DeleteDepartmentUnitModal
              isDepartment={false}
              form={data}
              {...props}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    (async () => {
      await dispatch(getOrganizationGoals());
      await dispatch(getDepartmentsUnits());
      await dispatch(getDepartmentsLists());
    })();
  }, []);

  const departments = useSelector((state) => state.organization.deptList);

  const departmentsUnits = useSelector(
    (state) => state.organization.departmentsUnits
  );

  const AddModal = (props) => {
    const [modal, setModal] = useState(false);
    const toggle = () => {
      setModal(!modal);
    };

    const {
      isDepartment,
      departmentId = null,
      departmentName = null,
      departmentGoals,
    } = props;

    return (
      <Modal
        open={modal}
        size="small"
        onClick={(e) => stopPropagation(e)}
        onFocus={(e) => stopPropagation(e)}
        onOpen={(e) => stopPropagation(e)}
        closeIcon={<Icon onClick={toggle} name="close" className="closeicon" />}
        className="add-ref-model"
        trigger={
          <div className="ref-plans-show">
            <div className="add-new-ref">
              {isDepartment ? (
                <Button
                  onClick={toggle}
                  className={'Primary-Button add-reference-btn'}
                >
                  <Icon name="plus" />
                  {isDepartment ? 'Add Department' : 'Add Unit'}
                </Button>
              ) : (
                <span className="add-unit-label" onClick={toggle}>
                  Add Unit
                </span>
              )}
            </div>
          </div>
        }
      >
        <Modal.Header>
          {isDepartment ? 'Add Department' : `Add Unit to ${departmentName}`}
        </Modal.Header>
        <Modal.Content>
          <DepartmentForm
            toggle={toggle}
            departments={departments}
            departmentId={departmentId}
            isDepartment={isDepartment}
            departmentGoals={departmentGoals}
            {...props}
          />
        </Modal.Content>
      </Modal>
    );
  };

  return (
    <div>
      <Segment
        className="org-dept-unit-segment"
        //   loading={organisationGoalsReferencesLoading}
      >
        <div className="add-dept-buttons">
          <AddModal isDepartment={true} {...props} />
        </div>
        <CustomTable
          header
          columns={columns}
          data={departmentsUnits}
          customClass="org-ref-table"
          height={true}
          emptyMessage="No departments/unit have been added"
          keyExtractor={(item, index) => item.id}
        />
      </Segment>
    </div>
  );
};
