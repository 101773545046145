/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Segment,
  Grid,
  Button,
  Modal,
  Icon,
  Message,
  Divider,
  Input,
} from 'semantic-ui-react';
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from 'availity-reactstrap-validation';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import {
  getDocuments,
  postGranteeDocument,
  deleteDocument,
  updateDocument,
  getReports,
} from '../../../../store/actions';
import '../activity.scss';
import { GRANTEE_CSV_TEMPLATE } from '../../../../utils/constant';
import { isEmpty, get } from '../../../../utils/lodash';
import { CustomTable, CustomTooltip } from '../../../../components';
import { getCommonError, getPageDisable } from '../../../../utils';
import { NotificationManager } from 'react-notifications';

class GranteeUploadDelete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async deleteDocument(id) {
    try {
      const { deleteDocument, currentActivity } = this.props;
      await deleteDocument(currentActivity.id, id, true);
      this.toggle();
    } catch (error) {
      // handle error here
    }
  }

  render() {
    const { modal } = this.state;
    const { record, deleteDocumentsLoading } = this.props;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <div className="grants-modal-close" onClick={() => this.toggle()}>
            &times;
          </div>
        }
        size="tiny"
        trigger={
          <label className="activity-actions-error first-down mb-0">
            Remove
          </label>
        }
      >
        <Modal.Header className="grants-modal-header">
          Delete Item - {record.document.name}
        </Modal.Header>
        <Modal.Content className="grants-modal-content">
          <p>
            Are you sure you want to remove this file from the grant program?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="grants-modal-close-btn"
            onClick={() => this.toggle()}
          >
            No
          </Button>
          <Button
            className="grants-modal-confirm-btn"
            loading={deleteDocumentsLoading}
            disabled={deleteDocumentsLoading}
            onClick={() => this.deleteDocument(record.document.id)}
          >
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
class GranteeUploadForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      fileName: null,
      fileValue: null,
      prevFileValue: null,
      errorList: null,
      warningList: null,
      loading: false,
      uploadButtonDisabled: true,
      isIgnoreWarning: false,
    };
    this.fileInputRef = React.createRef();
  }

  handleBrowseClick = () => {
    this.setState({
      fileValue: '',
      file: [],
      fileName: null,
      errorList: [],
      warningList: [],
    });
  };

  async handleValidSubmit(event, values) {
    try {
      const {
        currentActivity,
        toggle,
        smartyGrant,
        postGranteeDocument,
        edit,
        record,
      } = this.props;
      const { file, isIgnoreWarning } = this.state;
      this.setState({
        errorList: null,
        warningList: null,
      });

      if (file) {
        values.file = file;
      }
      if (smartyGrant) {
        values.smartyGrant = smartyGrant;
      }

      if (edit) {
        await this.props.updateDocument(
          record.projectId,
          record.documentId,
          values,
          true
        );
      } else {
        await postGranteeDocument(currentActivity.id, values, isIgnoreWarning);
      }

      toggle();
    } catch (error) {
      // handling the error (warnings and errors)
      if (!this.props.edit) {
        const getError = get(error, 'response.data.message', null);

        if (getError && getError.startsWith('{')) {
          const parsedError = JSON.parse(getError);
          if (
            parsedError.errors.length > 0 ||
            parsedError.warnings.length > 0
          ) {
            this.setState(
              {
                errorList: parsedError.errors,
                warningList: parsedError.warnings,
              },
              () => {
                if (
                  parsedError &&
                  parsedError.errors &&
                  parsedError.errors.length > 0
                ) {
                  this.setState({
                    uploadButtonDisabled: true,
                  });
                }
                if (
                  parsedError &&
                  parsedError.warnings &&
                  parsedError.warnings.length > 0
                ) {
                  this.setState({
                    isIgnoreWarning: true,
                  });
                }
              }
            );
          }
        } else {
          //errors coming from grantee validation class (as strings)
          if (getError) {
            this.setState({
              errorList: getError.split(','),
              uploadButtonDisabled: true,
              warningList: [],
            });
          }
        }
      }
    }
  }

  handleFileChange = ({ target: { files, value } }) => {
    const cancel = !files.length;

    if (cancel) {
      const { prevFileValue } = this.state;
      this.setState({
        fileValue: prevFileValue,
        file: [],
        fileName: null,
        errorList: [],
        warningList: [],
      });
      return;
    }

    this.setState({
      fileValue: '',
    });

    this.setState({
      fileValue: value,
      prevFileValue: value,
      uploadButtonDisabled: false,
      isIgnoreWarning: false,
      errorList: [],
      warningList: [],
    });

    // if (this.state.fileName) {
    //   this.setState({
    //     fileName: null,
    //   });
    // }

    this.setState({
      file: files[0],
      fileName: files[0].name,
    });
  };

  validateFile = (value, ctx, input, cb) => {
    if (!value) {
      cb('CSV is required');
      return;
    }
    cb(true);
  };

  render() {
    const { toggle, edit, record, postDocumentsLoading } = this.props;
    const {
      fileName,
      errorList,
      warningList,
      uploadButtonDisabled,
      fileValue,
    } = this.state;
    return (
      <AvForm
        ref={(c) => (this.form = c)}
        onValidSubmit={(event, values) => this.handleValidSubmit(event, values)}
        autoComplete="off"
        model={{
          description: record ? get(record, 'description', '') : '',
        }}
      >
        {edit ? null : (
          <div className="browse-area warning-box">
            <label className="grants-modal-label">CSV File</label>
            <Input
              label={
                <Button
                  onClick={this.handleBrowseClick}
                  className="ui white button custom"
                  as="label"
                  htmlFor="file"
                  type="button"
                >
                  <Icon name="upload" />
                  Browse File
                </Button>
              }
              readOnly
              labelPosition="right"
              fluid
              value={fileName ? fileName : ''}
            />
            <AvField
              innerRef={this.fileInputRef}
              style={{
                display: 'none',
              }}
              name="file"
              type="file"
              multiple={false}
              accept={'.csv'}
              onChange={this.handleFileChange}
              validate={{ custom: this.validateFile }}
              value={fileValue}
            />
            <div className="text-muted grants-modal-guideline">
              Max file size - 50 MB
            </div>
            <br />
            {isEmpty(errorList) ? null : (
              <Message
                className="grants-error-msg-container"
                error
                header={
                  <div className="grants-error-msg-title">
                    Required information errors preventing upload. Please
                    resolve the following:
                  </div>
                }
                list={errorList}
              />
            )}
            {isEmpty(warningList) ? null : (
              <div className="box-margin">
                <Message
                  className="grantee-upload-warning-box"
                  color="blue"
                  header={
                    <div>
                      Non-required field entry errors detected. You can proceed
                      with upload, but the following entries will not be
                      uploaded if errors are not resolved.
                    </div>
                  }
                  list={warningList}
                />
              </div>
            )}
          </div>
        )}
        <AvField
          className="grants-modal-textarea"
          label="Description"
          style={{ minHeight: '5rem' }}
          name="description"
          type="textarea"
          validate={{
            required: {
              value: true,
              errorMessage: 'Description is required',
            },
          }}
          autoComplete="off"
        />
        <div className="grants-modal-buttons">
          <Button
            className="grants-modal-close-btn"
            type="button"
            onClick={() => toggle()}
          >
            Cancel
          </Button>
          <Button
            loading={postDocumentsLoading}
            disabled={
              edit
                ? postDocumentsLoading
                : postDocumentsLoading || uploadButtonDisabled
            }
            content={edit ? 'Update' : 'Upload'}
            type="submit"
            className="grants-modal-confirm-btn"
          />
        </div>
      </AvForm>
    );
  }
}
class GranteeUploadModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    const { edit } = this.props;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <div className="grants-modal-close" onClick={() => this.toggle()}>
            &times;
          </div>
        }
        size="small"
        trigger={
          edit ? (
            <label className="activity-actions-warn mb-0">Edit</label>
          ) : (
            <Button
              basic
              className="export-program-data-btn"
              icon="cloud upload"
              color="orange"
              content="Import Grantees"
            />
          )
        }
      >
        <Modal.Header className="grants-modal-header">
          Import Grantees
        </Modal.Header>
        <Modal.Content className="grants-upload-modal-content">
          <GranteeUploadForm toggle={() => this.toggle()} {...this.props} />
        </Modal.Content>
      </Modal>
    );
  }
}

class ProgramDataUpload extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      isChecked: !prevState.isChecked,
    }));
  }

  render() {
    const { isChecked } = this.state;
    const { currentActivity, getReports, getReportsLoading, user, helpTips } =
      this.props;

    const smartyGrants = get(currentActivity, 'smartyGrants', false);
    return (
      <Segment
        attached
        disabled={getPageDisable(
          currentActivity,
          user,
          'project.edit.activityPlan',
          true
        )}
      >
        <Grid>
          <Grid.Row className="grant-export-row">
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <div className="program-data-upload">
                <CustomTooltip
                  state={get(user, 'options.tips')}
                  contentObject={{
                    data: helpTips,
                    key: '109',
                  }}
                  position="top left"
                >
                  <label className="title">Export</label>
                </CustomTooltip>
                <label className="description">
                  Export program information needed for the grant application
                  form.
                </label>
                <Button
                  basic
                  className="export-program-data-btn"
                  size="small"
                  disabled={getReportsLoading['programExport']}
                  loading={getReportsLoading['programExport']}
                  icon="cloud download"
                  color="orange"
                  content="Export Program Data"
                  onClick={() =>
                    getReports(
                      `/project/${currentActivity.id}/program/export`,
                      'programExport'
                    )
                  }
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Divider section />
          <Grid.Row className="grant-import-row">
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <div className="program-data-upload">
                <CustomTooltip
                  state={get(user, 'options.tips')}
                  contentObject={{
                    data: helpTips,
                    key: '110',
                  }}
                  position="top left"
                >
                  <label className="title">Import</label>
                </CustomTooltip>
                <label className="description">
                  Import successful grant applicant information to create the
                  linked activities for this program.
                </label>
                <div className="button-segment">
                  <div>
                    <GranteeUploadModal
                      smartyGrant={isChecked}
                      {...this.props}
                    />
                  </div>
                  <div>
                    <CSVLink
                      className="template-link"
                      headers={GRANTEE_CSV_TEMPLATE}
                      data={[]}
                      filename="grantees-template.csv"
                    >
                      <label>Download CSV Template</label>
                    </CSVLink>
                  </div>
                </div>
                {smartyGrants ? (
                  <AvForm>
                    <AvCheckboxGroup
                      name="smartyGrantsGroup"
                      className="grantee-checkbox"
                    >
                      <AvCheckbox
                        label="Import grantees from Smarty Grants"
                        value="smartyGrants"
                        checked={isChecked}
                        onChange={() => this.toggle()}
                      />
                    </AvCheckboxGroup>
                  </AvForm>
                ) : null}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

class ProgramDataList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'Name',
          width: 4,
          render: (data) => {
            if (data.document) {
              const format = data.document.name.split('.');
              return (
                <div className="activity-users-container">
                  {format && format.length ? format[0] : null}
                  <br></br>
                  <div className="file-description">
                    {get(data, 'description', '')}
                  </div>
                </div>
              );
            } else {
              return '';
            }
          },
        },
        {
          title: 'Format',
          width: 2,
          render: (data) => {
            if (data.document) {
              // eslint-disable-next-line
              const [name, format] = data.document.name.split('.');

              return (
                <div className="activity-users-container">
                  {format ? format.toUpperCase() : ''}
                </div>
              );
            } else {
              return '';
            }
          },
        },
        {
          title: 'Date Added',
          width: 3,
          render: (data) => {
            return (
              <div className="activity-users-container">
                {data.date ? moment(data.date).format('DD-MM-YYYY') : '-'}
              </div>
            );
          },
        },
        {
          title: 'Type',
          width: 2,
          render: (data) => {
            return (
              <div className="activity-users-container">
                {data.type === 'SMCSV' ? 'Smarty Grants' : 'Grants'}
              </div>
            );
          },
        },
        {
          title: '',
          width: 4,
          render: (data) => {
            return (
              <div className="activity-actions justify-content-center">
                <GranteeUploadModal edit record={data} {...this.props} />
                <label className="activity-actions-success first-down mb-0">
                  <a
                    className="activity-actions-success download-button"
                    href={get(data, 'document.documentUrl', '')}
                    rel="noopener noreferrer"
                    download
                    target="_blank"
                  >
                    Download
                  </a>
                </label>
                <GranteeUploadDelete record={data} {...this.props} />
              </div>
            );
          },
        },
      ],
    };
  }

  render() {
    const {
      dataSet,
      getDocumentsLoading,
      currentActivity,
      user,
      currentState,
    } = this.props;
    const { columns } = this.state;
    return (
      <Segment
        className="report-activity-seg"
        loading={getDocumentsLoading}
        disabled={getPageDisable(currentActivity, user, currentState, true)}
      >
        <CustomTable
          header
          headerFixed={true}
          columns={columns}
          data={dataSet}
          customClass="evidence-table-grant"
          keyExtractor={(item, index) => item.id}
        />
      </Segment>
    );
  }
}

class GrantProgramData extends PureComponent {
  componentDidMount() {
    const { getDocuments, currentActivity } = this.props;
    getDocuments(currentActivity.id, true);
  }

  render() {
    const { documents = [] } = this.props;
    return (
      <>
        <ProgramDataUpload {...this.props} />
        {isEmpty(documents) ? null : (
          <ProgramDataList dataSet={documents} {...this.props} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { activity, authentication, extra } = state;
  return {
    ...activity,
    user: authentication.user,
    documents: activity.documents,
    helpTips: extra.activityHelpTips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDocuments,
      postGranteeDocument,
      deleteDocument,
      updateDocument,
      getReports,
    },
    dispatch
  );
};

GrantProgramData = connect(
  mapStateToProps,
  mapDispatchToProps
)(GrantProgramData);

export { GrantProgramData };
