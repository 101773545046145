/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { isBrowser, isTablet } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  Divider,
  Form,
  Icon,
  Input,
  Modal,
  Segment,
} from 'semantic-ui-react';
import plusOutline from '../../../../assets/add-icon-white-bg.svg';
import addIcon from '../../../../assets/add-icon.svg';
import { CustomTable } from '../../../../components';
import {
  CustomCheckbox,
  CustomInput,
  CustomTextArea,
} from '../../../../components/form';
import {
  getOrgVenuesTypes,
  getVenueTypes,
  postVenueTypes,
  updateVenueType,
} from '../../../../store/actions';
import {
  initialValues,
  venueTypeValidation,
} from '../../../../validations/addVenueTypeValidation';
import { authMsg } from '../../../../utils';

export default class VenueTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  componentDidMount() {
    const { getVenueTypes, getOrgVenuesTypes } = this.props;
    getVenueTypes();
    getOrgVenuesTypes();
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { modal } = this.state;
    const { postVenueTypes, venueTypes, orgVenueTypes } = this.props;

    return (
      <div>
        <div className="outcome-admin-padded">
          <div className="button-outcome">
            <Modal
              open={modal}
              onOpen={() => this.toggle()}
              closeIcon={
                <Icon
                  onClick={() => this.toggle()}
                  name="close"
                  className="closeicon"
                />
              }
              size="small"
              trigger={
                <Button className="Primary-Button">
                  <div className="add-icon-activity-add">
                    <img className="icons-add" src={addIcon} alt="icon" />
                    <div className="add-text">Add Venue Type</div>
                  </div>
                </Button>
              }
              className="create-venue-type"
            >
              <Modal.Header>Create Venue Type</Modal.Header>
              <Modal.Content>
                <CreateVenueForm
                  toggle={() => this.toggle()}
                  {...this.props}
                  postVenueTypes={postVenueTypes}
                  isEdit={false}
                />
              </Modal.Content>
            </Modal>
          </div>
        </div>
        <div className="industry-list-container">
          {!isEmpty(venueTypes) && !isEmpty(orgVenueTypes) && (
            <div className="accordion-container">
              <div className="gap-between-windows-help">
                <VenueList {...this.props} dataSource={orgVenueTypes} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
class EditVenueModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      form: {
        name: '',
        description: '',
        isOnline: '',
      },
    };
  }

  editSelectedVenue(form) {
    this.setState({
      form,
    });
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  render() {
    const { modal, form } = this.state;
    const { item } = this.props;
    return (
      <Modal
        open={modal}
        onOpen={() => this.toggle()}
        closeIcon={
          <Icon
            onClick={() => this.toggle()}
            name="close"
            className="closeicon"
          />
        }
        size="small"
        trigger={
          <label
            className="activity-actions-warn mb-0"
            onClick={() => this.editSelectedVenue(item)}
          >
            Edit
          </label>
        }
        className="edit-venue-type"
      >
        <Modal.Header>Edit Venue Type</Modal.Header>
        <Modal.Content>
          <CreateVenueForm
            {...this.props}
            form={form}
            toggle={() => this.toggle()}
            isEdit={true}
          />
        </Modal.Content>
      </Modal>
    );
  }
}
class VenueList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'Name',
          render: (data) => {
            return <div className="venue-name">{data.name}</div>;
          },
          width: 9,
        },
        {
          title: 'Created Date',
          render: (data) => {
            return <div>{moment(data.createdAt).format('ll hh:mm A')}</div>;
          },
          width: 3,
        },
        {
          title: 'Updated Date',
          render: (data) => {
            return <div>{moment(data.updatedAt).format('ll hh:mm A')}</div>;
          },
          width: 3,
        },
        {
          title: '',
          render: (data) => {
            return (
              <div className="venue-edit">
                <EditVenueModal item={data} {...this.props} {...this.props} />
              </div>
            );
          },
          textAlign: isBrowser || isTablet ? 'center' : null,
          width: 1,
        },
      ],
      currentPage: 1,
    };
  }

  render() {
    const { vLoading, dataSource } = this.props;
    const { columns } = this.state;

    return (
      <Segment
        className="content-segment"
        loading={vLoading}
        disabled={vLoading}
      >
        <CustomTable
          header
          customClass="super-admin-table tbody-hover"
          columns={columns}
          data={dataSource}
          emptyMessage="No tooltips"
          keyExtractor={(item, index) => item.id}
        />
      </Segment>
    );
  }
}

const CreateVenueForm = ({
  form,
  toggle,
  venueTypeLoading,
  venueTypes,
  isEdit,
  updateVenueType,
  postVenueTypes,
}) => {
  const [isOnline, setIsOnline] = useState(false);
  const [subOptionsArr, setSubOptionsArr] = useState(
    !isEdit
      ? [
          {
            key: 1,
            name: '',
          },
        ]
      : []
  );
  const [subOptionsArrLength, setSubOptionsArrLength] = useState(
    !isEdit ? 1 : 0
  );
  useEffect(() => {
    if (form) {
      const options = form.venueSubOptions.map((item, key) => {
        return {
          key,
          id: item.id,
          name: item.name,
        };
      });
      setSubOptionsArr(
        !isEdit
          ? [
              {
                key: 1,
                name: '',
              },
            ]
          : options
      );
    }
  }, []);
  const handleAddNewSubOption = () => {
    setSubOptionsArr([
      ...subOptionsArr,
      {
        key: Math.random().toString(36).substr(2, 9),
        name: '',
      },
    ]);
    setSubOptionsArrLength(subOptionsArrLength + 1);
  };

  const onSubmit = async (values) => {
    const updatedValues = {
      name: values.name,
      description: values.description,
    };
    try {
      if (isEdit) {
        let emptyNameInItems;
        subOptionsArr.forEach((item) => {
          if (item.name.trim() === '') {
            emptyNameInItems = true;
          }
        });
        if (emptyNameInItems) {
          return authMsg('error', 'Option cannot be empty.');
        } else {
          const venueOptions = subOptionsArr;
          updatedValues.venueOptions = venueOptions;
          const data = {
            ...updatedValues,
            isOnline: isOnline,
          };
          await updateVenueType(form.id, data);
        }
      } else {
        const venueOptions = subOptionsArr.map((item) => item.name.trim());
        if (venueOptions[0] !== '') {
          updatedValues.venueOptions = venueOptions;
        }
        await postVenueTypes(updatedValues);
      }
      toggle();
    } catch (error) {
      console.error('Venue Type Create Submit ~~', error);
    }
  };

  const onChangeFilter = () => {
    setIsOnline(!isOnline);
  };

  useEffect(() => {
    form && setIsOnline(form.isOnline);
  }, []);

  const onDeleteOption = (key) => {
    setSubOptionsArrLength(subOptionsArrLength - 1);
    const updatedSubOptionsArr = subOptionsArr.filter(
      (item) => item.key !== key
    );
    setSubOptionsArr(updatedSubOptionsArr);
  };

  const onChangeOptions = (data, key) => {
    const updatedValue = subOptionsArr.map((item) => {
      if (item.key === key) {
        return {
          key: key,
          id: item.id,
          name: data.trim(),
        };
      }
      return item;
    });
    setSubOptionsArr(updatedValue);
  };
  const deletingArrLength = !isEdit
    ? subOptionsArr.length
    : subOptionsArr.length;

  return (
    <Formik
      onSubmit={(values) => onSubmit(values)}
      initialValues={initialValues(form)}
      validationSchema={venueTypeValidation}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <div className="container">
            <CustomInput
              id="name"
              name="name"
              placeholder="Enter title"
              labelText="Title"
              requiredStar
            />

            {(!isEdit || subOptionsArr.length > 0) && (
              <div>
                <label>Venue Type Options</label>
              </div>
            )}

            {subOptionsArr.map((item, index) => {
              const key = `checkBoxOptions${item.key}`;
              const isExist = item.id > 0 ? true : false;

              return (
                <div className="d-flex add-venue-sub-label" key={key}>
                  {isEdit ? (
                    <div className="sub-options-container">
                      <Input
                        id={`venueOptions${item.key}`}
                        name={`venueOptions${item.key}`}
                        className="edit-venue-type-input"
                        placeholder=""
                        onChange={(e) =>
                          onChangeOptions(e.target.value, item.key)
                        }
                        value={item.name}
                        icon={
                          !isExist && deletingArrLength > 0 ? (
                            <div className="delete-btn">
                              <Icon
                                onClick={() => onDeleteOption(item.key)}
                                name="trash"
                              />
                            </div>
                          ) : null
                        }
                      />
                    </div>
                  ) : (
                    <CustomInput
                      id={`venueOptions${item.key}`}
                      name={`venueOptions${item.key}`}
                      placeholder=""
                      getValue={(e) => onChangeOptions(e, item.key)}
                      icon={
                        index !== 0 && deletingArrLength > 1 ? (
                          <div className="delete-btn">
                            <Icon
                              onClick={() => onDeleteOption(item.key)}
                              name="trash"
                            />
                          </div>
                        ) : null
                      }
                    />
                  )}
                </div>
              );
            })}
            <div className="d-flex align-items-end flex-column">
              <div>
                <Button
                  onClick={handleAddNewSubOption}
                  className="btn-add-ref"
                  type="button"
                >
                  <span>
                    <img className="plus-icon" alt="plus" src={plusOutline} />
                    <div className="add-button-text">Add More Options</div>
                  </span>
                </Button>
              </div>
            </div>
            <CustomTextArea
              id="description"
              name="description"
              placeholder="Enter sector type description"
              labelText="Description"
              requiredStar
            />
            {isEdit && (
              <CustomCheckbox
                className="check-box"
                id="isOnline"
                name="isOnline"
                label="This Venue does not have a street address"
                checked={isOnline}
                onChange={onChangeFilter}
              />
            )}
          </div>
          <Divider section />
          <div className="actions">
            <Button className="Secondary-Button" type="button" onClick={toggle}>
              Cancel
            </Button>
            <Button
              content={isEdit ? 'Update' : 'Save'}
              type="submit"
              className="Primary-Button"
              loading={venueTypeLoading}
              disabled={venueTypeLoading}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    orgVenueTypes: state.extra.orgVenueTypes,
    venueTypeLoading: state.admin.venueTypeLoading,
    venueTypeLimit: state.admin.venueTypeLimit,
    vLoading: state.admin.vLoading,
    venueTypes: state.extra.venueTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getVenueTypes,
      postVenueTypes,
      updateVenueType,
      getOrgVenuesTypes,
    },
    dispatch
  );
};

VenueTypes = connect(mapStateToProps, mapDispatchToProps)(VenueTypes);

export { VenueTypes };
