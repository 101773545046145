/* eslint-disable eqeqeq */
import axios from 'axios';
import { cloneDeep, get, isEmpty, isEqual } from '../../utils/lodash';
import { getCommonError, authMsg } from '../../utils';
import { NotificationManager } from 'react-notifications';
import { LIMIT } from '../../utils/constant';
import {
  getPolicies,
  getOutcomes,
  getEventDetails,
  getMethods,
  getEvidenceTypes,
  getActivityHelpTips,
  activityPlanTypes,
  festivalTypes,
} from '../actions';
import { getProjectOutcomeSummary } from './report';
import { isParentProgramActivity } from '../../utils/activity';
import { projectValidation } from '../../utils/projectValidation';

/*** TYPES ***/

const SET_ACT_OWN_LOADING = 'SET_ACT_OWN_LOADING';
const SET_OWN_ACT = 'SET_OWN_ACT';
const SET_ACT_INV_LOADING = 'SET_ACT_INV_LOADING';
const SET_INV_ACT = 'SET_INV_ACT';
const SET_NEW_INV_LOADING = 'SET_NEW_INV_LOADING';
const SET_NEW_INV = 'SET_NEW_INV';
const ACTIVITY_POST_LOADING = 'ACTIVITY_POST_LOADING';
const ACTIVITY_DELETE_LOADING = 'ACTIVITY_DELETE_LOADING';
const ACTIVITY_GET_LOADING = 'ACTIVITY_GET_LOADING';
const ACTIVITY_PUT_LOADING = 'ACTIVITY_PUT_LOADING';
const CLEAR_CURRENT_ACT = 'CLEAR_CURRENT_ACT';
export const SET_CURRENT_ACT = 'SET_CURRENT_ACT';
const SET_CURRENT_OBJ = 'SET_CURRENT_OBJ';
const SET_EVIDENCE_LOADING = 'SET_EVIDENCE_LOADING';
const SET_EVIDENCE_SEARCH = 'SET_EVIDENCE_SEARCH';
const GET_LINKED_ACTIVITIES = 'GET_LINKED_ACTIVITIES';
const LINKED_ACTIVITY_LOADING = 'LINKED_ACTIVITY_LOADING';
const LINKED_ACTIVITY__ACTIVATE_LOADING = 'LINKED_ACTIVITY__ACTIVATE_LOADING';
const LINKED_ACTIVITY_LOADING_DELETE = 'LINKED_ACTIVITY_LOADING_DELETE';
const EVALUATION_LOADER = 'EVALUATION_LOADER';

const LOADING_PROJECT_EVALUATION = 'LOADING_PROJECT_EVALUATION';
const GET_PROJECT_EVALUATION = 'GET_PROJECT_EVALUATION';

const SET_ORG_MEMBERS = 'SET_ORG_MEMBERS';
const SET_ACTIVITY_MEMBERS = 'SET_ACTIVITY_MEMBERS';

const GET_DOCUMENTS_LOADING = 'GET_DOCUMENTS_LOADING';
const GET_DOCUMENTS = 'GET_DOCUMENTS';

const DELETE_DOCUMENT_LOADING = 'DELETE_DOCUMENT_LOADING';
const PUT_DOCUMENT_LOADING = 'PUT_DOCUMENT_LOADING';
const UPDATE_DOCUMENT_LOADING = 'UPDATE_DOCUMENT_LOADING';

const GET_BROWSE_ACTIVITIES = 'GET_BROWSE_ACTIVITIES';

//responses which are taken by survey / forum
const SET_EVALUATION_RESPONSES_CSV = 'SET_EVALUATION_RESPONSES_CSV';
//responses which are imported/ collected by File(CSV)
const SET_EVALUATION_RESPONSES_BY_FILE = 'SET_EVALUATION_RESPONSES_BY_FILE';

const ACTIVITY_GET_REPORTS = 'ACTIVITY_GET_REPORTS';

const SET_PARENT_OBJ_LOADING = 'SET_PARENT_OBJ_LOADING';
const SET_PARENT_DOMAIN_LOADING = 'SET_PARENT_DOMAIN_LOADING';
const SET_SELECTED_GROUP = 'SET_SELECTED_GROUP';
const SET_EVALUATION_SELECTED_GROUP = 'SET_EVALUATION_SELECTED_GROUP';

// const SET_EVALUATION_PLAN_GROUP = 'SET_EVALUATION_PLAN_GROUP';

const SELECTED_GROUP_DEL_LOADING = 'SELECTED_GROUP_DEL_LOADING';
const SELECTED_GROUP_PARTICIPANT_LOADING = 'SELECTED_GROUP_PARTICIPANT_LOADING';

const EVALUATION_PLAN_LOADER = 'EVALUATION_PLAN_LOADER';
const SET_PROJECT_GROUP = 'SET_PROJECT_GROUP';
const SET_PROJECT_GROUP_LOADING = 'SET_PROJECT_GROUP_LOADING';
const DUPLICATE_ACTIVITY_LOADING = 'DUPLICATE_ACTIVITY_LOADING';
const GET_ALL_GOALS = 'GET_ALL_GOALS';
const SET_ACTIVITY_GROUP_LOADING = 'SET_ACTIVITY_GROUP_LOADING';
const DELETE_PROJECT_GROUP_LOADING = 'DELETE_PROJECT_GROUP_LOADING';
const SET_PROJECT_GROUP_RENAME_LOADING = 'SET_PROJECT_GROUP_RENAME_LOADING';

const GET_INDIVIDUAL_PROJECT_LOADING = 'GET_INDIVIDUAL_PROJECT_LOADING';

const GET_INDIVIDUAL_PROJECT = 'GET_INDIVIDUAL_PROJECT';
// const SET_PARENT_OBJ = 'SET_PARENT_OBJ';

const GET_EVAL_COMMENTS_DATA_LOADING = 'GET_EVAL_COMMENTS_DATA_LOADING';
const GET_EVAL_COMMENTS_DATA = 'GET_EVAL_COMMENTS_DATA';

const LINKED_ACTIVITY_ACTIVATED_LOADING = 'LINKED_ACTIVITY_ACTIVATED_LOADING';
const LINKED_ACTIVITY_ACTIVATED = 'LINKED_ACTIVITY_ACTIVATED';

const FIND_SYSTEM_USER_LOADING = 'FIND_SYSTEM_USER_LOADING';
const FIND_SYSTEM_USER = 'FIND_SYSTEM_USER';
const CLEAR_SYSTEM_USER = 'CLEAR_SYSTEM_USER';
const LINKED_ACTIVITY_UPDATE_LOADING = 'LINKED_ACTIVITY_UPDATE_LOADING';
const REINVITE_WITHDRAWN_LOADING = 'REINVITE_WITHDRAWN_LOADING';
const ADD_SERIES_SUBTITLE_LOADING = 'ADD_SERIES_SUBTITLE_LOADING';
const DELETE_SERIES_SUBTITLE_LOADING = 'DELETE_SERIES_SUBTITLE_LOADING';
const SET_PROGRAM_INCLUDED_REPORTS = 'SET_PROGRAM_INCLUDED_REPORTS';
const DELETE_PROGRAM_INCLUDED_REPORTS_LOADING =
  'DELETE_PROGRAM_INCLUDED_REPORTS_LOADING';
const SET_PROGRAM_INCLUDED_REPORTS_LOADING =
  'SET_PROGRAM_INCLUDED_REPORTS_LOADING';
const SET_PROJECT_CROSS_REF_DOCUMENT = 'SET_PROJECT_CROSS_REF_DOCUMENT';
const SET_PROJECT_CROSS_REF_LOADING = 'SET_PROJECT_CROSS_REF_LOADING';
const SET_PROJECT_CROSS_REF = 'SET_PROJECT_CROSS_REF';
const PROJECT_GOALS_REFERENCES = 'PROJECT_GOALS_REFERENCES';
const SET_EVALUATION_SELF_ASSESS_LOADING = 'SET_EVALUATION_SELF_ASSESS_LOADING';

const SET_PROJECT_NOTE_LOADING = 'SET_PROJECT_NOTE_LOADING';
const GET_PROJECT_NOTE_LOADING = 'GET_PROJECT_NOTE_LOADING';
const DELETE_PROJECT_NOTE_LOADING = 'DELETE_PROJECT_NOTE_LOADING';

/*** REDUCERS ***/

const initialState = {
  ownLoading: false,
  invLoading: false,
  newInvLoading: false,
  ownActivities: {},
  invitedActivities: {},
  invitations: {},
  actPostLoading: false,
  currentActivity: {},
  actGetLoading: false,
  actPut: {},
  currentObjective: [],
  evaluationLoading: false,
  evaluations: [],
  evidenceLoading: false,
  evidenceSearch: [],
  orgMembers: [],
  getDocumentsLoading: false,
  documents: [],
  deleteDocumentsLoading: false,
  postDocumentsLoading: false,
  updateDocumentsLoading: false,
  imageGenLoading: false,
  getReportsLoading: {},
  actMembers: [],
  allActivities: {},
  evaluationResponsesCsvLink: {},
  linkedActivities: [],
  linkedActivitiesLoading: false,
  deleteLinkedActivitiesLoading: false,
  parentOutcomesLoading: false,
  parentDomainsLoading: false,
  participantGroupDeleteLoading: false,
  groupParticipantLoading: false,
  evaluationPlans: [],
  evaluationPlansLoading: false,
  evalPlanLoading: false,
  // parentOutcomes: [],
  duplicateActLoading: false,
  goals: {},
  projectGroup: {},
  projectGroupLoading: false,
  groupAddLoading: false,
  deleteProjectGroupLoading: false,
  groupRenameLoading: false,
  individualProjectExport: {},
  individualProjectExportLoading: false,
  evalComments: {},
  evalCommentsLoading: false,
  activatedLinkedAct: {},
  activatedLinkedActLoading: false,
  findSystemUser: {},
  findSystemUserLoading: false,
  linkedUpdateLoading: false,
  reinviteWithdrawnLoading: false,
  addSeriesSubtitleLoading: false,
  programIncludedReports: [],
  projectCrossReferences: [],
  projectCrossReferencesLoading: false,
  projectGoalsReferences: false,
  evaluationSelfAssessLoading: false,
  projectNotes: [],
  postProjectNoteLoading: false,
  deleteProjectNoteLoading: false,
  parentReportOutcomeAddressedLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_CURRENT_ACT:
      return { ...state, currentActivity: {} };
    case SET_NEW_INV_LOADING:
      return { ...state, newInvLoading: action.payload };
    case SET_NEW_INV:
      return {
        ...state,
        invitations: action.payload ? action.payload : [],
      };
    case SET_ACT_OWN_LOADING:
      return { ...state, ownLoading: action.payload };
    case SET_OWN_ACT:
      return {
        ...state,
        ownActivities: action.payload ? action.payload : [],
      };
    case SET_ACT_INV_LOADING:
      return { ...state, invLoading: action.payload };

    case SET_SELECTED_GROUP:
      return { ...state, selectedGroup: action.payload };

    case SELECTED_GROUP_PARTICIPANT_LOADING:
      return { ...state, groupParticipantLoading: action.payload };
    case SET_EVALUATION_SELECTED_GROUP:
      return { ...state, evaluationGroupSelected: action.payload };
    // case SET_EVALUATION_PLAN_GROUP:
    //   return { ...state, evaluationPlans: action.payload };
    case SELECTED_GROUP_DEL_LOADING:
      return { ...state, participantGroupDeleteLoading: action.payload };
    case EVALUATION_LOADER:
      return { ...state, evaluationPlansLoading: action.payload };

    case ACTIVITY_GET_REPORTS:
      return {
        ...state,

        getReportsLoading: {
          [action.payload.type]: action.payload.loading,
        },
      };
    case SET_INV_ACT:
      return {
        ...state,
        invitedActivities: action.payload ? action.payload : [],
      };
    case ACTIVITY_POST_LOADING:
      return { ...state, actPostLoading: action.payload };
    case ACTIVITY_DELETE_LOADING:
      return { ...state, ownLoading: action.payload };
    case ACTIVITY_GET_LOADING:
      return { ...state, actGetLoading: action.payload };
    case ACTIVITY_PUT_LOADING:
      return {
        ...state,
        actPut: {
          [action.payload.type]: action.payload.status,
        },
      };
    case SET_CURRENT_ACT:
      return {
        ...state,
        currentActivity: action.payload ? action.payload : {},
      };
    case SET_CURRENT_OBJ:
      return {
        ...state,
        currentObjective: action.payload ? action.payload : [],
      };
    case GET_PROJECT_EVALUATION:
      return { ...state, evaluations: action.payload };
    case LOADING_PROJECT_EVALUATION:
      return { ...state, evaluationLoading: action.payload };
    case SET_EVIDENCE_LOADING:
      return { ...state, evidenceLoading: action.payload };
    case SET_EVIDENCE_SEARCH:
      return { ...state, evidenceSearch: action.payload };
    case SET_ORG_MEMBERS:
      return { ...state, orgMembers: action.payload };
    case GET_DOCUMENTS_LOADING:
      return { ...state, getDocumentsLoading: action.payload };
    case GET_DOCUMENTS:
      return { ...state, documents: action.payload };
    case DELETE_DOCUMENT_LOADING:
      return { ...state, deleteDocumentsLoading: action.payload };
    case PUT_DOCUMENT_LOADING:
      return { ...state, postDocumentsLoading: action.payload };
    case UPDATE_DOCUMENT_LOADING:
      return { ...state, updateDocumentsLoading: action.payload };
    case SET_ACTIVITY_MEMBERS:
      return { ...state, actMembers: action.payload };
    case GET_BROWSE_ACTIVITIES:
      return { ...state, allActivities: action.payload };
    case LINKED_ACTIVITY_UPDATE_LOADING:
      return { ...state, linkedUpdateLoading: action.payload };

    case SET_EVALUATION_RESPONSES_BY_FILE:
      return { ...state, evaluationResponsesCsvLink: action.payload };
    case SET_EVALUATION_RESPONSES_CSV:
      return { ...state, evaluationResponsesCsvLink: action.payload };
    case GET_LINKED_ACTIVITIES:
      return { ...state, linkedActivities: action.payload };
    case LINKED_ACTIVITY_LOADING:
      return { ...state, linkedActivitiesLoading: action.payload };
    case LINKED_ACTIVITY__ACTIVATE_LOADING:
      return { ...state, linkedActivitiesActivateLoading: action.payload };
    case SET_PARENT_OBJ_LOADING:
      return { ...state, parentOutcomesLoading: action.payload };
    case SET_PARENT_DOMAIN_LOADING:
      return { ...state, parentDomainsLoading: action.payload };
    case EVALUATION_PLAN_LOADER:
      return { ...state, evalPlanLoading: action.payload };
    case SET_PROJECT_GROUP_LOADING:
      return { ...state, projectGroupLoading: action.payload };

    case SET_PROJECT_GROUP:
      return { ...state, projectGroup: action.payload };
    case DUPLICATE_ACTIVITY_LOADING:
      return { ...state, duplicateActLoading: action.payload };

    case GET_ALL_GOALS:
      return { ...state, goals: action.payload };
    case SET_ACTIVITY_GROUP_LOADING:
      return { ...state, groupAddLoading: action.payload };
    case LINKED_ACTIVITY_LOADING_DELETE:
      return { ...state, deleteLinkedActivitiesLoading: action.payload };

    case DELETE_PROJECT_GROUP_LOADING:
      return { ...state, deleteProjectGroupLoading: action.payload };
    case SET_PROJECT_GROUP_RENAME_LOADING:
      return { ...state, groupRenameLoading: action.payload };

    case GET_INDIVIDUAL_PROJECT:
      return { ...state, individualProjectExport: action.payload };
    case GET_INDIVIDUAL_PROJECT_LOADING:
      return { ...state, individualProjectExportLoading: action.payload };

    case GET_EVAL_COMMENTS_DATA:
      return { ...state, evalComments: action.payload };
    case GET_EVAL_COMMENTS_DATA_LOADING:
      return { ...state, evalCommentsLoading: action.payload };
    case LINKED_ACTIVITY_ACTIVATED:
      return { ...state, activatedLinkedAct: action.payload };
    case LINKED_ACTIVITY_ACTIVATED_LOADING:
      return { ...state, activatedLinkedActLoading: action.payload };
    case FIND_SYSTEM_USER_LOADING:
      return { ...state, findSystemUserLoading: action.payload };
    case FIND_SYSTEM_USER:
      return { ...state, findSystemUser: action.payload };
    case REINVITE_WITHDRAWN_LOADING:
      return { ...state, reinviteWithdrawnLoading: action.payload };

    case CLEAR_SYSTEM_USER:
      return {
        ...state,
        findSystemUser: {},
        findSystemUserLoading: false,
      };
    case ADD_SERIES_SUBTITLE_LOADING:
      return {
        ...state,
        addSeriesSubtitleLoading: action.payload,
      };
    case DELETE_SERIES_SUBTITLE_LOADING:
      return {
        ...state,
        deleteSeriesSubtitleLoading: action.payload,
      };
    case SET_PROGRAM_INCLUDED_REPORTS:
      return { ...state, programIncludedReports: action.payload };
    case DELETE_PROGRAM_INCLUDED_REPORTS_LOADING:
      return {
        ...state,
        deleteProgramIncludedLoading: action.payload,
      };
    case SET_PROGRAM_INCLUDED_REPORTS_LOADING:
      return {
        ...state,
        setProgramIncludedLoading: action.payload,
      };
    case SET_PROJECT_CROSS_REF_DOCUMENT:
      return { ...state, projectCrossRefDocument: action.payload };
    case SET_PROJECT_CROSS_REF:
      return { ...state, projectCrossReferences: action.payload };
    case SET_PROJECT_CROSS_REF_LOADING:
      return { ...state, projectCrossReferencesLoading: action.payload };
    case PROJECT_GOALS_REFERENCES:
      return { ...state, projectGoalsReferences: action.payload };
    case SET_EVALUATION_SELF_ASSESS_LOADING:
      return { ...state, evaluationSelfAssessLoading: action.payload };

    // project notes
    case GET_PROJECT_NOTE_LOADING:
      return { ...state, getProjectNoteLoading: action.payload };
    case SET_PROJECT_NOTE_LOADING:
      return { ...state, projectNoteLoading: action.payload }; // project notes
    case DELETE_PROJECT_NOTE_LOADING:
      return { ...state, deleteProjectNoteLoading: action.payload };

    default:
      return state;
  }
}

/*** ACTIONS ***/

export function getEvaluationGroups(projectId) {
  return async (dispatch) => {
    try {
      dispatch({ type: EVALUATION_LOADER, payload: true });

      const response = await axios.get(
        `project/${projectId}/targetGroups?type=ALL`
      );
      await dispatch({
        type: SET_EVALUATION_SELECTED_GROUP,
        payload: response.data,
      });
      dispatch({ type: EVALUATION_LOADER, payload: false });
    } catch (error) {
      //dispatch({ type: SET_NEW_INV, payload: [] });
      dispatch({ type: EVALUATION_LOADER, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getSelectedGroup(projectId, type = 'ALL') {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const currentActivity = cloneDeep(state.activity.currentActivity);
      dispatch({ type: SET_NEW_INV_LOADING, payload: true });
      const response = await axios.get(
        `project/${projectId}/targetGroups?type=${type}`
      );
      await dispatch({
        type: SET_SELECTED_GROUP,
        payload: response.data,
      });
      if (isEmpty(response.data)) {
        if (currentActivity.participantGroup) {
          dispatch(
            putActivity('projectedOutputs', {
              participantGroup: false,
            })
          );
        }
      }
    } catch (error) {
      dispatch({ type: SET_NEW_INV, payload: [] });
      dispatch({ type: SET_NEW_INV_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function postGroupSelected(values, id, func) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SELECTED_GROUP_PARTICIPANT_LOADING,
        payload: true,
      });
      await axios.post(`/project/${id}/targetGroup`, values);
      authMsg('success', [
        'Group has been successfully created!',
        'Participant Group',
      ]);
      dispatch(getSelectedGroup(id, 'GROUP'));
      dispatch({
        type: SELECTED_GROUP_PARTICIPANT_LOADING,
        payload: false,
      });
      func.resetGroupParticipant();
    } catch (error) {
      dispatch({
        type: SELECTED_GROUP_PARTICIPANT_LOADING,
        payload: false,
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteGroupSelected(groupId, projectId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SELECTED_GROUP_PARTICIPANT_LOADING,
        payload: true,
      });
      await axios.delete(`/project/${projectId}/targetGroup/${groupId}/delete`);
      authMsg('success', [
        'Group has been successfully deleted!',
        'Participant Group',
      ]);
      dispatch(getSelectedGroup(projectId, 'GROUP'));
      dispatch({
        type: SELECTED_GROUP_PARTICIPANT_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: SELECTED_GROUP_PARTICIPANT_LOADING,
        payload: false,
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateGroupSelected(form, id, projectId, func) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: SELECTED_GROUP_PARTICIPANT_LOADING,
        payload: true,
      });
      await axios.put(`/project/${projectId}/targetGroup/${id}`, form);
      authMsg('success', [
        'Group has been successfully Updated!',
        'Participant Group',
      ]);
      dispatch({
        type: SELECTED_GROUP_PARTICIPANT_LOADING,
        payload: false,
      });
      dispatch(getSelectedGroup(projectId, 'GROUP'));
      func.resetGroupParticipant();
    } catch (error) {
      dispatch({
        type: SELECTED_GROUP_PARTICIPANT_LOADING,
        payload: false,
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getEvaluationPlans(id, preview = false) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const currentActivity = cloneDeep(state.activity.currentActivity);
      if (!isParentProgramActivity(currentActivity.activityPlanTypeId)) {
        dispatch({ type: EVALUATION_PLAN_LOADER, payload: true });
        const response = await axios.get(`/project/${id}/eval-plans`);
        await dispatch(
          checkCompletionProgress({ evaluationPlans: response.data }, preview)
        );

        dispatch({ type: EVALUATION_PLAN_LOADER, payload: false });
      }
    } catch (error) {
      dispatch({ type: EVALUATION_PLAN_LOADER, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function postEvaluationPlan(id, values, func) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: SELECTED_GROUP_PARTICIPANT_LOADING,
          payload: true,
        });
        const response = await axios.post(`/project/${id}/eval-plan`, values);
        resolve(response);
        authMsg('success', [
          'Evaluation Plan has been successfully created!',
          'Evaluation Plan',
        ]);
        dispatch(getEvaluationPlans(id));
        dispatch({
          type: SELECTED_GROUP_PARTICIPANT_LOADING,
          payload: false,
        });
        func.success();
      } catch (error) {
        dispatch({
          type: SELECTED_GROUP_PARTICIPANT_LOADING,
          payload: false,
        });
        authMsg('error', getCommonError(error));
        const status = get(error, 'response.status', null);
        if (status === 409) {
          func.errorFound();
        }
      }
    });
  };
}

export function updateEvaluationPlan(id, evalPlanId, values, func) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SELECTED_GROUP_PARTICIPANT_LOADING,
        payload: true,
      });
      await axios.put(`/project/${id}/eval-plan/${evalPlanId}`, values);
      authMsg('success', [
        'Evaluation Plan been successfully Updated!',
        'Evaluation Plan',
      ]);
      dispatch({
        type: SELECTED_GROUP_PARTICIPANT_LOADING,
        payload: false,
      });
      dispatch(getEvaluationPlans(id));
      if (func && func.success) {
        func.success();
      }
      if (func && func.modalStatus) {
        func.modalStatus(false);
      }
    } catch (error) {
      dispatch({
        type: SELECTED_GROUP_PARTICIPANT_LOADING,
        payload: false,
      });

      // setErrorResponseModal & setErrorConflictModal func's passed from EvaluationPlanMeasure component
      if (func && func.setErrorResponseModal && func.setErrorConflictModal) {
        const status = get(error, 'response.status', null);
        // get if the participant responses exist in the evaluation plan -> Status 409
        if (status === 409) {
          func.setErrorConflictModal(true);
        }
        // get if the evaluation plan has duplicate -> Status 412
        if (status === 412) {
          func.setErrorResponseModal(true);
        }
      } else {
        authMsg('error', getCommonError(error));
        if (func) {
          func.forceFormReset();
        }
      }
    }
  };
}
export function updateEvaluationPlanName(id, evalPlanId, values) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SELECTED_GROUP_PARTICIPANT_LOADING,
        payload: true,
      });
      let msg = await axios.put(
        `/project/${id}/eval-plan-name/${evalPlanId}`,
        values
      );

      authMsg('success', [msg.data.message, 'Evaluation Plan']);
      dispatch({
        type: SELECTED_GROUP_PARTICIPANT_LOADING,
        payload: false,
      });
      dispatch(getEvaluationPlans(id));
    } catch (error) {
      dispatch({
        type: SELECTED_GROUP_PARTICIPANT_LOADING,
        payload: false,
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteEvaluationPlan(id, evalPlanId, isSeries) {
  return async (dispatch) => {
    try {
      dispatch({ type: EVALUATION_LOADER, payload: true });

      let payload = {
        isSeries,
      };
      await axios.delete(`/project/${id}/eval-plan/${evalPlanId}`, {
        params: payload,
      });

      authMsg('success', [
        'Evaluation Plan has been successfully deleted!',
        'Evaluation Plan',
      ]);
      dispatch(getEvaluationPlans(id));
      dispatch({ type: EVALUATION_LOADER, payload: false });
    } catch (error) {
      dispatch({ type: EVALUATION_LOADER, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateSeriesSubtitle(projectId, evalPlanId, subtitle) {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_SERIES_SUBTITLE_LOADING, payload: true });

      await axios.put(
        `/project/${projectId}/eval-plan/${evalPlanId}/series-subtile`,
        { subtitle }
      );

      authMsg('success', ['Evaluation Plan has been successfully Updated!']);
      dispatch(getEvaluationPlans(projectId));
      dispatch({ type: ADD_SERIES_SUBTITLE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: ADD_SERIES_SUBTITLE_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteSeriesSubtitle(projectId, evalPlanId) {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_SERIES_SUBTITLE_LOADING, payload: true });
      await axios.delete(
        `/project/${projectId}/eval-plan/${evalPlanId}/series-subtile`
      );
      authMsg('success', ['Subtitle has been successfully deleted!']);
      dispatch(getEvaluationPlans(projectId));
      dispatch({ type: DELETE_SERIES_SUBTITLE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: DELETE_SERIES_SUBTITLE_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function evalPlanManage(
  projectId,
  evalId,
  values,
  evalPlanName = null,
  handlerEvaluationComplete = null
) {
  return async (dispatch) => {
    try {
      let res = await axios.put(
        `/project/${projectId}/eval-plan/${evalId}/manage`,
        values
      );

      if (values.isSeries) {
        res.data.result.inSeries && res.data.result.isAllObjectivesCompleted
          ? authMsg('success', [
              `${evalPlanName} has been successfully closed and next plan in the series is now active!`,
            ])
          : res.data.result.isAllObjectivesCompleted
            ? authMsg('success', [
                `Final evaluation plan is successfully closed.`,
              ])
            : authMsg('success', [
                'Evaluation Plan has been successfully Updated!',
              ]);
      } else {
        // authMsg('success', ['Evaluation Plan has been successfully Updated!']);
      }
      handlerEvaluationComplete && handlerEvaluationComplete(false);
      dispatch(getEvaluationPlans(projectId));
    } catch (error) {
      handlerEvaluationComplete && handlerEvaluationComplete(true);
      authMsg('error', getCommonError(error));
    }
  };
}

export function evalPlanManageComplete(
  projectId,
  evalId,
  values,
  evalPlanName = null,
  handlerEvaluationComplete = null
) {
  return async (dispatch) => {
    try {
      let res = await axios.put(
        `/project/${projectId}/eval-plan/${evalId}/manage/complete`,
        values
      );

      if (values.isSeries) {
        res.data.result.inSeries && res.data.result.isAllObjectivesCompleted
          ? authMsg('success', [
              `${evalPlanName} has been successfully closed and next plan in the series is now active!`,
            ])
          : res.data.result.isAllObjectivesCompleted
            ? authMsg('success', [
                `Final evaluation plan is successfully closed.`,
              ])
            : authMsg('success', [
                'Evaluation Plan has been successfully Updated!',
              ]);
      } else {
        authMsg('success', ['Evaluation Plan has been successfully Updated!']);
      }

      // TODO: Need to Re think this code
      handlerEvaluationComplete && handlerEvaluationComplete(false);
      dispatch(getEvaluationPlans(projectId));
    } catch (error) {
      handlerEvaluationComplete && handlerEvaluationComplete(true);
      authMsg('error', getCommonError(error));
    }
  };
}

export function evaluationPlanTransformSelfAssess(
  id,
  evalPlanId,
  values,
  func
) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SELECTED_GROUP_PARTICIPANT_LOADING,
        payload: true,
      });
      let res = await axios.put(
        `/project/${id}/eval-plan/${evalPlanId}/transform/self-assess`,
        values
      );
      dispatch({
        type: SELECTED_GROUP_PARTICIPANT_LOADING,
        payload: false,
      });

      /*
       * seriesNoConflicts - only for checking series have conflicts [exclude normal plans]
       * this variable used for getting approval model for series plan only
       */
      let seriesNoConflicts = false;
      if (res.data.result) {
        seriesNoConflicts = res.data.result.seriesNoConflicts;
      }

      if (seriesNoConflicts && func && func.setErrorConflictModal) {
        func.setSeriesConflict(true);
        func.setErrorConflictModal(true);
      } else {
        authMsg('success', [
          'Evaluation Plan been successfully Updated!',
          'Evaluation Plan',
        ]);

        Promise.all([
          dispatch(getEvaluationPlans(id)),
          dispatch(getProjectOutcomeSummary(id)), // get updates to report
        ]);

        if (func && func.success) {
          // func.success();
        }
        if (func && func.modalStatus) {
          func.modalStatus(false);
        }
        if (func && func.setNoChecked && !seriesNoConflicts) {
          func.setNoChecked(true);
        }
      }
    } catch (error) {
      dispatch({
        type: SELECTED_GROUP_PARTICIPANT_LOADING,
        payload: false,
      });

      if (func && func.setNoChecked) {
        func.setNoChecked(false);
      }
      // setErrorResponseModal & setErrorConflictModal func's passed from EvaluationPlanMeasure component
      if (func && func.setErrorResponseModal && func.setErrorConflictModal) {
        const status = get(error, 'response.status', null);
        // get if the participant responses exist in the evaluation plan -> Status 409
        if (status === 409) {
          func.setErrorConflictModal(true);
        }
        // get if the evaluation plan has duplicate -> Status 412
        if (status === 412) {
          func.setErrorResponseModal(true);
        }
      } else {
        authMsg('error', getCommonError(error));
        if (func) {
          func.forceFormReset();
        }
      }
    }
  };
}

export function evaluationPlanManageDisable(
  projectId,
  evalId,
  values,
  evalPlanName = null,
  handlerEvaluationComplete = null
) {
  return async (dispatch) => {
    try {
      let res = await axios.put(
        `/project/${projectId}/eval-plan/${evalId}/manage/disable`,
        values
      );

      if (values.isSeries) {
        res.data.result.inSeries
          ? authMsg('success', [
              `${evalPlanName} has been successfully closed and next plan in the series is now active!`,
            ])
          : authMsg('success', [
              `Final evaluation plan is successfully closed.`,
            ]);
      } else {
        authMsg('success', ['Evaluation Plan has been successfully Updated!']);
      }

      handlerEvaluationComplete && handlerEvaluationComplete(false);
      dispatch(getEvaluationPlans(projectId));
    } catch (error) {
      handlerEvaluationComplete && handlerEvaluationComplete(true);
      authMsg('error', getCommonError(error));
    }
  };
}

export function evalPlanCollectionMethodManage(projectId, evalId, values) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.put(
          `/project/${projectId}/eval-plan/${evalId}/manage/data-collection`,
          values
        );
        authMsg('success', ['Evaluation Plan has been successfully Updated!']);
        dispatch(getEvaluationPlans(projectId));
        resolve(true);
      } catch (error) {
        authMsg('error', getCommonError(error));
        reject(false);
      }
    });
  };
}

export function evalManage(projectId, evalId, objectiveId, body) {
  return async (dispatch) => {
    try {
      await axios.put(
        `/project/${projectId}/eval-plan/${evalId}/objective/${objectiveId}`,
        body
      );
      authMsg('success', ['Evaluation Plan been successfully Updated!']);
      dispatch(getEvaluationPlans(projectId));
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getAllInviteActivities(invitedType) {
  return (dispatch) => {
    Promise.all([
      dispatch(getInvitations()),
      dispatch(getInvitedActivities(invitedType)),
    ]);
  };
}

export function acceptInvitation(projectId, accept) {
  return async (dispatch, getState) => {
    const state = getState();
    const invitedType = get(
      state.authentication.user,
      'options.activity-filter.invited',
      'all'
    );
    try {
      await axios.post(`/project/${projectId}/accept-invite`, {
        accept,
      });
      if (accept) {
        authMsg('success', 'Activity has been successfully accepted!');
      } else {
        authMsg('success', 'Activity has been successfully rejected!');
      }
      dispatch(getAllInviteActivities(invitedType));
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getInvitations(page = 1, limit = LIMIT) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_NEW_INV_LOADING, payload: true });
      const response = await axios.get(`/projects/new-invited`, {
        params: {
          limit,
          page,
        },
      });
      await dispatch({ type: SET_NEW_INV, payload: response.data });
      dispatch({ type: SET_NEW_INV_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_NEW_INV, payload: [] });
      dispatch({ type: SET_NEW_INV_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOwnActivities(
  status = 'all',
  type = 'all',
  group = 'null',
  page = 1,
  limit = LIMIT,
  attributeName,
  order = 'desc',
  searchQuery
) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ACT_OWN_LOADING, payload: true });

      const response = await axios.get(`/projects/owned`, {
        params: {
          limit,
          page,
          status,
          type,
          group,
          attributeName,
          order,
          searchQuery,
        },
      });

      dispatch({ type: SET_OWN_ACT, payload: response.data });
      dispatch({ type: SET_ACT_OWN_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_OWN_ACT, payload: [] });
      dispatch({ type: SET_ACT_OWN_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getAllGoals() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/goals`);
      dispatch({ type: GET_ALL_GOALS, payload: response.data });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getInvitedActivities(
  status = 'all',
  type = 'all',
  page = 1,
  limit = LIMIT,
  attributeName,
  order = 'desc',
  searchQuery
) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ACT_INV_LOADING, payload: true });
      const response = await axios.get(`/projects/invited`, {
        params: {
          limit,
          page,
          status,
          type,
          attributeName,
          order,
          searchQuery,
        },
      });
      dispatch({ type: SET_INV_ACT, payload: response.data });
      dispatch({ type: SET_ACT_INV_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_INV_ACT, payload: [] });
      dispatch({ type: SET_ACT_INV_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function postActivity(data) {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const state = getState();
        data.projectId = state.survey.surveyData.projectId;
        dispatch({ type: ACTIVITY_POST_LOADING, payload: true });

        const response = await axios.post(`/project`, data);
        await dispatch({
          type: SET_CURRENT_ACT,
          payload: response.data,
        });
        dispatch({ type: ACTIVITY_POST_LOADING, payload: false });
        resolve(response.data.id);
      } catch (error) {
        dispatch({ type: ACTIVITY_POST_LOADING, payload: false });
        authMsg('error', getCommonError(error));
        reject(error);
      }
    });
  };
}

export function deleteActivity(actID, filter, actType, group, page) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTIVITY_DELETE_LOADING, payload: true });
      const { data, error } = await axios.delete(`/project/${actID}`);
      if (!error) {
        authMsg('success', data.message);
        await dispatch(getOwnActivities(filter, actType, group, page));
        dispatch({ type: ACTIVITY_DELETE_LOADING, payload: false });
      } else {
        authMsg('error', error.message);
      }
    } catch (error) {
      dispatch({ type: ACTIVITY_DELETE_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getOrgMembers(keyword) {
  return async (dispatch, getState) => {
    const state = getState();
    if (state.authentication.user.role !== 'guest') {
      try {
        const response = await axios.get(
          `/organisation/members${keyword ? `?keyword=${keyword}` : ``}`
        );
        dispatch({ type: SET_ORG_MEMBERS, payload: response.data });
      } catch (error) {
        authMsg('error', getCommonError(error));
      }
    }
  };
}

export function getActivityMembers(actID) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type: 'activityMembers', status: true },
      });
      const response = await axios.get(`/project/${actID}/members`);
      dispatch({ type: SET_ACTIVITY_MEMBERS, payload: response.data });
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type: 'activityMembers', status: false },
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type: 'activityMembers', status: false },
      });
    }
  };
}

export function addActivityMember(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type: 'activityMembers', status: true },
      });
      await axios.post(`/project/${data.projectId}/member`, data);
      dispatch(getActivityMembers(data.projectId));
      authMsg('success', [
        'Your new activity member has been successfully added!',
        'Activity Member',
      ]);
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type: 'activityMembers', status: false },
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type: 'activityMembers', status: false },
      });
    }
  };
}

export function updateActivityMember({ userId, projectId }, permissions) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type: 'activityMembers', status: true },
      });
      axios
        .put(`/project/${projectId}/member/${userId}`, {
          permissions,
        })
        .then(() => {
          dispatch({
            type: ACTIVITY_PUT_LOADING,
            payload: { type: 'activityMembers', status: false },
          });
          dispatch(getActivityMembers(projectId));
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: ACTIVITY_PUT_LOADING,
            payload: { type: 'activityMembers', status: false },
          });
          reject();
        });
    });
  };
}

export function deleteActivityMember({ userId, projectId }, data) {
  return async (dispatch) => {
    try {
      await axios.delete(`/project/${projectId}/member/${userId}/delete`, {
        data,
      });
      dispatch(getActivityMembers(projectId));
    } catch (error) {
      throw error;
    }
  };
}

export function getActPreviewData(id) {
  return async (dispatch) => {
    try {
      await dispatch(setCurrentActivityPreview(id));
      Promise.all([
        dispatch(activityPlanTypes()),
        dispatch(getEvidenceTypes()),
        dispatch(getEventDetails()),
        dispatch(getOutcomes()),
        dispatch(festivalTypes()),
        // dispatch(getParentHeadlineData(id)),
      ]);
    } catch (error) {
      // authMsg('error', getCommonError(error));
    }
  };
}

export function getActParticipantData(id) {
  return (dispatch) => {
    try {
      Promise.all([
        dispatch(getActivityMembers(id)),
        dispatch(setCurrentActivity(id)),
        dispatch(getActivityHelpTips()),
      ]);
    } catch (error) {
      // authMsg('error', getCommonError(error));
    }
  };
}

export function getAllActivityData(id) {
  return async (dispatch) => {
    try {
      await dispatch(setCurrentActivity(id));
      Promise.all([
        dispatch(activityPlanTypes()),
        dispatch(getPolicies()),
        dispatch(getOutcomes()),
        dispatch(getEventDetails()),
        dispatch(festivalTypes()),
        dispatch(setObjective(id)),
        dispatch(getActivityMembers(id)),
        dispatch(getLinkedActivities(id)),
        dispatch(getMethods()),
        dispatch(getEvidenceTypes()),
        dispatch(getActivityHelpTips()),
      ]);
    } catch (error) {
      // authMsg('error', getCommonError(error));
    }
  };
}

export function clearCurrentActivity() {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      await dispatch({ type: CLEAR_CURRENT_ACT });
      resolve();
    });
  };
}

export function setCurrentActivityPreview(actID) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTIVITY_GET_LOADING, payload: true });
      const response = await axios.get(`/project/${actID}/preview`);
      await dispatch({ type: SET_CURRENT_ACT, payload: response.data });
      await dispatch(getEvaluationPlans(actID, true)); //set preview to true
      dispatch({ type: ACTIVITY_GET_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: ACTIVITY_GET_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function setCurrentActivity(id, ignoreLoading = false) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTIVITY_GET_LOADING, payload: !ignoreLoading });
      const response = await axios.get(`/project/${id}`);
      await dispatch({ type: SET_CURRENT_ACT, payload: response.data });
      await dispatch(getEvaluationPlans(id));
      dispatch({ type: ACTIVITY_GET_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: ACTIVITY_GET_LOADING, payload: false });
      authMsg('error', getCommonError(error));
      throw error;
    }
  };
}

export function putActivity(type, data) {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const state = getState();
      const currentActivity = cloneDeep(state.activity.currentActivity);
      try {
        dispatch({
          type: ACTIVITY_PUT_LOADING,
          payload: { type, status: true },
        });

        const response = await axios.put(
          `/project/${state.activity.currentActivity.id}`,
          {
            data,
          }
        );

        await dispatch(setActivityState(response.data, true));
        const output = get(data, 'output', []);
        for (const outputItem of output) {
          if (outputItem.actualValue) {
            await dispatch(
              getProjectOutcomeSummary(state.activity.currentActivity.id)
            );
            break;
          }
        }

        dispatch({
          type: ACTIVITY_PUT_LOADING,
          payload: { type, status: false },
        });
        resolve(true);
      } catch (error) {
        data.completionProgress = currentActivity.completionProgress;
        authMsg('error', getCommonError(error));
        dispatch({
          type: ACTIVITY_PUT_LOADING,
          payload: { type, status: false },
        });
        reject(false);
      }
    });
  };
}

export function updateProjectInputs(type, projectId, formData) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type: type, status: true },
      });
      const response = await axios.put(`/project/${projectId}`, formData);
      dispatch(setActivityState(response.data, true));
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type: type, status: false },
      });
    } catch (error) {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function putActivityTheory(type, data, target) {
  return async (dispatch) => {
    dispatch({
      type: ACTIVITY_PUT_LOADING,
      payload: { type, status: true },
    });
    await axios
      .put(`/project/${data[target].projectId}/objective/${data[target].id}`, {
        tocDo: data[target].tocDo,
        tocKnown: data[target].tocKnown,
      })
      .then(function () {
        dispatch(checkCompletionProgress());
        dispatch({
          type: ACTIVITY_PUT_LOADING,
          payload: { type, status: false },
        });
      })
      .catch(function (error) {
        authMsg('error', getCommonError(error));
        dispatch({
          type: ACTIVITY_PUT_LOADING,
          payload: { type, status: false },
        });
      });
  };
}

export function setActivityState(data, updateAct = false) {
  return async (dispatch, getState) => {
    const state = getState();
    const currentActivity = cloneDeep(state.activity.currentActivity);
    let response = Object.assign(currentActivity, data);
    try {
      const completionProgress = projectValidation(response).completionProgress;
      response = Object.assign(response, { completionProgress });
      if (updateAct) {
        dispatch(
          putActivityStatus(currentActivity.id, {
            completionProgress: response.completionProgress,
          })
        );
      }
    } catch (error) {
      // console.error('setActivityState -> error', error);
    }
    await dispatch({ type: SET_CURRENT_ACT, payload: response });
  };
}

export function setObjective(actID, updateAct = false) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/project/${actID}/objectives`);
      await dispatch({ type: SET_CURRENT_OBJ, payload: response.data });
      dispatch(setActivityState({ objective: response.data }, updateAct));
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function putParentObjective(projectId, form) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_PARENT_OBJ_LOADING, payload: true });
      const response = await axios.put(
        `/project/${projectId}/parent-outcomes`,
        form
      );
      let updateAct = true;
      dispatch(setActivityState(response.data, updateAct));
      dispatch({ type: SET_PARENT_OBJ_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_PARENT_OBJ_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function putParentFestivalType(type, projectId, form) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });
      const response = await axios.put(
        `/project/${projectId}/parent-festival-types`,
        form
      );
      dispatch(setActivityState(response.data, true));
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    } catch (error) {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function putParentDomain(projectId, form) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_PARENT_DOMAIN_LOADING, payload: true });
      const response = await axios.put(
        `/project/${projectId}/parent-domains`,
        form
      );
      dispatch(setActivityState(response.data, true));
      dispatch({ type: SET_PARENT_DOMAIN_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_PARENT_DOMAIN_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function putObjective(type, outcome, position, resetForm) {
  return async (dispatch, getState) => {
    const state = getState();
    let objectives = state.activity.currentObjective;
    if (objectives[position]) {
      objectives[position] = Object.assign(objectives[position], outcome);
    } else {
      objectives.push(outcome);
    }

    try {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });
      const response = await axios.put(
        `/project/${state.activity.currentActivity.id}/objectives`,
        {
          objectives,
        }
      );
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      await dispatch({
        type: SET_CURRENT_OBJ,
        payload: response.data,
      });

      dispatch(
        checkCompletionProgress({ objective: response.data }, false, true)
      );
    } catch (error) {
      if (resetForm) {
        resetForm();
      }
      authMsg('error', getCommonError(error));
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    }
  };
}

export function getEvidenceSearch({ searchQuery, onlyOwnEvidence = false }) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_EVIDENCE_LOADING, payload: true });
      const response = await axios.get(
        `/evidenceSearch?keyword=${searchQuery}&onlyOwnEvidence=${onlyOwnEvidence}`
      );
      dispatch({ type: SET_EVIDENCE_SEARCH, payload: response.data });
      dispatch({ type: SET_EVIDENCE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_EVIDENCE_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function createProjectEvidence(type, formData) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const currentActivity = cloneDeep(state.activity.currentActivity);

      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });
      const response = await axios.post(`/evidence`, {
        ...formData,
        activityPlanTypeId: currentActivity.activityPlanTypeId,
      });
      let evidence = currentActivity.evidence;
      evidence.push(response.data);

      if (!isParentProgramActivity(currentActivity.activityPlanTypeId)) {
        let updateAct = true;
        dispatch(setObjective(formData.projectId, updateAct));
      }

      dispatch(checkCompletionProgress({ evidence: evidence }, false, true));

      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      NotificationManager.success(
        'Objective evidences has been successfully created!',
        'Activity Evidence'
      );
      return response.data;
    } catch (error) {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateProjectEvidence(type, projectId, evidenceId, formData) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const currentActivity = cloneDeep(state.activity.currentActivity);
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });

      await axios.put(`/evidence/${evidenceId}`, {
        ...formData,
        activityPlanTypeId: currentActivity.activityPlanTypeId,
      });

      if (!isParentProgramActivity(currentActivity.activityPlanTypeId)) {
        dispatch(setObjective(projectId));
      }

      await dispatch(setCurrentActivity(projectId, true));

      dispatch(checkCompletionProgress({}, false, true));

      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      NotificationManager.success(
        'Objective evidences has been successfully updated!',
        'Activity Evidence'
      );
    } catch (error) {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteObjectiveEvidence(type, projectId, evidenceId) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const currentActivity = cloneDeep(state.activity.currentActivity);
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });
      await axios.delete(`/project/${projectId}/evidence/${evidenceId}`, {
        data: {
          activityPlanTypeId: currentActivity.activityPlanTypeId,
        },
      });

      authMsg('success', [
        'Objective evidences has been successfully removed!',
        'Activity Evidence',
      ]);

      if (!isParentProgramActivity(currentActivity.activityPlanTypeId)) {
        dispatch(setObjective(projectId));
      }

      await dispatch(setCurrentActivity(projectId, true));

      dispatch(checkCompletionProgress({}, false, true));

      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    } catch (error) {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getDocuments(actID, grantee = false) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_DOCUMENTS, payload: [] });
      dispatch({ type: GET_DOCUMENTS_LOADING, payload: true });
      let params = {};
      if (grantee) {
        params.grantee = grantee;
      }
      const response = await axios.get(`/project/${actID}/documents`, {
        params,
      });
      dispatch({ type: GET_DOCUMENTS, payload: response.data });
      dispatch({ type: GET_DOCUMENTS_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: GET_DOCUMENTS_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function postDocument(reqData) {
  return async (dispatch) => {
    try {
      let formData = new FormData();
      formData.set('upload', reqData.upload);
      formData.append('description', get(reqData, 'description', ''));
      formData.append('location', get(reqData, 'location', ''));
      formData.append('date', get(reqData, 'date', ''));
      formData.append('urlText', get(reqData, 'urlText', ''));
      formData.append('title', get(reqData, 'title', ''));

      dispatch({ type: PUT_DOCUMENT_LOADING, payload: true });
      await axios.post(`/project/${reqData.projectId}/document`, formData);
      dispatch(getDocuments(reqData.projectId));
      dispatch({ type: PUT_DOCUMENT_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: PUT_DOCUMENT_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function postGranteeDocument(projectId, reqData, isIgnoreWarning) {
  return async (dispatch) => {
    try {
      let formData = new FormData();
      formData.set('csvFile', reqData.file);
      formData.append('description', get(reqData, 'description', ''));
      if (reqData.smartyGrant) {
        formData.append('smartyGrant', get(reqData, 'smartyGrant', false));
      }
      formData.set('isIgnoreWarning', isIgnoreWarning);

      dispatch({ type: PUT_DOCUMENT_LOADING, payload: true });
      const response = await axios.post(
        `/project/${projectId}/grantee-document`,
        formData
      );

      dispatch(getDocuments(projectId, true));
      dispatch(getLinkedActivities(projectId));
      NotificationManager.success(
        `${response.data.numberOfLinkedActivities} activities successfully created!`
      );

      dispatch({ type: PUT_DOCUMENT_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: PUT_DOCUMENT_LOADING, payload: false });
      throw error;
    }
  };
}

export function postProjectedLinkedList(projectId, values) {
  return async (dispatch) => {
    try {
      dispatch({ type: LINKED_ACTIVITY_LOADING, payload: true });
      const response = await axios.post(
        `/project/${projectId}/linked-list`,
        values
      );
      dispatch(getLinkedActivities(projectId));
      authMsg('success', [
        'New projected activity has been Added.',
        'Activity ',
      ]);
      dispatch({ type: LINKED_ACTIVITY_LOADING, payload: false });
      return response.data;
    } catch (error) {
      dispatch({ type: LINKED_ACTIVITY_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateDocument(actID, documentID, data, grantee = false) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_DOCUMENT_LOADING, payload: true });
      await axios.put(`/project/${actID}/document/${documentID}`, data);
      dispatch(getDocuments(actID, grantee));
      dispatch({ type: UPDATE_DOCUMENT_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: UPDATE_DOCUMENT_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteDocument(actID, documentID, grantee = false) {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_DOCUMENT_LOADING, payload: true });
      await axios.delete(`/project/${actID}/document/${documentID}`);
      dispatch(getDocuments(actID, grantee));
      dispatch({ type: DELETE_DOCUMENT_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: DELETE_DOCUMENT_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProjectVenue(type, projectId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });
      const response = await axios.get(`/project/${projectId}/venues`);

      dispatch(setActivityState({ venues: response.data }, true));

      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    } catch (error) {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function assignProjectVenue(type, projectId, formData) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });

      const venueData = get(formData, 'venue', {});
      if (!isEmpty(venueData)) {
        // deleting organisation id & createdBy  from payload
        formData.venue.hasOwnProperty('organisationId') &&
          delete formData.venue.organisationId;
        formData.venue.hasOwnProperty('createdBy') &&
          delete formData.venue.createdBy;
      }

      await axios.post(`/project/${projectId}/venue`, formData);
      authMsg('success', [
        'Project venues has been successfully added!',
        'Project Venues',
      ]);
      dispatch(getProjectVenue(type, projectId));

      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    } catch (error) {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteProjectVenue(type, projectId, venueId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });
      await axios.delete(`/project/${projectId}/venue/${venueId}/delete`);
      authMsg('success', [
        'Project venue has been successfully deleted!',
        'Project Venue',
      ]);
      dispatch(getProjectVenue(type, projectId));

      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    } catch (error) {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProjectContractor(type, projectId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });
      const response = await axios.get(`/project/${projectId}/contractors`);
      dispatch(setActivityState({ contractors: response.data }));
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    }
  };
}

export function assignProjectContractor(type, projectId, formData) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });

      const contractorData = get(formData, 'contractor', {});
      if (!isEmpty(contractorData)) {
        // deleting organisation id & createdBy  from payload
        formData.contractor.hasOwnProperty('organisationId') &&
          delete formData.contractor.organisationId;
        formData.contractor.hasOwnProperty('createdBy') &&
          delete formData.contractor.createdBy;
      }

      await axios.post(`/project/${projectId}/contractor`, formData);
      authMsg('success', [
        'Project contractor has been successfully added!',
        'Project Contractor',
      ]);
      dispatch(getProjectContractor(type, projectId));
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    } catch (error) {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteProjectContractor(type, projectId, contractorId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });
      await axios.delete(
        `/project/${projectId}/contractor/${contractorId}/delete`
      );
      authMsg('success', [
        'Project contractor has been successfully deleted!',
        'Project Contractor',
      ]);
      dispatch(getProjectContractor(type, projectId));
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    } catch (error) {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateProjectContractorCost(
  type,
  projectId,
  contractorId,
  formData
) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });
      await axios.put(
        `/project/${projectId}/contractor/${contractorId}`,
        formData
      );
      dispatch(getProjectContractor(type, projectId));
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    } catch (error) {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function postProjectEvaluations(dataSet, type) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });
      const response = await axios.put(
        `/project/${dataSet.projectId}/evaluation/${dataSet.objectiveId}/participant-response`,
        { participantResponse: dataSet.participantResponse }
      );
      dispatch(setActivityState(response.data));
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    } catch (error) {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function uploadActivityCsv(
  { projectId, evaluationPlanId, objectiveId },
  formData
) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `/project/${projectId}/eval-plan/${evaluationPlanId}/objective/${objectiveId}/document`,
        formData,
        { timeout: 120000 }
      );
      if (isEmpty(response.data.errors)) {
        try {
          Promise.all([
            dispatch(getEvaluationPlans(projectId)),
            dispatch(getProjectOutcomeSummary(projectId)), // get updates to report
          ]);
        } catch (error) {
          // authMsg('error', getCommonError(error));
        }
        authMsg('success', [
          'Your evaluation template has been successfully uploaded!',
          'Activity Documentation',
        ]);
      } else {
        authMsg('error', [
          'There was a problem with the following rows and columns row: 0 column: before response (1-10) row: 0 column: after response (1-10)',
          'Oops, Something went wrong!',
        ]);
      }
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

// to upload multiple objectives in evaluation plan
export function uploadActivityByMultipleObjectives(
  { projectId, evaluationPlanId },
  formData
) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `/project/${projectId}/eval-plan/${evaluationPlanId}/objectives/document`,
        formData,
        { timeout: 120000 }
      );
      if (isEmpty(response.data.errors)) {
        try {
          Promise.all([
            dispatch(getEvaluationPlans(projectId)),
            dispatch(getProjectOutcomeSummary(projectId)), // get updates to report
          ]);
        } catch (error) {
          // authMsg('error', getCommonError(error));
        }
        authMsg('success', [
          'Your evaluation template has been successfully uploaded!',
          'Activity Documentation',
        ]);
      } else {
        authMsg('error', [
          'There was a problem with the following rows and columns row: 0 column: before response (1-10) row: 0 column: after response (1-10)',
          'Oops, Something went wrong!',
        ]);
      }
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function getBrowseActivities(
  type,
  page = 1,
  keyword = null,
  limit = LIMIT
) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });
      const params = {
        limit,
        page,
      };
      if (keyword) {
        params.keyword = keyword;
      }
      const response = await axios.get(`/organisation/closed-projects`, {
        params,
      });
      dispatch({ type: GET_BROWSE_ACTIVITIES, payload: response.data });
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    } catch (error) {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    }
  };
}

// for saving (in redux store) responses imported by file (CSV)
export function exportEvaluationResponsesByFile(
  type,
  { projectId, evaluationPlanId, objectiveId }
) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });
      const response = await axios.get(
        `/project/${projectId}/eval-plan/${evaluationPlanId}/objective/${objectiveId}/export-evaluation-responses`
      );
      dispatch({
        type: SET_EVALUATION_RESPONSES_BY_FILE,
        payload: response.data,
      });
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    } catch (error) {
      await dispatch({ type: SET_EVALUATION_RESPONSES_BY_FILE, payload: {} });
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function exportEvaluationResponses(
  type,
  { projectId, evaluationPlanId }
) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });
      const response = await axios.get(
        `/project/${projectId}/eval-plan/${evaluationPlanId}/export-evaluation-responses`
      );
      dispatch({
        type: SET_EVALUATION_RESPONSES_CSV,
        payload: response.data,
      });
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    } catch (error) {
      await dispatch({ type: SET_EVALUATION_RESPONSES_CSV, payload: {} });
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getReports(url, type) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: ACTIVITY_GET_REPORTS,
          payload: { type, loading: true },
        });
        const response = await axios.get(url);

        var link = document.createElement('a');
        link.href = response.data.download_link;
        link.download = response.data.download_link.substr(
          response.data.download_link.lastIndexOf('/') + 1
        );
        link.click();

        dispatch({
          type: ACTIVITY_GET_REPORTS,
          payload: { type, loading: false },
        });

        resolve(true);
      } catch (error) {
        dispatch({
          type: ACTIVITY_GET_REPORTS,
          payload: { type, loading: false },
        });
        authMsg('error', getCommonError(error));
        reject(false);
      }
    });
  };
}

export function getReportViaEmail(projectId) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(`project/${projectId}/report/export`);
        if (response && Number(response.status) === 202) {
          resolve(true);
        } else {
          reject(false);
        }
      } catch (error) {
        authMsg('error', getCommonError(error));
        reject(false);
      }
    });
  };
}

export function exportIndividualProject(organisationId) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_INDIVIDUAL_PROJECT_LOADING, payload: true });
      const response = await axios.get(`/account/${organisationId}/projects`);
      dispatch({ type: GET_INDIVIDUAL_PROJECT, payload: response.data });
      dispatch({ type: GET_INDIVIDUAL_PROJECT_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: GET_INDIVIDUAL_PROJECT_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getLinkedActivities(
  id,
  status = 'all',
  sort = 'activityName',
  order = 'asc',
  keyword
) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: LINKED_ACTIVITY_LOADING, payload: true });
      const response = await axios.get(
        `/project/${id}/linked?status=${status}&sort=${sort}&order=${order}${
          keyword ? `&keyword=${keyword}` : ''
        }`
      );
      dispatch(checkCompletionProgress({ linkedActivities: response.data }));
      dispatch({ type: GET_LINKED_ACTIVITIES, payload: response.data });
      dispatch({ type: LINKED_ACTIVITY_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: LINKED_ACTIVITY_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function activateActivity(
  id,
  activityId,
  exist = false,
  isInvited = false
) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: LINKED_ACTIVITY__ACTIVATE_LOADING, payload: true });

        await axios.put(`/project/${id}/linked/activate/${activityId}`, {
          exist,
          isInvited,
        });

        authMsg('success', [
          'Your projected activity has been activated.',
          'Activity ',
        ]);
        dispatch({ type: LINKED_ACTIVITY__ACTIVATE_LOADING, payload: false });
        dispatch(getLinkedActivities(id));
        resolve(true);
      } catch (error) {
        dispatch({ type: LINKED_ACTIVITY__ACTIVATE_LOADING, payload: false });
        authMsg('error', getCommonError(error));
        reject(false);
      }
    });
  };
}

export function updateProjectedActivity(id, activityId, active = false, data) {
  return async (dispatch) => {
    try {
      dispatch({ type: LINKED_ACTIVITY_LOADING, payload: true });
      const response = await axios.put(
        `/project/${id}/linked/user/${activityId}`,
        data
      );
      dispatch(
        checkCompletionProgress({ linkedActivities: response.data }, true)
      );
      if (!active) {
        dispatch(getLinkedActivities(id));
        authMsg('success', [
          'Your projected activity has been updated.',
          'Activity ',
        ]);
      }
      dispatch({ type: LINKED_ACTIVITY_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: LINKED_ACTIVITY_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function changeLinkedActivityOwnership(id, activityId, data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: LINKED_ACTIVITY_ACTIVATED_LOADING,
        payload: true,
      });
      const response = await axios.put(
        `/project/${id}/linked-activity/${activityId}/change-ownership`,
        data
      );
      dispatch({
        type: LINKED_ACTIVITY_ACTIVATED,
        payload: response.data,
      });
      dispatch(checkCompletionProgress({ linkedActivities: response.data }));
      dispatch(getLinkedActivities(id));
      authMsg('success', [
        'Your projected activity has been updated.',
        'Activity ',
      ]);
      dispatch({
        type: LINKED_ACTIVITY_ACTIVATED_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: LINKED_ACTIVITY_ACTIVATED_LOADING,
        payload: false,
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function reInviteLinkActivity(id, activityId) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: LINKED_ACTIVITY__ACTIVATE_LOADING, payload: true });
        await axios.post(
          `/project/${id}/linked/resend-invitation/${activityId}`
        );
        authMsg('success', ['Project Invitation has been sent', 'Activity']);
        dispatch({ type: LINKED_ACTIVITY__ACTIVATE_LOADING, payload: false });
        dispatch(getLinkedActivities(id));
        resolve(true);
      } catch (error) {
        dispatch({ type: LINKED_ACTIVITY__ACTIVATE_LOADING, payload: false });
        authMsg('error', getCommonError(error));
        reject(false);
      }
    });
  };
}

export function inviteToLinkedActivity(id, activityId, data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: LINKED_ACTIVITY_ACTIVATED_LOADING,
        payload: true,
      });
      await axios.post(`/project/${id}/linked/user/invite/${activityId}`, data);
      authMsg('success', ['Project Invitation has been sent', 'Activity']);
      dispatch({
        type: LINKED_ACTIVITY_ACTIVATED_LOADING,
        payload: false,
      });
      dispatch(getLinkedActivities(id));
    } catch (error) {
      dispatch({
        type: LINKED_ACTIVITY_ACTIVATED_LOADING,
        payload: false,
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function activationToggleActivity(id, activityId, form) {
  return async (dispatch) => {
    try {
      dispatch({ type: LINKED_ACTIVITY_UPDATE_LOADING, payload: true });
      const response = await axios.put(
        `/project/${id}/linked/activation-toggle/${activityId}`,
        form,
        {
          timeout: 120000,
        }
      );
      dispatch({ type: LINKED_ACTIVITY_UPDATE_LOADING, payload: false });

      dispatch({ type: GET_LINKED_ACTIVITIES, payload: response.data });
      dispatch(
        checkCompletionProgress({ linkedActivities: response.data }, true)
      );

      authMsg('success', [
        `Your activity has been ${form.status ? 'activated' : 'deactivated'}.`,
        'Linked Activity',
      ]);
    } catch (error) {
      dispatch({ type: LINKED_ACTIVITY_UPDATE_LOADING, payload: true });
      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteProjectedLinkedActivity(id, activityId) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const currentActivity = cloneDeep(state.activity.currentActivity);
      dispatch({ type: LINKED_ACTIVITY_LOADING_DELETE, payload: true });
      const response = await axios.delete(
        `/project/${id}/projected-linked/delete/${activityId}`
      );
      dispatch(getLinkedActivities(currentActivity.id));
      dispatch(
        checkCompletionProgress({ linkedActivities: response.data }, true)
      );
      authMsg('success', ['Projected activity has been deleted.', 'Activity ']);
      dispatch({ type: LINKED_ACTIVITY_LOADING_DELETE, payload: false });
    } catch (error) {
      dispatch({ type: LINKED_ACTIVITY_LOADING_DELETE, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteLinkedActivity(activityId) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const currentActivity = cloneDeep(state.activity.currentActivity);
      const response = await axios.delete(
        `/project/${currentActivity.id}/linked/delete/${activityId}`
      );
      dispatch(getLinkedActivities(currentActivity.id));
      dispatch(
        checkCompletionProgress({ linkedActivities: response.data }, true)
      );
      authMsg('success', ['Linked Activity has been deleted.', 'Activity ']);
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function approvalToggleActivity(id, activityId, status) {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTIVITY_GET_LOADING, payload: true });
      const response = await axios.put(
        `/project/${id}/linked/approval-toggle/${activityId}`,
        {
          status,
        },
        { timeout: 120000 }
      );
      await dispatch({ type: SET_CURRENT_ACT, payload: response.data });
      dispatch({ type: ACTIVITY_GET_LOADING, payload: false });
      authMsg('success', [
        status
          ? 'The linked activity has been accepted'
          : 'The linked activity is re-opened for review',
        'Activity Report',
      ]);
    } catch (error) {
      dispatch({ type: ACTIVITY_GET_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateSelfAssessed(type, evaluation, body, surveySubmit) {
  return async (dispatch) => {
    try {
      const {
        projectId = null,
        evaluationPlanId = null,
        objectiveId = null,
      } = evaluation;
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });
      await axios.put(
        `/project/${projectId}/eval-plan/${evaluationPlanId}/objective/${objectiveId}`,
        body
      );
      // dispatch(getEvaluationPlans(projectId));
      Promise.all([
        dispatch(getEvaluationPlans(projectId)),
        dispatch(getProjectOutcomeSummary(projectId)),
      ]);

      if (surveySubmit) {
        authMsg('success', 'Question completed and saved');
      }
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    } catch (error) {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProgramIncludedReportCharts(projectId) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `/project/${projectId}/program/included-reports`,
        {
          timeout: 120000,
        }
      );
      dispatch({
        type: SET_PROGRAM_INCLUDED_REPORTS,
        payload: response.data,
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function addMultipleProjectOutcomeCharts(projectId, outcomeId, data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_PROGRAM_INCLUDED_REPORTS_LOADING,
        payload: true,
      });
      await axios.post(
        `/project/${projectId}/outcome/${outcomeId}/program-reports`,
        { data }
      );
      dispatch({
        type: SET_PROGRAM_INCLUDED_REPORTS_LOADING,
        payload: false,
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteAllProjectOutcomeCharts(projectId, outcomeId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: DELETE_PROGRAM_INCLUDED_REPORTS_LOADING,
        payload: true,
      });
      await axios.delete(
        `/project/${projectId}/outcome/${outcomeId}/program-reports`
      );
      dispatch({
        type: DELETE_PROGRAM_INCLUDED_REPORTS_LOADING,
        payload: false,
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateOutcomeChart(projectId, body, inReport = false) {
  return async (dispatch) => {
    try {
      const response = await axios.put(
        `/project/${projectId}/outcome-chart`,
        body,
        {
          timeout: 120000,
        }
      );
      await dispatch(setActivityState(response.data));
      if (!inReport) {
        authMsg('success', [
          'Outcome Summary been successfully Updated!',
          'Outcome Summary',
        ]);
      }
    } catch (error) {
      authMsg('error', getCommonError(error));
    }
  };
}

export function postDuplicateActivity(projectId, data) {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: DUPLICATE_ACTIVITY_LOADING, payload: true });

        const response = await axios.post(
          `/project/${projectId}/duplicate`,
          data,
          { timeout: 180000 }
        );
        dispatch({ type: DUPLICATE_ACTIVITY_LOADING, payload: false });
        resolve(response.data.id);
      } catch (error) {
        dispatch({ type: DUPLICATE_ACTIVITY_LOADING, payload: false });
        authMsg('error', getCommonError(error));
        reject(error);
      }
    });
  };
}

export function reInviteWithdrawnMember(id, data) {
  return async (dispatch) => {
    try {
      dispatch({ type: REINVITE_WITHDRAWN_LOADING, payload: true });
      await axios.post(`/project/${id}/re-invite-withdrawn`, data);
      dispatch({ type: REINVITE_WITHDRAWN_LOADING, payload: false });
      dispatch(getActivityMembers(id));
    } catch (error) {
      dispatch({ type: REINVITE_WITHDRAWN_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getUserActivityGroups() {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_PROJECT_GROUP_LOADING, payload: true });
      const response = await axios.get(`/user/project-groups`);
      await dispatch({ type: SET_PROJECT_GROUP, payload: response.data });
      dispatch({ type: SET_PROJECT_GROUP_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_PROJECT_GROUP_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function postActivityGroup(
  projectId,
  values,
  userId,
  filter,
  actType,
  group,
  currentPage
) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ACTIVITY_GROUP_LOADING, payload: true });
      await axios.post(`/project/${projectId}/groups`, values);
      authMsg('success', [
        'Activity group has been successfully updated!',
        'Activity Group',
      ]);
      Promise.all([
        dispatch(
          getOwnActivities(
            filter.owned,
            actType.owned,
            group.owned,
            currentPage
          )
        ),
        dispatch(getUserActivityGroups()),
      ]);
      dispatch({ type: SET_ACTIVITY_GROUP_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ACTIVITY_GROUP_LOADING, payload: false });
      throw error;
    }
  };
}

export function deleteProjectGroup(
  groupId,
  userId,
  filter,
  actType,
  currentPage,
  userOwnedGroupId = null
) {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_PROJECT_GROUP_LOADING, payload: true });
      await axios.delete(`/project-group/${groupId}/delete`);
      authMsg('success', [
        'Group has been successfully deleted!',
        'Project Group',
      ]);

      dispatch(getUserActivityGroups());

      if (userOwnedGroupId && userOwnedGroupId === groupId) {
        dispatch(
          getOwnActivities(filter.owned, actType.owned, 'null', currentPage)
        );
      }

      dispatch({ type: DELETE_PROJECT_GROUP_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: DELETE_PROJECT_GROUP_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function checkCompletionProgress(
  data = {},
  preview = false,
  force = false
) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const currentActivity = cloneDeep(state.activity.currentActivity);
      const setCurrent = Object.assign(currentActivity, data);
      const oldProgress = setCurrent.completionProgress;
      let newProgress;
      try {
        newProgress = projectValidation(setCurrent).completionProgress;
      } catch (error) {
        newProgress = oldProgress;
      }

      if (!isEmpty(data)) {
        await dispatch(setActivityState(setCurrent));
      }
      if (!isEqual(newProgress, oldProgress) || force) {
        if (!preview) {
          dispatch(
            putActivityStatus(setCurrent.id, {
              completionProgress: newProgress,
            })
          );
        }
      }
    } catch (error) {
      // console.error('putActivity projectValidation-> data error', error);
    }
  };
}

export function updateProjectGroupRename(groupId, values, userId) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_PROJECT_GROUP_RENAME_LOADING, payload: true });
      await axios.put(`/project/${groupId}/group`, values);
      authMsg('success', [
        'Your project group name has been successfully updated!',
        'Project Group Name',
      ]);
      await dispatch(getUserActivityGroups());
      dispatch({
        type: SET_PROJECT_GROUP_RENAME_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: SET_PROJECT_GROUP_RENAME_LOADING,
        payload: false,
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function putActivityStatus(activityId, data) {
  return async (dispatch) => {
    await axios
      .put(`/project/${activityId}`, {
        data,
      })
      .then(async function (response) {
        await dispatch(setActivityState(response.data));
      })
      .catch(function (error) {
        authMsg('error', getCommonError(error));
      });
  };
}

export function getEvaluationComment(projectId, evaluationPlanId) {
  return async (dispatch, getState) => {
    const state = getState();

    const evalComment = cloneDeep(state.activity.evalComments);
    try {
      dispatch({ type: GET_EVAL_COMMENTS_DATA_LOADING, payload: true });
      const response = await axios.get(
        `/project/${projectId}/eval-plan/${evaluationPlanId}/evaluation-comments`
      );
      dispatch({
        type: GET_EVAL_COMMENTS_DATA,
        payload: { ...evalComment, [evaluationPlanId]: response.data },
      });
      dispatch({ type: GET_EVAL_COMMENTS_DATA_LOADING, payload: false });
    } catch (error) {
      authMsg('error', getCommonError(error));
      dispatch({ type: GET_EVAL_COMMENTS_DATA_LOADING, payload: false });
    }
  };
}

export function findSystemUser(email) {
  return async (dispatch) => {
    try {
      if (email === '') {
        authMsg('Please enter email');
      }

      dispatch({ type: FIND_SYSTEM_USER_LOADING, payload: true });
      const response = await axios.get(`/users/find`, {
        params: {
          email,
        },
      });

      dispatch({ type: FIND_SYSTEM_USER, payload: response.data });
      dispatch({ type: FIND_SYSTEM_USER_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: FIND_SYSTEM_USER, payload: {} });
      dispatch({ type: FIND_SYSTEM_USER_LOADING, payload: false });

      // authMsg('error', getCommonError(error));
    }
  };
}

export function findInvitedUser(email) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (email === '') {
          throw new Error('Please enter email');
        }
        dispatch({ type: FIND_SYSTEM_USER_LOADING, payload: true });
        const response = await axios.get(`/invited/user/find`, {
          params: {
            email,
          },
        });

        dispatch({ type: FIND_SYSTEM_USER, payload: response.data });
        dispatch({ type: FIND_SYSTEM_USER_LOADING, payload: false });
        resolve(response.data ? response.data : {});
      } catch (error) {
        dispatch({ type: FIND_SYSTEM_USER, payload: {} });
        dispatch({ type: FIND_SYSTEM_USER_LOADING, payload: false });
        reject({});
        // authMsg('error', getCommonError(error));
      }
    });
  };
}

export function postProjectCrossReferences(projectId, data) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: SET_PROJECT_CROSS_REF_LOADING, payload: true });

        await axios.post(`/project/${projectId}/cross-reference`, data);

        // get current activity data / update data
        const response = await axios.get(`/project/${projectId}`);

        await dispatch({ type: SET_CURRENT_ACT, payload: response.data });

        dispatch({ type: SET_PROJECT_CROSS_REF_LOADING, payload: false });

        resolve(true);
        authMsg('success', [
          'Your project references have been successfully updated!',
          'Project References',
        ]);
      } catch (error) {
        dispatch({ type: SET_PROJECT_CROSS_REF_LOADING, payload: false });
        reject(false);
        authMsg('error', getCommonError(error));
      }
    });
  };
}

export function updateEvaluationSelfAssess(projectId, evaluationId, data) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_EVALUATION_SELF_ASSESS_LOADING, payload: true });

      const response = await axios.put(
        `/evaluation/${evaluationId}/self-assess`,
        { projectId, ...data }
      );

      if (response.status === 200) {
        Promise.all([
          await dispatch(getProjectOutcomeSummary(projectId)), // get updates to report
          await dispatch(getEvaluationPlans(projectId)),
        ]);
      }

      dispatch({ type: SET_EVALUATION_SELF_ASSESS_LOADING, payload: false });

      // authMsg('success', [
      //   'Evaluation self assess response have been successfully updated!',
      //   'Project Evaluation',
      // ]);
    } catch (error) {
      dispatch({ type: SET_EVALUATION_SELF_ASSESS_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProjectPartner(type, projectId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });
      const response = await axios.get(`/project/${projectId}/partners`);
      dispatch(setActivityState({ partners: response.data }));
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    } catch (error) {
      authMsg('error', getCommonError(error));
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    }
  };
}

export function assignProjectPartner(type, projectId, formData) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });

      const partnerData = get(formData, 'partner', {});
      if (!isEmpty(partnerData)) {
        // deleting organisation id & createdBy  from payload
        formData.partner.hasOwnProperty('organisationId') &&
          delete formData.partner.organisationId;
        formData.partner.hasOwnProperty('createdBy') &&
          delete formData.partner.createdBy;
      }

      await axios.post(`/project/${projectId}/partner`, formData);
      authMsg('success', [
        'Project partner has been successfully added!',
        'Project Partner',
      ]);
      dispatch(getProjectPartner(type, projectId));
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    } catch (error) {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function deleteProjectPartner(type, projectId, partnerId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });
      await axios.delete(`/project/${projectId}/partner/${partnerId}/delete`);
      authMsg('success', [
        'Project partner has been successfully deleted!',
        'Project Partner',
      ]);
      dispatch(getProjectPartner(type, projectId));
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
    } catch (error) {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function updateProjectPartner(
  type,
  projectId,
  partnerId,
  formData,
  showNotification = false
) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: true },
      });
      await axios.put(`/project/${projectId}/partner/${partnerId}`, formData);
      dispatch(getProjectPartner(type, projectId));
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      if (showNotification) {
        authMsg('success', [
          'Project partner description has been successfully updates!',
          'Project Partner',
        ]);
      }
    } catch (error) {
      dispatch({
        type: ACTIVITY_PUT_LOADING,
        payload: { type, status: false },
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getProjectNotes(projectId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_PROJECT_NOTE_LOADING,
        payload: true,
      });
      const state = getState();
      const currentActivity = cloneDeep(state.activity.currentActivity);
      const response = await axios.get(`/project/${projectId}/notes`);

      await dispatch({
        type: SET_CURRENT_ACT,
        payload: {
          ...currentActivity,
          projectNotes: response.data,
        },
      });

      dispatch({
        type: GET_PROJECT_NOTE_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: GET_PROJECT_NOTE_LOADING,
        payload: false,
      });
      authMsg('error', getCommonError(error));
    }
  };
}

export function postProjectNote(projectId, formData) {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: SET_PROJECT_NOTE_LOADING,
          payload: true,
        });
        await axios.post(`/project/${projectId}/note`, formData);

        // get project  notes
        dispatch(getProjectNotes(projectId));

        dispatch({
          type: SET_PROJECT_NOTE_LOADING,
          payload: false,
        });
        // if (showNotification) {
        authMsg('success', [
          'Project note has been successfully created!',
          'Project Notes',
        ]);
        // }
        resolve(true);
      } catch (error) {
        dispatch({
          type: SET_PROJECT_NOTE_LOADING,
          payload: false,
        });
        authMsg('error', getCommonError(error));
        reject();
      }
    });
  };
}
export function putProjectNote(projectId, formData) {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: SET_PROJECT_NOTE_LOADING,
          payload: true,
        });
        await axios.put(`/project/${projectId}/note`, formData);
        // dispatch(getProjectPartner(type, projectId));
        dispatch({
          type: SET_PROJECT_NOTE_LOADING,
          payload: false,
        });

        authMsg('success', [
          'Project note has been successfully created!',
          'Project Notes',
        ]);

        // get project  notes
        dispatch(getProjectNotes(projectId));

        resolve(true);
      } catch (error) {
        dispatch({
          type: SET_PROJECT_NOTE_LOADING,
          payload: false,
        });
        authMsg('error', getCommonError(error));
        reject();
      }
    });
  };
}

export function deleteProjectNote(projectId, noteId) {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: DELETE_PROJECT_NOTE_LOADING,
          payload: true,
        });
        await axios.delete(`/project/${projectId}/note/${noteId}`);

        // get project  notes
        dispatch(getProjectNotes(projectId));

        dispatch({
          type: DELETE_PROJECT_NOTE_LOADING,
          payload: false,
        });
        authMsg('success', [
          'Project note has been successfully deleted!',
          'Project Notes',
        ]);
        resolve(true);
      } catch (error) {
        dispatch({
          type: DELETE_PROJECT_NOTE_LOADING,
          payload: false,
        });
        authMsg('error', getCommonError(error));
        reject();
      }
    });
  };
}

export function assignChartTypeToActivity(reportCharts) {
  return async (dispatch, getState) => {
    const state = getState();
    const currentActivity = cloneDeep(state.activity.currentActivity);

    dispatch({
      type: SET_CURRENT_ACT,
      payload: { ...currentActivity, reportCharts },
    });
  };
}
