import './auth.scss';

import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Dimmer, Grid, Loader } from 'semantic-ui-react';
import { get } from '../../utils/lodash';
import { SuccessContainer } from '../../components';
import { APP_NAME } from '../../utils/constant';
import { checkMailVerified } from '../../store/actions';
import { EMAIL_VERIFICATION_STATUS } from '../../utils/constant';
class MailVerification extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = `Email Verification | ${APP_NAME}`;
    const {
      checkMailVerified,
      match: {
        params: { token = null },
      },
    } = this.props;
    checkMailVerified(token);
  }

  render() {
    const { emailVerifyLoading, emailVerify } = this.props;
    let emailVerificationStatus;
    if (emailVerify) {
      emailVerificationStatus = EMAIL_VERIFICATION_STATUS.find(
        (entry) => entry.status === emailVerify.status
      );
    }

    if (!emailVerificationStatus) {
      return <></>;
    }

    const emailStatus = get(emailVerificationStatus, 'status', null);

    return (
      <Grid.Row>
        <Container className="email-verification-container">
          <Grid>
            <Grid.Row>
              {emailVerifyLoading && !emailVerificationStatus ? null : (
                <SuccessContainer
                  noBgEffects
                  header={get(emailVerificationStatus, 'header', null)} //message
                  msg={get(emailVerificationStatus, 'message', null)}
                  description={get(
                    emailVerificationStatus,
                    'description',
                    null
                  )}
                  linkExpired={emailStatus === 'REQUEST_INVALID'}
                  success={emailStatus !== 'REQUEST_INVALID'}
                  button={
                    emailStatus !== 'REQUEST_INVALID'
                      ? 'Return to Takso'
                      : 'Return to Sign in'
                  }
                  buttonRoute="/dashboard"
                  overRideBtn={emailStatus !== 'REQUEST_INVALID'}
                />
              )}
            </Grid.Row>
          </Grid>
          <Dimmer active={emailVerifyLoading} inverted>
            <Loader content="Loading" />
          </Dimmer>
        </Container>
      </Grid.Row>
    );
  }
}

const mapStateToProps = (state) => {
  const { emailVerify, emailVerifyLoading } = state.authentication;
  return {
    emailVerify,
    emailVerifyLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      checkMailVerified,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MailVerification);
